import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import NormalCarousel from "../../../Atoms/Carousel/Carousel";

function AdPanel(props) {
    const { t, adsList } = props;
    
    return (
        <Grid
            item
            md={8}
            style={{
                height: "100px",
                borderRadius: "16px"
            }}
        >
            {adsList?.length > 0 &&
                <NormalCarousel
                    items={adsList}
                    type='ads'
                    showThumbs={false}
                    autoPlay={'true'}
                    interval='2000'
                    infiniteLoop={true}
                />
            }
        </Grid>
    );
}

export default withTranslation()(AdPanel);