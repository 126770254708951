import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Menu, Typography } from "@mui/material";
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../../../Assets/images/IconHelper";
import SelectBox from "../../../../../Atoms/Select/Select";
import TextFieldInput from "../../../../../Atoms/Input/TextField/TextFieldInput";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { updateTelemedicineSettingsAPI } from "../../../../../../Contexts/API/DoctorProfile/telemedicine";
import Loader from "../../../../../Atoms/Loader/Loader";
import { getPriceLimitsAPI } from "../../../../../../Contexts/API/DoctorProfile/telemedicine";

function EditSettings (props) {
    const { t } = props;
    const lang = localStorage.getItem('lang')
    const [durationList, setDurationList] = useState([]);
    const [priceLimit, setPriceLimit] = useState([]);
    const [limits, setLimits] = useState([]);
    const [openLoader, setLoader] = useState(false);
    const [limitError, setLimitError] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    const getDurationList = () => {
        props.data.map((duration) => (
            setDurationList(durationList => [...durationList, {name: duration.duration + t("TelemedicineSettings.min"), id: duration.duration}])
        ))
    }
    const getSelectValue = (val) => {
        const priceLimitObj = props.data.filter((item) => {
            return val === item.duration
        })
        setPriceLimit(priceLimitObj);
    };

    const getLimits = async () => {
        const res = await getPriceLimitsAPI("personal_telemedicine");
        if (res) setLimits(res?.data?.price_limits)
    }

    useEffect(() => {
        getDurationList();
        getLimits()
    }, [])

    const editSettings = async (data) => {
        if (parseFloat(priceLimit[0]?.lower_limit) <= parseFloat(data.fees_SAR) && parseFloat(data.fees_SAR) <= parseFloat(priceLimit[0]?.upper_limit)){
            setLoader(true);
            const res = await updateTelemedicineSettingsAPI('personal_telemedicine', data);
            if (res) props.updateEditView(false, 'edit', data);
            setLoader(false);
        }else{
            setLimitError(true);
        }
    }

    const SettingsIntialValues = {
        duration: "",
        price: "",
    };

    const SettingsValidationSchema = yup.object({
        duration: yup.string().required(t("Validation.required_field")),
        price: yup.string().required(t("Validation.required_field"))
    })
    
    const formik = useFormik({
        initialValues: SettingsIntialValues,
        validationSchema: SettingsValidationSchema,
        onSubmit: (values) => {
            editSettings({
                'duration': values.duration,
                'fees_SAR': values.price
            })
        }
    })
    const handleEdit = (editFlag, data) => {
        if(data === 'edit') {
            formik.handleSubmit();
        }
        props.updateEditView(editFlag, data);
    }

    return (
        <Grid container className="edit-settings-container" >
            <Loader open={openLoader} />
            <Grid xs={12}>
                <Typography 
                    component="h3"
                    style={{fontSize: "20px", fontWeight: "bold"}}
                    className="mb-20px"
                >
                    {t("TabsOptions.settings")}
                </Typography>
                <div className="select-box">
                    <SelectBox
                        id="duration"
                        name="duration" 
                        items={durationList} 
                        title={t("DoctorProfile.duration")}
                        label={t("DoctorProfile.duration")}
                        labelStyle="select-label"
                        onchange={formik.handleChange}
                        value={formik.values.duration}
                        error={formik.touched.duration && Boolean(formik.errors.duration)}
                        helperText={formik.touched.duration && formik.errors.duration}
                        onclick={(val) => getSelectValue(val)}
                    />
                </div>
                <TextFieldInput
                    id="price"
                    name="price"
                    className="mb-20px"
                    placeholder={t("DoctorProfile.prices")}
                    label={t("DoctorProfile.prices")}
                    labelStyle="field-label"
                    onchange={formik.handleChange}
                    value={formik.values.price}
                    error={(formik.touched.price && Boolean(formik.errors.price)) || limitError}
                    helperText={
                        (formik.touched.price && formik.errors.price) ||
                        (limitError && t("DoctorProfile.set_fees") + priceLimit[0]?.lower_limit + t("TelemedicineSettings.sar") + t("DoctorProfile.to") + priceLimit[0]?.upper_limit + t("TelemedicineSettings.sar"))
                    }
                    EndAdornment={
                        <PrimaryButton
                            btnType="icon"
                            startIcon={<img
                                src={icons.Information}
                                alt='fees information'
                            />}
                            aria_controls={open ? 'basic-menu' : undefined}
                            aria_haspopup="true"
                            aria_expanded={open ? 'true' : undefined}
                            click={handleClick}
                        />
                    }
                />
                <Typography component="p">
                    { priceLimit[0] && (t("DoctorProfile.set_fees") + priceLimit[0]?.lower_limit + t("TelemedicineSettings.sar") + t("DoctorProfile.to") + priceLimit[0]?.upper_limit + t("TelemedicineSettings.sar"))}
                </Typography>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ direction: lang === 'ar' ? 'rtl' : 'ltr', boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.35)' }}
                >
                    <Grid container sx={{ width: lang === 'ar' ? '370px' : "650px", paddingX: '15px', borderRadius: '18px' }}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#369faf' }}>
                                {t("DoctorProfile.prices")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={2} mt={1}>
                            <Typography sx={{ fontSize: '14px', color: '#909090' }}>
                                {t("DoctorProfile.set_fee")}
                            </Typography>
                        </Grid>
                        {limits?.length > 0 && limits?.map(limit => {
                            return (
                                <>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize: '15px', color: '#878787' }}>
                                            {`${limit?.duration} ${t("TelemedicineSettings.min")}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#393939' }}>
                                            {`${t("DoctorProfile.between")} ${limit?.lower_limit} ${t("DoctorProfile.to")} ${limit?.upper_limit} ${t("TelemedicineSettings.sar")}`}
                                        </Typography>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Menu>
            </Grid>
            <Grid container className="settings-btns-container">
                <Grid xs={5.5}>
                    <PrimaryButton
                        classBtn="settings-edit-btn" 
                        text={t("DoctorProfile.save_btn")} 
                        click={() => handleEdit(true, 'edit')}
                    />
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={5.5}>
                    <PrimaryButton
                        classBtn="settings-cancel-btn" 
                        text={t("DoctorProfile.cancel_btn")} 
                        click={() => handleEdit(false, 'cancel')}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(EditSettings);