import ContactUsTemplate from '../../../Templates/Main/ContactUs/ContactUsTemplate'
import { ContactUsAPI } from '../../../Contexts/API/General/ContactUsApi.js'
import { toast } from 'react-toastify'

const ContactUsPage = () => {

    const SendMessage = async (data) => {
        const {res,err} = await ContactUsAPI(data)
        if (res) return res
        if(err) toast.error(err)
    }

    return <ContactUsTemplate SendMessage={SendMessage} />

}

export default ContactUsPage