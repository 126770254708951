import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import ServiceOptions from "../../../../Molecules/Home/UserHome/ServiceOptions";
import UserPersonalInfo from "../../../../Molecules/Home/UserHome/UserPersonalInfo";
import { useState } from "react";

const TelemedicineSettings = (props) => {
    const { t, profileObj, updateProfileCallback, getProfileData } = props;
    const [doctorGender, setDoctorGender] = useState("");
    const [serviceType, setServiceType] = useState("");
    const getServiceOptions = (doctorGender, serviceType) => {
        setDoctorGender(doctorGender);
        setServiceType(serviceType);
    };

    return (
        <Grid container className="telemedicine-settings-container">
            <Typography component="h1">{t("UserProfile.telemedicine")}</Typography>
            <Grid container md={12} className="telemedicine-settings-body">
                <Grid xs={11} style={{margin: "auto", padding: "1.5rem 0"}}>
                    <ServiceOptions getServiceOptions={(doctorGender, serviceType) => getServiceOptions(doctorGender, serviceType)}/>
                </Grid>
                <Divider style={{width: "100%"}}/>
                <Grid xs={11} style={{margin: "auto", padding: "1.5rem 0"}}>
                    <UserPersonalInfo 
                        serviceType={serviceType} 
                        doctorGender={doctorGender}
                        profileObj={profileObj}
                        updateProfileCallback={updateProfileCallback}
                        getProfileData={getProfileData} 
                    />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withTranslation()(TelemedicineSettings);