import UserWalletTemplate from "../../../Templates/Main/Wallet/UserWalletTemplate";
import { GetWalletInfoAPI, depositWalletToAccountAPI, getPackagesAPI, subscripeToPackageAPI } from "../../../Contexts/API/Wallet/Wallet";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { toast } from "react-toastify";

const UserWalletPage = ({t}) => {
    const [walletInfo, setWalletInfo] = useState({});
    const [packages, setPackages] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openLoader, setOpenLoader] = useState(false);

    const getWallet = async () => {
        setOpenLoader(true);
        const res = await GetWalletInfoAPI();
        if(res) setWalletInfo(res?.data);
        setOpenLoader(false);
    };
    const getPackages = async () => {
        setOpenLoader(true);
        const res = await getPackagesAPI();
        if(res) setPackages(res?.data?.packages);
        setOpenLoader(false);
    };
    const depositToAccount = async () => {
        setOpenLoader(true);
        const { err, res } = await depositWalletToAccountAPI();
        if(res) {
            getWallet();
            toast.success(t("Wallet.message"))
        }
        if(err) toast.error(err)
        setOpenLoader(false);
    }

    const subscripePackage = async (id) => {
        setOpenLoader(true);
        const res = await subscripeToPackageAPI(id);
        if(res) {
            localStorage.removeItem("packageId");
            searchParams.delete('status');
            setSearchParams(searchParams);
            getWallet();
        }
        setOpenLoader(false);
    }
    useEffect(() => {
        if(searchParams.get("status")){
            if (searchParams.get("status") === "paid"){
                subscripePackage(localStorage.getItem("packageId"));
            }
        }
    }, [searchParams])

    useEffect(() => {
        getWallet();
        getPackages();
    }, [])

    return (
        <>
            <Loader open={openLoader} />
            <UserWalletTemplate 
                walletInfo={walletInfo}
                depositToAccount={depositToAccount}
                packages={packages}
            />
        </>
    )
}

export default UserWalletPage