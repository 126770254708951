import { withTranslation } from "react-i18next";
import EditMsg from "../../../../Atoms/FlashMessages/EditMsg";
import FileInput from "../../../../Atoms/Input/FileInput/FileInput";
import { icons, images } from "../../../../../Assets/images/IconHelper";
import { Grid } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import { getPresignedLinkAPI, uploadFileAPI } from "../../../../../Contexts/API/General/upload";
import { useState, useEffect } from "react";
import { deleteFileAPI } from "../../../../../Contexts/API/DoctorProfile/doctorCertificates";
import Loader from "../../../../Atoms/Loader/Loader";
import { toast } from "react-toastify";

function EditCertificates(props) {
  const { t } = props;
  const [certificatesList, setCertificateList] = useState(props.images);
  const [localCertificatesList, setlocalCertificatesList] = useState([]);

  const [openLoader, setLoader] = useState(false);

  const uploadCertificates = async (event) => {
    const file = event.target.files[0];
    if (file.type.indexOf("image") !== -1 || file.type.indexOf("pdf") !== -1) {
      const extension = [{ "extension": file.type.split("/")[1] }];
      setLoader(true);
      const res = await getPresignedLinkAPI(extension);
      const presignedUrl = res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        getCertificatesUrls()
        setlocalCertificatesList([...localCertificatesList, res?.data[0]?.file_url]);
        //setCertificateList(upload?.data?.user?.medical_provider?.certificates);

      }
      setLoader(false);
    } else toast.error(t("EditUserProfile.pdf"));
  };
  const removeCertificate = async (id, index) => {
    var array = [...localCertificatesList];
    if (index !== -1) {
      if (id == undefined) {
        array.splice(index, 1);
        setlocalCertificatesList(array);
      } else {
        setLoader(true);
        const deleteRes = await deleteFileAPI(id);
        setCertificateList(deleteRes?.data.certificates);
        setLoader(false);
      }
    }
  };
  const getCertificatesUrls = () => {
    var urls = [];
    props?.images?.map((image) => {
      urls.push(image?.url);
    })
    setlocalCertificatesList(urls);
  };

  useEffect(() => {
    props.updateCallback(localCertificatesList);
  }, [localCertificatesList])

  return (
    <div>
      <h3 className="mb-20px">{t("DoctorProfile.edit_certificate")}</h3>
      <EditMsg className="edit-flash-msg mb-20px" message={t("FlashMessages.edit_msg")} />
      <Grid container>
        <Loader open={openLoader} />
        <Grid xs={3}>
          <FileInput
            icon={images.UploadCertificate}
            btnStyle="upload-certificates mb-20px"
            change={(e) => uploadCertificates(e)}
            accept="image/*,application/pdf"
          />
        </Grid>
        {certificatesList?.map((item, index) => (
          <Grid xs={3}>
            <div className="edit-certificate-view mb-20px">
              <PrimaryButton
                startIcon={<ClearIcon />}
                btnType="icon"
                classBtn="close-icon"
                click={() => removeCertificate(item.certificate_id, index)}
              />
              {item.url.includes("pdf") ?
                <img src={icons.PDF} /> :
                <img src={item.url} />
              }
            </div>
          </Grid>
        ))}
        {localCertificatesList?.map((item, index) => (
          <Grid xs={3}>
            <div className="edit-certificate-view mb-20px">
              <PrimaryButton
                startIcon={<ClearIcon />}
                btnType="icon"
                classBtn="close-icon"
                click={() => removeCertificate(item?.certificate_id, index)}
              />
              {item.includes("pdf") ?
                <img src={icons.PDF} /> :
                <img src={item} />
              }
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withTranslation()(EditCertificates);