// export const BASE_URL = "https://stagingapi.medvalley.co/api/v1";
// export const BASE = "https://stagingapi.medvalley.co";
// export const BASE_URL =
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_DEV_MODE
//     : window.location.origin === "https://development.medvalley.co"
//     ? process.env.REACT_APP_DEV_MODE
//     : process.env.REACT_APP_PRO_MODE;

export const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log(BASE_URL);
export const AUTH_LOGIN = "/auth/users_login";
export const AUTH_SIGNUP = "/auth/register";
export const AUTH_VALIDATE = "/auth/validate";
export const MEDICAL_TITLES = "/medical_titles";
export const SPECIALITIES = "/specialities";
export const VERIFY = "/auth/messages/verify_otp?code_scope=verification";
export const VERIFY_PHONE = "/user/settings/verify_phone";
export const DOCTOR_CERTIFICATES = "/medical_provider/get_certificates";
export const PRESIGNED_URL = "/presigned_url";
export const USER_STR = "/user/";
export const PROFILE_STR = "/profile";
export const PROFILES_STR = "profiles";
export const DIAGNOSES_STR = "/diagnoses/";
export const PRESCRIPTIONS_STR = "/prescriptions/";
export const MEDICAL_SERVICES = "/medical_services/";
export const PRICE_LIMITS = "/price_limits";
export const TIME_INTERVALS = "/time_intervals/";
export const DELETE_CERTIFICATE = "/user/profiles/delete_certificate";
export const ADD_CERTIFICATE = "/user/profiles/add_certificates";
export const GET_USER_RESERVATIONS = "/profile/patient_reservations";
export const GET_PASSWORD_RESET_OTP = "/auth/messages/send_verification_message";
export const GET_VERIFICATION_CODE = "/auth/messages/send_verification_message?code_scope=verification";
export const RESET_PASSWORD = "/auth/messages/verify_otp";
export const CHANGE_PASSWORD = "/auth/passwords/reset_password";
export const DOCTOR_RESERVATIONS = "/doctor_reservations";
export const SUBSPECIALITIES = "/subspecialities";
export const MEDICINE_INTAKE_METHODS = "/lookups/medicine_intake_methods";
export const MEDICINE_DOSAGE_PERIODS = "/lookups/medicine_dosage_periods";
export const EXAMINATIONS = "/examinations/";
export const DOCTORS = "/doctors/";
export const MEDICAL_PROVIDER = "/medical_provider/";
export const GET_SERVICE_INTERVALS = "/get_service_intervals";
export const RESERVE = "/reserve";
export const INDEX_INSTITUTES = "/index_institutes";
export const SURGERIES = "/surgeries";
export const PATIENTS = "/patients/";
export const PRIVACY_POLICY = "/lookups/privacy_policy";
export const LIFESTYLE_QUESTIONS = "/lifestyle_questions";
export const USER_LIFESTYLE = "/user_lifestyle";
export const ALLERGIES = "/allergies";
export const DELETE_ACCOUNT = "/user/settings/delete_user";
export const PASSWORD_CHANGE = "/auth/passwords/change_password";
export const UPDATE_PHONE = "/user/settings/update_phone";
export const CONTACT_US = "/user/contact_us";
export const ABOUT_US = "/lookups/about_us";
export const OFFERS_LIST = "/offers/";
export const CHECK_STATE = "/check_state";
export const MEDICINE_DURATIONS = "/lookups/medicine_durations";
export const DOCTOR_RESERVATIONS_SUMMARY = "/doctor_reservations_summary";
export const SYMPTOMS = "/symptoms";
export const NOTIFICATIONS = "/notifications";
export const UNSEEN_COUNT = "/unseen_count";
export const PDF = "/pdf";
export const SETTINGS = "settings";
export const GET_WALLET = "/get_wallet";
export const DEPOSIT_WALLET = "/deposit_wallet";
export const PACKAGES = "/packages";
export const PAYMENT = "/payments";
export const GET_PAYMENT_INFO = "/get_payment_info";
export const SUBSCRIPE = "/subscripe";
export const ACCEPT_START_EARLY = "/accept_start_early";
export const REJECT_START_EARLY = "/reject_start_early";
export const RESERVATIONS = "/reservations/";
export const DISEASES = "/diseases";
export const MEDICINES = "/lookups/medicines";
export const ADS = "/lookups/list_of_ads";
export const LIST_SERVICE_TYPES_OFFERS = "/list_service_types";
export const TERMS_CONDITIONS = "/lookups/terms_and_conditions";
export const CHECK_PHONE = "/auth/passwords/check_phone";
export const LOGOUT = "/auth/logout";
export const BATCH_LOGOUT = "/auth/batch_logout";
