import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function getDoctorMainInfo(id) {
  return await axiosFn("get", URL.USER_STR + id + URL.PROFILE_STR)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getDoctorMainInfoAPI(id = localStorage.getItem("userId")) {
  let { err, res } = await getDoctorMainInfo(id);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function editProfile(data) {
  return await axiosFn("put", URL.USER_STR + URL.PROFILES_STR, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function editProfileAPI(data) {
  let { res,err } = await editProfile(data);
  if (res) {
    return {
      res,
      err: null,
    };
  }
  if (err) {
    return {
      res: null,
      err,
    };
  }
}