import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";
import { Button, Link, Typography } from "@mui/material";

function PrivacyPolicyModal(props) {
  const { t } = props;
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const handleClickOpen = (val) => {
    setDialogTitle(val === 0 ? 'Privacy Policy' : 'Terms of use')
    setContent(val === 0 ? props.policy : props.terms)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link className="Link" onClick={() => handleClickOpen(0)}>
        {t("Signup.privacy")}
      </Link>
      {t("Signup.and")}
      <Link onClick={() => handleClickOpen(1)} className="Link">
        {" "}
        {t("Signup.terms")}{" "}
      </Link>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          {ReactHtmlParser(content) }
          {/* <h4>
            {" "}
            <b> Privacy</b>
          </h4>
          <p>
            This page is used to inform website visitors regarding our policies
            with the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection
            and use of information in relation with this policy. The Personal
            Information that we collect are used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at MedValley, unless
            otherwise defined in this Privacy Policy.
          </p>
          <h4>
            <b>Information Collection and Use</b>
          </h4>
          <p>
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, email and phone number. The
            information that we collect will be used to contact or identify you.
          </p>
          <h4>
            <b>Log Data</b>
          </h4>
          <p>
            We want to inform you that whenever you visit our Service, we
            collect information that your browser sends to us that is called Log
            Data. This Log Data may include information such as your computer's
            Internet Protocol ("IP") address, browser version, pages of our
            Service that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
          </p>
          <h4>
            <b>Security</b>
          </h4>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <h4>
            <b>Links to Other Sites</b>
          </h4>
          <p>
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
          <h4>
            <b>Changes to This Privacy Policy</b>
          </h4>
          <p>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </p> */}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withTranslation()(PrivacyPolicyModal);
