import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton";
import SelectBox from "../../../../../Atoms/Select/Select";
import { DaysList, Duration } from "../../../../../../Contexts/API/Helper/Helper";
import { useState, useEffect } from "react";
import { getAvailableTelemedicineAPI, sendTimesIntervalsTelemedicineAPI } from "../../../../../../Contexts/API/DoctorProfile/telemedicine";
import { toast } from "react-toastify";
import Loader from "../../../../../Atoms/Loader/Loader";
import { convertTimeFormate } from "../../../../../../Contexts/API/Helper/Helper";

function NewAppointment(props) {
    const { t } = props;
    const [dayValue, setDayValue] = useState('');
    const [intervalValue, setIntervalValue] = useState('');
    const [intervalsList, setIntervalsList] = useState([]);
    const [appointmentsList, setAppointmentsList] = useState([]);
    const [openLoader, setLoader] = useState(false);
    const [activeClass, setActiveClass] = useState([]);
    const handleEdit = async (editFlag, action) => {
        if (action === 'Add') {
            if (dayValue === '') {
                toast.error(t("Validation.day"))
            } else if (intervalValue === '') {
                toast.error(t("Validation.interval"))
            } else if (appointmentsList.length === 0) {
                toast.error(t("Validation.duration"))
            } else {
                setLoader(true);
                const res = await sendTimesIntervalsTelemedicineAPI('personal_telemedicine', appointmentsList);
                if (res) props.updateAddView(editFlag, action);
                setLoader(false);
            }
        } else {
            props.updateAddView(editFlag, action);
        }
    }
    useEffect(() => {
        if (dayValue && intervalValue) {
            getAvailableTelemedicineIntervals();
        }
    }, [dayValue, intervalValue, appointmentsList])

    const getAvailableTelemedicineIntervals = async () => {
        let data = {
            day: dayValue,
            period: intervalValue
        }
        const res = await getAvailableTelemedicineAPI('personal_telemedicine', data);
        if (res) setIntervalsList(res?.data?.available_intervals);
    }
    const selectAppointmentOption = (i, start_time, end_time, day) => {
        if (activeClass.includes(i)) {
            const array = [...activeClass]
            const list = [...appointmentsList]
            const index = array.indexOf(i)
            array.splice(index,1)
            list.splice(index,1)
            setActiveClass(array)
            setAppointmentsList(list)
        } else {
            setActiveClass(activeClass => [...activeClass, i]);
            setAppointmentsList(appointmentsList => [...appointmentsList, {
                day: day,
                start_time: start_time,
                end_time: end_time
            }])
        }
    }
    
    const handleSelectChange = (indecator, e) => {
        if (indecator === 'day') setDayValue(e.target.value);
        if (indecator === 'interval') setIntervalValue(e.target.value);
    }

    return (
        <Grid container className="edit-settings-container">
            <Loader open={openLoader} />
            <Grid xs={12}>
                <Typography
                    component="h3"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                    className="mb-20px"
                >
                    {t("DoctorProfile.add_appointment")}
                </Typography>
                <div className="select-box">
                    <SelectBox
                        id="day"
                        name="day"
                        items={DaysList}
                        title={t("DoctorProfile.day")}
                        label={t("DoctorProfile.day")}
                        labelStyle="select-label"
                        onchange={(e) => handleSelectChange('day', e)}
                        value={dayValue}
                    />
                </div>
                <div className="select-box">
                    <SelectBox
                        id="interval"
                        name="interval"
                        items={Duration}
                        title={t("DoctorProfile.duration")}
                        label={t("DoctorProfile.duration")}
                        labelStyle="select-label"
                        onchange={(e) => handleSelectChange('interval', e)}
                        value={intervalValue}
                    />
                </div>
            </Grid>
            <Grid
                container
                className="appointments-options-container"
            >
                {intervalsList?.map((item, index) => (
                    <Grid xs={4} className="mb-20px" key={index}>
                        <PrimaryButton
                            classBtn={`${"appointment-option"} ${activeClass.includes(index) ? "active-appointment" : ''}`}
                            text={t("DoctorProfile.from") + convertTimeFormate(item?.start_time) + t("DoctorProfile.to") + convertTimeFormate(item?.end_time)}
                            click={() => selectAppointmentOption(index, item?.start_time, item?.end_time, item?.day)}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container className="settings-btns-container">
                <Grid xs={5.5}>
                    <PrimaryButton
                        classBtn="settings-edit-btn"
                        text={t("DoctorProfile.add_btn")}
                        click={() => handleEdit(false, 'Add')}
                    />
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={5.5}>
                    <PrimaryButton
                        classBtn="settings-cancel-btn"
                        text={t("DoctorProfile.cancel_btn")}
                        click={() => handleEdit(false, 'cancel')}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(NewAppointment);