import { useState, useEffect } from "react"
import { Grid, Box, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material"
import { withTranslation } from "react-i18next"
import { Formik, Form, Field } from 'formik'
import moment from "moment-hijri"
import * as Yup from 'yup'

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton"
import RadioButtonsGroup from "../../Atoms/Input/Radio/RadioInput.js"
import SelectBox from "../../Atoms/Select/Select"
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput"
import ClearIcon from '@mui/icons-material/Clear'
import BasicDatePicker from "../../Atoms/Input/DatePicker/DatePicker.js"
import { icons } from '../../../Assets/images/IconHelper'

function EditUserProfile({ t, open, handleCallback, editProfileData, userInfo, accounts }) {

    const [userGender, setUserGender] = useState('')
    const [date, setDate] = useState()
    const [idType, setIdType] = useState('')
    const [redBorder, setRedBorder] = useState("");

    const handleClose = () => {
        handleCallback(false)
    }

    const handleDateChange = (date) => {
        setDate(date);
        setRedBorder("");
    };

    // const validateIdNumber = (value) => {
    //     if (value) {
    //         const empty = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    //         if (value.length > 10) return t("Validation.personal_id_max")
    //         else if (value.length < 10) return t("Validation.personal_id_min")
    //         else if (empty.test(value)) return t("Validation.empty")
    //     }
    //     else {
    //         if (idType !== '') {
    //             return t("Validation.required_field")
    //         }else{
    //             return null
    //         }
    //     }
    // }

    // const validateDate = () => {
    //     if (date === undefined) { return t("Validation.required_field") }
    //     else { return null }
    // }

    const setState = () => {
        userInfo?.birth_date !== null ? handleDateChange(moment(userInfo?.birth_date)) : setDate(null)
        setUserGender(userInfo?.gender == 'أنثى' || userInfo?.gender == 'Female' ? 'female' : 'male')
        setIdType(userInfo?.personal_id_type ?? '')
    }

    useEffect(() => {
        setState()
    }, [open])

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={600}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <Formik
                initialValues={{
                    name: userInfo?.name,
                    personal_id_type: idType,
                    personal_id_number: userInfo?.personal_id_number,
                    email: userInfo?.email,
                    insurance_number: userInfo?.insurance_number,
                    hijri_birth_date: "",
                    birth_date: ""
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("Validation.required_field")).max(30, t("Validation.too_long")),
                    personal_id_number: Yup.string()
                        .when("personal_id_type", (val) => {
                            if (val) {
                                return Yup.string()
                                    .required(t("Validation.required_field"))
                                    .max(10, t("Validation.personal_id_max"))
                                    .min(10, t("Validation.personal_id_min"))
                                    .matches(
                                        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                        t("Validation.empty")
                                    )
                            } else {
                                return Yup.string().notRequired().nullable();
                            }
                        })
                })}
                onSubmit={(values) => {
                    if (date === null) {
                        setRedBorder("red-border")
                    }else{
                        const data = {
                            user: {
                                name: values.name,
                                gender: userGender,
                                personal_id_type: values.personal_id_type,
                                personal_id_number: values.personal_id_number,
                                birth_date: date.locale('en').format("DD/MM/YYYY"),
                                hijri_birth_date: date.locale('en').format("iDD/iMM/iYYYY"),
                                email: values.email,
                                insurance_number: values.insurance_number
                            }
                        }
                        editProfileData(data)
                        if (accounts) {
                            const array = accounts.map(account => {
                                if (account.userId === parseInt(localStorage.getItem("userId"))) {
                                    return { ...account, name: values.name, email: values.email }
                                }
                                return account
                            })
                            localStorage.setItem("Accounts", JSON.stringify(array))
                        }
                        handleClose()
                    }
                }}
            >
                {({ isSubmitting, submitForm, errors, touched, isValid, values, handleChange }) => (
                    <Form>
                        <Grid container style={{ width: "600px" }}>
                            <Grid container xs={12}>
                                <Grid xs={11}>
                                    <DialogTitle
                                        id="customized-dialog-title"
                                        onClose={handleClose}
                                        style={{ fontWeight: "bolder" }}
                                    >
                                        {t("EditUserProfile.edit_profile")}
                                    </DialogTitle>
                                </Grid>
                                <Grid xs={1} style={{ lineHeight: "4" }}>
                                    <PrimaryButton
                                        startIcon={<ClearIcon />}
                                        btnType="icon"
                                        classBtn="clear-icon"
                                        click={() => handleClose()}
                                    />
                                </Grid>
                            </Grid>
                            <DialogContent>
                                <Grid xs={12}>
                                    <Box className="input-field">
                                        <Field
                                            as={TextFieldInput}
                                            id="name"
                                            name="name"
                                            label={t("Signup.name")}
                                            labelStyle="field-label"
                                        />
                                        {errors.name ? (
                                            <Box sx={{
                                                color: '#d32f2f',
                                                paddingTop: '5px',
                                                fontSize: '13px'
                                            }}>
                                                {errors.name}
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Grid>
                                <Grid container xs={12} mb='30px'>
                                    <Grid container xs={4} alignContent='center'>
                                        <Typography
                                            style={{
                                                fontSize: "14px",
                                                color: "var(--secondary-color)",
                                                paddingInlineStart: '40px'
                                            }}
                                            component="p"
                                        >
                                            {t("DoctorProfile.gender")}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} className="radio-full-width">
                                        <RadioButtonsGroup
                                            options={[
                                                {
                                                    label: t("TelemedicineSettings.male"),
                                                    value: "male"
                                                },
                                                {
                                                    label: t("TelemedicineSettings.female"),
                                                    value: "female"
                                                },
                                            ]}
                                            row={true}
                                            containerStyle={{ width: "44%" }}
                                            change={(val) => setUserGender(val)}
                                            value={userGender}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid xs={12} mb='30px'>
                                    <Field
                                        as={SelectBox}
                                        id="personal_id_type"
                                        name="personal_id_type"
                                        items={[
                                            { id: "national_id", name: t("EditUserProfile.national_id") },
                                            { id: "iqama", name: t("EditUserProfile.iqama") },
                                        ]}

                                        label={t("EditUserProfile.choose_id")}
                                        labelStyle="select-label"
                                        value={values.personal_id_type}
                                        onchange={handleChange}
                                    />
                                </Grid>
                                <Grid xs={12} mb='30px'>
                                    <Field
                                        as={TextFieldInput}
                                        id="personal_id_number"
                                        name="personal_id_number"
                                        label={t("UserProfile.personal_id_number")}
                                        labelStyle="field-label"
                                    // validate={validateIdNumber}
                                    />
                                    {errors.personal_id_number && touched.personal_id_number ? (
                                        <Box sx={{
                                            color: '#d32f2f',
                                            paddingTop: '5px',
                                            fontSize: '13px'
                                        }}>
                                            {errors.personal_id_number}
                                        </Box>
                                    ) : null}
                                </Grid>
                                <Grid
                                    mb='30px'
                                    xs={12}
                                >
                                    <Field
                                        as={BasicDatePicker}
                                        selected={date}
                                        change={handleDateChange}
                                        label={t("TelemedicineSettings.birth_date")}
                                        labelStyle="field-label"
                                        icon={icons.Calendar}
                                        name='birth_date'
                                        id='birth_date'
                                        className={redBorder}
                                        errorText={t("TelemedicineSettings.select_date")}
                                        // validate={validateDate}
                                    />
                                    {/* {errors.birth_date ? (
                                        <Box sx={{
                                            color: '#d32f2f',
                                            paddingTop: '5px',
                                            fontSize: '13px'
                                        }}>
                                            {errors.birth_date}
                                        </Box>
                                    ) : null} */}
                                </Grid>
                                <Grid
                                    mb='30px'
                                    xs={12}
                                >
                                    <Field
                                        as={BasicDatePicker}
                                        selected={date}
                                        change={handleDateChange}
                                        calendar="hijri"
                                        label={t("TelemedicineSettings.hijri_birth_date")}
                                        labelStyle="field-label"
                                        icon={icons.Calendar}
                                        name='hijri_birth_date'
                                        id='hijri_birth_date'
                                        className={redBorder}
                                        errorText={t("TelemedicineSettings.select_date")}
                                        // validate={validateDate}
                                    />
                                    {/* {errors.hijri_birth_date ? (
                                        <Box sx={{
                                            color: '#d32f2f',
                                            paddingTop: '5px',
                                            fontSize: '13px'
                                        }}>
                                            {errors.hijri_birth_date}
                                        </Box>
                                    ) : null} */}
                                </Grid>
                                <Grid xs={12} mb='30px'>
                                    <Field
                                        as={TextFieldInput}
                                        id="email"
                                        name="email"
                                        type='email'
                                        label={t("Signup.email")}
                                        labelStyle="field-label"
                                    />
                                </Grid>
                                <Grid xs={12} mb='30px'>
                                    <Field
                                        as={TextFieldInput}
                                        id="insurance_number"
                                        name="insurance_number"
                                        label={t("UserProfile.insurance_number")}
                                        labelStyle="field-label"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    sx={{ justifyContent: 'flex-start', alignContent: 'center', marginTop: '42px' }}
                                >
                                    <Typography variant="caption" sx={{ color: 'var(--secondary-color)' }}>
                                        {t("AddPrescriptionModal.required_fields")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    xs={12}
                                    mb='30px'
                                >
                                    <PrimaryButton
                                        type='submit'
                                        classBtn="edit-btn"
                                        text={t("DoctorProfile.save_btn")}
                                        click={submitForm}
                                        disabled={!isValid}
                                    />
                                </Grid>
                            </DialogContent>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default withTranslation()(EditUserProfile)
