import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"

// List History Surgery 
export async function getListSurgery(id, filter_obj) {
    return await axiosFn("get", URL.USER_STR + id + URL.SURGERIES + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getListSurgeryAPI(id = localStorage.getItem("userId"), filter_obj ) {
    let { err, res } = await getListSurgery(id, filter_obj)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Show History Surgery
export async function showSurgery(id) {
    return await axiosFn("get", URL.USER_STR + localStorage.getItem("userId") + URL.SURGERIES + '/' + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function showSurgeryAPI(id) {
    let { err, res } = await showSurgery(id)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Create History Surgery
export async function createSurgery(data) {
    return await axiosFn("post", URL.USER_STR + localStorage.getItem("userId") + URL.SURGERIES, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function createSurgeryAPI(data) {
    let { err, res } = await createSurgery(data)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Edit History Surgery
export async function editSurgery(id, data) {
    const Url = URL.USER_STR + localStorage.getItem("userId") + URL.SURGERIES + '/' + id
    return await axiosFn("put", Url, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function editSurgeryAPI(id, data) {
    let { err, res } = await editSurgery(id, data);
    if (res) {
        return res;
    }
    if (err) {
        return err;
    }
}

// Delete History Surgery
export async function deleteSurgery(id) {
    return await axiosFn("delete", URL.USER_STR + localStorage.getItem("userId") + URL.SURGERIES + '/' + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function deleteSurgeryAPI(id) {
    let { err, res } = await deleteSurgery(id)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
} 