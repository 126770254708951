import React from "react";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import NewAppointment from "../../../Molecules/DoctorProfile/TabPanels/Telemedicine/Appointment/NewAppointment";
import AppointmentsList from "../../../Molecules/DoctorProfile/TabPanels/Telemedicine/Appointment/AppointmentsList";
import { getSavedTimeIntervalsAPI } from "../../../../Contexts/API/DoctorProfile/telemedicine.js";
import { groupBy } from "../../../../Contexts/API/Helper/Helper";
import Loader from "../../../Atoms/Loader/Loader";

function Appointments(props) {
    const { t } = props;
    const [viewFlag, changeView] = useState(false);
    const [intervalsList, setIntervalsList] = useState([]);
    const [openLoader, setLoader] = useState(false);
    const [paginationInfo, setPaginationInfo] = useState({});

    const updateView = (appointmentFlag, action) => {
        if (action === 'Add' || action === 'delete') TimeIntervals({
            page_size: 10,
            page: 1
        });
        changeView(appointmentFlag);
    }

    const TimeIntervals = async (filter_obj) => {
        setLoader(true);
        const res = await getSavedTimeIntervalsAPI('personal_telemedicine', filter_obj);
        if (res) {
            var grouped = groupBy(res.data.time_intervals, 'day');
            grouped = Object.entries(grouped)
            setIntervalsList([grouped]);
            setPaginationInfo(res?.extra);
        }
        setLoader(false);
    }
    useEffect(() => {
        TimeIntervals({
            page_size: 10,
            page: 1
        });
    }, []);

    return (
        <Grid container>
            <Loader open={openLoader} />
            <Grid
                xs={12}
                justifyContent="flex-end"
                alignItems="baseline"
                style={{ textAlign: "end" }}
            >
                {!viewFlag && (
                    <PrimaryButton
                        classBtn="appointment-btn mb-20px"
                        text={t("DoctorProfile.new_appointment")}
                        click={() => updateView(true, '')}
                    />
                )}
            </Grid>
            <Grid container>
                {!viewFlag && (
                    <AppointmentsList
                        appointmentlist={intervalsList}
                        updateAddView={(appointmentFlag, action) => updateView(appointmentFlag, action)}
                        paginationInfo={paginationInfo}
                        updateAppointmentsList={(filter_obj) => TimeIntervals(filter_obj)}
                    />
                )}
                {viewFlag && (
                    <NewAppointment updateAddView={(appointmentFlag, action) => updateView(appointmentFlag, action)} />
                )}
            </Grid>
        </Grid>
    )
}
export default withTranslation()(Appointments);