import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes as Switch, Navigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Navbar from "../../Components/Organisms/Layout/Navbar";
import DoctorProfile from "../../Pages/Main/Profile/DoctorProfile.page.jsx";
import HomePage from "../../Pages/Main/Home/Home.page.jsx";
import UserHomePage from "../../Pages/Main/Home/UserHome.page.jsx";
import UserProfile from "../../Pages/Main/Profile/UserProfile.page.jsx";
import TelemedicineDoctorProfilePage from "../../Pages/Main/Home/TelemedicineDoctorProfile.page.jsx";
import DrTelemedicineSession from "../../Pages/Main/Home/TelemedicineSession/DrTelemedicineSession.Page.jsx";
import PatientTelemedicineSession from "../../Pages/Main/Home/TelemedicineSession/PatientTelemedicineSession.Page.jsx";
import UserPrivacyPolicyPage from "../../Pages/Main/PrivactPolicy/UserPrivacyPolicy.page.jsx";
import UserSettingsPage from "../../Pages/Main/Settings/UserSettingsPage.jsx";
import OffersPage from "../../Pages/Main/Offers/OffersPage";
import ContactUsPage from "../../Pages/Main/ContactUs/ContactUsPage";
import AboutUsPage from "../../Pages/Main/AboutUs/AboutUsPage";
import UserAppointmentsPage from "../../Pages/Main/Appointments/UserAppointments/UserAppointments.page.jsx";
import ChatHistory from "../../Pages/Main/Appointments/ChatHistory/ChatHistory.page.jsx";
import UserWalletPage from "../../Pages/Main/Wallet/UserWalletPage";

const MainTemplate = (props) => {
  const { t, notificationsList, refetchNotificationsList, notificationsCount, openLoader, updateCallStatus } = props;
  const isAuth = localStorage.getItem("TOKEN");
  const [avatar, setAvatar] = useState();
  const [userName, setUserName] = useState();
  useEffect(() => {
    setAvatar(localStorage.getItem("avatar"))
    setUserName(localStorage.getItem("name"))
  }, []);
  return (
    <Grid container>
      <Switch>
        
        <Route path="doctor/home/telemedicine/telemedicine_session/:id" element={<DrTelemedicineSession />} />

        <Route path="user/home/telemedicine/telemedicine_session/:id" element={<PatientTelemedicineSession />} />
        
        <Route path="/*" element={
          <>
            <Navbar 
              notificationsList={notificationsList}
              refetchNotificationsList={refetchNotificationsList}
              notificationsCount={notificationsCount}
              openLoader={openLoader}
              updateCallStatus={updateCallStatus}
              avatar={avatar} 
              UserName={userName}
            />
            <Grid container style={{ marginTop: "80px" }}>
              <Switch>
                <Route path="*"
                  element={localStorage.getItem("userType") == 'patient' ?
                    <Navigate to="/user/home/main" /> : <Navigate to="/doctor/home" />} />
                <Route path="/doctor/profile" element={<DoctorProfile setAvatar={setAvatar} />} />
                <Route path="/user/profile" exact element={<UserProfile setAvatar={setAvatar} setUserName={setUserName} />} />
                <Route path="/doctor/home" element={<HomePage />} />
                <Route path="/user/home/*" element={<UserHomePage />} />
                <Route path="user/home/:param/:param/doctor-profile" element={<TelemedicineDoctorProfilePage t={t}/>} />
                <Route path="/user/privacy-policy" exact element={<UserPrivacyPolicyPage />} />
                <Route path="/doctor/privacy-policy" exact element={<UserPrivacyPolicyPage />} />
                <Route path="/user/settings" exact element={<UserSettingsPage />} />
                <Route path="/offers" exact element={<OffersPage />} />
                <Route path="/contact-us" exact element={<ContactUsPage />} />
                <Route path="/about-us" exact element={<AboutUsPage />} />
                <Route path="/user/appointments" exact element={<UserAppointmentsPage />} />
                <Route path="/user/appointments/chat-history/:id" exact element={<ChatHistory />} />
                <Route path="/doctor/settings" exact element={<UserSettingsPage />} />
                <Route path="wallet" exact element={<UserWalletPage t={t}/>} />
              </Switch>
            </Grid>
          </>
        } />
      </Switch>
    </Grid>
  );
};
export default withTranslation()(MainTemplate);
