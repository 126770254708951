import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePasswordAPI } from "../../Contexts/API/Auth/ChangePassword";
import ChangePasswordTemplate from "../../Templates/Auth/ChangePassword.Template.jsx";

const ChangePasswordPage = (props) => {

  const navigate = useNavigate()
  const type = localStorage.getItem("TYPE")
  const filter = {
    "code_scope": "reset_password",
    "user_type": type
  }

  const handleSubmit = async (data) => {
    const {res,err} = await changePasswordAPI({
      user: {
        country_code: localStorage.getItem("country_code"),
        phone_number: localStorage.getItem("phone_number"),
        password: data.user.password,
        password_confirmation: data.user.password_confirmation,
      },
      device: {
        uuid: "dummy",
        device_type: "web", //"android" , "ios" , "web", "unknown"
        fcm_token: "dummy",
        voip_token: "dummy",
      },
    }, filter);
    if (res) {
      toast.success(res?.res?.message);
      localStorage.removeItem('country_code')
      localStorage.removeItem('phone_number')
      localStorage.removeItem('OTP')
      localStorage.removeItem('VERIFY')
      localStorage.removeItem('TYPE')
      navigate('/login')
    }
    if (err) console.log(err)
  };
  return (
    <ChangePasswordTemplate
      mode={"ChangePassword"}
      handleSubmit={handleSubmit}
    />
  );
};

export default ChangePasswordPage;
