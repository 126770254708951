import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function GetVerificationOTPs(recivedData) {
  const data = { ...recivedData };

  return await axiosFn("post", URL.GET_VERIFICATION_CODE, data)
    .then(async (res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function GetVerificationOTP(data) {
  let { err, res } = await GetVerificationOTPs(data);
  return { err, res };

}
