import { useState } from "react"
import { toast } from "react-toastify"

import UserSettingsTemplate from "../../../Templates/Main/Settings/UserSettingsTemplate"
import { DeleteAccountAPI } from '../../../Contexts/API/General/DeleteAccount.js'
import { changePasswordAPI } from "../../../Contexts/API/Auth/PasswordChange.js"
import { updatePhoneDataAPI } from "../../../Contexts/API/Auth/UpdatePhone.js"
import { GetVerificationOTP } from "../../../Contexts/API/Auth/GetVerificationOTP.js"
import { VerifyDataAPI } from "../../../Contexts/API/Auth/Verify.js"
import { verifyPhoneDataAPI } from "../../../Contexts/API/Auth/VerifyPhone.js"
import { DeactivateAccountAPI } from "../../../Contexts/API/General/DeactivateAccount"
import Loader from "../../../Components/Atoms/Loader/Loader"
import { LogoutAPI } from "../../../Contexts/API/Auth/Logout"

const UserSettingsPage = ({ t }) => {
    //state for opening delete confirmatiom modal
    const [open, setOpen] = useState(false)
    const [openLoader, setLoader] = useState(false);
    const lang = localStorage.getItem("lang")
    const accounts = JSON.parse(localStorage.getItem("Accounts"))

    //functions for delete confirmatiom modal
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    // Remove account function
    const remove = async () => {
        handleClose()
        setLoader(true)
        const res = await DeleteAccountAPI()
        if (res == "You can't deactivate or delete this account" || res == "لا يمكنك تعطيل او مسح الحساب") {
            toast.error(res)
        } else {
            localStorage.clear()
            window.location.href = "/login"
        }
        setLoader(false)
    }

    //Deactivate account function

    const deactivate = async () => {
        const res = await DeactivateAccountAPI();
        if (res == "You can't deactivate or delete this account" || res == "لا يمكنك تعطيل او مسح الحساب") {
            toast.error(res)
        } else {
            localStorage.clear()
            window.location.href = "/login"
        }
    };

    // Change Password function
    const changePassword = async (data) => {
        setLoader(true)
        const { res, err } = await changePasswordAPI(data)
        if (res) {
            toast.success(res?.message)
            const result = await LogoutAPI()
            if (result) {
                const lang = localStorage.getItem("lang");
                localStorage.clear();
                localStorage.setItem("lang", lang)
                window.location.href = "/login"
            }
        }
        if (err) toast.error(err)
        setLoader(false)
    }

    // Update Phone functions
    const updatePhone = async (data) => {   // function for updaing phone in database
        const { err, res } = await updatePhoneDataAPI(data)  //data = phone + country code
        // if(err === "phone_already_taken" && lang === "ar"){
        //     toast.error("رقم الهاتف مأخوذ مسبقا");
        // }else if(err === "phone_already_taken" && lang === "en"){
        //     toast.error("Phone already taken");
        // }
        return { err, res }
    }

    const sendOTP = async (data) => {   // function for sending OTP to the user via SMS
        const { err, res } = await GetVerificationOTP(data)  //data = phone + country code
        return { err, res }  // res = otp
    }

    const verifyOTP = async (data) => {   // function for verifying entered OTP from user
        const { err, res } = await VerifyDataAPI(data)  //data = otp + phone + country code
        return { err, res }  // res = token
    }

    const verifyPhone = async (data) => {   // function for verifying phone on database
        //data = phone + country code (token->headers)
        const { err, res } = await verifyPhoneDataAPI(data)
        return { err, res }
    }

    return <>
        <Loader open={openLoader} />
        <UserSettingsTemplate
            remove={remove}
            open={open}
            handleClose={handleClose}
            handleOpen={handleOpen}
            changePassword={changePassword}
            updatePhone={updatePhone}
            sendOTP={sendOTP}
            verifyOTP={verifyOTP}
            verifyPhone={verifyPhone}
            deactivateAccount={deactivate}
            accounts={accounts}
        />
    </>
}

export default UserSettingsPage