import { useEffect, useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Box } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput"
import SelectBox from "../../../Atoms/Select/Select"
import MedicineCard from "../../../Molecules/PatientProfile/Cards/MedicineCard"

function AddPrescriptionModal({ t, open, handleClose, type, item, createPrescription, editPrescription, medicineIntakeMethods, medicineDosagePeriods, diseasesList }) {

    const noWhiteSpaces = /^(?!\s+$).*/
    const medicine = {
        "medicine_name": '',
        "def_medicine_intake_method_id": '',
        "daily_dosage": '',
        "dosage_period": ''
    }
    const dosage = [
        { "id": 1, "name": t("AddPrescriptionModal.one_dosage") },
        { "id": 2, "name": t("AddPrescriptionModal.two_dosage") },
        { "id": 3, "name": t("AddPrescriptionModal.three_dosage") }
    ]

    const [oldMedicines, setOldMedicines] = useState([])
    const [editMedicines, setEditMedicines] = useState([])
    const [newMedicines, setNewMedicines] = useState([])
    const [newMedicine, setNewMedicine] = useState(medicine)
    const [error, setError] = useState('')
    const [diseaseId, setDiseaseId] = useState(0)
    
    const getNewMedicine = (e) => {
        const { name, value } = e.target
        setNewMedicine({ ...newMedicine, [name]: value })
    }

    const addNewMedicine = (name) => {
        if (name === '' || !noWhiteSpaces.test(name)) {
            setError(t("Validation.required_field"))
            return
        } else {
            setError('')
            setNewMedicines([...newMedicines, newMedicine])
            setNewMedicine(medicine)
        }
    }

    const removeNewMedicine = (name) => {
        setNewMedicines(newMedicines.filter(item => item.medicine_name !== name))
    }

    const removeOldMedicine = async (medicineId) => {
        let obj = editMedicines.find(item => item.id === medicineId)
        const newArray = editMedicines.filter(item => item.id !== medicineId)
        obj = { ...obj, ['_destroy']: true }
        setEditMedicines([obj, ...newArray])
        setOldMedicines(oldMedicines.filter(item => item.id !== medicineId))
    }

    const setOld = () => {
        setOldMedicines(item?.prescription?.prescription_medicines || [])
        setEditMedicines(item?.prescription?.prescription_medicines || [])
        setNewMedicine(medicine)
    }

    useEffect(() => {
        setNewMedicines([])
        setOldMedicines([])
        setEditMedicines([])
        if (type === 'edit') {
            setOld()
        }else{
            setNewMedicine({
                "medicine_name": '',
                "def_medicine_intake_method_id": '',
                "daily_dosage": '',
                "dosage_period": ''
            })
        }
    }, [open,item])

    useEffect(() => {
        if (type === 'edit') setDiseaseId(diseasesList?.filter((disease) => { return disease.name === item?.diagnosis_name })[0]?.id)
        else setDiseaseId(0)
    }, [open, diseasesList, item]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto'
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogTitle>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={11}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {type === 'add' ? t("AddDiagnosisModal.add_new_diagnosis") : t("AddDiagnosisModal.edit_diagnosis")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        sx={{ justifyContent: 'flex-end', alignContent: 'center' }}
                    >
                        <PrimaryButton
                            type='icon'
                            startIcon={<CancelIcon sx={{ color: 'var(--primary-color)' }} />}
                            click={handleClose} />

                    </Grid>
                </Grid>
            </DialogTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    disease_id: diseaseId || 0,
                    description: type === 'edit' ? item?.description : '',
                    medicine_name: ''
                }}
                validationSchema={
                    Yup.object().shape({
                        disease_id: Yup.string().required(t("Validation.required_field"))
                    })
                }
                onSubmit={(values) => {
                    const data = {
                        diagnosis: {
                            disease_id: values.disease_id,
                            description: values.description,
                            diagnosis_name: diseasesList?.filter((disease) => { return disease.id === values.disease_id })[0].name,
                            prescription_medicines: [...editMedicines, ...newMedicines],
                            source: "history"
                        },
                        id: type === 'edit' ? item?.id : '',
                        user_id: type === 'edit' ? item?.user_id : ''
                    }
                    // const data = {
                    //     prescription: {
                    //         disease_id: values.disease_id,
                    //         description: values.description,
                    //         diagnosis_name: diseasesList?.filter((disease) => {return disease.id === values.disease_id})[0].name,
                    //         prescription_medicines_attributes: [...editMedicines, ...newMedicines]
                    //     }
                    // }
                    type === 'add' ?
                        createPrescription(data) :
                        editPrescription(item?.id, data)
                    handleClose()
                }}
            >
                {({ isSubmitting, submitForm, isValid, errors, values, handleChange }) => (
                    <Form>
                        <DialogContent >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    style={{ marginBottom: 30 }}
                                >
                                    <Field
                                        as={SelectBox}
                                        label={t("AddDiagnosisModal.diagnosis_disease")}
                                        labelStyle='field-label'
                                        items={diseasesList}
                                        name='disease_id'
                                        id='disease_id'
                                        value={values.disease_id}
                                        onchange={handleChange}
                                    />
                                    {errors.disease_id ?
                                        <Box sx={{
                                            color: '#d32f2f',
                                            paddingTop: '5px',
                                            fontSize: '13px'
                                        }}>
                                            {errors.disease_id}
                                        </Box> : null}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    sx={{ height: 140 }}
                                >
                                    <Field
                                        as={TextFieldInput}
                                        multiline={true}
                                        className='textarea'
                                        placeholder={t("AddDiagnosisModal.remarks")}
                                        name='description'
                                        id='description'
                                        rows={5}
                                        inputProps={{
                                            style: {
                                                position: 'absolute',
                                                top: '10px',
                                                width: '95%'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    mt={1}
                                    mb={6}
                                    sx={{ justifyContent: 'flex-start', alignContent: 'center' }}>
                                    <Typography variant="caption">
                                        {t("AddPrescriptionModal.remarks_description")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    style={{ marginBottom: 30 }}
                                >
                                    <Field
                                        as={TextFieldInput}
                                        placeholder={t("AddPrescriptionModal.medicine_name")}
                                        name='medicine_name'
                                        id='medicine_name'
                                        value={newMedicine.medicine_name}
                                        onChange={(e) => getNewMedicine(e)}
                                    />
                                    {error !== '' ?
                                        <Box sx={{
                                            color: '#d32f2f',
                                            paddingTop: '5px',
                                            fontSize: '13px'
                                        }}>
                                            {error}
                                        </Box> : null}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                >
                                    <Field
                                        as={SelectBox}
                                        title={t("AddPrescriptionModal.usage")}
                                        items={medicineIntakeMethods}
                                        name='def_medicine_intake_method_id'
                                        id='def_medicine_intake_method_id'
                                        value={newMedicine.def_medicine_intake_method_id}
                                        onchange={(e) => getNewMedicine(e)}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={1}
                                    sx={{
                                        justifyContent: 'flex-start',
                                        paddingTop: '15px'
                                    }}
                                >
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                        {t("AddPrescriptionModal.dosage")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={5.5}
                                    container
                                    style={{ paddingInline: '20px' }}
                                >
                                    <Field
                                        as={SelectBox}
                                        items={dosage}
                                        name='daily_dosage'
                                        id='daily_dosage'
                                        value={newMedicine.daily_dosage}
                                        onchange={(e) => getNewMedicine(e)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={5.5}
                                    container
                                    style={{ paddingInlineStart: '10px' }}
                                >
                                    <Field
                                        as={SelectBox}
                                        items={medicineDosagePeriods}
                                        name='dosage_period'
                                        id='dosage_period'
                                        value={newMedicine.dosage_period}
                                        onchange={(e) => getNewMedicine(e)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="end"

                                >
                                    <PrimaryButton
                                        text={t("AddPrescriptionModal.add_new_medicine")}
                                        classBtn='primary btn-rounded btn-small'
                                        style={{ maxWidth: '140px' }}
                                        click={() => addNewMedicine(newMedicine.medicine_name)}

                                    />
                                </Grid>
                                {oldMedicines?.length !== 0 && oldMedicines?.map((medicine) => {
                                    return (
                                        <MedicineCard
                                            t={t}
                                            key={medicine.id}
                                            type='old'
                                            id={medicine.id}
                                            medicine_name={medicine.medicine_name}
                                            dosage_display={medicine.dosage_display}
                                            intake_method={medicineIntakeMethods.find(item => item.id === medicine.medicine_intake_method_id).name}
                                            removeOldMedicine={removeOldMedicine}
                                        />
                                    )
                                })}
                                {newMedicines?.length !== 0 && newMedicines?.map((medicine, index) => {
                                    return (
                                        <MedicineCard
                                            t={t}
                                            key={index}
                                            type='new'
                                            medicine_name={medicine.medicine_name}
                                            daily_dosage={dosage.find(item => item.id === medicine.daily_dosage)?.name}
                                            dosage_period={medicineDosagePeriods.find(item => item.id === medicine.dosage_period)?.name}
                                            intake_method={medicineIntakeMethods.find(item => item.id === medicine.def_medicine_intake_method_id)?.name}
                                            removeNewMedicine={removeNewMedicine}
                                        />
                                    )
                                })}
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    sx={{ justifyContent: 'flex-start', alignContent: 'center', marginTop: '42px' }}
                                >
                                    <Typography variant="caption" sx={{ color: 'var(--secondary-color)' }}>
                                        {t("AddPrescriptionModal.required_fields")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                justifyContent: 'center',
                                marginBottom: '30px'
                            }}>
                            <PrimaryButton
                                type='submit'
                                text={t("DoctorProfile.save_btn")}
                                classBtn='primary btn-rounded'
                                style={{
                                    maxWidth: '300px',
                                    fontWeight: 600
                                }}
                                click={submitForm}
                                disabled={isSubmitting || !isValid}
                            />
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    )
}

export default AddPrescriptionModal
