import { Typography } from '@mui/material';
import React from 'react'
import { icons, images } from '../../../../Assets/images/IconHelper'
import './MessageComponents.css'

const MessageItem = ({ message, date, type, files, image, name }) => {

    let Messagetime = new Date(date).toLocaleString().split(',')[1];
    const lang = localStorage.getItem("lang")
    return (
        <div
            style={type == 'sent' ?
                { display: "flex", alignItems: 'center', justifyContent: 'end' } :
                { display: "flex", justifyContent: 'start' }
            }
        >
            {type == 'received' &&
                <img
                    width="36"
                    height="36"
                    style={{
                        margin: "0 15px",
                        borderRadius: '50%',
                        border: "1px solid var(--secondary-color)",
                        marginTop:'20px'
                    }}
                    src={image ? image : images.userAvatar}
                />
            }
            <div style={{ marginTop: type == 'sent' ? "0px" : "20px" }}>
                {type == 'received' &&
                    <Typography sx={{
                        fontSize: '12px', color: '#484848',textAlign:lang === 'ar' ? 'right' : 'left'
                    }}>
                        {name?.split(" ")[0]}
                    </Typography>
                }

                <div className={type == 'sent' ? "sent" : "received"}>
                    {message &&
                        <h5>
                            {message}
                        </h5>
                    }
                    {files && files?.includes("pdf") ?
                        <a target="_blank" href={files}>
                            <img src={icons.PDF} />
                            {/* <u style={{ fontStyle: 'italic' }}>docs</u> */}
                        </a>
                        : files &&
                        <a target="_blank" href={files}>
                            <img src={files} />
                        </a>
                    }
                    <p>{Messagetime}</p>
                </div>
            </div>
        </div>

    )
}

export default MessageItem
