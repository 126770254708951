import { Grid, Box, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'

import { icons } from "../../../../../../Assets/images/IconHelper"
import NormalTabs from "../../../../../Atoms/Tabs/NormalTabs"
import TabPanel from "../../../../../Atoms/Tabs/TabPanel"
import AppDiagnosis from './AppDiagnosis'
import PreviousDiagnosis from './PreviousDiagnosis'
import CustomTablePagination from "../../../../../Atoms/Pagination/Pagination";
import PreviousPrescription from "../Prescriptions/PreviousPrescription"

const DiagnosisTab = (props) => {
    const { t } = useTranslation()
    const { paginationInfo, getDiagnosisApp, paginationPrescriptionHistory, getPrescriptionHistory, diseasesList, getPdfPrescription, paginationDiagnosisHistory, getDiagnosisHistory } = props;
    const [diagnosis, setDiagnosis] = useState(0)

    const handleDiagnosisChange = (event, newValue) => {
        setDiagnosis(newValue)
    }
    const DiagnosisTabOptions = [
        {
            id: 1,
            label: t("Prescription.movile_app_prescription")
        },
        {
            id: 2,
            label: t("Prescription.prev_prescription")
        }
    ];

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const [historyFilterObj, setHistoryFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const paginationChangePage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_number': value + 1 });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_size': value });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_size': value });
    };
    useEffect(() => {
        getDiagnosisApp(filterObj)
    }, [filterObj])

    // useEffect(() => {
    //     getPrescriptionHistory(historyFilterObj)
    // }, [historyFilterObj])

    useEffect(() => {
        getDiagnosisHistory(historyFilterObj)
    }, [historyFilterObj])

    return (
        <Grid>
            <Typography mt={4} variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                {t("UserProfile.prescriptions")}
            </Typography>
            <Grid container
                mt={5}
                py={2}
                px={3}
                sx={{ backgroundColor: "#e2f8fb", borderRadius: '14px' }}
            >
                <Grid item mx={2}>
                    <img src={icons.MedFile} alt='Medical File Number' />
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1'>
                        {t("UserProfile.your_medFile_number")}
                    </Typography>
                    <Typography variant='subtitle1'
                        sx={{
                            fontWeight: 'bold',
                            color: 'var(--secondary-color)',
                            lineHeight: '1',
                            letterSpacing: '1.5px'
                        }}>
                        {props?.medicalFileNumber}
                    </Typography>
                </Grid>
            </Grid>
            <Box my={4} >
                <NormalTabs
                    TabsOptions={DiagnosisTabOptions}
                    value={diagnosis}
                    change={handleDiagnosisChange}
                    ContainerStyle="switch-container"
                    TabStyle="switch-btn diagnosis-switch"
                />
            </Box>
            {DiagnosisTabOptions.map((option, index) => (
                <TabPanel
                    value={diagnosis}
                    index={index}
                    key={index}>
                    {index === 0 ?
                        <>
                            <AppDiagnosis
                                t={t}
                                appDiagnosisList={props.appDiagnosisList}
                                showDiagnosis={props.showDiagnosis}
                                idNumber={props.idNumber}
                                getPdfPrescription={getPdfPrescription}
                            />
                            {paginationInfo?.total_count > 10 && (
                                <CustomTablePagination
                                    totalCount={paginationInfo?.total_count}
                                    changePage={(val) => paginationChangePage(val, "app")}
                                    handleRowsPerPage={(val) => handleRowsPerPage(val, "app")}
                                    t={t} 
                                />
                            )}
                        </> 
                        :
                        // <>
                        //     <PreviousPrescription
                        //         openPrescription={props.openPrescription}
                        //         typePrescription={props.typePrescription}
                        //         itemPrescription={props.itemPrescription}
                        //         prescriptionList={props.prescriptionList}
                        //         handleClosePrescription={props.handleClosePrescription}
                        //         openPrescriptionModal={props.openPrescriptionModal}
                        //         createPrescription={props.createPrescription}
                        //         removePrescription={props.removePrescription}
                        //         editPrescription={props.editPrescription}
                        //         medicineIntakeMethods={props.medicineIntakeMethods}
                        //         openPrescriptionDtails={props.openPrescriptionDtails}
                        //         openPrescriptionDetailsModal={props.openPrescriptionDetailsModal}
                        //         handleClosePrescriptionDetails={props.handleClosePrescriptionDetails}
                        //         medicineDosagePeriods={props.medicineDosagePeriods}
                        //         diseasesList={diseasesList}
                        //     />
                        //     {paginationPrescriptionHistory?.total_count !== 0 && (
                        //         <CustomTablePagination
                        //             totalCount={paginationPrescriptionHistory?.total_count}
                        //             changePage={(val) => paginationChangePage(val, "history")}
                        //             handleRowsPerPage={(val) => handleRowsPerPage(val, "history")}
                        //             t={t}
                        //         />
                        //     )}
                        // </>
                        <>
                            <PreviousDiagnosis
                                profileData={props.profileData}
                                diagnosisList={props.diagnosisList}
                                open={props.open}
                                item={props.item}
                                type={props.type}
                                openModal={props.openModal}
                                createDiagnosis={props.createDiagnosis}
                                removeDiagnosis={props.removeDiagnosis}
                                editDiagnosis={props.editDiagnosis}
                                handleClose={props.handleClose}
                                diseasesList={diseasesList}
                                medicineIntakeMethods={props.medicineIntakeMethods}
                                medicineDosagePeriods={props.medicineDosagePeriods}
                                openPrescriptionDetailsModal={props.openPrescriptionDetailsModal}
                                handleClosePrescriptionDetails={props.handleClosePrescriptionDetails}
                                openPrescriptionDtails={props.openPrescriptionDtails}
                                itemPrescription={props.itemPrescription}
                            />
                            {paginationDiagnosisHistory?.total_count > 10 && (
                                <CustomTablePagination
                                    totalCount={paginationDiagnosisHistory?.total_count}
                                    changePage={(val) => paginationChangePage(val, "history")}
                                    handleRowsPerPage={(val) => handleRowsPerPage(val, "history")}
                                    t={t} 
                                />
                            )}
                        </>
                    }
                </TabPanel>
            ))}
        </Grid>
    )
}

export default DiagnosisTab