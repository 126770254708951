import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function sendResetPasswordData(recivedData,filter) {
  const data = { ...recivedData };

  return await axiosFn("post", URL.RESET_PASSWORD + makeFilterString(filter), data)
    .then(async (res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function resetPasswordAPI(data, filter) {
  let { err, res } = await sendResetPasswordData(data, filter);
  return { err, res };
}
