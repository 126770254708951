import { withTranslation } from "react-i18next";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import "./ProfilePanels.css";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState, useEffect } from "react";

const DatesPanel = (props) => {
    const { t, getDoctorAppointments, appointmentsList, appointmentObj } = props;
    const lang = localStorage.getItem("lang");
    const [page, setPage] = useState(0);
    const nextPage = () => {
        if (page < 2) {
            setPage(page + 1);
        }
    };
    const prevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    useEffect(() => {
        var serviceType = "telemedicine";
        if (page === 1) {
            serviceType = "clinic_service";
        } else if (page === 2) {
            serviceType = "hospital_service";
        }
        getDoctorAppointments(serviceType);
    }, [page]);

    return (
        <Grid
            container
            className="profile-Dates-container mb-20px"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12} className="profile-Dates-title grey-bg">
                <h4>{t("DoctorProfile.timesـofـwork")}</h4>
            </Grid>
            <Grid item xs={12} className="profile-Dates-body mb-20px">
                <Grid
                    container
                    className="txt-body"
                    mt={3}
                    mb={4}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Grid item xs={2}>
                        {lang === 'ar' ?
                            <IconButton disabled={page === 2} onClick={() => nextPage()}>
                                <ArrowForwardIosIcon
                                    sx={{ color: "var(--primary-color)", cursor: 'pointer' }}
                                />
                            </IconButton> :
                            <IconButton disabled={page === 0} onClick={() => prevPage()}>
                                <ArrowBackIosNewIcon
                                    sx={{
                                        color: "var(--primary-color)",
                                        cursor: 'pointer'
                                    }}
                                />
                            </IconButton>
                        }
                    </Grid>
                    <Grid container item xs={8}
                        justifyContent='center'>
                        <Typography
                            variant='h6'
                            px={3}
                            sx={{ color: "var(--secondary-color)" }}
                        >
                            {page === 0 ? t("TabsOptions.telemedicine") : page === 1 ? t("TabsOptions.clinics") : t("TabsOptions.hospitals")}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {lang === 'ar' ?
                            <IconButton disabled={page === 0} onClick={() => prevPage()}>
                                <ArrowBackIosNewIcon
                                    sx={{
                                        color: "var(--primary-color)",
                                        cursor: 'pointer'
                                    }}
                                />
                            </IconButton> :
                            <IconButton disabled={page === 2} onClick={() => nextPage()}>
                                <ArrowForwardIosIcon
                                    sx={{ color: "var(--primary-color)", cursor: 'pointer' }}
                                />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                {page === 0 && (
                    <Typography component="h1">
                        {`${t("DoctorProfile.appointments_no")} ${appointmentObj?.number_of_appointments !== undefined ? appointmentObj?.number_of_appointments : " -- "}`}
                    </Typography>
                )}
                {page === 1 && (
                    <>
                        {appointmentsList?.length > 0 ? appointmentsList?.map((appointment) => (
                            <>
                                <Typography component="h1" className="mb-10px">
                                    {`${t("DoctorProfile.reservation_no")} ${appointment?.number_of_reservations}`}
                                </Typography>
                                <Typography component="h1" style={{ fontSize: "13px" }}>
                                    {appointment?.institute_name}
                                </Typography>
                            </>
                        )) :
                            <Typography component="h1">
                                {`${t("DoctorProfile.appointments_no")} 0`}
                            </Typography>
                        }
                    </>
                )}
                {page === 2 && (
                    <>
                        {appointmentsList?.length > 0 ? appointmentsList?.map((appointment) => (
                            <>
                                <Typography component="h1" className="mb-10px">
                                    {`${t("DoctorProfile.reservation_no")} ${appointment?.number_of_reservations}`}
                                </Typography>
                                <Typography component="h1" style={{ fontSize: "13px" }}>
                                    {appointment?.institute_name}
                                </Typography>
                            </>
                        )) :
                            <Typography component="h1">
                                {`${t("DoctorProfile.appointments_no")} 0`}
                            </Typography>
                        }
                    </>
                )}

            </Grid>

        </Grid>
    );
};

export default withTranslation()(DatesPanel);
