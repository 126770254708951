import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Form from "../../Components/Organisms/Forms/Form";
import en from "react-phone-number-input/locale/en.json";
import UserSelection from "../../Components/Organisms/UserSelection/UserSelection";
import "./Auth_Signup.css";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const SignupTemplate = (props) => {
  const { 
    t, 
    mode, 
    setMode, 
    handleSubmit, 
    medicalTitlesList, 
    specialities, 
    handleSpecialityChange, 
    subSpecialities,
    policy,
    terms
  } = props;
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center" alignItems="center" className="unauth_body">
      <Container style={{ marginTop: "30px" }}>
        <PrimaryButton
          btnType="icon"
          startIcon={props.i18n.language === "ar" ? <ArrowForwardOutlinedIcon /> : <ArrowBackOutlinedIcon />}
          click={() => {
            navigate("/login");
          }}
        />
      </Container>
      <Grid container spacing={20} className="Auth_container_signup">
        {/*
      Picking account type (Doctor/Patient)
      */}

        <Grid
          item
          xs={12}
          md={5}
          container
          direction="column"
          alignItems="flex-end"
        >
          <UserSelection t={t} mode={mode} />
        </Grid>
        {/*
      Create account Form
      */}
        <Grid
          item
          xs={12}
          md={7}
          container
          direction="column"
          alignItems="flex-start"
        >
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            style={{ width: "512px" }}
          >
            <h1 className="signup-title1">{t("Login.signup")}</h1>
          </Grid>
          <Grid container>
            <Form
              setMode={setMode}
              mode={mode}
              handleSubmit={handleSubmit}
              labels={en}
              medicalTitlesList={medicalTitlesList}
              specialities={specialities}
              subSpecialities={subSpecialities}
              handleSpecialityChange={handleSpecialityChange}
              policy={policy}
              terms={terms}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(SignupTemplate);
