import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function getSubSpecialities(id) {
  return await axiosFn("get", URL.SPECIALITIES + '/' + id + URL.SUBSPECIALITIES)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getSubSpecialitiesAPI(id) {
  let { err, res } = await getSubSpecialities(id);

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
