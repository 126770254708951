import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function getSpecialities() {
  return await axiosFn("get", URL.SPECIALITIES)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getSpecialitiesAPI() {
  let { err, res } = await getSpecialities();

  if (res) {
    return res;
  }

  if (err) {
    return err;
  }
}
