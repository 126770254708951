import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { useState, useEffect } from "react";
import EditMsg from "../../Atoms/FlashMessages/EditMsg";
import SelectBox from "../../Atoms/Select/Select";
import { Grid } from "@mui/material";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import ClearIcon from '@mui/icons-material/Clear';
import { Gender } from "../../../Contexts/API/Helper/Helper";
import { editProfileAPI } from "../../../Contexts/API/DoctorProfile/mainInfo";

function EditProfile(props) {
  const { t } = props;
  const dir = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    medical_title: '',
    bio: '',
    gender: ''
  })
  const handleClose = () => {
    props.handleCallback(false);
  };
  const getInputValue = (indicator, e) => {
    if(indicator === 'bio') setUser({ ...user, bio: e.target.value });
    if(indicator === 'gender') setUser({ ...user, gender: e.target.value });
    if(indicator === 'medicalTitle') setUser({ ...user, medical_title: e.target.value });
  };
  const setOldData = () => {
    var id ='';
    props?.medicalTitles?.map((title) => {
        if(title.name == props.userInfo.medical_title){
            id = title.id;
        }
    })
    setUser( 
        {
            medical_title: id,
            bio: props?.userInfo?.bio,
            gender: props?.userInfo?.gender
        }
    )
  }
  const editProfile = async () => {
    let data = {
        user: {
            gender: user.gender,
        },
        medical_provider: {
            medical_title_id: user.medical_title,
            bio: user.bio,
        }
    }
    const {res,err} = await editProfileAPI(data);
    if(res) props.handleCallback(false);
  }
  useEffect(() => {
    setOpen(props.open);
    setOldData();
  }, [props.open]);

  return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={600}
        style={{direction: dir}}
      >
        <Grid container style={{width: "600px"}}>
            <Grid container md={12}>
                <Grid xs={11}>
                    <DialogTitle 
                        id="customized-dialog-title" 
                        onClose={handleClose}
                        style={{fontWeight: "bolder"}}
                    >
                        {t("DoctorProfile.edit_profile_info")}
                    </DialogTitle>
                </Grid>
                <Grid xs={1} style={{lineHeight: "4"}}>
                    <PrimaryButton
                        startIcon={<ClearIcon/>}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={() => handleClose()}
                    />
                </Grid>
            </Grid>
            <DialogContent dividers>
                <Grid md={12}>
                      <EditMsg className="edit-flash-msg mb-20px" message={t("FlashMessages.edit_msg")}/>
                </Grid>
                <Grid md={12}>
                    <div className="select-box">
                        <SelectBox
                            id="medical_title"
                            name="medical_title"
                            items={props.medicalTitles}
                            title={t("DoctorProfile.medical_title")}
                            label={t("DoctorProfile.medical_title")}
                            labelStyle="select-label"
                            value={user.medical_title}
                            onchange={(e) => getInputValue('medicalTitle', e)}
                        />
                    </div>
                    <div className="select-box">
                        <SelectBox
                            id="gender"
                            name="gender"
                            items={Gender}
                            title={t("DoctorProfile.gender")}
                            label={t("DoctorProfile.gender")}
                            labelStyle="select-label"
                            value={user.gender}
                            onchange={(e) => getInputValue('gender', e)}
                        />
                    </div>
                    <div className="input-field">
                        <TextFieldInput
                            id="bio"
                            name="bio"
                            className="textarea mb-20px"
                            placeholder={t("TelemedicineSettings.bio")}
                            label={t("TelemedicineSettings.bio")}
                            labelStyle="field-label"
                            style={{height: "130px"}}
                            value={user.bio}
                            onchange={(e) => getInputValue('bio', e)}
                            rows={5}
                            inputProps={{
                                style: {
                                    position: 'absolute',
                                    top: '10px',
                                    width: '95%'
                                }
                            }}
                            multiline={5}
                        />
                    </div>
                </Grid>
                <Grid container md={12}>
                    <PrimaryButton 
                        classBtn="edit-btn" 
                        text={t("DoctorProfile.save_btn")}
                        click={() => editProfile()}
                    />
                </Grid>
            </DialogContent>
        </Grid>
        
      </Dialog>
  );
}

export default withTranslation()(EditProfile);
