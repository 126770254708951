import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import UserAppointmentsContainer from "../../../Components/Organisms/Appointments/UserAppointments/UserAppointmentsContainer";

const UserAppointmentsTemplate = (props) => {
  const { getUserAppointments, appointmentsList, SendReviewData, getDiagnosis, diagnosisList, paginationInfo, servType } = props;
  
  return (
    <Grid container spacing={0}>
      <Grid item xs className="grey-bg"></Grid>
      <Grid
        item
        xl={7}
        lg={9.5}
        md={11}
        sm={12}
        xs={12} 
        className="grey-bg tp-bt-padding main-container"
      >
        <Grid container>
          <UserAppointmentsContainer 
            getUserAppointments={getUserAppointments}
            appointmentsList={appointmentsList}
            SendReviewData={SendReviewData}
            getDiagnosis={getDiagnosis}
            diagnosisList={diagnosisList}
            paginationInfo={paginationInfo}
            servType={servType}
          />
        </Grid>
      </Grid>
      <Grid item xs className="grey-bg"></Grid>
    </Grid>
  );
};

export default withTranslation() (UserAppointmentsTemplate);
