import { useState } from "react"
import { Grid, Typography } from "@mui/material"

import { icons } from "../../../Assets/images/IconHelper"
import TabPanel from "../../Atoms/Tabs/TabPanel"
import NormalTabs from "../../Atoms/Tabs/NormalTabs"
import DiagnosisPanel from "../../Molecules/Telemedicine/TabPanels/DiagnosisPanel"
import AllergiesPanel from "../../Molecules/Telemedicine/TabPanels/AllergiesPanel"
import SurgeriesPanel from "../../Molecules/Telemedicine/TabPanels/SurgeriesPanel"
import CheckupsPanel from "../../Molecules/Telemedicine/TabPanels/CheckupsPanel"
import Prescriptionspanel from "../../Molecules/Telemedicine/TabPanels/Prescriptionspanel"

function MedicalFileActionView(props) {
    const {
        paginationInfo,
        getDiagnosisApp,
        paginationDiagnosisHistory,
        getDiagnosisHistory,
        paginationPrescriptionApp,
        paginationPrescriptionHistory,
        getPrescriptionApp,
        getPrescriptionHistory,
        analysisListPaginationInfo,
        xrayPaginationInfo,
        getAnalysisList,
        getXrayList,
        surgeriesPaginationInfo,
        getSurgeries,
        diseasesList,
        medicinesList
    } = props;

    const [medFilevalue, setMedFileValue] = useState(0)
    const MedicalFileTabOptions = [
        {
            id: 1,
            label: props.t("UserProfile.prescriptions"),
            icon: <img src={icons.Diagnostics} alt="diagnostics" />,
        },
        // {
        //     id: 2,
        //     label: props.t("UserProfile.prescriptions"),
        //     icon: <img src={icons.Prescriptions} alt="Prescriptions" />,
        // },
        {
            id: 2,
            label: props.t("UserProfile.checkups"),
            icon: <img src={icons.Checkups} alt="Checkups" />,
        },
        {
            id: 3,
            label: props.t("UserProfile.surgical_operations"),
            icon: <img src={icons.Surgical_operations} alt="Surgical operations" />,
        },
        {
            id: 4,
            label: props.t("UserProfile.allergies"),
            icon: <img src={icons.Allergies} alt="Allergies" />,
        }
    ]

    const handleMedFileChange = (event, newValue) => {
        setMedFileValue(newValue)
    }

    return (
        <Grid container columnSpacing={{ xs: 3 }}>
            <Grid item xs={3}>
                <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }} mt={4}>
                    {props.t("UserProfile.medical_file")}
                </Typography>
                <NormalTabs
                    TabsOptions={MedicalFileTabOptions}
                    value={medFilevalue}
                    change={handleMedFileChange}
                    orientation="vertical"
                    ContainerStyle="toggle-container"
                    TabStyle="toggle-btn mb-20px"
                />
            </Grid>
            <Grid item xs={9}>
                {MedicalFileTabOptions.map((option, index) => (
                    <TabPanel
                        value={medFilevalue}
                        index={index}
                        key={index}>
                        {index === 0 ?
                            <DiagnosisPanel
                                t={props.t}
                                diagnosisList={props.diagnosisList}
                                appDiagnosisList={props.appDiagnosisList}
                                showDiagnosis={props.showDiagnosis}
                                idNumber={props.idNumber}
                                medicineIntakeMethods={props.medicineIntakeMethods}
                                medicineDurations={props.medicineDurations}
                                symptoms={props.symptoms}
                                doctorNumber={props.doctorNumber}
                                medicalTitles={props.medicalTitles}
                                specialityList={props.specialityList}
                                getSubSpecialityList={props.getSubSpecialityList}
                                createDiagnosis={props.createDiagnosis}
                                medicineDosagePeriods={props.medicineDosagePeriods}
                                editDiagnosis={props.editDiagnosis}
                                reservationId={props.reservationId}
                                paginationInfo={paginationInfo}
                                getDiagnosisApp={getDiagnosisApp}
                                paginationDiagnosisHistory={paginationDiagnosisHistory}
                                getDiagnosisHistory={getDiagnosisHistory}
                                diseasesList={diseasesList}
                                medicinesList={medicinesList}
                            /> :
                            // index === 1 ?
                            //     <Prescriptionspanel
                            //         t={props.t}
                            //         prescriptionList={props.prescriptionList}
                            //         showPrescription={props.showPrescription}
                            //         idNumber={props.idNumber}
                            //         medicineIntakeMethods={props.medicineIntakeMethods}
                            //         appPrescriptionList={props.appPrescriptionList}
                            //         medicineDurations={props.medicineDurations}
                            //         paginationPrescriptionApp={paginationPrescriptionApp}
                            //         paginationPrescriptionHistory={paginationPrescriptionHistory}
                            //         getPrescriptionApp={getPrescriptionApp}
                            //         getPrescriptionHistory={getPrescriptionHistory}
                            //     /> :
                            index === 1 ?
                                <CheckupsPanel
                                    t={props.t}
                                    xrayList={props.xrayList}
                                    analysisList={props.analysisList}
                                    analysisListPaginationInfo={analysisListPaginationInfo}
                                    xrayPaginationInfo={xrayPaginationInfo}
                                    getAnalysisList={getAnalysisList}
                                    getXrayList={getXrayList}
                                /> :
                                index === 2 ?
                                    <SurgeriesPanel
                                        t={props.t}
                                        surgeries={props.surgeries}
                                        surgeriesPaginationInfo={surgeriesPaginationInfo}
                                        getSurgeries={getSurgeries}
                                    /> :
                                    <AllergiesPanel
                                        t={props.t}
                                        isAllergic={props.isAllergic}
                                        allergyList={props.allergyList}
                                    />
                        }
                    </TabPanel>
                ))
                }
            </Grid>
        </Grid>)
}

export default MedicalFileActionView