import React from "react";
import { Grid, Typography } from "@mui/material";
import "./Placeholder.css";

function Placeholder(props) {
    const { t } = props;

    return (
        <Grid container
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: "center" }}
        >
            <Grid
                item
                xs={12}
                className={props.imgContainer}
            >
                <img style={props.imgStyle} src={props.src} alt={props.alt} />
            </Grid>
            <Grid item xs={12} className={props.txtContainer}>
                <Typography component="h1">
                    {props.mainTitle}
                </Typography>
                <Typography component="p">
                    {props.subTitle}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Placeholder;
