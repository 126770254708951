import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function CheckPhone(recivedData) {
    const data = { ...recivedData };

    return await axiosFn("post", URL.CHECK_PHONE, data)
        .then(async (res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function CheckPhoneAPI(data) {
    let { err, res } = await CheckPhone(data);
    return { err, res };
}
