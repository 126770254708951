import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function getUserReview(medical_provider_id, filter_obj) {
    return await axiosFn("get", `/doctor/${medical_provider_id}/reviews${makeFilterString(filter_obj)}`)
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
  }
  export async function getUserReviewApi(medical_provider_id, filter_obj) {
    let { err, res } = await getUserReview(medical_provider_id, filter_obj);
    if (res) {
      return res;
    }
    if (err) {
      return err;
    }
  }