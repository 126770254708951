import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../../../../Assets/images/IconHelper";

function SettingsView (props) {
    const { t } = props;
    const updateView = (editFlag, data) => {
        props.updateEditView(editFlag, data)
    }

    return (
        <Grid container className="settings-view-container">
            <Grid xs={5}>
                <Typography>
                    <h3>{t("DoctorProfile.duration")}</h3>
                    <p>{props?.data?.duration === null ? t("DoctorProfile.empty") : props?.data?.duration + " " + t("TelemedicineSettings.min")}</p>
                </Typography>
            </Grid>
            <Grid xs={5}>
                <Typography>
                    <h3>{t("DoctorProfile.prices")}</h3>
                    <p>{props?.data?.fees_SAR === null ? t("DoctorProfile.empty") : props?.data?.fees_SAR + " " + t("TelemedicineSettings.sar")}</p>
                </Typography>
            </Grid>
            <Grid 
                xs={2} 
                justifyContent="flex-end"
                alignItems="baseline"
                style={{textAlign:"end", lineHeight:4}} 
            >
                <PrimaryButton
                    btnType="icon"
                    startIcon={<img src={images.PenIcon}/>}
                    click={() => updateView(true, '')}
                />
            </Grid>
        </Grid>
    )
}

export default withTranslation()(SettingsView);