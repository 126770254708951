import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"

// List History Checkups
export async function getListCheckup(id,filter_obj, pagination) {
    return await axiosFn("get", URL.USER_STR + id + URL.EXAMINATIONS + makeFilterString({index_scope: filter_obj, page_number: pagination.page_number, page_size: pagination.page_size}))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getListCheckupAPI(id = localStorage.getItem("userId"),filter_obj, pagination) {
    let { err, res } = await getListCheckup(id,filter_obj, pagination)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Create History Checkups
export async function createCheckup(data) {
    return await axiosFn("post", URL.USER_STR + localStorage.getItem("userId") + URL.EXAMINATIONS, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function createCheckupAPI(data) {
    let { err, res } = await createCheckup(data)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Delete History Checkup
export async function deleteCheckup(filter_obj) {
    return await axiosFn("delete", URL.USER_STR + localStorage.getItem("userId") + URL.EXAMINATIONS + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function deleteCheckupAPI(filter_obj) {
    let { err, res } = await deleteCheckup(filter_obj)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Update History Checkup Date
export async function editCheckupDate(id,date) {
    return await axiosFn("put", URL.USER_STR + localStorage.getItem("userId") + URL.EXAMINATIONS + id,date)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function editCheckupDateAPI(id,date) {
    let { err, res } = await editCheckupDate(id,date)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}