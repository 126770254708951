import React from "react";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import "./AppointmentsList.css";
import { convertTimeFormate } from "../../../../../Contexts/API/Helper/Helper";

function AppointmentsList(props) {
    const { t } = props;
    
    return (
        <Grid container className="clinics-hospitals-list-container">
            {props?.list?.map((item) => (
                <Grid container md={12} className="clinic-hospital-item mb-20px">
                    <Grid xs={12} className="mb-20px">
                        <Typography component="h1">
                            {item?.parent_medical_provider?.name}
                        </Typography>
                    </Grid>
                    {item?.time_intervals.map((appointment) => (
                        <Grid container className="mb-10px">
                            <Grid 
                                md={6}
                                style={{textAlign: "start"}}
                            >
                                <Typography component="p">
                                    {
                                        t("DoctorProfile.from") + 
                                        convertTimeFormate(appointment?.start_time) +
                                        t("DoctorProfile.to") + 
                                        convertTimeFormate(appointment?.end_time)
                                    } 
                                </Typography>
                            </Grid>
                            <Grid 
                                md={6}
                                style={{textAlign: "end"}}
                            >
                                <Typography component="p">
                                    {appointment?.reservations_count + ' ' + t("Home.reservations_from_app")}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </Grid>
    );
  }
  
export default withTranslation()(AppointmentsList);