import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function DeleteAccount() {
    return await axiosFn("post", URL.DELETE_ACCOUNT)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function DeleteAccountAPI() {
    let { err, res } = await DeleteAccount()

    if (res) return res

    if (err) return err
}
