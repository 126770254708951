import {
    // useEffect,
    useState
} from "react"
import { withTranslation } from "react-i18next"
import { Grid, Box, Typography } from "@mui/material"

import "./ProfilePanels.css"
import { images } from "../../../Assets/images/IconHelper"
import FileInput from "../../Atoms/Input/FileInput/FileInput"
import EditUserProfile from "../Modals/EditUserProfile"
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton"
import { getPresignedLinkAPI, uploadFileAPI } from "../../../Contexts/API/General/upload"
import { editProfileAPI } from "../../../Contexts/API/DoctorProfile/mainInfo"
import Loader from "../../Atoms/Loader/Loader"
import AvatarModal from "../Modals/Profile/AvatarModal"
import { toast } from "react-toastify"
import moment from "moment-hijri";

const UserInfoPanel = ({ t, mainInfo, editProfileData, handleCallMainInfo, phoneNumber, countryCode, age, birthDate, setAvatar, hijriBirthDate }) => {
    const [open, setOpen] = useState(false)
    const [openAvatar, setOpenAvatar] = useState(false)
    const [openLoader, setLoader] = useState(false)
    // const [email, setEmail] = useState('')
    // const [emailRemain, setEmailRemain] = useState('')
    const lang = localStorage.getItem("lang")
    const accounts = JSON.parse(localStorage.getItem("Accounts"))

    const changeProfilePic = async (event) => {
        const file = event.target.files[0]
        if (file.type.indexOf("image") !== -1) {
            const files = [{ "extension": file.type.split("/")[1] }]
            setLoader(true)
            const res = await getPresignedLinkAPI(files)
            const presignedUrl = res?.data[0].presigned_url
            const upload = await uploadFileAPI(presignedUrl, file)
            if (upload) {
                await updateProfilePic(res?.data[0].file_url)
                if (accounts) {
                    const array = accounts.map(account => {
                        if (account.userId === parseInt(localStorage.getItem("userId"))) {
                            return { ...account, avatar: res?.data[0].file_url }
                        }
                        return account
                    })
                    localStorage.setItem("Accounts", JSON.stringify(array))
                }
            }
            setLoader(false)
        } else toast.error(t("EditUserProfile.image"));
    }

    const updateProfilePic = async (avatar) => {
        setLoader(true)
        let data = {
            user: {
                avatar: avatar
            }
        }
        const { res, err } = await editProfileAPI(data)
        if (res) {
            handleCallMainInfo()
            localStorage.setItem("avatar", res?.data?.user?.avatar)
            setAvatar(res?.data?.user?.avatar)
        }
        if(err) toast.error(err)
        setLoader(false)
    }

    const openEditProfile = () => setOpen(true)

    const handleCallback = (flag) => {
        setOpen(flag)
        handleCallMainInfo()
    }

    const userInformation = [
        {
            title: t("UserProfile.georgian_birthdate"),
            txt: birthDate ? `${lang === 'ar' ? moment(birthDate).locale("ar").format("YYYY/MM/DD") : moment(birthDate).locale("en").format("DD/MM/YYYY")}  (${age})` : ''
        },
        {
            title: t("UserProfile.hijri_birthdate"),
            txt: hijriBirthDate ? `${lang === 'ar' ? moment(birthDate).locale("ar").format("iYYYY/iMM/iDD") : moment(birthDate).locale("en").format("iDD/iMM/iYYYY")}` : ''
        },
        {
            title: t("DoctorProfile.gender"),
            txt: mainInfo?.gender
        },
        {
            title: t("Signup.email"),
            txt: mainInfo?.email, //email,
            // text: emailRemain
        },
        {
            title: t("UserProfile.insurance_number"),
            txt: mainInfo?.insurance_number
        },
        {
            title: t("UserProfile.personal_id_number"),
            txt: mainInfo?.personal_id_number
        },
    ]

    // useEffect(() => {
    //     const text = mainInfo?.email
    //     if (text?.length > 27) {
    //         setEmail(text)
    //         setEmail(text?.slice(0, 27))
    //         setEmailRemain(text?.slice(27, text?.length ))
    //     } else setEmail(text)
    // }, [mainInfo]);

    return (
        <Box className="main-info-container">
            <Loader open={openLoader} />
            <Box className="profile-cover">
                <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', width: '95%', margin: 'auto' }}>
                    {t("UserProfile.personal_file")}
                </Typography>
            </Box>
            <Box className="profile-pic mb-20px">
                <img
                    src={(mainInfo?.avatar === null || mainInfo?.avatar === '') ? images.userAvatar : mainInfo?.avatar}
                    alt=""
                />
                {mainInfo?.avatar === null || mainInfo?.avatar === '' ?
                    <FileInput
                        icon={images.Camera}
                        btnStyle="upload-profile-pic"
                        change={(e) => changeProfilePic(e)}
                        accept="image/*"
                    /> :
                    <div className='upload-profile-pic'>
                        <img
                            src={images.Camera}
                            alt='upload avatar'
                            onClick={() => {
                                setOpenAvatar(true)
                            }}
                        />
                    </div>
                }
            </Box>
            <Grid className="info-container">
                <Grid container>
                    <Grid md={10} className="info-name mb-20px">
                        <Typography variant='h5' style={{ color: 'var(--secondary-color)', fontWeight: 'bold' }}>
                            {mainInfo?.name}
                        </Typography>
                        {mainInfo?.phone_number !== undefined &&
                            <Typography variant='subtitle1'>
                                {lang === 'ar' ?
                                    `${phoneNumber} ${countryCode}+` :
                                    `+${countryCode} ${phoneNumber}`}
                            </Typography>
                        }
                    </Grid>
                    <Grid
                        xs={2}
                        justifyContent="flex-end"
                        alignItems="baseline"
                        style={{ textAlign: "end", lineHeight: 4 }}
                    >
                        <PrimaryButton
                            btnType="icon"
                            startIcon={<img src={images.PenIcon} alt='Edit Icon' />}
                            click={() => openEditProfile()}
                        />
                    </Grid>
                </Grid>
                <Grid rowSpacing={5} container className="information-body" mb={10}>
                    <Grid item xs={12} >
                        <Typography variant='subtitle1' style={{ color: 'var(--secondary-color)' }}>
                            {t("UserProfile.name")}
                        </Typography>
                        <Typography variant='h6' style={{ color: 'var(--font-color)' }}>
                            {mainInfo?.name}
                        </Typography>
                    </Grid>
                    {userInformation.map((item, i) => (
                        <Grid item xs={6} key={i} >
                            <Typography variant='subtitle1' style={{ color: 'var(--secondary-color)' }}>
                                {item.title}
                            </Typography>
                            <Typography variant='h6' style={{ color: 'var(--font-color)', wordWrap: 'break-word' }}>
                                {item.txt}
                            </Typography>
                            {/* {item.text &&
                                <Typography variant='h6' style={{ color: 'var(--font-color)' }}>
                                    {item.text}
                                </Typography>
                            } */}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <EditUserProfile
                handleCallback={(openFlag) => handleCallback(openFlag)}
                open={open}
                editProfileData={editProfileData}
                userInfo={mainInfo}
                accounts={accounts}
            />
            <AvatarModal
                open={openAvatar}
                handleClose={() => setOpenAvatar(false)}
                text={t("UserProfile.avatar_delete")}
                btnActionText={t("Settings.change")}
                btnDeleteText={t("Settings.delete")}
                btnActionStyle='btn-no'
                btnDeleteStyle='btn-yes'
                handleAction={() => {
                    setOpenAvatar(false)
                    document.getElementById("hiddenInput").click();
                }}
                handleDelete={() => {
                    setOpenAvatar(false)
                    updateProfilePic(null)
                }}
            />
            <FileInput
                id='hiddenInput'
                change={(e) => changeProfilePic(e)}
                accept="image/*"
            />
        </Box>
    )
}

export default withTranslation()(UserInfoPanel)
