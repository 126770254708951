import { Grid } from "@mui/material"

import UserSettingsPanel from "../../../Components/Organisms/ProfilePanels/UserSettingsPanel"

const UserSettingsTemplate = (props) => {
    return (
        <Grid container>
            <Grid item xs></Grid>
            <UserSettingsPanel
                remove={props.remove} 
                open={props.open}
                handleClose={props.handleClose}
                handleOpen={props.handleOpen}
                changePassword={props.changePassword}
                updatePhone={props.updatePhone}
                sendOTP={props.sendOTP}
                verifyOTP={props.verifyOTP}
                verifyPhone={props.verifyPhone}
                deactivateAccount={props.deactivateAccount}
                accounts={props.accounts}
                />
            <Grid item xs></Grid>
        </Grid>
    )
}

export default UserSettingsTemplate