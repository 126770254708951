import React from "react";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import AppointmentsList from "../../../../Molecules/Home/DoctorHome/AppointmentsLists/AppointmentsList";
import Placeholder from "../../../../Atoms/Placeholder/Placeholder";
import { images } from "../../../../../Assets/images/IconHelper";

function ClinicsAppointments(props) {
    const { t } = props;
    const [value, setValue] = useState(0);
    
    return (
        <Grid container md={12}>
            {props?.list?.length == 0 ? (
                <Grid container>
                    <Placeholder
                        src={images.EmptyPlaceholder}
                        alt="Empty Appointments"
                        mainTitle={t("Placeholders.no_appointments")}
                        txtContainer="text-container"
                    />
                </Grid>
            ) : (
                <AppointmentsList list={props.list}/>
            )}
        </Grid>
    );
  }
  
export default withTranslation()(ClinicsAppointments);