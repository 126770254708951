import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../../Assets/images/IconHelper";
import { convertTimeFormate } from "../../../../../Contexts/API/Helper/Helper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SelectBox from "../../../../Atoms/Select/Select";

function AvailableSessions(props) {
    const { t, list, getPaymentInfo, createReservationDirect, offers, offer, setOffer, paymentInfo } = props;

    var times = [];
    var mainService = [];
    var mainIndex = [];
    // var availableOffers = [];

    for (let index = 0; index < list?.length; index++) {
        mainService.push(list[index]);
        for (let j = 0; j < list[index]?.available_time_slots?.length; j++) {
            times.push(list[index]?.available_time_slots[j]);
            mainIndex.push(index);
        }
        // for (let j = 0; j < list[index]?.available_offers?.length; j++) {
        //     availableOffers.push(list[index]?.available_offers[j]);
        // }
    };

    // console.log(availableOffers)
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1,
            // partialVisibilityGutter: 0
        },
    };
    const lang = localStorage.getItem("lang");
    const createReservation = (id, fees) => {
        if (fees === null || fees === 0) {
            createReservationDirect(id);
        } else {
            getPaymentInfo(id, offer);
        }
    };
    const getOfferValue = (e, id) => {
        setOffer(e.target.value);
        getPaymentInfo(id, e.target.value);
    }

    return (
        <Grid
            container
            // className="available-sessions-container"
            justifyContent={times.length > 1 ? 'center' : 'start'}
        >
            <Carousel
                swipeable={false}
                draggable={false}
                centerMode={false}
                showDots={true}
                responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                // autoPlaySpeed={1000}
                keyBoardControl={true}
                partialVisible={false}
                // customTransition="all .5"
                // transitionDuration={500}
                containerClass={times.length === 1 ? "carousel-container" : "carousel-container-width100"}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"desktop"}
                // dotListClass="custom-dot-list-style"
                itemClass={mainService.length === 1 && times.length === 1 ? "w-538px" : ""}
            >
                {times?.map((timeSlot, index) => (
                    <Grid xs={11.5} item className="session-item mb-30px" sx={{ minHeight: '450px' }}>
                        <Grid container className="session-item-scroll">
                            <Grid xs={12}>
                                <Typography style={{ color: "var(--secondary-color)", fontSize: "20px", fontWeight: "bold" }} component="h1"
                                    className="mb-20px"
                                >
                                    {mainService[mainIndex[index]]?.title}
                                </Typography>
                            </Grid>
                            <Grid xs={12}
                                className="mb-30px"
                            >
                                <Typography component="p" style={{ color: "#848484", fontWeight: "lighter", fontSize: "15px" }}>
                                    {mainService[mainIndex[index]]?.description}
                                </Typography>
                            </Grid>
                            <Grid container style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
                                <Grid container md={7}
                                    className="mb-20px"
                                >
                                    <Grid xs={2} style={{ position: "relative" }}>
                                        <img className="therapy-icon" src={icons.ClockIcon} />
                                    </Grid>
                                    <Grid xs={10}>
                                        <Typography component="h3" style={{ color: "var(--secondary-color)", fontSize: "13px" }}>
                                            {t("DoctorProfile.session_date")}
                                        </Typography>
                                        <Typography component="p" style={{ color: "#434343", fontSize: "12px" }}>
                                            {t("DoctorProfile.from") + convertTimeFormate(timeSlot?.start_time) + t("DoctorProfile.to") + convertTimeFormate(timeSlot?.end_time)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container md={5}
                                    className="mb-20px"
                                >
                                    <Grid xs={3.5} style={{ position: "relative" }}>
                                        <img className="therapy-icon" src={icons.StopWatchIcon} />
                                    </Grid>
                                    <Grid xs={8.5}>
                                        <Typography component="h3" style={{ color: "var(--secondary-color)", fontSize: "13px" }}>
                                            {t("DoctorProfile.session_duration")}
                                        </Typography>
                                        <Typography component="p" style={{ color: "#434343", fontSize: "12px" }}>
                                            {mainService[mainIndex[index]]?.duration + ' ' + t("TelemedicineSettings.min")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container md={7}
                                // className="mb-20px"
                                >
                                    <Grid xs={2} style={{ position: "relative" }}>
                                        <img className="therapy-icon" src={icons.ChairIcon} />
                                    </Grid>
                                    <Grid xs={10}>
                                        <Typography component="h3" style={{ color: "var(--secondary-color)", fontSize: "13px" }}>
                                            {t("Clinics.available_chairs")}
                                        </Typography>
                                        <Typography component="p" style={{ color: "#434343", fontSize: "12px" }}>
                                            {timeSlot?.remaining_capacity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container md={5}
                                // className="mb-20px"
                                >
                                    <Grid xs={3.5} style={{ position: "relative" }}>
                                        <img className="therapy-icon" src={icons.MoneyIcon} />
                                    </Grid>
                                    <Grid xs={8.5}>
                                        <Typography component="h3" style={{ color: "var(--secondary-color)", fontSize: "13px" }}>
                                            {t("DoctorProfile.prices")}
                                        </Typography>
                                        {mainService[mainIndex[index]]?.fees_SAR !== 0 &&
                                            mainService[mainIndex[index]]?.has_offers &&
                                            offer !== 0 ?
                                            <>
                                                <Typography display='inline' component="del" style={{ color: "#434343", fontSize: "12px" }}>
                                                    {mainService[mainIndex[index]]?.fees_SAR + ' ' + t("TelemedicineSettings.sar")}&nbsp;&nbsp;
                                                </Typography>
                                                <Typography display='inline' component="span" style={{ color: "#434343", fontSize: "12px" }}>
                                                    {paymentInfo?.amount ? paymentInfo?.amount : mainService[mainIndex[index]]?.fees_after_discount}{' '}{t("TelemedicineSettings.sar")}
                                                </Typography>
                                            </> :
                                            <Typography display='inline' component="p" style={{ color: "#434343", fontSize: "12px" }}>
                                                {mainService[mainIndex[index]]?.fees_SAR === null || mainService[mainIndex[index]]?.fees_SAR === 0 ? t("DoctorProfile.free") : mainService[mainIndex[index]]?.fees_SAR + ' ' + t("TelemedicineSettings.sar")}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {mainService[mainIndex[index]]?.fees_SAR !== 0 &&
                            mainService[mainIndex[index]]?.has_offers &&
                            <Grid xs={12} mt={2}>
                                <div className="select-box">
                                    <SelectBox
                                        id="offers"
                                        name="offers"
                                        items={offers}
                                        // title={t("Offers.offer")}
                                        label={t("Offers.offer")}
                                        labelStyle="select-label"
                                        value={offer}
                                        onchange={(e) => getOfferValue(e, timeSlot?.id)}
                                    />
                                </div>
                            </Grid>
                        }
                        <Grid container
                            sx={{
                                marginTop: mainService[mainIndex[index]]?.fees_SAR !== 0 &&
                                    mainService[mainIndex[index]]?.has_offers ? "" : "100px"
                            }}
                        >
                            <Grid xs={8} style={{ margin: "auto" }}>
                                <PrimaryButton
                                    classBtn="settings-edit-btn"
                                    text={t("TelemedicineSettings.book")}
                                    click={() => createReservation(timeSlot?.id, mainService[mainIndex[index]]?.fees_SAR)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Carousel>
        </Grid>
    )
}

export default withTranslation()(AvailableSessions);