import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import { images, icons } from "../../../../../Assets/images/IconHelper";
import { useLocation } from "react-router-dom";
// import StarRatings from "react-star-ratings";
import RatingAtom from "../../../../Atoms/Rating/Rating";
import { ConvertToArabicNumbers } from "../../../../../Contexts/API/Helper/Helper";

function DoctorProfileInfo(props) {

    const { t, telemedicineDoctorProfile, duration, fees, feesAfterDiscount } = props;

    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const imgWidth = pathnameArr[4] === 'group-therapy-search' ? 1.3 : 2.5;
    const infoWidth = pathnameArr[4] === 'group-therapy-search' ? 10.7 : 9.5;
    const lang = localStorage.getItem("lang");
    const isDiscount = fees !== feesAfterDiscount

    return (
        <Grid container className="profile-panel-container mb-30px">
            <Grid
                container item md={12}
                className="doctor-info-panel mb-30px"
            >
                <Grid container item md={11} style={{ margin: "auto" }}>
                    <Grid item xs={imgWidth}>
                        <img className="doctor-image-search" src={telemedicineDoctorProfile?.avatar ? telemedicineDoctorProfile?.avatar : images.userAvatar} alt="" />
                    </Grid>
                    <Grid item xs={infoWidth} className="current-panel-info">
                        <Typography style={{ color: "var(--secondary-color)" }} component="h1">
                            {telemedicineDoctorProfile?.name}
                        </Typography>
                        <Typography component="p">
                            {telemedicineDoctorProfile?.medical_title} - {telemedicineDoctorProfile?.speciality}
                        </Typography>
                        <Typography component="p">
                            {telemedicineDoctorProfile?.subspeciality}
                        </Typography>
                        <Typography component="p">
                            {telemedicineDoctorProfile?.gender === 'female' && lang === 'ar' ? 'أنثي' : telemedicineDoctorProfile?.gender === 'male' && lang === 'ar' ? 'ذكر' : telemedicineDoctorProfile?.gender}
                        </Typography>
                        <Grid item xs={12} className="rate-container">
                            {/* <StarRatings
                                rating={telemedicineDoctorProfile?.avg_rating}
                                starRatedColor="#fcb862"
                                starSpacing="1px"
                            /> */}
                            <RatingAtom
                                initialValue={telemedicineDoctorProfile?.avg_rating}
                                readonly={true}
                                size={17}
                                fillColor='#fcb862'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider style={{ width: "100%", margin: "10px 0" }} />
                <Grid container item md={11} style={{ margin: "auto" }}>
                    <Typography component="h1" style={{ fontWeight: "bolder", color: "var(--secondary-color)", fontSize: "17px" }}>
                        {t("TelemedicineSettings.bio")}
                    </Typography>
                    <Typography component="p" style={{ fontWeight: "lighter", color: "var(--font-color)", fontSize: "15px", width: "100%", marginTop: "10px" }}>
                        {telemedicineDoctorProfile?.bio === null ? t("TelemedicineSettings.no_bio") : telemedicineDoctorProfile?.bio}
                    </Typography>
                </Grid>
                {pathnameArr[4] === 'private-doctor-search' && (
                    <Grid container>
                        <Divider style={{ width: "100%", margin: "10px 0" }} />
                        <Grid container item md={11} style={{ margin: "auto" }}>
                            <Grid item xs={6} style={{ textAlign: "start" }}>
                                <Typography component="p" style={{ fontWeight: "bold" }}>
                                    {lang === 'en' ? duration : ConvertToArabicNumbers(duration)} {t("TelemedicineSettings.min")}
                                </Typography>
                                <Typography component="p" style={{ color: "var(--font-color)" }}>
                                    {t("TelemedicineSettings.deal_with_doctor")}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "end" }}>
                                {telemedicineDoctorProfile?.medical_services?.[0]?.has_offers && isDiscount &&
                                    <>
                                        <Typography display='inline' component="del" style={{ color: "#7e7e7e", fontWeight: "bold", fontSize: '16px' }}>
                                        {lang === 'en' ? fees : ConvertToArabicNumbers(fees)}
                                        </Typography>
                                        <Typography display='inline' component="del" style={{ fontSize: '14px', color: "#7e7e7e", marginInlineEnd: '18px' }}>
                                            {t("TelemedicineSettings.sar")}
                                        </Typography>
                                    </>
                                }
                                <Typography display='inline' component="p" style={{ color: "var(--secondary-color)", fontWeight: "bold", paddingStart: '18px' }}>
                                    {lang === 'en' ? feesAfterDiscount : ConvertToArabicNumbers(feesAfterDiscount)} {t("TelemedicineSettings.sar")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {pathnameArr[4] === 'medical-provider' && (
                    <Grid container>
                        <Divider style={{ width: "100%", margin: "10px 0" }} />
                        <Grid container item md={11} style={{ margin: "auto" }}>
                            <Grid container item xs={5} style={{ textAlign: "start" }}>
                                <Grid item xs={3}>
                                    <img src={icons.MoneyIcon} />
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component="p" style={{ color: "var(--font-color", fontSize: "14px" }}>
                                        {t("Clinics.examination_fee")}
                                    </Typography>
                                    {telemedicineDoctorProfile?.medical_services?.[0]?.has_offers && isDiscount &&
                                        <>
                                            <Typography display='inline' component="del" style={{ color: "#7e7e7e", fontWeight: "bold", fontSize: '16px' }}>
                                            {lang === 'en' ? fees : ConvertToArabicNumbers(fees)}
                                            </Typography>
                                            <Typography display='inline' component="del" style={{ fontSize: '14px', color: "#7e7e7e", marginInlineEnd: '18px' }}>
                                                {t("TelemedicineSettings.sar")}
                                            </Typography>
                                        </>
                                    }
                                    <Typography display='inline' component="p" style={{ color: "var(--secondary-color)", fontWeight: "bold", paddingStart: '18px' }}>
                                        {lang === 'en' ? feesAfterDiscount : ConvertToArabicNumbers(feesAfterDiscount)} {t("TelemedicineSettings.sar")}
                                    </Typography>
                                    {/* <Typography component="p" style={{ color: "var(--secondary-color)" }}>
                                        {fees} {t("TelemedicineSettings.sar")}
                                    </Typography> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid container item xs={4} style={{ textAlign: "start" }}>
                                <Grid item xs={3}>
                                    <img src={icons.AirPort} />
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography component="p" style={{ color: "var(--font-color", fontSize: "14px" }}>
                                        {t("TelemedicineSettings.waiting_time")}
                                    </Typography>
                                    <Typography component="p" style={{ color: "var(--secondary-color)" }}>
                                        {lang === 'en' ? duration : ConvertToArabicNumbers(duration)} {t("TelemedicineSettings.min")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default withTranslation()(DoctorProfileInfo);