import { Grid, Typography } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

function MedicineCard({ t, medicine_name, dosage_display, daily_dosage, dosage_period, intake_method, removeNewMedicine, type, id, removeOldMedicine}) {
    const dosage = daily_dosage !== undefined ? `${daily_dosage} ${dosage_period}` : ''
    return (<Grid container sx={{
        padding: '1rem',
        borderRadius: 'var(--lg-radius)',
        boxShadow: '0px 0px 5px 1px #ddd',
        padding: '1rem',
        marginTop:'10px'
    }}>
        <Grid container item xs={11}>
            <Grid item xs={4} sx={{
                justifyContent: 'flex-start'
            }}>
                <Typography variant="subtitle2" sx={{
                    fontWeight: 'bold',
                    color: 'var(--secondary-color)'
                }}>
                    {t("AddPrescriptionModal.medicine_name")}
                </Typography>
                <Typography variant="body2">
                    {medicine_name}
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{
                justifyContent: 'flex-start'
            }}>
                <Typography variant="subtitle2" sx={{
                    fontWeight: 'bold',
                    color: 'var(--secondary-color)'
                }}>
                    {t("AddPrescriptionModal.dosage")}
                </Typography>
                <Typography variant="body2">
                    {type === 'old' ? dosage_display : dosage}
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{
                justifyContent: 'flex-start'
            }}>
                <Typography variant="subtitle2" sx={{
                    fontWeight: 'bold',
                    color: 'var(--secondary-color)'
                }}>
                    {t("AddPrescriptionModal.usage")}
                </Typography>
                <Typography variant="body2">
                    {intake_method}
                </Typography>
            </Grid>
        </Grid>
        <Grid item xs={1} style={{
            textAlign: "end"
        }}>
            <PrimaryButton
                startIcon={<ClearIcon />}
                btnType="icon"
                classBtn="clear-icon"
                click={() => {
                    type === 'new' ?
                        removeNewMedicine(medicine_name) :
                        removeOldMedicine(id)
                }}
            />
        </Grid>
    </Grid>)
}

export default MedicineCard