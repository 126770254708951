import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton";
import { images, icons } from "../../../../../../Assets/images/IconHelper";
import ClearIcon from '@mui/icons-material/Clear';
import { deleteIntervalAPI } from "../../../../../../Contexts/API/DoctorProfile/telemedicine";
import { convertTimeFormate, convertDayLocale } from "../../../../../../Contexts/API/Helper/Helper";
import { toast } from "react-toastify";

function TherapyList(props) {
    const { t } = props;

    const removeSession = async (id) => {
        const {res,err} = await deleteIntervalAPI('group_session', id);
        if (res) props.updateView(false, 'delete');
        if(err) toast.error(err)
    }
    const openEditView = (flag, action, data) => {
        props.updateView(flag, action, data);
    }
    return (
        <Grid container className="therapy-list-container">
            {props.list.map((therapyItem) => (
                <Grid md={12} className="therapy-item mb-20px">
                    <Grid container className="mb-20px">
                        <Grid xs={10}>
                            <Typography component="h1">
                                {therapyItem?.medical_service?.title}   
                            </Typography>
                        </Grid>
                        <Grid xs={2} style={{textAlign: "end"}}>
                            <PrimaryButton
                                btnType="icon"
                                startIcon={<img src={images.PenIcon}/>}
                                click={() => openEditView(true, 'Edit', therapyItem)}
                            />
                            <PrimaryButton 
                                startIcon={<ClearIcon/>}
                                btnType="icon"
                                classBtn="clear-icon"
                                click={() => removeSession(therapyItem.id)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="mb-20px">
                        <Typography component="p" style={{color:"#848484", fontWeight: "lighter"}}>
                            {therapyItem?.medical_service?.description} 
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid container md={4} className="mb-20px">
                            <Grid xs={2} style={{position: "relative"}}>
                                <img className="therapy-icon" src={icons.CalendarIcon} />
                            </Grid>
                            <Grid xs={10}>
                                <Typography component="h3">
                                    {t("DoctorProfile.session_day")}  
                                </Typography>
                                <Typography component="p" style={{color: "#434343"}}>
                                    {convertDayLocale(therapyItem?.day)}   
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container md={4} className="mb-20px">
                            <Grid xs={2} style={{position: "relative"}}>
                                <img className="therapy-icon" src={icons.ClockIcon} />
                            </Grid>
                            <Grid xs={10}>
                                <Typography component="h3">
                                    {t("DoctorProfile.session_date")}  
                                </Typography>
                                <Typography component="p" style={{color: "#434343"}}>
                                    {t("DoctorProfile.from") + convertTimeFormate(therapyItem?.start_time) + t("DoctorProfile.to") + convertTimeFormate(therapyItem?.end_time)}   
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container md={4} className="mb-20px">
                            <Grid xs={2} style={{position: "relative"}}>
                                <img className="therapy-icon" src={icons.StopWatchIcon} />
                            </Grid>
                            <Grid xs={10}>
                                <Typography component="h3">
                                    {t("DoctorProfile.session_duration")} 
                                </Typography>
                                <Typography component="p" style={{color: "#434343"}}>
                                    {therapyItem?.medical_service?.duration + t("TelemedicineSettings.min")}   
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container md={4} className="mb-20px">
                            <Grid xs={2} style={{position: "relative"}}>
                                <img className="therapy-icon" src={icons.ChairIcon} />
                            </Grid>
                            <Grid xs={10}>
                                <Typography component="h3">
                                    {t("Clinics.available_chairs")}  
                                </Typography>
                                <Typography component="p" style={{color: "#434343"}}>
                                    {therapyItem?.capacity}   
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container md={4} className="mb-20px">
                            <Grid xs={2} style={{position: "relative"}}>
                                <img className="therapy-icon" src={icons.MoneyIcon} />
                            </Grid>
                            <Grid xs={10}>
                                <Typography component="h3">
                                    {t("DoctorProfile.prices")}  
                                </Typography>
                                <Typography component="p" style={{color: "#434343"}}>
                                    {therapyItem?.medical_service?.fees_SAR === null || therapyItem?.medical_service?.fees_SAR === 0 ? t("DoctorProfile.free") : therapyItem?.medical_service?.fees_SAR }   
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default withTranslation()(TherapyList);