import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Stepper } from "react-form-stepper";
import { Link } from "react-router-dom";

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import HelpModal from "../../Organisms/Modals/HelpModal/HelpModal";
import PrivacyPolicyModal from "../../Organisms/Modals/PrivacyModal/PrivacyPolicyModal";
import { ValidateAPI } from "../../../Contexts/API/Auth/Validate";
import "./FormSubmit.css";
import { toast } from "react-toastify";

const FormSubmit = ({ mode, t, setMode, login, handleSubmit, values, policy, errors, certificates, terms, doctorCountryCode }) => {
  const [step, setStep] = useState(0);

  return mode === "Login" ? (
    <Grid container className="Auth_account">
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
        className="mb-30px"
      >
        <Link className="" to="/forgot-password">
          <span className="Auth_account">{t("Login.forgot_password")}</span>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <PrimaryButton
          text={t("Login.login_header")}
          classBtn="primary btn-rounded btn-medium"
          click={() => {
            handleSubmit();
          }}
        // disabled={!phonee || !passwordd}
        />
      </Grid>
    </Grid>
  ) : mode === "User" ? (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography
          component="p"
          style={{ marginTop: "46px", marginBottom: "54px" }}
        >
          {t("Signup.privacy_terms")}{" "}
          {/* <Link className="Link" to="/">
            {t("Signup.privacy")}
          </Link> */}
          <PrivacyPolicyModal policy={policy} terms={terms} />
        </Typography>
        <Typography component="p">
          {" "}
          <HelpModal />
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "16px", marginBottom: "20px" }}>
        <PrimaryButton
          text={t("Signup.signup_header")}
          classBtn="primary btn-rounded btn-medium "
          click={() => {
            handleSubmit();
            sessionStorage.setItem("SignupData", JSON.stringify(values));
            localStorage.setItem("FormType", "User")
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: "60px" }}>
        <Typography component="p">
          <Link to="/Login" className="Link">
            {t("Signup.have_account")}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  ) : mode === "ForgotPassword" ? (
    <Grid container justifyContent="center" style={{ marginTop: 62 }}>
      <PrimaryButton
        text={t("Signup.change_password")}
        classBtn="primary btn-rounded btn-medium"
        click={() => {
          handleSubmit();
        }}
      // disabled={!phonee || !passwordd}
      />
    </Grid>
  ) : mode === "ChangePassword" ? (
    <Grid container justifyContent="center" style={{ marginTop: 62 }}>
      <PrimaryButton
        text={t("Signup.confirm_btn")}
        classBtn="primary btn-rounded btn-medium"
        click={() => {
          handleSubmit();
        }}
      // disabled={!phonee || !passwordd}
      />
    </Grid>
  ) : (
    <Grid container justifyContent="center">
      <Grid item xs={12} style={{ marginTop: "16px", marginBottom: "20px" }}>
        <Typography component="p" style={{ marginBottom: "50px" }}>
          {t("Signup.privacy_terms")}{" "}
          <PrivacyPolicyModal policy={policy} terms={terms} />
        </Typography>
        {/* <Typography
          component="p"
          style={{ marginTop: "46px", marginBottom: "54px" }}
        >
          {t("Signup.privacy_terms")}{" "}
          <PrivacyPolicyModal policy={policy}/>
        </Typography> */}
        <Typography component="p" style={{ marginBottom: "31px" }}>
          {" "}
          <HelpModal />
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <PrimaryButton
              text={t("Signup.previous")}
              classBtn="primary btn-rounded btn-medium "
              style={mode == "Doctor" ? { opacity: "0.34" } : {}}
              disabled={mode == "Doctor"}
              click={() => {
                setMode("Doctor");
                setStep(0);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {mode == "Doctor" ? (
              <PrimaryButton
                text={t("Signup.next")}
                classBtn="primary btn-rounded btn-medium "
                click={async () => {
                  const result = await ValidateAPI({
                    "user": {
                      "user_type": "medical_provider",
                      "medical_provider_type": "Doctor",
                      "name": values.name,
                      "password": values.password,
                      "personal_id_type": values.personal_id_type,
                      "personal_id_number": values.personal_id,
                      "email": values.email,
                      "country_code": doctorCountryCode,
                      "phone_number": values.phone
                    }
                  });
                  if (result.err) toast.error(result.err)
                  else {
                    handleSubmit();
                    if (
                      values.confirm_password
                      && values.password
                      && values.personal_id
                      && values.personal_id_type
                      // && values.email 
                      && values.phone
                      && values.gender
                      && values.name
                      && Object.keys(errors).length === 0
                    ) {
                      setMode("Doctor2");
                      setStep(1);
                      localStorage.setItem("SignupStep", 1);
                    }
                  }
                }}
              />
            ) : (
              <PrimaryButton
                text={t("Signup.signup_header")}
                classBtn="primary btn-rounded btn-medium "
                click={() => {
                  handleSubmit();
                  sessionStorage.setItem("SignupData", JSON.stringify(values));
                  localStorage.setItem("certificates", JSON.stringify(certificates));
                  localStorage.setItem("FormType", "Doctor")
                  localStorage.setItem("SignupStep", 2);
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Stepper
            hideConnectors
            styleConfig={{
              activeBgColor: "#55d4e5",
              inactiveBgColor: "#bbbcbc",
              completedBgColor: "#bbbcbc",
              circleFontSize: "0em",
              size: "1em",
            }}
            steps={[{ label: "" }, { label: "" }]}
            activeStep={step}
            className="stepper"
            style={{ width: "60px", padding: "20px" }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "55px" }}>
          <Typography component="p">
            <Link to="/Login" className="Link">
              {t("Signup.have_account")}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormSubmit;
