import { withTranslation } from "react-i18next"
import { Grid, Box, Typography, Divider, IconButton } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton"
import AppointmentDetails from './AppointmentDetails'
import "./ProfilePanels.css"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AppointmentPanel = (props) => {
    const navigate = useNavigate();
    const { t, getUserAppointments, appointmentsList, setLoader } = props;
    const lang = localStorage.getItem("lang");
    const [page, setPage] = useState(0);

    const nextPage = () => {
        if (page < 2) {
            setPage(page + 1);
        }
    };
    const prevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };
    const getAppointments = async () => {
        setLoader(true);
        var serviceType = "telemedicine";
        if (page === 1) {
            serviceType = "clinic_service";
        } else if (page === 2) {
            serviceType = "hospital_service";
        }
        await getUserAppointments(serviceType);
        setLoader(false);
    }
    useEffect(() => {
        getAppointments()
    }, [page]);

    return (
        <Grid
            container
            className="profile-Dates-container mb-20px"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12} className="profile-Dates-title grey-bg">
                <Typography
                    variant='h6'
                    py={1}
                    sx={{ fontWeight: 'bold' }}>
                    {t("TabsOptions.appointments")}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                className="profile-Dates-body mb-20px">
                <Grid
                    container
                    className="txt-body"
                    mt={3}
                    mb={4}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Grid item xs={2}>
                        {lang === 'ar' ?
                            <IconButton disabled={page === 2} onClick={() => nextPage()}>
                                <ArrowForwardIosIcon
                                    sx={{ color: "var(--primary-color)", cursor: 'pointer' }}
                                />
                            </IconButton> :
                            <IconButton disabled={page === 0} onClick={() => prevPage()}>
                                <ArrowBackIosNewIcon
                                    sx={{
                                        color: "var(--primary-color)",
                                        cursor: 'pointer'
                                    }}
                                />
                            </IconButton>
                        }
                    </Grid>
                    <Grid container item xs={8} justifyContent='center'>
                        <Typography
                            variant='h6'
                            px={3}
                            sx={{ color: "var(--secondary-color)" }}
                        >
                            {page === 0 ? t("TabsOptions.telemedicine") : page === 1 ? t("TabsOptions.clinics") : t("TabsOptions.hospitals")}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {lang === 'ar' ?
                            <IconButton disabled={page === 0} onClick={() => prevPage()}>
                                <ArrowBackIosNewIcon
                                    sx={{
                                        color: "var(--primary-color)",
                                        cursor: 'pointer'
                                    }}
                                />
                            </IconButton> :
                            <IconButton disabled={page === 2} onClick={() => nextPage()}>
                                <ArrowForwardIosIcon
                                    sx={{ color: "var(--primary-color)", cursor: 'pointer' }}
                                />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                {page === 0 && (
                    <AppointmentDetails appointmentsList={appointmentsList} />
                )}
                {page === 1 && (
                    <AppointmentDetails appointmentsList={appointmentsList} />
                )}
                {page === 2 && (
                    <AppointmentDetails appointmentsList={appointmentsList} />
                )}
                {appointmentsList?.length != 0 && (
                    <PrimaryButton
                        text={t("UserProfile.more")}
                        classBtn="btn primary btn-rounded"
                        style={{ maxWidth: '160px', marginTop: "20px" }}
                        click={() => {
                            navigate('/user/appointments', { state: { type: page === 0 ? "telemedicine" : page === 1 ? "clinic_service" : "hospital_service" } })
                        }}
                    />
                )}
            </Grid>

        </Grid>
    )
}

export default withTranslation()(AppointmentPanel)
