import React, { useState } from "react"
import { Grid, Typography, Divider } from "@mui/material"

import PatientCard from '../../Molecules/Telemedicine/Cards/PatientCard'

const PatientList = ({ t, list, setUserID, UserStatus }) => {

    const [isActive,setIsActive] = useState(0)

    const handleChange = (id,i) => {
        setIsActive(i)
        setUserID(id)
    }

    return (
        <Grid container style={{
            borderRadius: '12px',
            boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.1)'
        }}>
            <Grid item xs={12} className="profile-wallet-title" style={{
                paddingInlineStart: '29px'
            }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold', marginY: '25px' }}>
                    {t("TelemedicineSettings.patients_list")}
                </Typography>
            </Grid>
            {list?.length > 0 && list?.map((reservation,i) =>
                <React.Fragment key={i}>
                    <Divider sx={{width:'100%'}} />
                    <PatientCard
                        t={t}
                        index={i}
                        reservation={reservation}
                        handleChange={() => handleChange(reservation?.patient_profile?.id, i)}
                        isActive={isActive}
                        UserStatus={UserStatus}
                        userFirebaseNode={new URL(reservation?.patient_profile?.firebase_node_url).pathname.slice(1)}
                    />
                </React.Fragment>
            )}
        </Grid>
    )
}

export default PatientList