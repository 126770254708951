import { Container, Dialog, Grid, Typography } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import { images } from "../../../../Assets/images/IconHelper";

function AccountTypeModal(props) {
    const {
        t,
        open,
        handleClose,
        setUserType,
        userType
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    padding: "40px",
                    width: "400px"
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <Grid container>
                <Grid container item xs={12} justifyContent='end' mb={2}>
                    <PrimaryButton
                        startIcon={<ClearIcon />}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={handleClose}
                    />
                </Grid>
                <Grid item xs={12} sx={{textAlign:'center'}} mb={1}>
                    <Typography>
                        {t("Login.please")}
                    </Typography>
                </Grid>
                <Grid container item xs={12} className="mb-30px"
                    sx={{ textAlign: "center" }}
                    alignItems='center'
                >
                    <Grid item xs={6} sx={{
                        cursor: "pointer",
                        border: userType === "patient" ? 'solid 2px #229cb0' : 'none',
                        height: '100% !important',
                        borderRadius:'10px'
                    }}
                        onClick={() => setUserType("patient")}
                    >
                        <img src={images.User} style={{ marginTop: '30px', marginBottom: '10px', width: '110px', height: '110px' }}/>
                        <Typography
                            component="p"
                            className={userType == "patient" ? "link1" : "link2"}
                            sx={{marginBottom:'10px'}}
                        >
                            {t("Signup.user")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{
                        cursor: "pointer",
                        border: userType === "doctor" ? 'solid 2px #229cb0' : 'none',
                        height: '100% !important',
                        borderRadius: '10px'
                    }}
                        onClick={() => setUserType("doctor")}
                    >
                        <img src={images.Doctor} style={{ marginTop: '30px',marginBottom:'10px',width:'110px',height:'110px' }} />
                        <Typography
                            component="p"
                            className={userType == "doctor" ? "link1" : "link2"}
                            sx={{ marginBottom: '10px' }}
                        >
                            {t("Signup.doctor")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                    <PrimaryButton
                        text={t("Checkups.close")}
                        classBtn='primary btn-rounded btn-medium'
                        click={handleClose}
                    />
                </Grid>
            </Grid>
        </Dialog >
    )
}

export default AccountTypeModal
