import { Grid, Divider, Dialog, DialogContent, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from '../../../../Assets/images/IconHelper';
import { useNavigate } from "react-router-dom";
import { ConvertToArabicNumbers } from "../../../../Contexts/API/Helper/Helper";
import moment from "moment";

function SuccessReservation(props) {
    const { t, open, handleClose, invoiceData } = props;
    const Navigate = useNavigate();
    const lang = localStorage.getItem('lang')

    const InoviceDetailsArray = [
        {
            title: t("UserAppointments.bill_no"),
            value: lang === 'en' ? invoiceData?.transaction?.id : ConvertToArabicNumbers(invoiceData?.transaction?.id)
        },
        {
            title: t("UserAppointments.date"),
            value: lang === 'ar' ? moment(invoiceData?.date).locale('ar').format("YYYY/MM/DD") : moment(invoiceData?.date).locale('en').format("DD/MM/YYYY")
        },
        {
            title: t("UserAppointments.medical_id"),
            value: invoiceData?.patient_profile?.medical_profile_number
        },
        {
            title: t("UserAppointments.patient_name"),
            value: invoiceData?.patient_profile?.name
        },
        {
            title: t("UserAppointments.service_type"),
            value: invoiceData?.transaction?.service_type_display
        },
        {
            title: t("UserAppointments.medical_provider"),
            value: invoiceData?.medical_service?.medical_provider?.name
        },
        {
            title: `${t("UserAppointments.discount")} ${lang === 'en' ? invoiceData?.transaction?.discount_percentage : ConvertToArabicNumbers(invoiceData?.transaction?.discount_percentage)} %`,
            value: `${invoiceData?.transaction?.discount_value == null && lang === 'en' ? "0" : invoiceData?.transaction?.discount_value == null && lang === 'ar' ? ConvertToArabicNumbers(0) : lang === 'en' ? invoiceData?.transaction?.discount_value : ConvertToArabicNumbers(invoiceData?.transaction?.discount_value)} ${t("TelemedicineSettings.sar")}`
        },
        {
            title: t("UserAppointments.total_amount"),
            value: `${invoiceData?.transaction?.amount == null && lang === 'en' ? "0.0" : invoiceData?.transaction?.amount == null && lang === 'ar' ? ConvertToArabicNumbers(0.0) : lang === 'en' ? invoiceData?.transaction?.amount : ConvertToArabicNumbers(invoiceData?.transaction?.amount)} ${t("TelemedicineSettings.sar")}`
        },
    ];
    const closeSuccessPopup = () => {
        Navigate("/user/appointments", { state: { type: invoiceData?.medical_service?.service_type } });
        handleClose();
    };
    return (
        <Dialog
            onClose={() => closeSuccessPopup()}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='600'
            PaperProps={{
                sx: {
                    borderRadius: "15px",
                    height: 'auto',
                    width: "600px",
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogContent>
                <Grid container md={12}>
                    <Grid className="mb-20px" sm={12} style={{ textAlign: "center" }}>
                        <img src={icons.Success} alt="invoice details" />
                    </Grid>
                    <Grid sm={12} style={{ textAlign: "center" }} className="mb-10px">
                        <Typography className="cairo-font" component="p" style={{ color: "#099731", fontWeight: "bold", fontSize: "25px" }}>{t("Reservation.success_reservation")}</Typography>
                    </Grid>
                    <Grid sm={12} style={{ textAlign: "center" }} className="mb-10px">
                        <Typography className="cairo-font" component="p" style={{ color: "var(--font-color)", fontWeight: "lighter", fontSize: "18px" }}>
                            {t("UserAppointments.bill_no")} {lang === 'en' ? invoiceData?.transaction?.id : ConvertToArabicNumbers(invoiceData?.transaction?.id)}
                        </Typography>
                    </Grid>
                    <Grid sm={12} style={{ textAlign: "center" }} className="mb-20px">
                        <Typography className="cairo-font" component="p" style={{ color: "#000", fontWeight: "lighter", fontSize: "19px" }}>
                            {t("Reservation.you_can_go_appointments")}
                        </Typography>
                    </Grid>
                    <Divider style={{ width: '100%', marginBottom: '10px' }} />
                    {InoviceDetailsArray.map((invoiceItem, i) => (
                        <Grid container sm={12} className="mb-20px" style={{ padding: "0 20px" }} key={i}>
                            <Grid xs={6} style={{ textAlign: "start" }}>
                                <Typography className="cairo-font" component="p" style={{ color: "var(--font-color)", fontSize: "20px" }}>{invoiceItem?.title}</Typography>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "end" }}>
                                <Typography className="cairo-font" component="p" style={{ color: "#000", fontSize: "20px" }}>{invoiceItem?.value}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container md={12}>
                        <PrimaryButton
                            classBtn="btn-rounded primary cairo-font"
                            text={t("Reservation.go_to_appointments")}
                            style={{ width: "fit-content", margin: "auto", padding: "10px 35px" }}
                            click={() => closeSuccessPopup()}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(SuccessReservation)
