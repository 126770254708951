import { Grid, Typography, Divider } from "@mui/material"
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'

import { icons } from '../../../Assets/images/IconHelper'

const AboutUsTemplate = ({ t, aboutUs }) => {

    return (
        <Grid container>
            <Grid item xs></Grid>
            <Grid
                container
                lg={7}
                md={10}
                xs={12}
                rowSpacing={3}
                columnSpacing={{ xs: 3 }}
                className="tp-bt-padding main-container"
            >
                <Grid container>
                    {ReactHtmlParser(aboutUs)}
                </Grid>
                <Divider sx={{ width: '100%', marginBottom: '20px' }} />
                <Grid container justifyContent='end' alignContent='start' space>
                    <Typography variant='subtitle1' sx={{ marginInlineEnd: '35px' }} >
                        {t("AboutUs.follow")}
                    </Typography>
                    <a href="https://www.instagram.com/medvalleyksa/" target="_blank" rel="noopener noreferrer">
                        <img src={icons.InstgramIcon} style={{ marginInlineEnd: '27px', cursor: 'pointer' }} />
                    </a>
                    <a href="https://twitter.com/MedvalleyKSA" target="_blank" rel="noopener noreferrer">
                        <img src={icons.TwitterIcon} style={{ marginInlineEnd: '27px', cursor: 'pointer' }} />
                    </a>
                    <a href="https://www.facebook.com/MedvalleyKSA/" target="_blank" rel="noopener noreferrer">
                        <img src={icons.FacebookIcon} style={{ marginInlineEnd: '5px', cursor: 'pointer' }} />
                    </a>
                </Grid>
            </Grid>
            <Grid item xs></Grid>
        </Grid>
    )
}

export default AboutUsTemplate