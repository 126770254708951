import { useState, useEffect } from 'react'
import { Grid, Typography, Divider } from '@mui/material'

import { icons, images } from "../../../../../../Assets/images/IconHelper"
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton"
import PlaceHolder from '../../../../../Atoms/Placeholder/Placeholder'
import SurgeryCard from '../../../Cards/SurgeryCard'
import AddEditSurgery from '../../../../../Organisms/Modals/Surgery/AddEditSurgery'
import CustomTablePagination from "../../../../../Atoms/Pagination/Pagination";

const SurgicalOperations = (props) => {
    const { t, surgeriesPaginationInfo, getSurgeries } = props
    const [open, setOpen] = useState(false)
    const [type, setType] = useState('')
    const [item, setItem] = useState('')

    const handleClose = () => setOpen(false)

    const openSurgeryModal = (type,item) => {
        setType(type)
        setItem(item)
        setOpen(true)
    };

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    useEffect(() => {
        getSurgeries(filterObj)
    }, [filterObj])

    return (
        <Grid>
            <Typography mt={4} variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                {t("Surgeries.surgeries")}
            </Typography>
            <Grid container
                my={5}
                py={2}
                px={3}
                sx={{ backgroundColor: "#e2f8fb", borderRadius: '14px' }}
            >
                <Grid item mx={2}>
                    <img src={icons.MedFile} alt='Medical File Number' />
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1'>
                        {t("UserProfile.your_medFile_number")}
                    </Typography>
                    <Typography variant='subtitle1' sx={{
                        fontWeight: 'bold',
                        color: 'var(--secondary-color)',
                        lineHeight: '1',
                        letterSpacing: '1.5px'
                    }}>
                        {props?.medicalFileNumber}
                    </Typography>
                </Grid>
            </Grid>
            {props.surgeries?.length !== 0 ?
                props.surgeries?.map((item, i) =>
                    <>
                        <SurgeryCard
                            t={t}
                            item={item}
                            key={i}
                            removeSurgery={props.removeSurgery}
                            openSurgeryModal={openSurgeryModal}
                        />
                        {i !== props?.surgeries?.length - 1 && <Divider />}
                    </>
                )
                :
                <PlaceHolder
                    src={images.EmptyPlaceholder}
                    alt='Empty Box'
                    mainTitle={t("Surgeries.no_surgeries")}
                    subTitle={t("Surgeries.add_surgeries")}
                    txtContainer='text-container'
                />
            }
            {surgeriesPaginationInfo?.total_count > 10 && (
                <CustomTablePagination
                    totalCount={surgeriesPaginationInfo?.total_count}
                    changePage={(val) => paginationChangePage(val)}
                    handleRowsPerPage={(val) => handleRowsPerPage(val)}
                    t={t} 
                />
            )}
            <Grid container mt={15} justifyContent='center'>
                <PrimaryButton
                    text={t("Checkups.new_add")}
                    classBtn='add-diagnosis-btn'
                    click={() => openSurgeryModal('add')}
                />
            </Grid>
            <AddEditSurgery
                t={t}
                open={open}
                handleClose={handleClose}
                type={type}
                item={item}
                createSurgery={props.createSurgery}
                editSurgery={props.editSurgery}
            />
        </Grid>
    )
}

export default SurgicalOperations