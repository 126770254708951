import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function changePassword(recivedData) {
    const data = { ...recivedData };

    return await axiosFn("put", URL.PASSWORD_CHANGE, data)
        .then(async (res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function changePasswordAPI(data) {
    let { res, err } = await changePassword(data);
    return { res, err };
}
