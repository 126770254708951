import { Grid, Typography } from "@mui/material"

const SurgeriesCard = ({ item }) => {

    return (
        <Grid md={12} mt={3} py={2} px={3}
            sx={{ borderRadius: '12px', boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.07)' }} >
            <Typography variant="caption">
                {item?.date}
            </Typography>
            <Typography
                variant="h6"
                style={{ color: 'var(--secondary-color)' }}
            >
                {item?.surgery_name}
            </Typography>
            <Typography variant="caption">
                {item?.description}
            </Typography>
        </Grid>
    )
}

export default SurgeriesCard