import { Grid, Typography, Skeleton, Avatar } from "@mui/material"

import { icons } from "../../../../Assets/images/IconHelper"

const PatientCard = ({ t, reservation, handleChange, index, isActive, UserStatus, userFirebaseNode }) => {
    return (
        <div
            onClick={handleChange}
            style={{ cursor: 'pointer', width: '100%' }}
        >
            <Grid
                container
                py={2}
                px={3}
                bgcolor={index === isActive ? '#44bbcb' : ''}
            >
                <Grid
                    container
                    item
                    md={2}
                    justifyContent='center'
                    alignContent='center'
                    sx={{position:'relative'}}
                >
                    <Avatar
                        src={reservation?.patient_profile?.avatar}
                        alt='Avatar'
                        style={{ width: '40px' }}
                    />
                    <span className={UserStatus[userFirebaseNode] == 'online' ? 'user-status active' : 'user-status offline'} style={{left:'15px',bottom:'3px'}}></span>
                </Grid>
                <Grid item md={8}>
                    <Typography variant="h6">
                        {reservation?.patient_profile?.name}
                    </Typography>
                    <Typography variant="body2">
                        {
                            reservation?.is_main_profile ?
                                t("DoctorTelemedicine.main_account") :
                                t("DoctorTelemedicine.sub_account")
                        }
                    </Typography>
                </Grid>
                <Grid container item md={2} justifyContent='center' alignContent='center'>
                    <img src={icons.GroupTherapy} alt='patient Data Icon' />
                </Grid>
            </Grid >
        </div>
    )
}

export default PatientCard