import { Grid, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'

import { images } from "../../../../../../Assets/images/IconHelper"
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton"

import PlaceHolder from '../../../../../Atoms/Placeholder/Placeholder'

const Xray = (props) => {
    const { t } = useTranslation()
    return (
        <Grid container justifyContent={'center'}>
            {props?.xrayList?.length > 0 ?
                props?.xrayList?.map((item, i) =>
                    <Grid md={12} className="therapy-item mb-10px">
                        <Grid container>
                            <Grid md={9}>
                                <Typography
                                    component="subtitle1"
                                    style={{ color: 'var(--font-color)' }}
                                >
                                    {item?.date}
                                </Typography>
                            </Grid>
                            <Grid
                                md={2}
                                style={{ textAlign: "end" }}>
                                <PrimaryButton
                                    btnType="icon"
                                    startIcon={<img src={images.PenReverse} alt='Edit Icon' />}
                                    click={() => props.handleOpenAddEditExaminationModal('xray', 'edit', item)}
                                />
                            </Grid>
                            <Grid
                                md={1}
                                style={{ textAlign: "end" }}>
                                <PrimaryButton
                                    startIcon={<ClearIcon />}
                                    btnType="icon"
                                    classBtn="clear-icon"
                                    click={() => {
                                        const ids = []
                                        item?.attachments?.map((attachment) => {
                                            ids.push(attachment.id)
                                        })
                                        props.handleOpenConfirmDeleteModal(ids, 'xray')
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className="mb-5px">
                            {item?.attachments?.length > 0 && item?.attachments?.map((attachment) => {
                                return <img
                                    src={attachment?.url}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        marginInlineEnd: '20px',
                                        marginBottom: '20px',
                                        cursor: 'pointer'
                                    }}
                                    alt='attachment'
                                    onClick={() => props.openView(attachment?.url)}
                                />
                            })}
                        </Grid>
                    </Grid>
                )
                :
                <PlaceHolder
                    src={images.EmptyPlaceholder}
                    alt='Empty Box'
                    mainTitle={t("Checkups.no_xray")}
                    subTitle={t("Checkups.add_xray")}
                    txtContainer='text-container'
                />
            }
            <Grid container mt={10} justifyContent='center'>
                <PrimaryButton
                    text={t("Checkups.new_add")}
                    classBtn='add-diagnosis-btn'
                    click={() => props.handleOpenAddEditExaminationModal('xray', 'add')} />
            </Grid>
        </Grid>
    )
}

export default Xray