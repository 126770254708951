import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import CertificatesView from "../../../Molecules/DoctorProfile/TabPanels/Certificates/CertificatesView";
import EditCertificates from "../../../Molecules/DoctorProfile/TabPanels/Certificates/EditCertificates";
import { useState, useEffect } from "react";
import { getDoctorCertificatesAPI, addCertificateAPI } from "../../../../Contexts/API/DoctorProfile/doctorCertificates";
import Loader from "../../../Atoms/Loader/Loader";

function Certificates(props) {
  const { t } = props;
  const [viewFlag, changeView] = useState(true);
  const [certificatesList, setCertificateList] = useState([]);
  const [newCertificatesList, setnewCertificateList] = useState([]);
  const [openLoader, setLoader] = useState(false);

  const handleEdit = async (flag, action) => {
    if(action == 'save'){
      setLoader(true);
      const res = await addCertificateAPI(newCertificatesList);
      if (res)  changeView(flag);
      window.location.reload();
      setLoader(false)  
    }else{
      changeView(flag);
    }
  }

  const certificates = async () => {
    setLoader(true);
    const res = await getDoctorCertificatesAPI();
    if (res) setCertificateList(res?.data?.certificates);
    setLoader(false);
  }

  const updateCallback = (newList) => {
    setnewCertificateList(newList);
  }

  useEffect(() => {
    certificates();
  }, []);
  
  return (
    <div>
      {viewFlag && (
        <div>
          <Loader open={openLoader} />
          <CertificatesView images={certificatesList} />
          <PrimaryButton
            classBtn="edit-btn"
            text={t("DoctorProfile.add_btn")}
            click={() => handleEdit(false, 'edit')}
          />
        </div>
      )}
      {!viewFlag && (
        <div>
          <EditCertificates
            updateCallback={(newList) => updateCallback(newList)}
            images={certificatesList}
          />
          <PrimaryButton
            classBtn="edit-btn"
            text={t("DoctorProfile.save_btn")}
            click={() => handleEdit(true, 'save')}
          />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Certificates);