import { axiosFn, handleError } from "../index"

export async function DeactivateAccount() {
    return await axiosFn("post", "/user/settings/deactivate")
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function DeactivateAccountAPI() {
    let { err, res } = await DeactivateAccount()

    if (res) return res

    if (err) return err
}
