import { useState } from "react"
import { Grid, Typography, Divider, Container, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import en from "react-phone-number-input/locale/en.json"
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { toast } from "react-toastify"
import { Link } from 'react-router-dom'

import { images, icons } from '../../../Assets/images/IconHelper'
import PhoneInput from "../../../Components/Atoms/Input/PhoneInput/PhoneInput"
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton"
import TextFieldInput from "../../../Components/Atoms/Input/TextField/TextFieldInput"

const ContactUsTemplate = ({ SendMessage }) => {

    const { t } = useTranslation()
    const [countryCode, setCountryCode] = useState("966")

    return (
        <Grid container>
            <Grid item xs></Grid>
            <Grid
                container
                lg={7}
                md={10}
                xs={12}
                rowSpacing={3}
                columnSpacing={{ xs: 3 }}
                className="tp-bt-padding main-container"
            >
                <Grid item xs={6}>
                    <Grid container mb={4}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }} >
                            {t("ContactUs.contact")}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <a href='https://web.whatsapp.com/' target="_blank" rel="noreferrer noopener">
                            <img src={icons.WhatsappIcon} style={{ marginInlineEnd: '16px' }} />
                        </a>
                        <Typography variant="body1">
                            {t("Signup.whats_app")}
                        </Typography>
                        <Divider sx={{ width: '100%', marginBottom: '15px', marginTop: '15px' }} />
                        <img src={icons.EmailIcon} style={{ marginInlineEnd: '16px' }} />
                        <Typography variant="body1">
                            {t("Signup.send_email")}
                        </Typography>
                        <Container style={{ marginInlineStart: '20px' }}>
                            <Link to='#'
                                onClick={(e) => {
                                    e.preventDefault()
                                    window.location.href = "mailto:info@Medicalapp.com"
                                }}
                            >
                                <Typography variant="body1">
                                    info@Medicalapp.com
                                </Typography>
                            </Link>
                        </Container>
                        <Divider sx={{ width: '100%', marginBottom: '29px', marginTop: '45px' }} >
                            {t("ContactUs.or")}
                        </Divider>
                        <Formik
                            initialValues={{
                                phone: '',
                                comment: ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    phone: Yup
                                        .string()
                                        .required(t("Validation.phone_required"))
                                        .matches(
                                            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                            t("Validation.valid_number")
                                        ),
                                    comment: Yup
                                        .string()
                                        .required(t("Validation.required_field"))
                                })
                            }
                            onSubmit={async (values, { resetForm }) => {
                                const data = {
                                    "message_data": {
                                        "contact_country_code": countryCode,
                                        "contact_phone": values.phone,
                                        "message": values.comment
                                    }
                                }
                                const res = await SendMessage(data)
                                if (res) toast.success(res.message)
                                resetForm()
                            }}
                        >
                            {({ submitForm, isValid, errors, values, handleChange, touched }) => (
                                <Form style={{ width: '100%' }}>
                                    <Grid container justifyContent='center'>
                                        <Field
                                            as={PhoneInput}
                                            id="phone"
                                            name="phone"
                                            inputValue={values.phone}
                                            setPhone={handleChange}
                                            //clickEnter={clickEnter}
                                            placeholder={t("ContactUs.phone")}
                                            labels={en}
                                            value={countryCode}
                                            onChange={(input) => setCountryCode(input)}
                                        />
                                        {errors.phone && touched.phone ?
                                            <Box sx={{
                                                color: 'red'
                                            }}>
                                                {errors.phone}
                                            </Box> : null}
                                        <Field
                                            as={TextFieldInput}
                                            multiline={true}
                                            className='textarea'
                                            placeholder={t("ContactUs.comment")}
                                            name='comment'
                                            id='comment'
                                            rows={5}
                                            inputProps={{
                                                style: {
                                                    position: 'absolute',
                                                    top: '10px',
                                                    width: '95%'
                                                }
                                            }}
                                        />
                                        {errors.comment && touched.comment ?
                                            <Box sx={{
                                                color: 'red'
                                            }}>
                                                {errors.comment}
                                            </Box> : null}
                                        <Grid container item xs={12} justifyContent='center' mt={2}>
                                            <PrimaryButton
                                                type='submit'
                                                text={t("ContactUs.send")}
                                                classBtn='primary btn-rounded btn-medium'
                                                click={submitForm}
                                                disabled={!isValid}
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
                <Grid item xs={6} justifyContent='center' alignContent='center'>
                    <img src={images.ContactUs} alt='Contact Us' />
                </Grid>
            </Grid>
            <Grid item xs></Grid>
        </Grid>
    )
}

export default ContactUsTemplate