import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper";

export async function getAppointmentsList(filter_obj) {
    return await axiosFn("get", URL.GET_USER_RESERVATIONS + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getAppointmentsListAPI(filter_obj) {
    let { err, res } = await getAppointmentsList(filter_obj)

    if (res) return res

    if (err) return err
}

export async function reviewReservation(data, id) {
    return await axiosFn("post", `/reservations/${id}/reviews`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function reviewReservationApi(data, id) {
    let { err, res } = await reviewReservation(data, id)

    if (res) return res

    if (err) return err
}

export async function getDiagnosisList(reservationId, userId) {
    return await axiosFn("get", `/users/${userId}/reservations/${reservationId}/diagnoses`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getDiagnosisListApi(reservationId, userId) {
    let { err, res } = await getDiagnosisList(reservationId, userId)

    if (res) return res

    if (err) return err
}
