import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"
import axios from "axios";

// List Prescription
export async function getListPrescription(id, type, filterObj) {
    return await axiosFn("get", URL.USER_STR + id + URL.PRESCRIPTIONS_STR + makeFilterString({ index_scope: type, page_number: filterObj.page_number, page_size: filterObj.page_size }))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getListPrescriptionAPI(id = localStorage.getItem("userId"), type = "history", filterObj) {
    let { err, res } = await getListPrescription(id, type, filterObj)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Show Prescription
export async function showPrescription(id, userID) {
    return await axiosFn("get", URL.USER_STR + userID + URL.PRESCRIPTIONS_STR + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function showPrescriptionAPI(id, userID = localStorage.getItem("userId")) {
    let { err, res } = await showPrescription(id, userID)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Get PDF Prescription
export async function getPdfPrescription(id, userID) {
    return await axios.get(URL.BASE_URL + URL.USER_STR + userID + URL.PRESCRIPTIONS_STR + id + URL.PDF,
        {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
                'Authorization': `Bearer ${localStorage.getItem("TOKEN")}`
            }
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Prescription.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(handleError);
};

export async function getPdfPrescriptionAPI(id, userID = localStorage.getItem("userId")) {
    let { err, res } = await getPdfPrescription(id, userID)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Create History Prescription
export async function createPrescription(data) {
    return await axiosFn("post", URL.USER_STR + localStorage.getItem("userId") + URL.PRESCRIPTIONS_STR, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function createPrescriptionAPI(data) {
    let { err, res } = await createPrescription(data)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Edit History Prescription
export async function editPrescription(id, data) {
    const Url = URL.USER_STR + localStorage.getItem("userId") + URL.PRESCRIPTIONS_STR + id
    return await axiosFn("put", Url, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function editPrescriptionAPI(id, data) {
    let { err, res } = await editPrescription(id, data);
    if (res) {
        return res;
    }
    if (err) {
        return err;
    }
}

// Delete History Prescription
export async function deletePrescription(id) {
    return await axiosFn("delete", URL.USER_STR + localStorage.getItem("userId") + URL.PRESCRIPTIONS_STR + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function deletePrescriptionAPI(id) {
    let { err, res } = await deletePrescription(id)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}