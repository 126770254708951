import { FormControl, FormHelperText, MenuItem } from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import "./Select.css";

function SelectBox({
  items,
  title,
  value,
  onchange,
  id,
  name,
  error,
  helperText,
  labelStyle,
  label,
  disabled,
  onclick = () => {},
  sx,
}) {
  // const [value, setValue] = React.useState("");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  return (
    <FormControl
      disabled={disabled}
      fullWidth
      style={{ marginBottom: "20px", direction: localStorage.getItem("lang") === "en" ? "ltr" : "rtl" }}
      error={error ? true : false}
      sx={sx}
    >
      {label && <span className={labelStyle}>{label}</span>}
      <Select
        id={id}
        name={name}
        className={error ? "select-error" : "select-input"}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        value={value}
        onChange={onchange}
        style={{ direction: localStorage.getItem("lang") === "en" ? "ltr" : "rtl" }}
      >
        <MenuItem value="" disabled style={{ direction: localStorage.getItem("lang") === "en" ? "ltr" : "rtl" }}>
          {title}
        </MenuItem>
        {items?.map((item) => (
          <MenuItem
            value={item.id}
            key={item.id}
            onClick={() => onclick(item.id)}
            style={{ direction: localStorage.getItem("lang") === "en" ? "ltr" : "rtl" }}
          >
            {item.icon ? <img style={{ margin: "0 10px" }} src={item.icon} /> : ""} {item.name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : ""}
    </FormControl>
  );
}

export default SelectBox;
