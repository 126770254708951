// import { useState, useEffect } from "react";
import { Grid, Divider, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import ClearIcon from '@mui/icons-material/Clear';
import { images } from '../../../../Assets/images/IconHelper';
import { ConvertToArabicNumbers } from "../../../../Contexts/API/Helper/Helper";
import moment from 'moment'

function InvoiceDetails(props) {
    const { t, open, handleClose, invoiceData, spName } = props;
    const lang = localStorage.getItem("lang")

    const InoviceDetailsArray = [
        {
            title: t("UserAppointments.bill_no"),
            value: lang === 'en' ? invoiceData?.transaction?.id : ConvertToArabicNumbers(invoiceData?.transaction?.id)
        },
        {
            title: t("UserAppointments.date"),
            value: lang === 'ar' ? moment(invoiceData?.date).locale('ar').format("YYYY/MM/DD") : moment(invoiceData?.date).locale('en').format("DD/MM/YYYY")
        },
        {
            title: t("UserAppointments.medical_id"),
            value: invoiceData?.patient_profile?.medical_profile_number
        },
        {
            title: t("UserAppointments.patient_name"),
            value: invoiceData?.patient_profile?.name
        },
        {
            title: t("UserAppointments.service_type"),
            value: invoiceData?.transaction?.service_type_display
        },
        {
            title: t("UserAppointments.medical_provider"),
            value: spName
        },
        {
            title: `${t("UserAppointments.discount")} ${lang === 'en' ? invoiceData?.transaction?.discount_percentage : ConvertToArabicNumbers(invoiceData?.transaction?.discount_percentage)} %`,
            value: `${invoiceData?.transaction?.discount_value === null && lang === 'en' ? "0" : invoiceData?.transaction?.discount_value === null && lang === 'ar' ? ConvertToArabicNumbers(0) : lang === 'en' ? invoiceData?.transaction?.discount_value : ConvertToArabicNumbers(invoiceData?.transaction?.discount_value)} ${t("TelemedicineSettings.sar")}`
        },
    ]
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='600'
            PaperProps={{
                sx: {
                    borderRadius: "15px",
                    height: 'auto',
                    width: "600px",
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogTitle>
                <Grid container justifyContent="end">
                    <PrimaryButton
                        startIcon={<ClearIcon />}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={handleClose}
                    />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item sm={12} style={{ textAlign: "center" }}>
                        <img src={images.InvoiceDetails} alt="invoice details" />
                    </Grid>
                    <Grid item sm={12} style={{ textAlign: "center" }} className="mb-30px">
                        <Typography className="cairo-font" component="p" style={{ color: "#000", fontWeight: "bold", fontSize: "25px" }}>{t("UserAppointments.bill_details")}</Typography>
                    </Grid>
                    {InoviceDetailsArray.map((invoiceItem, i) => (
                        <Grid container className="mb-20px" style={{ padding: "0 20px" }} key={i}>
                            <Grid item xs={6} style={{ textAlign: "start" }}>
                                <Typography className="cairo-font" component="p" style={{ color: "var(--font-color)", fontSize: "20px" }}>{invoiceItem?.title}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "end" }}>
                                <Typography className="cairo-font" component="p" style={{ color: "#000", fontSize: "20px" }}>{invoiceItem?.value}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                    <Divider style={{ width: "100%", margin: "10px 0", border: "none", borderTop: "2px dashed #bbb" }} />
                    <Grid container className="mb-20px" style={{ padding: "0 20px" }}>
                        <Grid item xs={6} style={{ textAlign: "start" }}>
                            <Typography className="cairo-font" component="p" style={{ color: "var(--font-color)", fontSize: "20px" }}>{t("UserAppointments.total_amount")}</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "end" }}>
                            <Typography className="cairo-font" component="p" style={{ color: "#000", fontSize: "20px" }}>
                                {lang === 'en' ? invoiceData?.transaction?.amount + t("TelemedicineSettings.sar") : ConvertToArabicNumbers(invoiceData?.transaction?.amount) + t("TelemedicineSettings.sar")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(InvoiceDetails)
