import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { AddMsg, ChatRoom, GetMessages, handleFileInputs, handleFiles, OffDatabaseConnection, SendMessage } from "../../../../Contexts/API/Chatting/chat";
import { SignInFirebase } from "../../../../Contexts/Firebase/Firebase";
import ChatHistoryTemplate from "../../../../Templates/Main/Appointments/ChatHistory/ChatHistory.template.jsx";


const ChatHistory = ({ }) => {
  const { id } = useParams();
  const [chatRoom,setchatRoom] = useState({});
  const [Chatmessages, setChatmessages] = useState([]);
  const [message, setmessage] = useState('');
  const [Files, setfiles] = useState([]);
  const [FilesToSend, setFilesToSend] = useState([]);
  const location = useLocation()
  const medicalProvider = location?.state?.medical_provider
  const getChatRoom = async () => {

    let {res,err} = await ChatRoom(id);
    if(res){
      let url = new URL(res?.data?.chat_room?.messages_url)
      setchatRoom(res?.data?.chat_room)
      localStorage.setItem('MESSAGE_NODE',url.pathname.slice(1))
      SignInFirebase();
      setChatmessages([]);
    }
  }
  const getMessages = async () => {
    
    await GetMessages(localStorage.getItem('MESSAGE_NODE'))
    window.addEventListener('getmessage', async (evt) => {
        setChatmessages(evt.data);
    });

}
const sendMessage = async () => {

  if (message != "" || FilesToSend?.length > 0) {
      await SendMessage(message, FilesToSend, localStorage.getItem('userId'), 'patient')
      let { err, res } = await AddMsg(id,{
        message: message,
        message_type: "text_msg",
        media_type:"",
        payload: FilesToSend
      })

      if (res) {
        setmessage("")
        setfiles([])
        setFilesToSend([])
        getMessages()
      }
  }
}

const handleFileInput = ({ target: { files } }) => {
  let result = handleFileInputs(Files, files)
  setfiles(result)
}

// const handleFileRemove = (index) => {
//   let result = handleFileRemoves(Files, index)
//   setfiles([...result])
// }

useEffect(async () => {
  let urls = await handleFiles(Files)
  const FilesArray = await Promise.all(urls)
  setFilesToSend(FilesArray)
}, [Files])

useEffect(() => {
    getChatRoom();
    setTimeout(() => {
        if (localStorage.getItem('MESSAGE_NODE')) getMessages();
    }, 3000);
    return () => {
        OffDatabaseConnection(localStorage.getItem('MESSAGE_NODE'));
        window.removeEventListener('getmessage', () => { console.log('event off') });
    };

}, [])



  return(
    <ChatHistoryTemplate 
      chatRoom={chatRoom} 
      Chatmessages={Chatmessages} 
      setmessage={setmessage} 
      message={message}
      sendMessage={sendMessage}
      handleFileInput={handleFileInput}
      medicalProvider={medicalProvider}
    />
  )
};

export default ChatHistory;
