import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function Logout() {
    return await axiosFn("post", URL.LOGOUT)
        .then(async (res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function LogoutAPI() {
    let { res, err } = await Logout();
    return { res, err };
}

export async function BatchLogout(data) {
    return await axiosFn("post", URL.BATCH_LOGOUT, data)
        .then(async (res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function BatchLogoutAPI(data) {
    let { res, err } = await BatchLogout(data);
    return { res, err };
}
