import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider, Box } from "@mui/material";
import { images } from "../../../../Assets/images/IconHelper";
import Placeholder from "../../../Atoms/Placeholder/Placeholder";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { convertTimeFormate, ConvertToArabicNumbers } from "../../../../Contexts/API/Helper/Helper";
// import StarRatings from "react-star-ratings";
import { firebaseSDK } from "../../../../Contexts/Firebase/Firebase.js";
import { useState, useEffect } from "react";
import RatingAtom from "../../../Atoms/Rating/Rating";

function DoctorInfoPanel(props) {
    const { t, list, date } = props;
    const lang = localStorage.getItem("lang");
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    var NewArr = [];

    const RealTimeDatabase = firebaseSDK.database();
    const [UserStatus, setUserStatus] = useState([]);

    const goToDoctorProfile = (id) => {
        if (pathnameArr[4] === 'telemedicine') {
            navigate("/user/home/telemedicine/" + pathnameArr[5] + "/doctor-profile?" + "id=" + id + "&date=" + date);
        } else {
            navigate("/user/home/" + pathnameArr[4] + "/medical-provider" + "/doctor-profile?" + "id=" + id + "&date=" + date);
        }
    };

    useEffect(() => {
        list?.map((appointment) => {
            const userUrl = appointment?.firebase_node_url
            const userNode = new URL(userUrl).pathname.slice(1);
            var statusObj = RealTimeDatabase.ref(userNode + "/status")
            statusObj.on('value', (snapshot) => {
                NewArr[userNode] = (snapshot.val());
                setUserStatus(NewArr);
            });
        })
    }, [list]);

    return (
        <Grid container md={12} className="doctor-info-container">
            {searchParams.get("name") !== 'null' && (
                <Grid container md={11} style={{ margin: "auto" }}>
                    <Typography className="mb-30px" component="span" style={{ color: "var(--font-color)", fontSize: "13px" }}>
                        {searchParams.get("name")}
                    </Typography>
                </Grid>
            )}
            {list?.length === 0 || list === null ? (
                <Placeholder
                    src={images.NoConnection}
                    mainTitle={t("TelemedicineSettings.use_other_result")}
                    subTitle={t("TelemedicineSettings.check_name")}
                    txtContainer="placeholder-text-container"
                />
            ) : (
                list?.map((doctorItem, i) => (
                    <Grid
                        container md={12}
                        className="doctor-info-panel mb-30px"
                        onClick={() => goToDoctorProfile(doctorItem.medical_provider_id)}
                    >
                        <Grid container md={11} style={{ margin: "auto" }}>
                            <Grid xs={1.8}>
                                <div style={{
                                    width: '78px',
                                    height: '78px',
                                    position: 'relative'
                                }}>
                                    <img className="doctor-image-search" src={doctorItem?.avatar ? doctorItem?.avatar : images.userAvatar} alt="" />
                                    <span className={UserStatus[new URL(doctorItem?.firebase_node_url).pathname.slice(1)] == 'online' ? 'user-status active' : 'user-status offline'}></span>
                                </div>
                            </Grid>
                            <Grid xs={10.2} className="current-panel-info">
                                <Typography style={{ color: "var(--secondary-color)" }} component="h1">
                                    {doctorItem?.name}
                                </Typography>
                                <Typography component="p">
                                    {doctorItem?.medical_title} - {doctorItem?.speciality}
                                </Typography>
                                <Typography component="p">
                                    {doctorItem?.subspeciality}
                                </Typography>
                                <Typography component="p">
                                    {doctorItem?.gender === 'female' && lang === 'ar' ? 'أنثي' : doctorItem?.gender === 'male' && lang === 'ar' ? 'ذكر' : doctorItem?.gender}
                                </Typography>
                                <Grid xs={12} className="rate-container">
                                    {/* <StarRatings
                                        rating={doctorItem?.avg_rating}
                                        starRatedColor="#fcb862"
                                        starSpacing="1px"
                                    /> */}
                                    <RatingAtom
                                        initialValue={doctorItem?.avg_rating}
                                        readonly={true}
                                        size={17}
                                        fillColor='#fcb862'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {pathnameArr[4] !== 'telemedicine' && (
                            <Grid container>
                                <Divider style={{ width: "100%", margin: "10px 0" }} />
                                {doctorItem?.medical_services[0]?.available_time_slots.map((time) => (
                                    <Grid container md={11} style={{ margin: "auto" }}>
                                        <Grid xs={6} style={{ textAlign: "start" }}>
                                            <Typography component="p" style={{ fontSize: "14px", color: "var(--font-color)" }}>
                                                {
                                                    t("DoctorProfile.from") +
                                                    convertTimeFormate(time?.start_time) +
                                                    t("DoctorProfile.to") +
                                                    convertTimeFormate(time?.end_time)
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} style={{ textAlign: "end" }}>
                                            <Typography component="p" style={{ fontSize: "14px", color: "var(--font-color)" }}>
                                                {lang === 'en' ? time?.remaining_capacity : ConvertToArabicNumbers(time?.remaining_capacity)}{t("Clinics.available_chairs")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Divider style={{ width: "100%", margin: "10px 0" }} />
                                <Grid container md={11} style={{ margin: "auto" }}>
                                    <Grid xs={6} style={{ textAlign: "start" }}>
                                        <Typography component="p" style={{ fontSize: "13px", color: "var(--font-color)" }}>
                                            {t("Clinics.examination_fee")}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={6} style={{ textAlign: "end" }}>
                                        {doctorItem?.medical_services?.[0]?.has_offers &&
                                            <>
                                                <Typography display='inline' component="del" style={{ color: "#7e7e7e", fontWeight: "bold", fontSize: '16px' }}>
                                                {lang === 'en' ? doctorItem?.medical_services[0]?.fees_SAR : ConvertToArabicNumbers(doctorItem?.medical_services[0]?.fees_SAR)}
                                                </Typography>
                                                <Typography display='inline' component="del" style={{ fontSize: '14px', color: "#7e7e7e", marginInlineEnd: '18px' }}>
                                                    {t("TelemedicineSettings.sar")}
                                                </Typography>
                                            </>
                                        }
                                        <Typography display='inline' component="p" style={{ color: "var(--secondary-color)", fontWeight: "bold", paddingStart: '18px' }}>
                                            {lang === 'en' ? doctorItem?.medical_services[0]?.fees_after_discount : ConvertToArabicNumbers(doctorItem?.medical_services[0]?.fees_after_discount)} {t("TelemedicineSettings.sar")}
                                        </Typography>
                                        {/* <Typography component="p" style={{ color: "var(--secondary-color)", fontWeight: "bold" }}>
                                            {doctorItem?.medical_services[0]?.fees_SAR}{t("TelemedicineSettings.sar")}
                                        </Typography> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {pathnameArr[5] === 'private-doctor-search' && (
                            <Grid container>
                                {
                                    doctorItem?.medical_services[0]?.has_offers ?
                                        <Grid container item xs={12} mb={1}
                                            sx={{
                                                height: '30px',
                                                bgcolor: '#55d4e580',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography component="p"
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: 'bold',
                                                    color: '#34a8b9'
                                                }}
                                            >
                                                {lang === 'en' ? doctorItem?.medical_services[0]?.applied_offer?.discount_percentage : ConvertToArabicNumbers(doctorItem?.medical_services[0]?.applied_offer?.discount_percentage)}&nbsp;{'%'}&nbsp;
                                            </Typography>
                                            <Typography component="p"
                                                style={{ fontSize: "12px", color: '#34a8b9' }}>
                                                {t("Login.discount_telemedicine")}
                                            </Typography>
                                        </Grid> :
                                        <Divider style={{ width: "100%", margin: "10px 0" }} />
                                }
                                <Grid container md={11} style={{ margin: "auto" }}>
                                    <Grid xs={6} style={{ textAlign: "start" }}>
                                        <Typography
                                            component="p"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {lang === 'en' ? doctorItem?.medical_services[0]?.duration : ConvertToArabicNumbers(doctorItem?.medical_services[0]?.duration)} {t("TelemedicineSettings.min")}
                                        </Typography>
                                        <Typography
                                            component="p"
                                            style={{ color: "var(--font-color)" }}
                                        >
                                            {t("TelemedicineSettings.deal_with_doctor")}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={6} style={{ textAlign: "end" }}>
                                        <Typography
                                            component="p"
                                            style={{
                                                color: "var(--secondary-color)",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {lang === 'en' ? doctorItem?.medical_services[0]?.fees_SAR : ConvertToArabicNumbers(doctorItem?.medical_services[0]?.fees_SAR)}{t("TelemedicineSettings.sar")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {i < list?.length - 1 && <Divider style={{ width: "100%", marginTop: "20px", borderBottomWidth: 7 }} />}
                    </Grid>
                ))
            )}
        </Grid>
    );
}

export default withTranslation()(DoctorInfoPanel);