import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState, useEffect } from "react";
import SelectBox from "../../../Atoms/Select/Select";
import { Grid } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import RadioButtonsGroup from "../../../Atoms/Input/Radio/RadioInput";

function FilterSortPopup(props) {
  const { t, mode, medicalTitles, specialities } = props;
  const dir = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const [open, setOpen] = useState(false);
  const [sortValue, setSortValue] = useState("rating");
  const [medicalFilterValue, setMedicalFilterValue] = useState("");
  const [specialityFilterValue, setSpecialityFilterValue] = useState("");
  const handleClose = () => {
    props.handleCallback(false);
  };
  const getSelectValue = (e, indicator) => {
    if(indicator === 'medical_title') setMedicalFilterValue(e.target.value);
    else setSpecialityFilterValue(e.target.value);
  };
  const sortOptions = [
    {
        label: t("TelemedicineSettings.highest_rating"),
        value: 'rating'
    },
    {
        label: t("TelemedicineSettings.highest_experience"),
        value: 'medical_title'
    },
    {
        label: t("TelemedicineSettings.lowest_price"),
        value: 'low_price'
    },
    {
        label: t("TelemedicineSettings.highest_price"),
        value: 'high_price'
    },
  ];
  const getSortValue = (value) => {
    setSortValue(value);
  };
  const sortFilter = (mode) => {
    if(mode === 'sort') {
        props.getSortValue(sortValue);
    }else {
        props.getFilterValues(medicalFilterValue, specialityFilterValue);
    }
    handleClose();
  };
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={600}
        style={{direction: dir}}
      >
        <Grid container style={{width: "600px", overflowX: "hidden"}}>
            <Grid container md={12}>
                <Grid xs={11}>
                    <DialogTitle 
                        id="customized-dialog-title" 
                        onClose={handleClose}
                        style={{fontWeight: "bolder"}}
                    >
                        {mode === 'filter' ? (
                           t("TelemedicineSettings.filter")
                        ) : (
                            t("TelemedicineSettings.sort")
                        )}
                    </DialogTitle>
                </Grid>
                <Grid xs={1} style={{lineHeight: "4"}}>
                    <PrimaryButton
                        startIcon={<ClearIcon/>}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={() => handleClose()}
                    />
                </Grid>
            </Grid>
            <DialogContent>
                {mode === 'filter' ? (
                    <Grid md={12}>
                        <div className="select-box">
                            <SelectBox
                                id="medical_title"
                                name="medical_title"
                                items={medicalTitles}
                                title={t("DoctorProfile.medical_title")}
                                label={t("DoctorProfile.medical_title")}
                                labelStyle="select-label"
                                value={medicalFilterValue}
                                onchange={(e) => getSelectValue(e, 'medical_title')}
                            />
                        </div>
                        <div className="select-box">
                            <SelectBox
                                id="speciality"
                                name="speciality"
                                items={specialities}
                                title={t("DoctorProfile.medical_specialty")}
                                label={t("DoctorProfile.medical_specialty")}
                                labelStyle="select-label"
                                value={specialityFilterValue}
                                onchange={(e) => getSelectValue(e, 'speciality')}
                            />
                        </div>
                    </Grid>
                ) : (
                    <Grid md={12} className=" mb-30px">
                        <RadioButtonsGroup 
                            options={sortOptions}
                            row={false}
                            style={{
                                width: "100%", 
                                padding: "10px"
                            }}
                            change={(val) => getSortValue(val)}
                            value={sortValue}
                        />
                    </Grid>
                )}
                <Grid container md={12}>
                    <PrimaryButton 
                        classBtn="edit-btn" 
                        text={t("TelemedicineSettings.search")}
                        click={() => sortFilter(mode)}
                    />
                </Grid>
            </DialogContent>
        </Grid>
        
      </Dialog>
  );
}

export default withTranslation()(FilterSortPopup);