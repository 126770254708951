import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, switchClasses, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom'
import "./AppointmentsTelemedicine.css";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import CallIcon from '@mui/icons-material/Call';
import { StartEarlySession, EndSession } from "../../../../../Contexts/Zoom/ZoomIntegration";
import { images, icons } from "../../../../../Assets/images/IconHelper";
import { useState, useEffect } from "react";
import { convertTimeFormate } from "../../../../../Contexts/API/Helper/Helper";
import { firebaseSDK, SignInFirebase } from "../../../../../Contexts/Firebase/Firebase";
import CustomPopup from "../../../../Organisms/Modals/CustomPopup/CustomPopup";

function CurrentPanel({
    t,
    patient,
    start_time,
    end_time,
    can_start_call,
    can_send_start_early_request,
    reservationID,
    duration,
    serviceType,
    openPatientsList,
    list,
    saudiCommissionNumber,
    earlyCountDown,
    startDateTime,
    updateView,
    UserStatus,
    userFirebaseNode,
    panelStatus,
    panelInfo
}) {
    // const [panelStatus, setPanelStatus] = useState("");
    // const [panelInfo, setInfoStatus] = useState("");
    const navigate = useNavigate();
    const currentTime = new Date();
    const [countDown, setCountDown] = useState(0);
    const RealTimeDatabase = firebaseSDK.database();
    const [CallUrl, setCallUrl] = useState("");
    const [CallStatus, setCallStats] = useState("");
    const [appointmentUrl, setAppointmentUrl] = useState("");
    const [appointmentId, setAppointmentId] = useState("");
    const [openPopup, setOpenPopup] = useState(false);

    // const switchClasses = () => {
    //     if (can_start_call && serviceType === 'personal_telemedicine') {
    //         setPanelStatus("current-panel-container");
    //         setInfoStatus("current-panel-info");
    //     } else if (can_send_start_early_request && serviceType === 'personal_telemedicine') {
    //         setPanelStatus("next-panel-container");
    //         setInfoStatus("next-panel-info");
    //     } else if (can_start_call && serviceType === 'group_session') {
    //         setPanelStatus("group-panel-container");
    //         setInfoStatus("group-panel-info");
    //     } else {
    //         setPanelStatus("incoming-panel-container");
    //         setInfoStatus("incoming-panel-info");
    //     }
    // };

    const getCountDown = () => {
        if (currentTime > new Date(earlyCountDown)) {
            var milliSec = new Date(startDateTime) - currentTime
            setCountDown(milliSec / 60000)
        }
    };

    useEffect(() => {
        updateView();
    }, [CallStatus]);

    useEffect(async () => {
        if (localStorage.getItem("userType") === "medical_provider") {
            var CallLink = await RealTimeDatabase.ref(localStorage.getItem("UserNode") + "/upcoming_appointment")
            CallLink.on('value', async (snapshot) => {
                const calllink = await snapshot.val();
                let callUrl = new URL(calllink);
                setAppointmentUrl(callUrl.pathname.slice(1) + "/appointment_id")
                setCallUrl(callUrl.pathname.slice(1) + "/meeting_status")
            });
            var CallObj = await RealTimeDatabase.ref(CallUrl);
            CallObj.on('value', async (snapshot) => {
                var CallStatus = await snapshot.val();
                setCallStats(CallStatus);
            });
            var AppointmentObj = await RealTimeDatabase.ref(appointmentUrl);
            AppointmentObj.on('value', async (snapshot) => {
                var AppointmentId = await snapshot.val();
                setAppointmentId(AppointmentId);
            });
        }
    }, [CallUrl]);

    useEffect(() => {
        getCountDown();
        // switchClasses();
    }, []);

    return (
        <Grid container className={panelStatus}>
            <Grid container md={7}>
                <Grid xs={3}>
                    <div className="panel-img-container">
                        {serviceType === 'personal_telemedicine' && (
                            <>
                                <img src={patient?.avatar === "" || patient?.avatar === null ? images.userAvatar : patient?.avatar} alt="" />
                                <span className={UserStatus[userFirebaseNode] === 'online' ? 'user-status active' : 'user-status offline'}></span>
                            </>
                        )}
                        {serviceType === 'group_session' && (
                            <img className="group-image" src={icons.GroupIcon} alt="" />
                        )}
                    </div>
                </Grid>
                <Grid xs={9} className={panelInfo}>
                    <Typography component="p">
                        {convertTimeFormate(start_time)} - {convertTimeFormate(end_time)} {" "}
                        {" ( " + duration + t("TelemedicineSettings.min") + " ) "}
                    </Typography>
                    {serviceType === 'group_session' && (
                        <Typography component="h1">
                            {t("TelemedicineSettings.group_therapy")}
                        </Typography>
                    )}
                    {serviceType === 'personal_telemedicine' && (
                        <>
                            <Typography component="h1">
                                {patient?.name}
                            </Typography>
                            <Typography component="p" className="mb-20px">
                                {patient?.gender} -
                                {
                                    patient?.age ? patient?.age.number + " " +
                                        `${patient?.age.period === 'year' ? t("TelemedicineSettings.year") :
                                            patient?.age.period === 'month' ? t("TelemedicineSettings.month") :
                                                patient?.age.period === 'day' ? t("TelemedicineSettings.day") : ""
                                        }` : ""
                                }
                            </Typography>
                        </>
                    )}
                    {can_send_start_early_request &&
                        <Typography component="p">
                            {t("call.start_through")} <span style={{ color: "var(--secondary-color)" }}> {parseInt(countDown) + 1} {t("TelemedicineSettings.min")}</span>
                        </Typography>
                    }
                </Grid>
            </Grid>
            {can_start_call && serviceType === 'personal_telemedicine' &&
                <Grid md={5} style={{ textAlign: "end" }}>
                    <PrimaryButton
                        click={() => {
                            navigate("/doctor/home/telemedicine/telemedicine_session/" + reservationID, { state: { id: patient?.id, suadiNumber: saudiCommissionNumber, serviceType: serviceType, patientList: list } })
                        }}
                        btnType="icon"
                        classBtn="call-btn"
                        startIcon={<CallIcon />}
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            <PrimaryButton
                                click={() => setOpenPopup(true)}
                                classBtn="end-session"
                                text={t("DoctorTelemedicine.cancel")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {can_start_call && serviceType === 'group_session' &&
                <Grid md={5} style={{ textAlign: "end" }}>
                    <PrimaryButton
                        click={() => {
                            navigate("/doctor/home/telemedicine/telemedicine_session/" + reservationID, { state: { id: patient?.id, suadiNumber: saudiCommissionNumber, serviceType: serviceType, patientList: list } })
                        }}
                        btnType="icon"
                        classBtn="call-btn"
                        startIcon={<CallIcon />}
                    />
                    <Grid container>
                        <Grid item xs={6} px={0.25}>
                            <PrimaryButton
                                click={() => openPatientsList(list)}
                                classBtn="end-session"
                                text={t("TelemedicineSettings.patients_list")}
                            />
                        </Grid>
                        <Grid item xs={6} px={0.25}>
                            <PrimaryButton
                                click={() => setOpenPopup(true)}
                                classBtn="end-session"
                                text={t("DoctorTelemedicine.cancel")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {can_send_start_early_request && serviceType === 'personal_telemedicine' &&
                <Grid md={4} style={{ textAlign: "end" }}>
                    <PrimaryButton
                        click={() => { StartEarlySession(reservationID) }}
                        classBtn={CallStatus === "rejected_request" ? "reject-btn" : "early-btn"}
                        text={CallStatus === "pending_request" ? t("call.request_sent") : CallStatus === "rejected_request" ? t("call.rejected_request") : t("Home.start_early")}
                        disabled={CallStatus === "pending_request" || CallStatus === "rejected_request"}
                    />
                </Grid>
            }
            {/* {serviceType === 'group_session' &&
                <Grid container md={12} style={{ marginInlineStart: '100px', marginBottom: '10px' }} alignItems='end' justifyContent='center'>
                    <PrimaryButton
                        click={() => openPatientsList(list)}
                        classBtn="patient-list-btn"
                        text={t("TelemedicineSettings.patients_list")}
                    />
                </Grid>
            } */}
            <CustomPopup
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                t={t}
                title={t("CustomPopup.end")}
                isAction={true}
                btnActionText={t("Allergy.yes")}
                btnCloseText={t("CustomPopup.No")}
                btnActionStyle="primary"
                btnCloseStyle="settings-cancel-btn"
                handleAction={async () => {
                    await EndSession(reservationID)
                    await updateView()
                    setOpenPopup(false)
                }}
            />
        </Grid>
    );
}

export default withTranslation()(CurrentPanel);