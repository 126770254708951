import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/images/IconHelper";
import { convertTimeFormate } from "../../../../Contexts/API/Helper/Helper";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function AppointmentCards(props) {
    const { t, list, appointmentsType, serviceType, openInvoice, openReview, openDiagnosis } = props;
    const lang = localStorage.getItem("lang");
    const navigate = useNavigate();
    return (
        <Grid
            container
            className="appointments-cards-container"
        >
            {list?.map((card) => (
                <Grid container md={5.5} className="appointment-card mb-20px" key={card?.id}>
                    <Grid container>
                        <Grid item sm={7.5}>
                            <Typography component="h1">{card?.medical_service?.medical_provider?.name}</Typography>
                            <Typography component="h2">{card?.medical_service?.medical_provider?.medical_title} - {card?.medical_service?.medical_provider?.speciality} </Typography>
                            <Typography component="h3">{card?.medical_service?.medical_provider?.subspeciality} </Typography>
                            {serviceType !== "telemedicine" && (
                                <Typography component="h3">{card?.medical_service?.parent_medical_provider?.name}</Typography>
                            )}
                        </Grid>
                        {appointmentsType === "upcoming" && serviceType === "telemedicine" && (
                            <Grid item sm={4.5} style={{ textAlign: "end" }}>
                                {/* <Typography component="p" style={{marginTop: "20px"}}>
                                    ٥٦:٤٢
                                </Typography>
                                <Typography component="h3">
                                    {t("UserAppointments.left_on_time")}   
                                </Typography> */}
                            </Grid>
                        )}
                        {appointmentsType === "past" && serviceType === "telemedicine" && (
                            <Grid item sm={4.5} style={{ textAlign: "end" }}>
                                <PrimaryButton
                                    btnType="icon"
                                    startIcon={<img src={icons.ChatHistory} />}
                                    click={() => {
                                        navigate("/user/appointments/chat-history/" + card?.id, { state: { medical_provider: card?.medical_service?.medical_provider } })
                                    }}
                                />
                            </Grid>
                        )}
                        {serviceType !== "telemedicine" && (
                            <Grid container sm={4.5} style={{ marginTop: "70px" }}>
                                <Grid item xs={2}>
                                    <img className={lang === 'en' ? "rotate-icon" : ""} src={icons.PhoneIcon} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography component="h6">{lang === 'ar' ?
                                        `${card?.medical_service?.parent_medical_provider?.phone_number} ${card?.medical_service?.parent_medical_provider?.country_code.slice(1, 3)}+` :
                                        `+${card?.medical_service?.parent_medical_provider?.country_code.slice(1, 3)} ${card?.medical_service?.parent_medical_provider?.phone_number}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Divider style={{ width: "100%", margin: "10px 0" }} />
                    <Grid container className="mb-20px">
                        <Grid container item sm={6}>
                            <Grid item xs={2}>
                                <img style={{ width: "20px" }} src={icons.CalendarIcon} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography component="h4">{t("UserAppointments.date")}</Typography>
                                <Typography component="h5">
                                    {lang === 'en' ? moment(card?.date).locale('en').format("DD/MM/YYYY") : moment(card?.date).locale('ar').format("YYYY/MM/DD")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item sm={6}>
                            <Grid item xs={2}>
                                <img style={{ width: "20px" }} src={icons.ClockIcon} />
                            </Grid>
                            <Grid item xs={10}>
                                {
                                    serviceType === "telemedicine" ? (<Typography component="h4">{t("UserAppointments.time")}</Typography>) :
                                        serviceType === "clinic_service" ? (<Typography component="h4">{t("UserAppointments.clinic_appointment")}</Typography>) :
                                            (<Typography component="h4">{t("UserAppointments.hospital_appointment")}</Typography>)
                                }
                                <Typography component="h5">{t("DoctorProfile.from") + convertTimeFormate(card?.time_interval?.start_time) + t("DoctorProfile.to") + convertTimeFormate(card?.time_interval?.end_time)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {appointmentsType === "past" && (
                        <>
                            <Divider style={{ width: "100%", margin: "10px 0" }} />
                            <Grid container className="mb-20px">
                                <Grid container >
                                    <Grid item xs={1}>
                                        <img style={{ width: "20px" }} src={icons.Star} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography component="h4">
                                            {t("UserAppointments.appointment_review")}
                                            <span style={{ color: "var(--font-color)", margin: "0 10px" }}>
                                                {
                                                    card?.current_user_review === null ? t("UserAppointments.there_no") :
                                                        card?.current_user_review && card?.current_user_review?.user_feedback === 'unhappy' ? t("UserAppointments.unhappy") :
                                                            card?.current_user_review && card?.current_user_review?.user_feedback === 'happy' ? t("UserAppointments.happy") :
                                                                ''
                                                }
                                            </span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid container
                        // className="appointments-btns-container"
                        style={{ justifyContent: "end" }}
                    >
                        {appointmentsType === "past" && (
                            <Grid item md={4} style={{ textAlign: "center" }} px={0.25}>
                                {card?.current_user_review === null && (
                                    <PrimaryButton
                                        classBtn="appoint-btn small-font h-30px"
                                        text={t("UserAppointments.review")}
                                        click={
                                            () => openReview(
                                                true,
                                                card?.medical_service?.parent_medical_provider?.name,
                                                card?.medical_service?.medical_provider?.name,
                                                card?.user_reservation[0].id,
                                                card?.medical_service?.service_type_display
                                            )
                                        }
                                    />
                                )}
                            </Grid>
                        )}
                        {appointmentsType === "past" && serviceType === "telemedicine" && (
                            <Grid item md={4} style={{ textAlign: "center" }} px={0.25}>
                                <PrimaryButton
                                    classBtn="appoint-btn small-font h-30px"
                                    text={t("UserAppointments.diagnosis")}
                                    click={
                                        () => openDiagnosis(
                                            true,
                                            card?.user_reservation[0].id,
                                            card?.user_reservation[0].patient_profile?.id,
                                            card?.user_reservation[0].patient_profile?.personal_id_number
                                        )
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item md={4} style={{ textAlign: "center" }} px={0.25}>
                            <PrimaryButton
                                classBtn="appoint-btn small-font h-30px"
                                text={t("UserAppointments.bill_details")}
                                click={() => openInvoice(true, card?.user_reservation[0], card?.medical_service?.medical_provider?.name)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default withTranslation()(AppointmentCards);