import { useEffect, useState } from "react"
import { t } from 'i18next'

import UserProfileTemplate from "../../../Templates/Main/UserProfile/UserProfile.template.jsx"
import { getListDiagnosisAPI, deleteDiagnosisAPI, createDiagnosisAPI, editDiagnosisAPI, showDiagnosisAPI } from '../../../Contexts/API/PatientProfile/DiagnosisApi.js'
import { getListCheckupAPI, createCheckupAPI, deleteCheckupAPI, editCheckupDateAPI } from '../../../Contexts/API/PatientProfile/CheckupApi.js'
import { editPatientProfileAPI } from '../../../Contexts/API/PatientProfile/EditPatientProfileApi.js'
import { getDoctorMainInfoAPI } from "../../../Contexts/API/DoctorProfile/mainInfo"
import { getMedicineIntakeMethodsAPI } from "../../../Contexts/API/Lookups/MedicineIntakeMethods.js"
import { getMedicineDosagePeriodsAPI } from "../../../Contexts/API/Lookups/MedicineDosagePeriods.js"
import { getListPrescriptionAPI, deletePrescriptionAPI, createPrescriptionAPI, editPrescriptionAPI, showPrescriptionAPI, getPdfPrescriptionAPI } from '../../../Contexts/API/PatientProfile/PrescriptionApi'
import { getListAllergyAPI, createAllergyAPI, deleteAllergyAPI } from '../../../Contexts/API/PatientProfile/AllergyApi.js'
import { getListSurgeryAPI, deleteSurgeryAPI, createSurgeryAPI, editSurgeryAPI } from '../../../Contexts/API/PatientProfile/SurgeryApi.js'
import { getListQuestionsAPI, getListAnswersAPI, saveAnswersAPI } from '../../../Contexts/API/PatientProfile/LifeStyleApi.js'
import { getAppointmentsListAPI } from "../../../Contexts/API/Appointments/AppointmentsApi";
import { getDiseasesListAPI } from "../../../Contexts/API/Lookups/DiseasesList";
import { ConvertToArabicNumbers } from "../../../Contexts/API/Helper/Helper.js"

const UserProfile = ({ setAvatar, setUserName }) => {

    const lang = localStorage.getItem("lang")
    const [openLoader, setOpenLoader] = useState(false)

    /* Patient State */
    const [profileData, setProfileData] = useState({})
    const [phoneNumber, setPhoneNumber] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [age, setAge] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [hijriBirthDate, setHijriBirthDate] = useState('')
    const [medicalFileNumber, setMedicalFileNumber] = useState('')
    const [idNumber, setIdNumber] = useState('')

    /* Medicine Intake Methods State*/
    const [medicineIntakeMethods, setMedicineIntakeMethods] = useState([])

    /* Medicine Dosage Periods state*/
    const [medicineDosagePeriods, setMedicineDosagePeriods] = useState([])

    /* Diagnosis States */
    const [diagnosisList, setDiagnosisList] = useState([])
    const [appDiagnosisList, setAppDiagnosisList] = useState([])
    const [open, setOpen] = useState(false)
    const [type, setType] = useState('')
    const [item, setItem] = useState({})

    /* Prescription States */
    const [openPrescription, setOpenPrescription] = useState(false)
    const [openPrescriptionDtails, setOpenPrescriptionDetails] = useState(false)
    const [typePrescription, setTypePrescription] = useState('')
    const [itemPrescription, setItemPrescription] = useState({})
    const [prescriptionList, setPrescriptionList] = useState([])
    const [appPrescriptionList, setAppPrescriptionList] = useState([])

    /* checkups states */
    const [xrayList, setXrayList] = useState([])
    const [analysisList, setAnalysisList] = useState([])

    /* Allergy States */
    const [isAllergic, setIsAllergic] = useState(false)
    const [allergyList, setAllergyList] = useState([])

    /* Surgery state */
    const [surgeries, setSurgeries] = useState([])

    /* LifeStyle states */
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])

    const [appointmentsList, setAppointmentsList] = useState([]);

    // Pagination Info
    const [paginationInfo, setPaginationInfo] = useState({})
    const [paginationDiagnosisHistory, setPaginationDiagnosisHistory] = useState({})
    const [surgeriesPaginationInfo, setSurgeriesPaginationInfo] = useState({})
    const [xrayPaginationInfo, setXrayPaginationInfo] = useState({})
    const [analysisListPaginationInfo, setAnalysisListPaginationInfo] = useState({})
    const [paginationPrescriptionApp, setPaginationPrescriptionApp] = useState({})
    const [paginationPrescriptionHistory, setPaginationPrescriptionHistory] = useState({})

    const [diseasesList, setDiseasesList] = useState([]);

    /* Patient Functions */
    // Patient User Data Api 
    const getProfileData = async () => {
        setOpenLoader(true)
        const res = await getDoctorMainInfoAPI()
        if (res) {
            const info = res?.data?.profile
            setProfileData(info)
            setPhoneNumber(info?.phone_number)
            setCountryCode(info?.country_code)
            const no = lang === 'en' ? info?.age?.number : ConvertToArabicNumbers(info?.age?.number)
            setAge(no + " " +
                `${info?.age?.period === 'year' ? t("TelemedicineSettings.year") :
                    info?.age?.period === 'month' ? t("TelemedicineSettings.month") :
                        info?.age?.period === 'day' ? t("TelemedicineSettings.day") : ""
                }`)
            setBirthDate(info?.birth_date)
            setHijriBirthDate(info?.hijri_birth_date)
            setMedicalFileNumber(info?.medical_profile_number)
            setIdNumber(info?.personal_id_number)
            localStorage.setItem("name",info?.name)
            setUserName(info?.name)
            localStorage.setItem("email",info?.email)
        }
        setOpenLoader(false)
    }
    const handleCallMainInfo = () => getProfileData()

    const editProfileData = async (data) => {
        const res = await editPatientProfileAPI(data)
        if (res) getProfileData()
    }

    /* Medicine Intake Methods Function */
    const getMedicineIntakeMethods = async () => {
        const res = await getMedicineIntakeMethodsAPI()
        if (res) setMedicineIntakeMethods(res?.data?.medicine_intake_methods)
    }

    /* Medicine Dosage Periods Function */
    const getMedicineDosagePeriods = async () => {
        const res = await getMedicineDosagePeriodsAPI()
        if (res) setMedicineDosagePeriods(res?.data?.dosage_periods)
    }

    /* Diagnosis Functions */
    // handle open & close add diagnosis modal
    const handleClose = () => setOpen(false)

    // const openModal = (operation, data) => {
    //     setType(operation)
    //     setOpen(true)
    //     operation === 'edit' ? setItem(data) : setItem({})
    // }

    const openModal = async (operation, id) => {
        setType(operation)
        setOpen(true)
        operation === 'edit' ?
            await showDiagnosis(id) :
            setItem({})
    }

    // Diagnosis Api Operations
    const createDiagnosis = async (data) => {
        const { res, err } = await createDiagnosisAPI(data)
        if (res) {
            getDiagnosisData({ page_number: 1, page_size: 10 })
            handleClose()
        }
    }

    const removeDiagnosis = async (id) => {
        const res = await deleteDiagnosisAPI(id)
        if (res) getDiagnosisData({ page_number: 1, page_size: 10 })
    }

    const editDiagnosis = async (id, data) => {
        const res = await editDiagnosisAPI(id, data)
        if (res) {
            getDiagnosisData({ page_number: 1, page_size: 10 })
            handleClose()
        }
    }

    const getDiagnosisData = async (filterObj) => {
        const res = await getListDiagnosisAPI(undefined, "history", filterObj)
        if (res) setDiagnosisList(res?.data?.diagnosis)
        setPaginationDiagnosisHistory(res?.extra)
    }

    const getAppDiagnosisData = async (filterObj) => {
        const res = await getListDiagnosisAPI(undefined, "app", filterObj)
        if (res) setAppDiagnosisList(res?.data?.diagnosis)
        setPaginationInfo(res?.extra)
    }

    const showDiagnosis = async (id) => {
        const res = await showDiagnosisAPI(id)
        if (res) {
            setItemPrescription(res?.data?.diagnosis)
            return res?.data?.diagnosis
        }
    }

    /* Prescription API operations */
    const handleOpenPrescription = () => setOpenPrescription(true)
    const handleClosePrescription = () => setOpenPrescription(false)

    const handleOpenPrescriptionDetails = () => setOpenPrescriptionDetails(true)
    const handleClosePrescriptionDetails = () => setOpenPrescriptionDetails(false)

    const openPrescriptionModal = async (operation, id) => {
        operation === 'edit' ?
            await showPrescription(id) :
            setItemPrescription({})
        setTypePrescription(operation)
        handleOpenPrescription()
    }

    const openPrescriptionDetailsModal = async (id) => {
        // await showPrescription(id)
        await showDiagnosis(id)
        handleOpenPrescriptionDetails()
    }

    const showPrescription = async (id) => {
        const res = await showPrescriptionAPI(id)
        if (res) setItemPrescription(res.data.prescription)
    }

    const getPdfPrescription = async (id) => {
        const res = await getPdfPrescriptionAPI(id)
    }

    const createPrescription = async (data) => {
        const res = await createPrescriptionAPI(data)
        if (res) getPrescriptionData({ page_number: 1, page_size: 10 })
    }

    const removePrescription = async (id) => {
        const res = await deletePrescriptionAPI(id)
        if (res) getPrescriptionData({ page_number: 1, page_size: 10 })
    }

    const editPrescription = async (id, data) => {
        const res = await editPrescriptionAPI(id, data)
        if (res) getPrescriptionData({ page_number: 1, page_size: 10 })
    }

    const getPrescriptionData = async (filterObj) => {
        const res = await getListPrescriptionAPI(undefined, "history", filterObj)
        if (res) setPrescriptionList(res?.data?.prescription)
        setPaginationPrescriptionHistory(res?.extra)
    }

    const getAppPrescriptionData = async (filterObj) => {
        const res = await getListPrescriptionAPI(undefined, "app", filterObj)
        if (res) setAppPrescriptionList(res?.data?.prescription)
        setPaginationPrescriptionApp(res?.extra)
    }

    /* Get Patient Xray data */
    const getXrayData = async (filterObj) => {
        const res = await getListCheckupAPI(undefined, 'xray', filterObj)
        if (res) setXrayList(res?.data?.examination)
        setXrayPaginationInfo(res?.extra)
    }

    /* Get Patient Analysis data */
    const getAnalysisData = async (filterObj) => {
        const res = await getListCheckupAPI(undefined, 'analysis', filterObj)
        if (res) setAnalysisList(res?.data?.examination)
        setAnalysisListPaginationInfo(res?.extra)
    }

    const addExaminations = async (data) => {
        const res = await createCheckupAPI(data)
        if (res) getXrayData({ page_number: 1, page_size: 10 })
        getAnalysisData({ page_number: 1, page_size: 10 })
    }

    const removeExaminations = async (filter_obj) => {
        await deleteCheckupAPI(filter_obj)
        getXrayData({ page_number: 1, page_size: 10 })
        getAnalysisData({ page_number: 1, page_size: 10 })
    }

    const editExaminationDate = async (id, date) => {
        await editCheckupDateAPI(id, date)
        getXrayData({ page_number: 1, page_size: 10 })
        getAnalysisData({ page_number: 1, page_size: 10 })
    }

    /* Allergy API Operations */
    const getAllergyData = async () => {
        const res = await getListAllergyAPI()
        if (res) {
            setIsAllergic(res?.data?.user?.is_allergic)
            setAllergyList(res?.data?.user?.user_allergies)
        }
    }

    const createAllergy = async (data) => {
        const res = await createAllergyAPI(data)
        if (res) getAllergyData()
    }

    const removeAllergy = async (filter_obj) => {
        const res = await deleteAllergyAPI(filter_obj)
        if (res) getAllergyData()
    }

    /* Surgeries API operations */
    const getSurgeriesData = async (filterObj) => {
        const res = await getListSurgeryAPI(undefined, filterObj)
        if (res) setSurgeries(res?.data?.surgeries)
        setSurgeriesPaginationInfo(res?.extra)
    }

    const removeSurgery = async (id) => {
        const res = await deleteSurgeryAPI(id)
        if (res) getSurgeriesData({ page_number: 1, page_size: 10 })
    }

    const createSurgery = async (data) => {
        const res = await createSurgeryAPI(data)
        if (res) getSurgeriesData({ page_number: 1, page_size: 10 })
    }

    const editSurgery = async (id, data) => {
        const res = await editSurgeryAPI(id, data)
        if (res) getSurgeriesData({ page_number: 1, page_size: 10 })
    }

    /* LifeStyle API Operations */
    const getQuestions = async () => {
        const res = await getListQuestionsAPI()
        if (res) setQuestions(res?.data?.questions)
    }

    const getAnswers = async () => {
        const res = await getListAnswersAPI()
        if (res) setAnswers(res?.data?.lifestyle)
    }

    const saveAnswers = async (data) => {
        const res = await saveAnswersAPI(data)
        if (res) {
            getQuestions()
            getAnswers()
        }
    };

    const getUserAppointments = async (service_type) => {
        var filterObj = {
            'page_size': 3,
            'page_number': 1,
            'list_scope': 'upcoming',
            'service_type': service_type
        };
        let res = await getAppointmentsListAPI(filterObj);
        if (res) setAppointmentsList(res?.data);
    };

    const getDiseases = async () => {
        const res = await getDiseasesListAPI({});
        if (res) setDiseasesList(res?.data?.diseases);
    };

    useEffect(() => {
        getProfileData()
        getMedicineIntakeMethods()
        getMedicineDosagePeriods()
        getAllergyData()
        getQuestions()
        getAnswers()
        getDiseases()
    }, [])

    return <UserProfileTemplate
        profileData={profileData}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        birthDate={birthDate}
        age={age}
        handleCallMainInfo={handleCallMainInfo}
        diagnosisList={diagnosisList}
        open={open}
        item={item}
        type={type}
        openModal={openModal}
        createDiagnosis={createDiagnosis}
        removeDiagnosis={removeDiagnosis}
        editDiagnosis={editDiagnosis}
        handleClose={handleClose}
        openPrescription={openPrescription}
        typePrescription={typePrescription}
        itemPrescription={itemPrescription}
        prescriptionList={prescriptionList}
        handleClosePrescription={handleClosePrescription}
        openPrescriptionModal={openPrescriptionModal}
        createPrescription={createPrescription}
        removePrescription={removePrescription}
        editPrescription={editPrescription}
        medicineIntakeMethods={medicineIntakeMethods}
        openPrescriptionDtails={openPrescriptionDtails}
        openPrescriptionDetailsModal={openPrescriptionDetailsModal}
        handleClosePrescriptionDetails={handleClosePrescriptionDetails}
        medicineDosagePeriods={medicineDosagePeriods}
        xrayList={xrayList}
        analysisList={analysisList}
        addExaminations={addExaminations}
        removeExaminations={removeExaminations}
        getXrayData={getXrayData}
        getAnalysisData={getAnalysisData}
        editExaminationDate={editExaminationDate}
        surgeries={surgeries}
        removeSurgery={removeSurgery}
        createSurgery={createSurgery}
        editSurgery={editSurgery}
        editProfileData={editProfileData}
        questions={questions}
        answers={answers}
        saveAnswers={saveAnswers}
        isAllergic={isAllergic}
        allergyList={allergyList}
        createAllergy={createAllergy}
        removeAllergy={removeAllergy}
        medicalFileNumber={medicalFileNumber}
        getUserAppointments={getUserAppointments}
        appointmentsList={appointmentsList}
        appDiagnosisList={appDiagnosisList}
        showDiagnosis={showDiagnosis}
        idNumber={idNumber}
        appPrescriptionList={appPrescriptionList}
        showPrescription={showPrescription}
        getPdfPrescription={getPdfPrescription}
        openLoader={openLoader}
        paginationInfo={paginationInfo}
        getDiagnosisApp={getAppDiagnosisData}
        paginationDiagnosisHistory={paginationDiagnosisHistory}
        getDiagnosisHistory={getDiagnosisData}
        paginationPrescriptionApp={paginationPrescriptionApp}
        paginationPrescriptionHistory={paginationPrescriptionHistory}
        getPrescriptionApp={getAppPrescriptionData}
        getPrescriptionHistory={getPrescriptionData}
        analysisListPaginationInfo={analysisListPaginationInfo}
        xrayPaginationInfo={xrayPaginationInfo}
        getAnalysisList={getAnalysisData}
        getXrayList={getXrayData}
        surgeriesPaginationInfo={surgeriesPaginationInfo}
        getSurgeries={getSurgeriesData}
        diseasesList={diseasesList}
        setAvatar={setAvatar}
        setLoader={setOpenLoader}
        hijriBirthDate={hijriBirthDate}
    />
}

export default UserProfile
