import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Grid } from "@mui/material";
import "./RadioInput.css";

export default function RadioButtonsGroup(props) {
  const [expanded, setExpanded] = React.useState(false);
  const lang = localStorage.getItem("lang");
  const accordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange = (event) => {
    props.change(event.target.value);
  }
  const handleSubChange = (event) => {
    props.subChange(event.target.value);
  }
  return (
    <FormControl>
        <FormLabel id="row-radio-buttons-group-label">{props.formLabel}</FormLabel>
        <RadioGroup
            row={props.row}
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={props.value}
        >
            {props?.options?.map((radioOption, index) => (
                <Grid style={props.containerStyle} className={radioOption.src ? 'container' : ''}>
                    {!props.Accordion && (
                        <>
                            {radioOption.src && (<img className={lang === 'en' ? 'radioAvatar' : 'radioAvatar-ar'} src={radioOption.src}/>)}
                            <FormControlLabel 
                                value={radioOption.value}
                                control={<Radio />} 
                                label={radioOption.label}
                                disabled={radioOption.disabled}
                                style={props.style}
                                onChange={handleChange}
                            />
                        </>
                    )}
                </Grid>
            ))}
        </RadioGroup>

        {props.Accordion && (
            <>
                {props?.options?.map((radioOption, index) => (
                    <Accordion 
                        style={{boxShadow: "none", borderTop: "1px solid rgba(0,0,0,0.12)", margin: "0"}}
                        expanded={expanded === index} 
                        onChange={accordionChange(index)}
                    >
                        <AccordionSummary>
                        <RadioGroup value={props.value}>
                            <FormControlLabel 
                            value={radioOption.value}
                            control={<Radio />} 
                            label={radioOption.label}
                            disabled={radioOption.disabled}
                            style={props.style}
                            onChange={handleChange}
                            />
                        </RadioGroup>
                        
                        </AccordionSummary>
                        <AccordionDetails className={props.AccordionStyle}>
                        {props.subList.map((subOption) => (
                            <RadioGroup value={props.subValue} >
                            <FormControlLabel 
                                value={subOption.value + 20}
                                control={<Radio />} 
                                label={subOption.label}
                                disabled={subOption.disabled}
                                style={props.nestedStyle}
                                onChange={handleSubChange}
                            />
                            </RadioGroup>
                        ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </>
        )}
    </FormControl>
  );
}