import Logo from "./images/Logo.png";
import DeleteAccount from "./images/DeleteAccount.png";
import Exclamationmark from "./images/icons/Exclamationmark.svg";
import Success from "./images/icons/SuccessIcon.svg";
import NotFound from "./images/icons/NotFound.svg";

export const dummy = {};

export const images = {
  Logo,
};

export const icons = {
  DeleteAccount,
  Exclamationmark,
  Success,
  NotFound,
};
