import { axiosFn, handleError } from "..";
import { firebaseSDK } from "../../Firebase/Firebase";
import { getPresignedLinkAPI, uploadFileAPI } from "../General/upload";

const RealTimeDatabase = firebaseSDK.database();

export async function ChatRooms(id) {
    return await axiosFn("get", `/reservations/${id}/chat_room`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function ChatRoom(id) {
    let { err, res } = await ChatRooms(id);
    return { res, err }
}


export async function AddMsgs(id, msg) {
    const data = {
        chat_message: {
            message: msg.message,
            message_type: msg.message_type, // "text_msg" or "media_msg"
            media_type: msg.media_type, // photo, video   // (in case of media_msg)
            payload: msg.payload // (in case of media_msg)
        }
    }
    return await axiosFn("post", `/reservations/${id}/chat_room/send_message`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function AddMsg(id, msg) {
    let { err, res } = await AddMsgs(id, msg);
    return { err, res }
}

export const SendMessage = async (message, file = [], sender_id, sender_type) => {
    // (to do) send multiple image
    let FirebaseDataObj = {};
    if (file.length > 0) {
        FirebaseDataObj = {
            created_at: new Date().toISOString(),
            sender_id: sender_id,
            sender_type: sender_type,
            message: message,
        };
        file.map(el => {
            FirebaseDataObj = {
                ...FirebaseDataObj,
                file_name: el.name,
                file_size: el.size,
                file_type: el.type,
                file_url: el.url,
            };
        })

    } else {
        FirebaseDataObj = {
            message: message,
            created_at: new Date().toISOString(),
            sender_id: sender_id,
            sender_type: sender_type
        };
    }

    Object.keys(FirebaseDataObj).forEach(
        (key) =>
            (FirebaseDataObj[key] === undefined ||
                FirebaseDataObj[key]?.length === 0) &&
            delete FirebaseDataObj[key]
    );
    await RealTimeDatabase.ref(localStorage.getItem('MESSAGE_NODE'))
        .push()
        .set(FirebaseDataObj);
}

export const GetMessages = async (MessageNode) => {
    let message_array = [];
    const evt = new CustomEvent('getmessage');
    await RealTimeDatabase.ref(MessageNode)
        .orderByChild("created_at")
        .on("child_added", async (snapshot) => {
            message_array = [...message_array, snapshot.val()]
            evt.data = message_array
            window.dispatchEvent(evt);
        })

}
// export const SearchMessages = async (queryText, MessageNode) => {
//     let message_array = [];
//     const event = new CustomEvent('searchmessage');

//     await RealTimeDatabase.ref(MessageNode)
//         .orderByChild("body")
//         .startAt(queryText)
//         .endAt(queryText + "\uf8ff")
//         .on("value", async (snapshot) => {
// snapshot.forEach(el => {
//     message_array = [...message_array, el.val()]
//     event.data = message_array
//     window.dispatchEvent(event);
// })

//         })

// }


export const OffDatabaseConnection = (MessageNode) => {
    RealTimeDatabase.ref(MessageNode).off();
}


export const handleFiles = async (files) => {

    let arr = [];
    let file = {};
    let result = files.map(async (el) => {
        //  if (el.type.includes('image')) {
            let res = await getPresignedLinkAPI([   {   //photo & pdf
                "extension":el.type.split("/")[1]
            }] )
            if (res) {
                await uploadFileAPI(res.data[0].presigned_url, el)
                file = {
                    name: res.data[0].file_name,
                    url: res.data[0].file_url,
                    type: el.type,
                    size: el.size
                }
            // }
        }
        return file;
    })

    return result;
    // const filesArr = Array.from(files).filter(
    //     (file) => file.type.split("/")[0] === "image"
    //   );
    //   if (filesArr.length !== Array.from(files).length) {
    //     const msg = "لا يمكن اضافة غير الصور";
    //     showToast(toastTypes.WAR, msg);
    //   }
    //   if (filesArr.length > 0) {
    //     setMessage("");
    //     setMessageType(1);
    //   }
    //   clearImages();
    //   addImages(filesArr);
}
export const handleFileInputs = (fileList, file) => {
    return [file[0]]
}
// export const handleFileRemoves = (fileList, index) => {
//     let arr = fileList
//     arr.splice(index, 1)
//     return arr;
// }