import { Grid } from "@mui/material";
import UserWalletPanel from "../../../Components/Organisms/Wallet/UserWalletPanel";
import PatientWalletPanel from "../../../Components/Organisms/Wallet/PatientWalletPanel";

const UserWalletTemplate = (props) => {
    const { walletInfo, depositToAccount, packages } = props;
    const userType = localStorage.getItem("userType");
    return (
        <Grid container>
            <Grid xs></Grid>
            {userType === "patient" ? (
                <PatientWalletPanel
                    walletInfo={walletInfo}
                    packages={packages} 
                />
            ) : (
                <UserWalletPanel 
                    walletInfo={walletInfo} 
                    depositToAccount={depositToAccount}
                />
            )}
            <Grid xs></Grid>
        </Grid>
    )
}

export default UserWalletTemplate