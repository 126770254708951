import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DatesPanel from "../../../Components/Organisms/ProfilePanels/DatesPanel";
import HomeMainPanel from "../../../Components/Organisms/Home/DoctorHome/HomeMainPanel";
import InfoPanel from "../../../Components/Organisms/ProfilePanels/InfoPanel";
import WalletPanel from "../../../Components/Organisms/ProfilePanels/WalletPanel";
import CustomPopup from "../../../Components/Organisms/Modals/CustomPopup/CustomPopup";
import { useState, useEffect } from "react";
import { images } from "../../../Assets/images/IconHelper";
import { useNavigate } from "react-router-dom";

const DoctorHomeTemplate = (props) => {
  const navigate = useNavigate();
  const { t, getDocAppointements, telemedicineList, hospitalsList, clinicsList, approvalStatus, rejectReason, getDoctorAppointments, appointmentsList, appointmentObj, paginationInfo, saudiCommissionNumber, walletAmount } = props;
  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState({
    title: "",
    text: "",
    btnText: "",
    src: "",
  });
  const switchReviewPopups = () => {
    if(approvalStatus === 'pending'){
      localStorage.setItem("readPopup", false);
      setOpen(true);
      setPopupData({
        title: t("CustomPopup.welcome"),
        text: t("CustomPopup.under_review"),
        btnText: t("CustomPopup.ok"),
        src: images.PendingStatus
      })
    }else if (approvalStatus === 'approved' && localStorage.getItem("readPopup") === "false"){
      setOpen(true);
      setPopupData({
        title: t("CustomPopup.congratulations"),
        text: t("CustomPopup.accept_review"),
        btnText: t("CustomPopup.ok"),
        src: images.AcceptStatus
      })
    }else if (approvalStatus === 'rejected'){
      setOpen(true);
      setPopupData({
        title: t("CustomPopup.sorry"),
        text: t("CustomPopup.reject_review"),
        btnText: t("CustomPopup.goto_edit_data"),
        src: images.RejectStatus,
      })
    }
  };
  const handleClose = () => {
    setOpen(false);
    if(approvalStatus === 'rejected'){
      navigate("/doctor/profile");
    }
    if(approvalStatus === 'approved'){
      localStorage.setItem("readPopup", true);
    }
  };
  useEffect(() => {
    switchReviewPopups();
  }, [approvalStatus]);
  return (
    <Grid container spacing={0}>
      <Grid item xs className="grey-bg"></Grid>
      <Grid
        container
        xl={7}
        lg={9.5}
        md={11}
        sm={12}
        xs={12} 
        rowSpacing={3}
        columnSpacing={{ xs: 3 }}
        className="grey-bg tp-bt-padding main-container"
      >
        <Grid item xs={9}>
          <HomeMainPanel 
            getDocAppointements={(date, service_type, page, rowsPerPage) => getDocAppointements(date, service_type, page, rowsPerPage)}
            telemedicineList={telemedicineList}
            hospitalsList={hospitalsList}
            clinicsList={clinicsList}
            paginationInfo={paginationInfo}
            saudiCommissionNumber={saudiCommissionNumber}
          />
        </Grid>
        <Grid item xs={3}>
          <DatesPanel
            getDoctorAppointments={getDoctorAppointments}
            appointmentsList={appointmentsList}
            appointmentObj={appointmentObj} 
          />
          <WalletPanel walletAmount={walletAmount}/>
        </Grid>
      </Grid>
      <Grid item xs className="grey-bg"></Grid>
      <Grid item md={12} className="white-bg"></Grid>
      <CustomPopup
        open={open}
        handleClose={() => handleClose()}
        src={popupData.src}
        t={t}
        title={popupData.title}
        text={popupData.text}
        subText={approvalStatus === 'rejected' ? " ( " + rejectReason + " ) " : ''}
        btnText={popupData.btnText}
      />
    </Grid>
  );
};

export default withTranslation() (DoctorHomeTemplate);
