import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { images } from "../../../Assets/images/IconHelper";

function EditMsg(props) {
  
  return (
    <Grid container className={props.className}>
        <Grid container xs={1} alignItems='center'>
            <img src={images.TipIcon} alt="" />
        </Grid>
      <Grid container xs={11} alignItems='center'>
            <p>{props.message}</p>
        </Grid>
    </Grid>
  );
}

export default withTranslation()(EditMsg);