import { useState, useEffect } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Divider } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'
import ClearIcon from '@mui/icons-material/Clear'
import moment from "moment-hijri"

import { images, icons } from "../../../../Assets/images/IconHelper"
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js"
import FileInput from "../../../Atoms/Input/FileInput/FileInput"
import { getPresignedLinkAPI, uploadFileAPI } from "../../../../Contexts/API/General/upload"
import Loader from "../../../Atoms/Loader/Loader"

const AddEditExamination = ({ t, open, handleClose, examinationType, operationType, addExaminations, item, removeExaminations, getXrayData, getAnalysisData, editExaminationDate }) => {

    const [openLoader, setLoader] = useState(false)
    const [oldImages, setOldImages] = useState([])
    const [newImages, setNewImages] = useState([])
    const [date, setDate] = useState()
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const setDateState = () => {
        operationType === 'add' ? setDate(undefined) : setDate(moment(item?.date))
    }

    const remove = (url, type) => {
        type === 'old' ?
            setOldImages(oldImages.filter(image => image.url !== url))
            : setNewImages(newImages.filter(image => image !== url))
    }

    const getImagesFromBackEnd = () => {
        const urls = []
        const attach = item?.attachments
        attach?.forEach((obj) => {
            urls.push(obj)
        })
        setOldImages(urls)
    }

    const uploadExaminations = async (event) => {
        const files = [{ "extension": "png" }]
        setLoader(true)
        const res = await getPresignedLinkAPI(files)
        const presignedUrl = res?.data[0].presigned_url
        const file = event.target.files[0]
        const upload = await uploadFileAPI(presignedUrl, file)
        if (upload) {
            setNewImages([...newImages, res?.data[0].file_url])
        }
        setLoader(false)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        if (date === undefined) {
            setError(true)
            return
        } else {
            if (operationType === 'edit') {
                if (oldImages.length === 0 && newImages.length === 0) {
                    setErrorText(t("Validation.image"))
                    return
                } else {
                    await editExaminationDate(item.id, {
                        "examination": {
                            "date": date.locale('en').format('DD/MM/YYYY')
                        }
                    })
                }
            }
            if (newImages.length > 0) {
                const data = {
                    "examination": {
                        "examination_type": examinationType,
                        "date": date.locale('en').format('DD/MM/YYYY'),
                        "attachments": newImages
                    }
                }
                await addExaminations(data)
            } else {
                if (operationType === 'add') {
                    setErrorText(t("Validation.image"))
                    return
                }
            }
            examinationType === 'xray' ?
                getXrayData() :
                getAnalysisData()
            handleClose()
        }
        setIsSubmitting(false)
    }

    useEffect(() => {
        setNewImages([])
        setOldImages([])
        if (operationType === 'edit') {
            getImagesFromBackEnd()
        }
        setDateState()
    }, [open])

    useEffect(() => {
        if (newImages.length > 0 || oldImages.length > 0) setErrorText('')
    }, [newImages, oldImages]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    minHeight: '600px',
                    width: '100%'
                }
            }}
            style={{ direction: localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr' }}
        >
            <DialogTitle>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={11}
                        sx={{ justifyContent: 'flex-start', alignContent: 'center' }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {t("Checkups.add_examination")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <PrimaryButton
                            type='icon'
                            startIcon={<CancelIcon sx={{ color: 'var(--primary-color)' }} />}
                            click={handleClose} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <Grid container>
                    <Loader open={openLoader} />
                    <Grid
                        item
                        xs={12}
                        container
                        style={{ marginBottom: 24 }}
                    >
                        <BasicDatePicker
                            selected={date}
                            change={e => {
                                setDate(e)
                                setError(false)
                                setIsSubmitting(false)
                            }}
                            placeholderText={t("Checkups.results_date")}
                            name='date'
                            id='date'
                            icon={icons.Calendar}
                            className={error && 'red-border'}
                            errorText={error && t("Validation.day")}
                        />
                    </Grid>
                    <Divider style={{ width: '100%', marginBottom: 24 }} />
                    <Grid item xs={2} sx={{ position: 'relative' }}>
                        <FileInput
                            icon={images.UploadCertificate}
                            iconStyle="upload-examinations "
                            change={(e) => uploadExaminations(e)}
                            accept="image/*"
                        />
                    </Grid>
                    <Grid container item xs={10} px={1}>
                        {oldImages.length !== 0 && oldImages.map((image) => {
                            return (
                                <Grid item
                                    className="edit-examination-view"
                                    style={{ marginInlineEnd: '15px',marginBottom:'15px' }}>
                                    <PrimaryButton
                                        startIcon={<ClearIcon />}
                                        btnType="icon"
                                        classBtn="close-icon"
                                        click={() => {
                                            removeExaminations({ attachment_ids: image.id })
                                            remove(image.url, 'old')
                                        }}
                                    />
                                    <img
                                        src={image.url}
                                        style={{
                                            width: '80px',
                                            height: '80px'
                                        }}
                                        alt='attachment'
                                    />
                                </Grid>
                            )
                        })}
                        {newImages.length !== 0 && newImages.map((image) => {
                            return (
                                <Grid item
                                    className="edit-examination-view"
                                    style={{ marginInlineEnd: '15px', marginBottom: '15px' }}>
                                    <PrimaryButton
                                        startIcon={<ClearIcon />}
                                        btnType="icon"
                                        classBtn="close-icon"
                                        click={() => { remove(image, 'new') }}
                                    />
                                    <img
                                        src={image}
                                        style={{
                                            width: '80px',
                                            height: '80px'
                                        }}
                                        alt='attachment'
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    {errorText &&
                        <Grid item xs={12}>
                            <Typography style={{
                                fontSize: "13px",
                                color: "#d32f2f",
                                // textAlign: "left",
                                width: "97%",
                                margin: "auto"
                            }}>
                                {errorText}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'center',
                    marginTop: '60px',
                    marginBottom: '30px'
                }}>
                <PrimaryButton
                    type='submit'
                    text={operationType === 'add' ? t("DoctorProfile.add_btn") : t("DoctorProfile.save_btn")}
                    classBtn='primary btn-rounded'
                    style={{ maxWidth: '300px' }}
                    click={onSubmit}
                    disabled={isSubmitting}
                />
            </DialogActions>
        </Dialog >
    )
}

export default AddEditExamination