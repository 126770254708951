import { AUTHLoading, DeleteAccountSuccess } from "./Actions";
import { makeFilterString } from "../../../Contexts/API/Helper/Helper";

import { reduxRequest } from "../../../common/utils/ReduxRequest";

export const DeleteAccountRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AUTHLoading,
    successFunction: DeleteAccountSuccess,
    url: `/auth/accounts${makeFilterString(variables?.params)}`,
    method: "DELETE",
    action: variables?.action,
    failAction: variables?.failAction,
    body: variables?.body,
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
