import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function getTermsConditions() {
    return await axiosFn("get", URL.TERMS_CONDITIONS)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getPrivacyPolicyTermsConditionsAPI() {
    let { err, res } = await getTermsConditions()

    if (res) return res

    if (err) return err
}
