import React from "react";
import "./Carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { icons } from "../../../Assets/images/IconHelper";

function NormalCarousel({ items, type, showThumbs, autoPlay, interval, infiniteLoop }) {
  
  return (
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={showThumbs}
        autoPlay={autoPlay}
        interval={interval}
        infiniteLoop={infiniteLoop}
      >
        {items?.map((item, i) => (
          <div
            className={type === 'cert' ? "carousel-img-container" : "ads"}
            key={item.id}
            onClick={()=>{
              if (type === 'ads' && item.is_clickable) window.location.href = item?.url
              else if (type !== 'ads' && item?.url?.includes("pdf")) window.location.href = item?.url
            }}
            style={{ cursor: type === 'cert' && item?.url?.includes("pdf") ? 'pointer' : 'initial' }}
          >
            <img src={type === 'ads' ? item?.image_en : item?.url?.includes("pdf") ? icons.DownloadPDF : item?.url} />
          </div>
        ))}
      </Carousel>
  );
}

export default NormalCarousel;
