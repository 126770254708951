import { useState, useEffect } from "react"
import { Grid, Box, Typography } from "@mui/material"

import NormalTabs from "../../../Atoms/Tabs/NormalTabs"
import TabPanel from "../../../Atoms/Tabs/TabPanel"
import ViewCheckup from '../../../Organisms/Modals/Checkups/ViewCheckup'
import { images } from "../../../../Assets/images/IconHelper"
import PlaceHolder from '../../../Atoms/Placeholder/Placeholder'
import CustomTablePagination from "../../../Atoms/Pagination/Pagination";

const CheckupsPanel = ({ t, analysisList, xrayList, analysisListPaginationInfo, xrayPaginationInfo, getAnalysisList, getXrayList }) => {
    const [checkup, setCheckup] = useState(0)

    const [openView, setOpenView] = useState(false)
    const [image, setImage] = useState([])

    const handleCheckupChange = (event, newValue) => {
        setCheckup(newValue)
    }

    const openViewImage = (image) => {
        setImage(image)
        setOpenView(true)
    }

    const handleCloseViewImage = () => setOpenView(false)

    const CheckupTabOptions = [
        {
            id: 1,
            label: t("Checkups.analysis")
        },
        {
            id: 2,
            label: t("Checkups.xray")
        }
    ];

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const [historyFilterObj, setHistoryFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const paginationChangePage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_number': value + 1 });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_size': value });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_size': value });
    };
    useEffect(() => {
        getAnalysisList(filterObj)
    }, [filterObj])

    useEffect(() => {
        getXrayList(historyFilterObj)
    }, [historyFilterObj])

    return (
        <Grid>
            <Typography mt={4} variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                {t("UserProfile.checkups")}
            </Typography>
            <Box my={4} >
                <NormalTabs
                    TabsOptions={CheckupTabOptions}
                    value={checkup}
                    change={handleCheckupChange}
                    ContainerStyle="switch-container"
                    TabStyle="switch-btn"
                />
            </Box>
            {CheckupTabOptions.map((option, index) => (
                <TabPanel
                    value={checkup}
                    index={index}
                    key={index}>
                    {index === 0 ?
                        <Grid container justifyContent={'center'}>
                            {analysisList?.length > 0 ?
                                analysisList?.map((item, i) =>
                                    <Grid md={12} mb={3}>
                                        <Typography
                                            component="subtitle1"
                                            style={{ color: 'var(--font-color)' }}
                                        >
                                            {item?.date}
                                        </Typography>
                                        <Grid
                                            container
                                            mt={1}
                                            className="mb-5px">
                                            {item?.attachments?.length > 0 && item?.attachments?.map((attachment) => {
                                                return (
                                                    <img
                                                        src={attachment?.url}
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            marginInlineEnd: '20px',
                                                            cursor: 'pointer'
                                                        }}
                                                        alt='attachment'
                                                        onClick={() => openViewImage(attachment?.url)}
                                                    />
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                )
                                :
                                <PlaceHolder
                                    src={images.EmptyPlaceholder}
                                    alt='Empty Box'
                                    mainTitle={t("Checkups.no_analysis")}
                                    subTitle={t("Checkups.add_analysis")}
                                    txtContainer='text-container'
                                />
                            }
                            {analysisListPaginationInfo.total_count > 10 && (
                                <CustomTablePagination
                                    totalCount={analysisListPaginationInfo.total_count}
                                    changePage={(val) => paginationChangePage(val, "app")}
                                    handleRowsPerPage={(val) => handleRowsPerPage(val, "app")}
                                    t={t} 
                                />
                            )}
                        </Grid>
                        :
                        <Grid container justifyContent={'center'}>
                            {xrayList?.length > 0 ?
                                xrayList?.map((item, i) =>
                                    <Grid md={12} mb={3}>
                                        <Typography
                                            component="subtitle1"
                                            style={{ color: 'var(--font-color)' }}
                                        >
                                            {item?.date}
                                        </Typography>
                                        <Grid
                                            container
                                            mt={1}
                                            className="mb-5px">
                                            {item?.attachments?.length > 0 && item?.attachments?.map((attachment) => {
                                                return <img
                                                    src={attachment?.url}
                                                    style={{
                                                        width: '100px',
                                                        height: '100px',
                                                        marginInlineEnd: '20px',
                                                        cursor: 'pointer'
                                                    }}
                                                    alt='attachment'
                                                    onClick={() => openViewImage(attachment?.url)}
                                                />
                                            })}
                                        </Grid>
                                    </Grid>
                                )
                                :
                                <PlaceHolder
                                    src={images.EmptyPlaceholder}
                                    alt='Empty Box'
                                    mainTitle={t("Checkups.no_xray")}
                                    subTitle={t("Checkups.add_xray")}
                                    txtContainer='text-container'
                                />
                            }
                            {xrayPaginationInfo.total_count > 10 && (
                                <CustomTablePagination
                                    totalCount={xrayPaginationInfo.total_count}
                                    changePage={(val) => paginationChangePage(val, "history")}
                                    handleRowsPerPage={(val) => handleRowsPerPage(val, "history")}
                                    t={t} 
                                />
                            )}
                        </Grid>
                    }
                </TabPanel>
            ))}
            <ViewCheckup
                t={t}
                open={openView}
                handleClose={handleCloseViewImage}
                image={image}
            />
        </Grid>
    )
}

export default CheckupsPanel
