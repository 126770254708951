import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import RadioButtonsGroup from "../../../Atoms/Input/Radio/RadioInput";
import SelectBox from "../../../Atoms/Select/Select";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker";
import moment from "moment-hijri";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/images/IconHelper";
import { toast } from "react-toastify";

function UserPersonalInfo(props) {
    const { t, profileObj, serviceType, doctorGender, updateProfileCallback, getProfileData } = props;
    const [date, setDate] = useState();
    const [oldYear, setOldYear] = useState("");
    const [userGender, setUserGender] = useState("");
    const [redBorder, setRedBorder] = useState("");
    const navigate = useNavigate();
    const [personalIdType, setPersonalIdType] = useState("");
    const [idTypeError, setIdTypeError] = useState(false);
    const [dateTypeError, setDateTypeError] = useState(false);
    const [idTypeErrorText, setIdTypeErrorText] = useState("");
    const gender = [
        {
            label: t("TelemedicineSettings.male"),
            value: "male",
            src: icons.ManAvatar
        },
        {
            label: t("TelemedicineSettings.female"),
            value: "female",
            src: icons.WomanAvatar
        },
    ];
    const getUserGender = (value) => {
        setUserGender(value);
    };
    const checkTypeError = (personalType) => {
        if (personalType === "") {
            setIdTypeError(true);
            setIdTypeErrorText(t("Validation.personal_id_required"));
        } else {
            setIdTypeError(false);
        }
    };
    const handleChange = (e) => {
        checkTypeError(e.target.value);
        setPersonalIdType(e.target.value);
    };
    const handleDateChange = (date) => {
        setDate(date);
        const birthYear = date.locale('en');
        const CurrentYear = moment().locale('en');
        const diffTime = Math.abs(CurrentYear - birthYear);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        var oldYear
        var oldYearStr
        if (diffDays >= 365) {
            oldYear = diffDays / 365
            oldYearStr = t("TelemedicineSettings.year")
        } else if (diffDays <= 30) {
            oldYear = diffDays
            oldYearStr = t("TelemedicineSettings.day")
        } else if (30 < diffDays < 365) {
            oldYear = diffDays / 30
            oldYearStr = t("TelemedicineSettings.month")
        }
        setOldYear(`${parseInt(oldYear)} ${oldYearStr}`);
        setRedBorder("");
    };
    const profileValidationSchema = yup.object({
        personal_id: yup.string().required(t("Validation.enter_personal_id")).min(10, t("Validation.personal_id_min")).max(10, t("Validation.personal_id_max")),
    });
    const profileIntialValues = {
        personal_id_type: profileObj ? profileObj?.personal_id_type : "",
        personal_id: profileObj ? profileObj?.personal_id_number : "",
        hijri_birth_date: "",
        birth_date: "",
        gender: ""
    };
    const formik = useFormik({
        initialValues: profileIntialValues,
        validationSchema: profileValidationSchema,
        onSubmit: (values) => {
            if (!idTypeError) {
                updateProfile({
                    personal_id_type: personalIdType, // "national_id" or "iqama"
                    personal_id_number: values.personal_id,
                    hijri_birth_date: date.format("iDD/iMM/iYYYY"),
                    birth_date: date.format("DD/MM/YYYY"),
                    gender: userGender
                })
            } else toast.error(t("EditUserProfile.choose_id"))
        }
    });
    const navigateToSearchPage = () => {
        if (date === null) {
            setRedBorder("red-border")
        }
        checkTypeError(personalIdType);
        formik.handleSubmit();
    };
    const updateProfile = async (data) => {
        const { res, err } = await updateProfileCallback(data);
        if (res) {
            if (serviceType === "privateDoctor") navigate("private-doctor-search?doctorGender=" + doctorGender);
            else navigate("group-therapy-search?doctorGender=" + doctorGender);
            getProfileData();
        }
        if (err) toast.error(err)
    };
    const setOldData = () => {
        profileObj?.birth_date !== null ? handleDateChange(moment(profileObj?.birth_date)) : setDate(null);
        profileObj?.gender !== null ? setUserGender(profileObj?.gender == 'أنثى' || profileObj?.gender == 'Female' ? 'female' : 'male') : setUserGender("");
        profileObj?.personal_id_number !== null ? formik.values.personal_id = profileObj?.personal_id_number : formik.values.personal_id = "";
        profileObj?.personal_id_type !== null ? setPersonalIdType(profileObj?.personal_id_type) : setPersonalIdType("");
    };
    useEffect(() => {
        setOldData();
    }, [profileObj]);
    return (
        <Grid container>
            <Grid container md={12} className="mb-30px">
                <Grid xs={12}>
                    <Typography
                        style={{ fontSize: "16px", color: "var(--font-color)" }}
                        component="p"
                        className="mb-20px"
                    >
                        {t("TelemedicineSettings.personal_info")}
                    </Typography>
                </Grid>
                <Grid xs={3.6}>
                    <Typography
                        style={{ fontSize: "14px", color: "var(--secondary-color)" }}
                        component="p"
                    >
                        {t("DoctorProfile.gender")}
                    </Typography>
                </Grid>
                <Grid xs={8.4} className="radio-full-width">
                    <RadioButtonsGroup
                        options={gender}
                        row={true}
                        containerStyle={{ width: "44%", position: "relative" }}
                        change={(val) => getUserGender(val)}
                        value={userGender}
                    />
                </Grid>
            </Grid>
            <Grid container md={12}>
                <Grid xs={12} className="mb-10px">
                    <SelectBox
                        id="personal_id_type"
                        name="personal_id_type"
                        label={t("EditUserProfile.choose_id") + '*'}
                        labelStyle="field-label"
                        value={personalIdType}
                        error={idTypeError}
                        helperText={idTypeErrorText}
                        onchange={(e) => handleChange(e)}
                        items={[
                            { id: "national_id", name: t("EditUserProfile.national_id") },
                            { id: "iqama", name: t("EditUserProfile.iqama") },
                        ]}
                        title={t("EditUserProfile.choose_id")}
                    />
                </Grid>
                <Grid xs={12} className="mb-30px">
                    <TextFieldInput
                        id="personal_id"
                        name="personal_id"
                        label={t("Signup.enter_iqama_id")}
                        labelStyle="field-label"
                        placeholder={t("Signup.enter_iqama_id")}
                        className="mb-20px"
                        onchange={formik.handleChange}
                        value={formik.values.personal_id}
                        error={
                            formik.touched.personal_id && Boolean(formik.errors.personal_id)
                        }
                        helperText={formik.touched.personal_id && formik.errors.personal_id}
                    />
                </Grid>
                <Grid xs={12} className="mb-30px">
                    <BasicDatePicker
                        label={t("TelemedicineSettings.birth_date")}
                        labelStyle="field-label"
                        selected={date}
                        change={handleDateChange}
                        className={redBorder}
                        errorText={t("TelemedicineSettings.select_date")}
                        placeholderText="dd/mm/yyyy"
                        icon={icons.Calendar}
                    />
                </Grid>
                <Grid xs={12} className="mb-30px">
                    <BasicDatePicker
                        label={t("TelemedicineSettings.hijri_birth_date")}
                        labelStyle="field-label"
                        calendar="hijri"
                        selected={date}
                        change={handleDateChange}
                        className={redBorder}
                        errorText={t("TelemedicineSettings.select_date")}
                        placeholderText="dd/mm/yyyy"
                        icon={icons.Calendar}
                    />
                </Grid>
                <Grid xs={12} style={{ textAlign: "center" }}>
                    {oldYear !== "" && (
                        <Typography
                            className="mb-30px"
                            component="p"
                            style={{ fontSize: "18px", color: "var(--secondary-color)" }}
                        >
                            {t("TelemedicineSettings.age") + oldYear}
                        </Typography>
                    )}
                </Grid>
                <Grid xs={12}>
                    <Typography style={{ fontSize: "13px", color: "var(--secondary-color)" }} component="p">
                        {t("Validation.required_fields")}
                    </Typography>
                </Grid>
                <Grid xs={12} className="mb-20px">
                    <PrimaryButton
                        classBtn="edit-btn"
                        text={t("Signup.next")}
                        click={() => navigateToSearchPage()}
                        style={{ maxWidth: "180px" }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withTranslation()(UserPersonalInfo);