import LoginLogo from "./login-logo.png";
import userAvatar from "./user-avatar.png";
import coins from "./coins.png";
import certificate from "./certificate.png";
import careerIcon from "./careerIcon.png";
import comment from "./comment.png";
import people from "./people.png";
import User from "./UserImage.png";
import Doctor from "./DoctorImage.png";
import Border from "./Border.png";
import certificateItem from "./certificateItem.png";
import cameraIcon from "./icons/cameraIcon.svg";
import TipIcon from "./tipIcon.png";
import UploadCertificate from "./upload-certificate.png";
import verificationLogo from "./verification-logo.png";
import Camera from "./Camera.png";
import PenIcon from "./pen.png";
import ForgotPasswordLogo from "./forgot-password-logo.png";
import EmptyPlaceholder from "./empty-placeholder.png";
import CalendarIcon from "./icons/calendar-3.svg";
import StopWatchIcon from "./icons/stop-watch.svg";
import ClockIcon from "./icons/clock.svg";
import Clock from "./icons/clock-icon.svg";
import MoneyIcon from "./icons/money.svg";
import ChairIcon from "./icons/chair.svg";
import Logo from "./Logo.png";
import NotificationIcon from "./icons/notification.svg";
import AvatarIcon from "./icons/avatar-icon.svg";
import WalletIcon from "./icons/wallet-icon.svg";
import SettingsIcon from "./icons/settings-icon.svg";
import PrivacyIcon from "./icons/privacy-icon.svg";
import LogoutIcon from "./icons/logout-icon.svg";
import EmailIcon from "./icons/email-icon.svg";
import WhatsappIcon from "./icons/whatsapp-icon.svg";
import MedicalFile from "./icons/medicalFile.svg";
import LifeStyle from "./icons/lifeStyle.svg";
import Diagnostics from "./icons/diagnostics.svg";
import Prescriptions from "./icons/prescriptions.svg";
import Checkups from "./icons/checkups.svg";
import Surgical_operations from "./icons/surgical_operations.svg";
import Allergies from "./icons/allergies.svg";
import MedFile from "./icons/MedFile.svg";
import Oval from "./oval.png";
import TelemedicineService from "./telemedicine-service.png";
import ClinicService from "./clinic-service.png";
import HospitalService from "./hospital-service.png";
import SearchImg from "./search-img.png";
import ReservingDoctorCall from "./reserveing-doctor-call.png";
import PenReverse from "./pen-reverse.png";
import InfoShield from "./info-shield.png";
import NotDiagnosis from "./not-diagnosis.png";
import NotForEmergency from "./not-for-emergency.png";
import FamilyDoctor from "./family-doctor.png";
import SortDesc from "./sort-desc.png";
import Filter from "./filter.png";
import Oval2 from "./oval2.png";
import NoConnection from "./no-connection.png";
import remove from "./icons/remove_img.png";
import MedicalEntity from "./medical-entity.png";
import AirPort from "./icons/airport-2.svg";
import ConfirmDelete from "./confirm-delete.png";
import callimg from "./callimg.png";
import acceptcall from "./icons/acceptcall.svg";
import rejectcall from "./icons/rejectcall.svg";
import send from "./icons/send.svg";
import attach from "./icons/attach.svg";
import Confirmation from "./confirmation.png";
import AcceptStatus from "./accept-status.png";
import PendingStatus from "./pending-status.png";
import RejectStatus from "./reject-status.png";
import Calendar from "./icons/calendar.svg";
import Mobile from "./mobile.png";
import Password from "./password.png";
import DeleteAccount from "./delete-account.png";
import verifayNumber from "./verifay-number.png";
import ManAvatar from "./icons/man-avatar.svg";
import WomanAvatar from "./icons/woman-avatar.svg";
import ContactUs from "./contact-us.png";
import FacebookIcon from "./icons/facebook-icon.svg";
import TwitterIcon from "./icons/twitter-icon.svg";
import InstgramIcon from "./icons/instgram-icon.svg";
import OfferEnded from "./offer-ended.png";
import LogoutImage from "./logout.png";
import GroupSession from "./Group-session.png";
import GridIcon from "./icons/gridIcon.svg";
import ListIcon from "./icons/listIcon.svg";
import TelemedicineIcon from "./icons/telemedicineIcon.svg";
import ClinicsIcon from "./icons/clinicsIcon.svg";
import HospitalsIcon from "./icons/hospitalsIcon.svg";
import ChatHistory from "./icons/chat-history.svg";
import Star from "./icons/star.svg";
import PhoneIcon from "./icons/phone-icon.svg";
import InvoiceDetails from "./invoice-details.png";
import ReviewStar from "./icons/review-star.svg";
import GroupIcon from "./icons/group-icon.svg";
import NewNotification from "./icons/new-notification.svg";
import GroupTherapy from "./icons/group_therapy.svg";
import Deactivate from "./deactivate.png";
import WalletImg from "./wallet-img.png";
import PatientWallet from "./icons/patient-wallet.svg";
import Coins from "./icons/Coins.svg";
import Visa from "./icons/visa.svg";
import MasterCard from "./icons/mastercard.svg";
import stcLogo from "./STC.png";
import GooglePay from "./googlePay.png";
import MadaLogo from "./mada.png";
import ApplePay from "./applePay.png";
import Success from "./icons/success.svg";
import Faild from "./icons/faild.svg";
import Logo1 from "./logo-inovaa-1.png";
import Logo2 from "./logo-inovaa-2.png";
import Logo3 from "./logo-inovaa-3.png";
import Information from "./icons/information.svg";
import PDF from "./icons/pdf.svg";
import SwitchAccounts from "./icons/switch-account.svg";
import DownloadPDF from "./icons/download-pdf.svg";

export const images = {
  LoginLogo,
  userAvatar,
  coins,
  User,
  Doctor,
  certificate,
  careerIcon,
  comment,
  people,
  Border,
  certificateItem,
  TipIcon,
  UploadCertificate,
  verificationLogo,
  Camera,
  PenIcon,
  EmptyPlaceholder,
  Logo,
  ForgotPasswordLogo,
  TelemedicineService,
  ClinicService,
  HospitalService,
  SearchImg,
  ReservingDoctorCall,
  Oval,
  PenReverse,
  InfoShield,
  NotDiagnosis,
  NotForEmergency,
  FamilyDoctor,
  SortDesc,
  Filter,
  Oval2,
  NoConnection,
  MedicalEntity,
  ConfirmDelete,
  callimg,
  Confirmation,
  AcceptStatus,
  PendingStatus,
  RejectStatus,
  Mobile,
  Password,
  DeleteAccount,
  verifayNumber,
  ContactUs,
  OfferEnded,
  LogoutImage,
  GroupSession,
  InvoiceDetails,
  Deactivate,
  WalletImg,
  stcLogo,
  GooglePay,
  MadaLogo,
  ApplePay,
  Logo1,
  Logo2,
  Logo3
};

export const icons = {
  cameraIcon,
  CalendarIcon,
  StopWatchIcon,
  ClockIcon,
  MoneyIcon,
  ChairIcon,
  NotificationIcon,
  AvatarIcon,
  WalletIcon,
  SettingsIcon,
  PrivacyIcon,
  LogoutIcon,
  EmailIcon,
  WhatsappIcon,
  MedicalFile,
  LifeStyle,
  Diagnostics,
  Prescriptions,
  Checkups,
  Surgical_operations,
  Allergies,
  MedFile,
  remove,
  AirPort,
  acceptcall,
  rejectcall,
  Calendar,
  ManAvatar,
  WomanAvatar,
  attach,
  send,
  FacebookIcon,
  TwitterIcon,
  InstgramIcon,
  GridIcon,
  ListIcon,
  TelemedicineIcon,
  ClinicsIcon,
  HospitalsIcon,
  ChatHistory,
  Star,
  PhoneIcon,
  ReviewStar,
  GroupIcon,
  NewNotification,
  GroupTherapy,
  PatientWallet,
  Coins,
  MasterCard,
  Visa,
  Success,
  Faild,
  Information,
  PDF,
  SwitchAccounts,
  Clock,
  DownloadPDF
};
