import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AddMsg, ChatRoom, GetMessages, handleFileInputs, handleFiles, OffDatabaseConnection, SendMessage } from "../../../../Contexts/API/Chatting/chat";
import { SignInFirebase } from "../../../../Contexts/Firebase/Firebase";
import { AcceptCall, websdkready } from "../../../../Contexts/Zoom/ZoomIntegration";
import PatientTelemedicineSessionTemplate from "../../../../Templates/Main/Home/TelemedicineSession/PatientTelemedicineSessionTemplate";
import Loader from "../../../../Components/Atoms/Loader/Loader";

const PatientTelemedicineSession = ({ }) => {
  const { id } = useParams();
  const [chatRoom,setchatRoom] = useState({})
  const [Chatmessages, setChatmessages] = useState([]);
  const [message, setmessage] = useState('');
  const [Files, setfiles] = useState([]);
  const [FilesToSend, setFilesToSend] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const Joinsession = async () => {
    let parent = document.getElementById("zoom_part");
    parent.appendChild(document.getElementById("zmmtg-root"))
    document.getElementById("zmmtg-root").classList.add("zoom-show");
    document.getElementById("zmmtg-root").classList.add("zoom-show")

    let { res, err } = await AcceptCall(Number(id));
    if (res) {
      websdkready(res?.data?.session_details?.meeting_id, res?.data?.session_details?.meeting_password, res?.data?.session_details?.current_user?.name,0);
    }
  }
  const getChatRoom = async () => {

    let {res,err} = await ChatRoom(id);
    if(res){
      let url = new URL(res?.data?.chat_room?.messages_url)
      setchatRoom(res?.data?.chat_room)
      localStorage.setItem('MESSAGE_NODE',url.pathname.slice(1))
      SignInFirebase();
      setChatmessages([]);
    }
  }
  const getMessages = async () => {
    
    await GetMessages(localStorage.getItem('MESSAGE_NODE'))
    window.addEventListener('getmessage', async (evt) => {
        setChatmessages(evt.data);
    });

}
const sendMessage = async () => {

  if (message != "" || FilesToSend?.length > 0) {
      const msg_type = Files.length === 0 ? "text_msg" : "media_msg"
      await SendMessage(message, FilesToSend, localStorage.getItem('userId'), 'patient')
      let { err, res } = await AddMsg(id,{
        message: message,
        message_type: msg_type,
        media_type: "photo",
        payload: FilesToSend[0]?.url
      })

      if (res) {
        setmessage("")
        setfiles([])
        setFilesToSend([])
        getMessages()
      }
  }
}

const handleFileInput = ({ target: { files } }) => {
  let result = handleFileInputs(Files, files)
  setfiles(result)
}

// const handleFileRemove = (index) => {
//   let result = handleFileRemoves(Files, index)
//   setfiles([...result])
// }

const removeImage = () => {
  setfiles([]);
  setFilesToSend([]);
};

useEffect(async () => {
  setOpenLoader(true);
  let urls = await handleFiles(Files);
  const FilesArray = await Promise.all(urls);
  setFilesToSend(FilesArray);
  setOpenLoader(false);
}, [Files])

useEffect(() => {
    Joinsession();
    getChatRoom();
    setTimeout(() => {
        if (localStorage.getItem('MESSAGE_NODE')) getMessages();
    }, 3000);
    return () => {
        OffDatabaseConnection(localStorage.getItem('MESSAGE_NODE'));
        window.removeEventListener('getmessage', () => { console.log('event off') });
    };

}, [])



  return (
    <>
      <Loader open={openLoader} />
      <PatientTelemedicineSessionTemplate 
        chatRoom={chatRoom} 
        Chatmessages={Chatmessages} 
        setmessage={setmessage} 
        message={message}
        sendMessage={sendMessage}
        handleFileInput={handleFileInput}
        Files={Files}
        removeImage={removeImage}
      />
    </>
  );
};

export default PatientTelemedicineSession;
