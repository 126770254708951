import { useEffect, useState } from "react"

import OffersTemplate from "../../../Templates/Main/Offers/OffersTemplate"
import { getOffersListAPI, checkOfferStateAPI } from '../../../Contexts/API/Offers/OffersApi.js'

const OffersPage = () => {

    const [offersList, setOffersList] = useState([])
    const [openLoader, setLoader] = useState(false)
    const [totalCount, setTotalCount] = useState()
    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1
    })
    
    const getList = async () => {
        setLoader(true)
        const res = await getOffersListAPI(filterObj)
        if (res) {
            setOffersList(res?.data?.offers)
            setTotalCount(res?.extra?.total_count)
        }
        setLoader(false)
    }

    const checkOffer = async (id) => {
        setLoader(true)
        const res = await checkOfferStateAPI(id)
        if (res) return res?.data
        setLoader(false)
    }

    useEffect(() => {
        getList()
    }, [filterObj])

    return <OffersTemplate
        offersList={offersList}
        checkOffer={checkOffer}
        openLoader={openLoader}
        totalCount={totalCount}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
    />
}

export default OffersPage