import { Grid, Typography } from "@mui/material"

import PrimaryButton from '../../../../Components/Atoms/Buttons/Primary/PrimaryButton'

function DiagnosisCard(props) {
    return (<Grid container className="therapy-item mb-10px">
        <Grid item md={10}>
            <Typography component="p" style={{
                color: "#848484",
                fontWeight: "lighter"
            }}>
                {props.item?.date}
            </Typography>
            <Typography component="h1">
                {props.item?.diagnosis_name}
            </Typography>
            <Typography component="p" style={{
                color: "#848484",
                fontWeight: "lighter"
            }}>
                {props.item?.medical_provider_name || props.item?.medical_provider?.name}
            </Typography>
        </Grid>
        <Grid container item md={2} alignContent='center'>
            <PrimaryButton
                text={props.t("UserProfile.more")}
                classBtn='appointment-btn btn-rounded'
                click={props.handleOpen} 
                />
        </Grid>
    </Grid>);
}

export default DiagnosisCard