import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import "./UserHome.css";
import DoctorProfileInfo from "../../../Molecules/Home/UserHome/DoctorProfile/DoctorProfileInfo";
import AppointementsPanel from "../../../Molecules/Home/UserHome/DoctorProfile/AppointementsPanel";
import { useLocation } from "react-router-dom";
import AvailableSessions from "../../../Molecules/Home/UserHome/DoctorProfile/AvailableSessions";
import PaymentMethod from "../../../Molecules/Home/UserHome/DoctorProfile/PaymentMethod";
import { useState } from 'react';
import Packages from "../../../Molecules/Wallet/Packages";
import SuccessReservation from "../../Modals/Reservation/SuccessReservation";
import CustomPopup from "../../Modals/CustomPopup/CustomPopup";
import { icons } from "../../../../Assets/images/IconHelper";
import { useEffect } from "react";

const DoctorProfile = (props) => {
    const {
        t,
        telemedicineDoctorProfile,
        duration,
        fees,
        getTimeSlots,
        timeSlots,
        gePaymentInfo,
        medicalServices,
        walletInfo,
        packages,
        paymentInfo,
        openSuccessPage,
        succesPageData,
        openFaildPopup,
        handleCloseFaildPopup,
        createReservation,
        acceptOnlinePayment,
        offers,
        feesAfterDiscount,
        setFeesAfterDiscount
    } = props;
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const fullWidth = pathnameArr[4] === 'group-therapy-search' ? 12 : 6;
    const [openPayment, setOpenPayment] = useState(false);
    const [showPackage, setShowPackages] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(false);
    const [offer, setOffer] = useState(0);
    
    useEffect(() => {
        const index = offers?.findIndex(object => object.value === medicalServices?.[0]?.applied_offer?.discount_percentage)
        setOffer(index)
    }, [offers]);
    return (
        <>
            <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 3 }}
            >
                <Grid item sm={fullWidth}>
                    <Typography
                        component="h1"
                        className="mb-30px"
                        style={{ fontWeight: "bolder", color: "var(--secondary-color)", fontSize: "18px" }}
                    >
                        {t("TelemedicineSettings.doctor_profile")}
                    </Typography>
                    <DoctorProfileInfo
                        telemedicineDoctorProfile={telemedicineDoctorProfile}
                        duration={duration}
                        fees={fees}
                        feesAfterDiscount={feesAfterDiscount}
                    />
                </Grid>
                {pathnameArr[4] === 'group-therapy-search' ? (
                    <Grid item sm={12}>
                        <Typography
                            component="h1"
                            className="mb-30px"
                            style={{ fontWeight: "bolder", color: "var(--font-color)", fontSize: "18px" }}
                        >
                            {t("TelemedicineSettings.available_sessions")}
                        </Typography>
                        <AvailableSessions
                            list={medicalServices}
                            getPaymentInfo={
                                (id, offer) => {
                                    gePaymentInfo(id, offer);
                                    setPaymentMethod(true);
                                }
                            }
                            createReservationDirect={(id) => createReservation(id)}
                            offers={offers}
                            offer={offer}
                            setOffer={setOffer}
                            feesAfterDiscount={feesAfterDiscount}
                            setFeesAfterDiscount={setFeesAfterDiscount}
                            paymentInfo={paymentInfo}
                        />
                    </Grid>
                ) : (
                    <Grid item sm={6}>
                        <Typography
                            component="h1"
                            className="mb-30px"
                            style={{ fontWeight: "bolder", color: "var(--font-color)", fontSize: "18px" }}
                        >
                            {t("TelemedicineSettings.choose_time_slots")}
                        </Typography>
                        <AppointementsPanel
                            getTimeSlots={(id, filterObj) => getTimeSlots(id, filterObj)}
                            timeSlots={timeSlots}
                            gePaymentInfo={
                                (id, offer) => {
                                    gePaymentInfo(id, offer);
                                    setPaymentMethod(true);
                                }
                            }
                            acceptOnlinePayment={acceptOnlinePayment}
                            createReservationDirect={(id) => createReservation(id)}
                            telemedicineDoctorProfile={telemedicineDoctorProfile}
                            offers={offers}
                            offer={offer}
                            setOffer={setOffer}
                            setFeesAfterDiscount={setFeesAfterDiscount}
                            fees={fees}
                        />
                    </Grid>
                )}
                {paymentMethod && (
                    <Grid item sm={6}>
                        <Typography
                            component="h1"
                            className="mb-30px"
                            style={{ fontWeight: "bolder", color: "var(--font-color)", fontSize: "18px" }}
                        >
                            {t("Payment.payment_method")}
                        </Typography>
                        <PaymentMethod
                            walletInfo={walletInfo}
                            openPaymentPanel={(flag) => setOpenPayment(flag)}
                            showPackages={(flag) => setShowPackages(flag)}
                            paymentInfo={paymentInfo}
                            duration={duration}
                            createReservation={createReservation}
                        />
                    </Grid>
                )}
                <Grid item sm={6} style={{ display: openPayment === true ? 'block' : 'none' }}>
                    <Typography
                        component="h1"
                        className="mb-30px"
                        style={{ fontWeight: "bolder", color: "var(--font-color)", fontSize: "18px" }}
                    >
                        {t("Payment.pay_now")}
                    </Typography>
                    <Grid className="profile-panel-container mb-30px">
                        <div className="mysr-form"></div>
                    </Grid>
                </Grid>
                <Grid item sm={6} style={{ display: showPackage === true ? 'block' : 'none' }}>
                    <Typography
                        component="h1"
                        className="mb-30px"
                        style={{ fontWeight: "bolder", color: "var(--font-color)", fontSize: "18px" }}
                    >
                        {t("Wallet.recharge")}
                    </Typography>
                    <Packages
                        packages={packages}
                        setOpenPayment={(flag) => {
                            setOpenPayment(flag)
                            setShowPackages(false)
                        }}
                    />
                </Grid>
            </Grid>
            <SuccessReservation
                t={t}
                open={openSuccessPage}
                handleClose={!openSuccessPage}
                invoiceData={succesPageData}
            />
            <CustomPopup
                open={openFaildPopup}
                handleClose={handleCloseFaildPopup}
                src={icons.Faild}
                t={t}
                title={t("Reservation.faild_reservation")}
                text={t("Reservation.faild_payment")}
                titleClass="faild-style"
                isAction={false}
                btnText={t("CustomPopup.ok")}
                btnCloseStyle="settings-cancel-btn"
            />
        </>
    )
};

export default withTranslation()(DoctorProfile);