import { Dialog, Grid, Typography } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

function AvatarModal(props) {
    const {
        open,
        handleClose,
        text,
        btnActionText,
        btnDeleteText,
        btnActionStyle,
        btnDeleteStyle,
        handleAction,
        handleDelete
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    padding: "40px",
                    width: "400px"
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <Grid container>
                <Grid container item xs={12} justifyContent='end' mb={2}>
                    <PrimaryButton
                        startIcon={<ClearIcon />}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={handleClose}
                    />
                </Grid>
                <Grid item xs={12} className="mb-30px" style={{ textAlign: "center" }}>
                    <Typography
                        className="cairo-font"
                        style={{ fontSize: "18px", color: "var(--font-color)" }}
                        component="p">
                        {text}
                    </Typography>
                </Grid>
                <Grid container item xs={12} style={{ textAlign: "center" }}>
                    <Grid item md={5.5}>
                        <PrimaryButton
                            text={btnActionText}
                            classBtn={"btn-rounded cairo-font " + btnActionStyle}
                            click={handleAction}
                        />
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={5.5}>
                        <PrimaryButton
                            text={btnDeleteText}
                            classBtn={"btn-rounded cairo-font " + btnDeleteStyle}
                            click={handleDelete}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Dialog >
    )
}

export default AvatarModal
