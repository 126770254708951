import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import { withTranslation } from "react-i18next";
import { icons } from "../../../../Assets/images/IconHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";
import { Button, Grid, Link, Typography, Divider } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

function HelpModal(props) {
  const { t } = props;
  const [open, setOpen] = React.useState(false);
  let email = '', emailSubject = '', emailBody = '', number = ''

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link className="Link" onClick={handleClickOpen}>
        {t("Signup.help")}
      </Link>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        dir={localStorage.getItem("lang") === 'ar' ? 'rtl' : 'ltr'}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ marginTop: "20px" }}>
          <Grid container style={{ width: 510 }} mb={5}>
            <Grid xs={11} container justifyContent="center">
              <Typography component="h1" style={{ fontWeight: "bold", fontSize: "24px" }}>
                {t("Signup.help_request")}
              </Typography>
            </Grid>
            <Grid xs={1}>
              <PrimaryButton
                startIcon={<ClearIcon />}
                btnType="icon"
                classBtn="clear-icon"
                click={handleClose}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="mb-30px">
          <Grid container>
            <Grid
              item
              xs={12}
              container
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open(`http://wa.me/${number}`)}
            >
              <img src={icons.WhatsappIcon} />
              <Typography style={{ margin: "0px 10px" }}>
                {t("Signup.whats_app")}
              </Typography>

            </Grid>
            <Divider style={{ width: "100%", margin: "20px 0" }} />
            <Grid item xs={12} container sx={{ cursor: 'pointer' }}
              onClick={() => window.open(`mailto:${email}?subject=${emailSubject}&body=${emailBody}`)}
            >
              <img src={icons.EmailIcon} />
              <Typography style={{ margin: "0px 10px" }}>
                {t("Signup.send_email")}
              </Typography>

            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withTranslation()(HelpModal);
