import { useState, useEffect } from "react"
import { Grid, Typography, Box } from "@mui/material"

import TabPanel from "../../../Atoms/Tabs/TabPanel"
import NormalTabs from "../../../Atoms/Tabs/NormalTabs"
import PreviousDiagnosisCard from "../Cards/Diagnosis/PreviousDiagnosisCard"
import AppDiagnosisCard from "../Cards/Diagnosis/AppDiagnosisCard"
import AppDiagnosisDetailsCard from "../Cards/Diagnosis/AppDiagnosisDetailsCard"
import AddNewDiagnosis from "../Forms/AddNewDiagnosis"
import EditAppDiagnosis from "../Forms/EditAppDiagnosis"
import PlaceHolder from '../../../Atoms/Placeholder/Placeholder'
import { images } from "../../../../Assets/images/IconHelper"
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import CustomTablePagination from "../../../Atoms/Pagination/Pagination";

function DiagnosisPanel(props) {
    const { t, paginationInfo, getDiagnosisApp, paginationDiagnosisHistory, getDiagnosisHistory, diseasesList, medicinesList } = props;
    const [diagnosisTab, setDiagnosisTab] = useState(0)
    const [diagnosis, setDiagnosis] = useState([])
    const [openAppCard, setOpenAppCard] = useState()
    const [newDiagnosis, setNewDiagnosis] = useState(false)
    const [editDiagnosis, setEditDiagnosis] = useState()
    const [isSameDoctor, setIsSameDoctor] = useState(true)
    const DiagnosisTabOptions = [
        {
            id: 1,
            label: props.t("Prescription.movile_app_prescription")
        },
        {
            id: 2,
            label: props.t("Prescription.prev_prescription")
        }
    ]

    const handleOpenAppCard = (i) => openAppCard === i ? setOpenAppCard() : setOpenAppCard(i)
    const handleOpenEditDiagnosis = (value, sameDoctor, i) => {
        setDiagnosis(value)
        setIsSameDoctor(sameDoctor)
        setOpenAppCard()
        editDiagnosis === i ? setEditDiagnosis() : setEditDiagnosis(i)
    }
    const handleCloseNewDiagnosis = () => setNewDiagnosis(false)

    const handleDiagnosisChange = (event, newValue) => setDiagnosisTab(newValue)

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const [historyFilterObj, setHistoryFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const paginationChangePage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_number': value + 1 });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_size': value });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_size': value });
    };
    useEffect(() => {
        getDiagnosisApp(filterObj)
    }, [filterObj])

    useEffect(() => {
        getDiagnosisHistory(historyFilterObj)
    }, [historyFilterObj])

    return (<Grid>
        <Box my={4}>
            <NormalTabs
                TabsOptions={DiagnosisTabOptions}
                value={diagnosisTab}
                change={handleDiagnosisChange}
                ContainerStyle="switch-container" TabStyle="switch-btn diagnosis-switch"
            />
        </Box>
        <Grid container alignItems='center'>
            <Grid item md={6} >
                <Typography mt={4} variant='subtitle1' sx={{
                    fontWeight: 'bold', marginInlineStart: '50px'
                }}>
                    {props.t("UserProfile.diagnostics")}
                </Typography>
            </Grid>
            <Grid container item md={6} justifyContent='flex-end' sx={{ paddingInlineEnd: '30px' }}>
                {(diagnosisTab === 0 && !newDiagnosis) && <PrimaryButton
                    text={props.t("DoctorTelemedicine.new_diagnosis")}
                    classBtn='appointment-btn btn-rounded'
                    click={() => setNewDiagnosis(true)}
                />}
            </Grid>
        </Grid>
        {DiagnosisTabOptions.map((option, index) =>
            <TabPanel
                value={diagnosisTab}
                index={index}
                key={index}
            >
                {index === 0 ?
                    !newDiagnosis ?
                        <>
                            {props?.appDiagnosisList?.length > 0 ?
                                props?.appDiagnosisList?.map((item, i) => {
                                    return (
                                        <>
                                            <AppDiagnosisCard
                                                key={i}
                                                i={i}
                                                openAppCard={openAppCard}
                                                handleOpenAppCard={() => handleOpenAppCard(i)}
                                                item={item}
                                            />
                                            {openAppCard === i && <AppDiagnosisDetailsCard
                                                t={props.t}
                                                showDiagnosis={props.showDiagnosis}
                                                id={item?.id}
                                                idNumber={props.idNumber}
                                                medicineIntakeMethods={props.medicineIntakeMethods}
                                                medicineDurations={props.medicineDurations}
                                                handleOpenEditDiagnosis={(value, sameDoctor) => handleOpenEditDiagnosis(value, sameDoctor, i)}
                                                medicinesList={medicinesList}
                                            />}
                                            {editDiagnosis === i && <EditAppDiagnosis
                                                t={props.t}
                                                diagnosis={diagnosis}
                                                medicineIntakeMethods={props.medicineIntakeMethods}
                                                medicineDurations={props.medicineDurations}
                                                symptoms={props.symptoms}
                                                doctorNumber={props.doctorNumber}
                                                medicalTitles={props.medicalTitles}
                                                specialityList={props.specialityList}
                                                getSubSpecialityList={props.getSubSpecialityList}
                                                handleClose={() => {
                                                    setEditDiagnosis(false)
                                                    setOpenAppCard()
                                                }}
                                                medicineDosagePeriods={props.medicineDosagePeriods}
                                                editDiagnosis={props.editDiagnosis}
                                                isSameDoctor={isSameDoctor}
                                                diseasesList={diseasesList}
                                                medicinesList={medicinesList}
                                            />}
                                        </>
                                    )
                                    
                                }):
                                <PlaceHolder
                                    src={images.EmptyPlaceholder}
                                    alt='Empty Box'
                                    mainTitle={t("UserProfile.no_diagnosis")}
                                    subTitle={t("UserProfile.add_prev_diagnosis")}
                                    txtContainer='text-container'
                                />
                            }
                            {paginationInfo?.total_count > 10 && (
                                <CustomTablePagination
                                    totalCount={paginationInfo?.total_count}
                                    changePage={(val) => paginationChangePage(val, "app")}
                                    handleRowsPerPage={(val) => handleRowsPerPage(val, "app")}
                                    t={t} 
                                />
                            )}
                        </> :
                        <AddNewDiagnosis
                            t={props.t}
                            handleClose={handleCloseNewDiagnosis}
                            medicineIntakeMethods={props.medicineIntakeMethods}
                            medicineDurations={props.medicineDurations}
                            symptoms={props.symptoms}
                            doctorNumber={props.doctorNumber}
                            medicalTitles={props.medicalTitles}
                            specialityList={props.specialityList}
                            getSubSpecialityList={props.getSubSpecialityList}
                            createDiagnosis={props.createDiagnosis}
                            medicineDosagePeriods={props.medicineDosagePeriods}
                            reservationId={props.reservationId}
                            diseasesList={diseasesList}
                            medicinesList={medicinesList}
                        />
                    :
                    <>
                        {props?.diagnosisList?.length > 0 ?
                            props?.diagnosisList?.map((item, i) => {
                                return <PreviousDiagnosisCard item={item} key={i} />
                            }) :
                            <PlaceHolder
                                src={images.EmptyPlaceholder}
                                alt='Empty Box'
                                mainTitle={t("UserProfile.no_diagnosis")}
                                subTitle={t("UserProfile.add_prev_diagnosis")}
                                txtContainer='text-container'
                            />
                        }
                        {paginationDiagnosisHistory?.total_count > 10 && (
                            <CustomTablePagination
                                totalCount={paginationDiagnosisHistory?.total_count}
                                changePage={(val) => paginationChangePage(val, "history")}
                                handleRowsPerPage={(val) => handleRowsPerPage(val, "history")}
                                t={t} 
                            />
                        )}
                    </>
                }
            </TabPanel>
        )
        }
    </Grid >);
}

export default DiagnosisPanel