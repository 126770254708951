import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function editPatientProfile(data) {
    return await axiosFn("put", URL.USER_STR + URL.PROFILES_STR, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function editPatientProfileAPI(data) {
    let { err, res } = await editPatientProfile(data);
    if (res) {
        return res;
    }
    if (err) {
        return err;
    }
}