import { useState } from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../Assets/images/IconHelper";

const TotalAmount = (props) => {
    const { t, totalAmount, depositToAccount } = props;
    
    return (
        <Grid container >
            <Grid md={12} style={{textAlign: "center"}}>
                <img src={images.WalletImg} />
            </Grid>
            <Grid className="mb-30px" md={12} style={{textAlign: "center"}}>
                <Typography className="mb-10px" component="h1" style={{fontSize: "25px", fontWeight: "bold", color: "var(--font-color)"}}>
                    {t("DoctorProfile.my_balance")}
                </Typography>
                <Typography component="p" style={{fontSize: "30px", fontWeight: "bold", color: "var(--secondary-color)"}}>
                    {totalAmount === undefined ? '' : totalAmount + t("TelemedicineSettings.sar")}
                </Typography>
            </Grid>
            <Grid md={12} style={{textAlign: "center", marginTop: "40px"}}>
                <PrimaryButton
                    text={t("Wallet.transfer_account")}
                    classBtn='primary btn-rounded'
                    style={{width: "fit-content", padding: "0px 20px"}}
                    click={depositToAccount}
                    disabled={totalAmount === "0.0"}
                />
            </Grid>
        </Grid>
    )
}

export default withTranslation()(TotalAmount)