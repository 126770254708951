import { Dialog, Grid, Typography } from "@mui/material"
import './CustomPopup.css'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

function CustomPopup(props) {
    const {
        t,
        open,
        handleClose,
        src,
        title,
        text,
        btnText,
        subText,
        isAction,
        btnActionText,
        btnCloseText,
        btnActionStyle,
        btnCloseStyle,
        handleAction,
        titleClass
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    padding: "40px",
                    width: "500px"
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img src={src} alt='' />
                </Grid>
                <Grid item xs={12} className="mb-10px " style={{ textAlign: "center" }}>
                    <Typography className={titleClass + ' cairo-font'} style={{ fontWeight: "bold", fontSize: "20px" }} component="h1">{title}</Typography>
                </Grid>
                <Grid item xs={12} className="mb-20px" style={{ textAlign: "center" }}>
                    <Typography className="cairo-font" style={{ fontSize: "18px", color: "var(--font-color)" }} component="p">{text}</Typography>
                </Grid>
                <Grid item xs={12} className="mb-20px" style={{ textAlign: "center" }}>
                    <Typography className="cairo-font" style={{ fontSize: "18px", color: "var(--font-color)" }} component="p">{subText}</Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    {!isAction ? (
                        <PrimaryButton
                            text={btnText}
                            classBtn="primary btn-rounded btn-medium cairo-font"
                            click={handleClose}
                        />
                    ) : (
                        <Grid container>
                            <Grid item md={5.5}>
                                <PrimaryButton
                                    text={btnActionText}
                                    classBtn={"btn-rounded cairo-font " + btnActionStyle}
                                    click={handleAction}
                                />
                            </Grid>
                            <Grid item md={1}></Grid>
                            <Grid item md={5.5}>
                                <PrimaryButton
                                    text={btnCloseText}
                                    classBtn={"btn-rounded cairo-font " + btnCloseStyle}
                                    click={handleClose}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Dialog >
    )
}

export default CustomPopup
