import axios from "axios";
// import { DeviceUUID } from "device-uuid";
import {
  BASE_URL,
  // BASE
} from "./URLs";

export function axiosFn(
  method,
  url,
  recivedData,
  auth = true,
  contentType = "application/json"
) {
  let token;
  token = localStorage.getItem("TOKEN");
  let data = {
    ...recivedData,
  };
  let verifyToken = localStorage.getItem("VERIFY");
  return axios({
    method,
    url: BASE_URL + url, // url == "/auth/token/create" ? BASE + url : BASE_URL + url,
    data,
    headers: {
      Authorization: `Bearer ${token}`, // verifyToken ? "" : `Bearer ${token}`
      "Content-Type": contentType,
      "verification-token": verifyToken,
      "Accept-Language": localStorage.getItem("lang"),
      "Timezone": "Cairo"
      // "Access-Control-Allow-Origin":"*",
    },
  });
}

export function handleError(err) {
  let errMsg = "Something went wrong!";
  if (err.response) errMsg = err.response.data.message;
  return {
    res: null,
    err: errMsg,
  };
}

export function defaultRes(res) {
  return {
    res: res.data.data,
    err: null,
  };
}
