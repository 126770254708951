import { Box, Container, Grid, Typography } from "@mui/material";
import { images } from "../../../Assets/images/IconHelper";
import { useNavigate } from "react-router-dom";
import "./UserSelection.css";

const UserSelection = ({ t, mode }) => {
  const navigate = useNavigate();
  return (
    <Box style={{ width: "343px" }}>
      <Container>
        <h1 className="signup-title2">{t("Signup.pick_account")} </h1>
      </Container>
      <Container disableGutters>
        <Typography variant="p" className="signup-text">
          {t("Signup.choose_type")}
        </Typography>
      </Container>
      <div style={{cursor: "pointer"}} onClick={() => {navigate("/register")}}>
        <Container>
          <img src={images.User} className="user-img"></img>
        </Container>
        <Typography
          component="p"
          className={mode == "User" ? "link1" : "link2"}
        >
          {t("Signup.user")}
        </Typography>
      </div>
      <Grid container justifyContent="center" rowSpacing={0} mt={4}>
        <Grid item xs={5}>
          <img src={images.Border} style={{ width: "" }}></img>
        </Grid>
        <Grid item xs={2}>
          <p>{t("Signup.or")}</p>
        </Grid>
        <Grid item xs={5}>
          <img src={images.Border} style={{ width: "" }}></img>
        </Grid>
        <div style={{cursor: "pointer"}} onClick={() => {navigate("/medical-register")}}>
          <Container>
            <img src={images.Doctor} className="doctor-img"></img>
          </Container>
          <Container>
            <Typography
              component="p"
              className={
                mode == "Doctor" || mode == "Doctor2" ? "link1" : "link2"
              }
            >
              {t("Signup.doctor")}
            </Typography>
          </Container>
        </div>
      </Grid>
    </Box>
  );
};

export default UserSelection;
