import * as React from 'react';
import "./Calendar.css";
import { Grid, Typography, Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import { useState, useEffect } from "react";

function CustomCalendar(props) {
  const { t, dateCallback } = props;
  moment.locale(localStorage.getItem("lang"));
  const [endOfWeek, setEndOfWeek] = useState(moment().add(7,'d'));
  const [dates, setDates] = useState([]);
  const [monthYear, setMonthYear] = useState([]);
  const [activeClass, setActiveClass] = useState([0]);
  const [disabledPrevArrow, setDisabledPrevArrow] = useState('disabled-arrow');
  const [disabledNextArrow, setDisabledNextArrow] = useState('');
  const [endOfCalendar, setEndOfCalendar] = useState(moment().add(21,'d'));

  const enumerateDaysBetweenDates = (startDate, endDate,flag="initial") => {
    var datesArr = [];
    setMonthYear([startDate.format("MMMM"), endDate.format("MMMM"), startDate.format("YYYY")]);
    if (flag == 'next' || flag == 'initial'){
      setEndOfWeek(endDate);
      while (startDate <= endDate) {
        datesArr.push([startDate.format("DD"), startDate.format("dddd"), startDate]);
        startDate = moment(startDate).add(1, 'days');
      }
    }else{
      setEndOfWeek(startDate);
      while (endDate <= startDate) {
        datesArr.push([endDate.format("DD"), endDate.format("dddd"), endDate]);
        endDate = moment(endDate).add(1, 'days');
      }
    }
    setDates(datesArr);
  };

  const handleNextClick = () => {
    setActiveClass([]);
    var from = moment(endOfWeek);
    var to = moment(from).add(1, 'weeks');
    from = moment(from).add(1, 'days');
    if(from > moment()){
      setDisabledPrevArrow('');
    }
    if(to <= endOfCalendar) enumerateDaysBetweenDates(from, to,'next');
    if(moment(to).add(1, 'days').format('DD/MM/YYYY') === moment(endOfCalendar).format('DD/MM/YYYY'))
    setDisabledNextArrow('disabled-arrow');
  };

  const handlePrevClick = () => {
    setActiveClass([]);
    var from = moment(endOfWeek).subtract(6, 'days');
    var to = moment(from).subtract(1, 'weeks');
    from = moment(from).subtract(1, 'days');
    if(moment(to).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')){
      setDisabledPrevArrow('disabled-arrow');
    }
    if(from > moment()){
      enumerateDaysBetweenDates(from, to, 'prev');
      setDisabledNextArrow('');
    }
  };

  const getDate = (date, i) => {
    setActiveClass([i]);
    var formatedDate = date.locale('en').format("DD/MM/YYYY");
    dateCallback(formatedDate);
  };

  useEffect(() => {
    var from = moment();
    var to = moment(from).add(6, 'days');
    enumerateDaysBetweenDates(from, to);
  }, []);

  return (
    <Grid container className="calendar-container">
      <Grid md={12} container className="calendar-header">
        <Grid xs={1} textAlign="center">
          {
            localStorage.getItem("lang") == "ar" ? 
            <ArrowForwardIosIcon className={disabledPrevArrow} onClick={() => handlePrevClick()}/> : 
            <ArrowBackIosIcon className={disabledPrevArrow} onClick={() => handlePrevClick()}/>
          }
        </Grid>
        <Grid xs={10} textAlign="center">
          <Typography component="p">
            {monthYear[0] == monthYear[1] ? monthYear[0] : monthYear[0] + ' / ' + monthYear[1]}
            {' ' + monthYear[2]} 
          </Typography>
        </Grid>
        <Grid xs={1} textAlign="center">
          {
            localStorage.getItem("lang") == "ar" ? 
            <ArrowBackIosIcon className={disabledNextArrow} onClick={() => handleNextClick()}/> : 
            <ArrowForwardIosIcon className={disabledNextArrow} onClick={() => handleNextClick()}/>
          }
        </Grid>
      </Grid>
      <Grid md={12} container className="calendar-body">
        {dates.map((day, index) => (
          <Grid xs={1.7} textAlign="center">
            <Box 
              className={`${"day-item"} ${activeClass.includes(index) ? "active-day" : ''}`} 
              onClick={() => getDate(day[2], index)}
            >
              <Typography component="p">{localStorage.getItem("lang") == "en" ? day[1]?.slice(0, 3) : day[1]}</Typography>
              <Typography component="p">{day[0]}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default withTranslation()(CustomCalendar);