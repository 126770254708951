import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import "./DoctorHome.css";
import NormalTabs from "../../../Atoms/Tabs/NormalTabs";
import TabPanel from "../../../Atoms/Tabs/TabPanel";
import TelemedicineAppointments from "./Appointments/TelemedicineAppointments";
import HospitalsAppointments from "./Appointments/HospitalsAppointments";
import ClinicsAppointments from "./Appointments/ClinicsAppointments";

const DoctorAppointments = (props) => {
    const { t, tabCallback, telemedicineList, hospitalsList, clinicsList, saudiCommissionNumber, updateView } = props;
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const TabsOptions = [
        { label: t("TabsOptions.telemedicine"), disabled: false },
        { label: t("TabsOptions.hospitals"), disabled: false },
        { label: t("TabsOptions.clinics"), disabled: false }
    ];
    const getTabValue = (tabValue) => {
        if (tabValue === 0) tabValue = 'telemedicine';
        else if (tabValue === 1) tabValue = 'hospital_service';
        else if (tabValue === 2) tabValue = 'clinic_service';
        tabCallback(tabValue);
    };
    return (
        <Grid container md={12}>
            <Grid
                container
                alignContent="center"
                justifyContent="center"
                alignItems="center"
            >
                <NormalTabs
                    TabsOptions={TabsOptions}
                    value={value}
                    change={handleChange}
                    orientation="horizontal"
                    ContainerStyle="switch-container mb-20px"
                    TabStyle="switch-btn"
                    onclick={(tabValue) => getTabValue(tabValue)}
                />
            </Grid>
            <Grid xs={12}>
                {TabsOptions.map((option, index) => (
                    <TabPanel value={value} index={index} className="tab-panel" key={index}>
                        {index === 0 && (
                            <TelemedicineAppointments
                                t={t}
                                list={telemedicineList}
                                saudiCommissionNumber={saudiCommissionNumber}
                                updateView={updateView}
                            />
                        )}
                        {index === 1 && (
                            <HospitalsAppointments list={hospitalsList} />
                        )}
                        {index === 2 && (
                            <ClinicsAppointments list={clinicsList} />
                        )}
                    </TabPanel>
                ))}
            </Grid>
        </Grid>
    )
};

export default withTranslation()(DoctorAppointments);
