import { Typography, Dialog, DialogActions } from "@mui/material"

// import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import { icons, images } from '../../../../Assets/images/IconHelper'

const SessionCallModal = ({ t, open, handleClose, acceptCall, callData }) => {
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    padding: '49px 96px'
                }
            }}
            style={{ direction: localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr' }}
        >
            <Typography
                variant='h6'
                align='center'
                style={{ fontSize: 'var(--small-font)', color: "#474747" }}
            >
                {t("call.incomingcall")}
            </Typography>
            <Typography
                variant='h6'
                align='center'
                style={{color:'#2794a4',fontWeight:'bold',fontSize:'var(--normal-font)'}}
            >
                {callData?.name}
            </Typography>
            <img
                src={callData?.avatar ? callData?.avatar : images.callimg}
                alt='call img'
                style={{ marginTop: '30px' ,width:'240px',height:'240px',borderRadius:'50%'}}
            />
            <DialogActions sx={{
                justifyContent: 'space-between',
                marginY: '50px'
            }}>
                <div style={{textAlign:'center'}}>
                    <img src={icons.acceptcall} style={{ cursor: 'pointer' }} onClick={acceptCall}/>
                    <p style={{ fontSize: 'var(--small-font)', color: "#474747" }}>{t("call.accept")}</p>
                </div>
                <div style={{textAlign:'center'}}>
                    <img src={icons.rejectcall} style={{ cursor: 'pointer' }} onClick={handleClose}/>
                    <p style={{ fontSize: 'var(--small-font)', color: "#474747" }}>{t("call.reject")}</p>
                </div>

            </DialogActions>
        </Dialog >
    )
}

export default SessionCallModal