import { Grid, Typography } from "@mui/material"

const PreviousDiagnosisCard = ({ item }) => {
    return (
        <Grid md={12} mt={3} py={2} px={3}
            sx={{ borderRadius: '12px', boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.07)' }} >
            <Grid container>
                <Typography
                    variant="h5"
                    style={{ color: 'var(--secondary-color)', fontWeight: '600' }}
                >
                    {item?.diagnosis_name}
                </Typography>
            </Grid>
            <Grid
                container
                mt={1}
            >
                <Typography
                    variant="body2"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {item?.description}
                </Typography>
            </Grid>
        </Grid >
    )
}

export default PreviousDiagnosisCard