import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import SearchPanel from "../../../../Molecules/Home/UserHome/SearchPanel";
import { images } from "../../../../../Assets/images/IconHelper";
import SearchOptions from "../../../../Molecules/Home/UserHome/SearchOptions";
import { useSearchParams } from "react-router-dom";

const GroupTherapySearch = (props) => {
    const { t, specialities, getSubSpecialities, subSpecialities } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    
    return (
        <Grid container className="telemedicine-settings-container">
            <Typography component="h1">{t("UserProfile.telemedicine")} - {t("TelemedicineSettings.group_therapy")}</Typography>
            <Grid container md={12}>
                <Grid xs={12} className="mb-20px">
                    <SearchPanel 
                        title={t("TelemedicineSettings.search_by_doctor")}
                        icon={images.SearchImg}
                        btnTxt={t("TelemedicineSettings.search")}
                        searchParams={searchParams}
                        searchBy="name"
                    />
                </Grid>
                <Grid xs={12} className="mb-20px">
                    <SearchOptions 
                        specialities={specialities}
                        searchParams={searchParams}
                        getSubSpecialities={(id) => getSubSpecialities(id)}
                        subSpecialities={subSpecialities}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withTranslation()(GroupTherapySearch);