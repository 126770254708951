import { Grid, Typography } from "@mui/material"
import { ErrorMessage } from "formik"

import SelectBox from "../../../../Components/Atoms/Select/Select"
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

function MedicineForm({ sameDoctor, t, medicinesList, dosage, medicineDosagePeriods, medicineIntakeMethods, medicineDurations, status, i, values, handleChange, arrayHelpers, setAddMedicine, oldMedicines, setDeletedIds, deletedIds }) {

    const isApproved = oldMedicines?.[i]?.status === 1
    const disabled = !sameDoctor && isApproved

    return (
        <Grid container mb={6}>
            <Grid item xs={12} style={{ marginBottom: 30 }}>
                <SelectBox
                    disabled={disabled}
                    label={t("AddPrescriptionModal.medicine_name")}
                    labelStyle='field-label'
                    items={medicinesList}
                    name={`medicines.${i}.medicine_name`}
                    value={values?.medicines?.[i]?.medicine_name}
                    onchange={handleChange}
                />
                <ErrorMessage
                    name={`medicines.${i}.medicine_name`}
                    component="div"
                    style={{ color: 'red' }}
                />
            </Grid>
            <Grid item xs={1} sx={{
                justifyContent: 'flex-start',
                paddingTop: '15px'
            }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t("AddPrescriptionModal.dosage")}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <SelectBox
                    disabled={disabled}
                    items={dosage}
                    name={`medicines.${i}.daily_dosage`}
                    value={values?.medicines?.[i]?.daily_dosage}
                    onchange={handleChange}
                />
                <ErrorMessage
                    name={`medicines.${i}.daily_dosage`}
                    component="div"
                    style={{ color: 'red', paddingBottom: '10px' }}
                />
            </Grid>
            <Grid item xs={6} style={{ paddingInlineStart: '10px' }}>
                <SelectBox
                    disabled={disabled}
                    items={medicineDosagePeriods}
                    name={`medicines.${i}.dosage_period`}
                    value={values?.medicines?.[i]?.dosage_period}
                    onchange={handleChange}
                />
                <ErrorMessage
                    name={`medicines.${i}.dosage_period`}
                    component="div"
                    style={{ color: 'red', paddingBottom: '10px' }}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectBox
                    disabled={disabled}
                    label={t("AddPrescriptionModal.usage")}
                    labelStyle='field-label'
                    items={medicineIntakeMethods}
                    name={`medicines.${i}.def_medicine_intake_method_id`}
                    value={values?.medicines?.[i]?.def_medicine_intake_method_id}
                    onchange={handleChange}
                />
                <ErrorMessage
                    name={`medicines.${i}.def_medicine_intake_method_id`}
                    component="div"
                    style={{ color: 'red', paddingBottom: '10px' }}
                />
            </Grid>
            <Grid item xs={6} style={{ paddingInlineStart: '10px' }}>
                <SelectBox
                    disabled={disabled}
                    label={t("DoctorTelemedicine.duration")}
                    labelStyle='field-label'
                    items={medicineDurations}
                    name={`medicines.${i}.def_medicine_duration_id`}
                    value={values?.medicines?.[i]?.def_medicine_duration_id}
                    onchange={handleChange}
                />
                <ErrorMessage
                    name={`medicines.${i}.def_medicine_duration_id`}
                    component="div"
                    style={{ color: 'red', paddingBottom: '10px' }}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectBox
                    disabled={disabled}
                    label={t("DoctorTelemedicine.status")}
                    labelStyle='field-label'
                    items={status}
                    name={`medicines.${i}.status`}
                    value={values?.medicines?.[i]?.status}
                    onchange={handleChange}
                />
                <ErrorMessage
                    name={`medicines.${i}.status`}
                    component="div"
                    style={{ color: 'red', paddingBottom: '10px' }}
                />
            </Grid>
            <Grid container item xs={6} justifyContent='end'>
                <PrimaryButton
                    disabled={disabled}
                    text={t("DoctorTelemedicine.remove")}
                    classBtn='delete-account-btn btn-rounded'
                    click={() => {
                        arrayHelpers.remove(i)
                        if (values?.medicines?.[i]?.id) setDeletedIds([...deletedIds, values?.medicines?.[i]?.id])
                        if (values.medicines.length - 1 < 1) setAddMedicine(false)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default MedicineForm