import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

function SearchGroup(props) {
    const { t, getSearchKeywordsCallback } = props;
    const [searchKeywords, setSearchKeywords] = useState("");
    const getSearchKeywords = () => {
        getSearchKeywordsCallback(searchKeywords.trim());
    };
    return (
        <Grid container>
            <Grid md={12} container className="search-group-container">
                <Grid xs={10}>
                    <TextFieldInput 
                        type="search"
                        placeholder={t("TelemedicineSettings.search_hint")}
                        StartAdornment={<SearchIcon />}
                        className="search-bg"
                        clickEnter={() => getSearchKeywords()}
                        onchange={(e) => setSearchKeywords(e.target.value)}
                        value={searchKeywords}
                    />
                </Grid>
                <Grid xs={2} style={{textAlign: "end"}}>
                    <PrimaryButton 
                        classBtn="search-btn" 
                        text={t("TelemedicineSettings.search")}
                        click={() => getSearchKeywords()}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
  }
  
export default withTranslation()(SearchGroup);