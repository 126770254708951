import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { images } from "../../../../Assets/images/IconHelper";
// import StarRatings from "react-star-ratings";
import RatingAtom from "../../../Atoms/Rating/Rating";

function DoctorInfo(props) {
    const { t, medicalProvider } = props;
    const lang = localStorage.getItem("lang");
    return (
        <Grid container  className="chat-history-doc-container">
            <Grid md={12} className="profile-wallet-title grey-bg mb-30px">
                <h4>{t("UserAppointments.doc_info")}</h4>
            </Grid>
            <Grid container>
                <Grid md={12} style={{textAlign: "center"}} className="mb-10px">
                    <img className="chat-history-doc-info-img" src={medicalProvider?.avatar === null ? images.userAvatar : medicalProvider?.avatar}/>
                </Grid>
                <Grid md={12} style={{textAlign: "center"}}>
                    <Typography component="h1">
                        {medicalProvider?.name}
                    </Typography>
                    <Typography component="p">
                        {`${medicalProvider?.medical_title} - ${medicalProvider?.speciality}`} 
                    </Typography>
                    <Typography component="p">
                        {medicalProvider?.subspeciality}  
                    </Typography>
                    <Typography style={{margin: "20px 0"}} component="p">
                        {lang === 'ar' && medicalProvider?.gender === 'male' ? 'ذكر' : lang === 'ar' && medicalProvider?.gender === 'female' ? 'أنثي' : medicalProvider?.gender}  
                    </Typography>
                    <Grid xs={12} className="rate-container">
                        {/* <StarRatings
                            rating={medicalProvider?.avg_rating == null ? 0 : medicalProvider?.avg_rating}
                            starRatedColor="#fcb862"
                            starSpacing="1px"
                        /> */}
                        <RatingAtom
                            initialValue={medicalProvider?.avg_rating == null ? 0 : medicalProvider?.avg_rating}
                            readonly={true}
                            size={17}
                            fillColor='#fcb862'
                        />
                    </Grid>
                    <Typography style={{margin: "10px 0"}} component="p">
                        {`(${medicalProvider?.ratings_count})`} 
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
  }
  
export default withTranslation()(DoctorInfo);