import { Rating } from 'react-simple-star-rating'

const RatingAtom = ({
    readonly,
    size,
    initialValue,
    fillColor,
    fillIcon,
    emptyIcon,
    onClick,
    className
}) => {
    const lang = localStorage.getItem("lang")
    return (
        <Rating
            readonly={readonly}
            rtl={lang === 'ar' ? true : false}
            allowFraction
            size={size}
            initialValue={initialValue}
            fillColor={fillColor}
            fillIcon={fillIcon}
            emptyIcon={emptyIcon}
            onClick={onClick}
            className={className}
        />
    );
}

export default RatingAtom;