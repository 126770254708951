import { Grid, Typography } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear'

import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton"
import { images } from "../../../../../../Assets/images/IconHelper"
import PrescriptionDetailsModal from "../../../../../Organisms/Modals/Prescription/PrescriptionDetailsModal"
import { useState } from "react"
import CustomPopup from "../../../../../Organisms/Modals/CustomPopup/CustomPopup"

const HistoryDiagnosisList = ({ t, item, removeDiagnosis, openEditView, openPrescriptionDetailsModal, itemPrescription, openPrescriptionDtails, handleClosePrescriptionDetails, medicineIntakeMethods }) => {
    
    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)
    return (
        <Grid md={12} className="therapy-item mb-10px">
            <Grid container alignItems='center'>
                <Grid item md={9}>
                    <Typography component="h1" style={{ cursor: 'pointer' }} onClick={() => {
                        openPrescriptionDetailsModal(item?.id)
                    }}>
                        {item?.diagnosis_name}
                    </Typography>
                </Grid>
                <Grid item md={2} style={{ textAlign: "end" }}>
                    <PrimaryButton
                        btnType="icon"
                        startIcon={<img src={images.PenReverse} alt='Edit Icon'/>}
                        click={() => openEditView('edit', item?.id)}
                    />
                </Grid>
                <Grid item md={1} style={{ textAlign: "end" }}>
                    <PrimaryButton
                        startIcon={<ClearIcon />}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={() => setOpen(true)}
                    />
                </Grid>
            </Grid>
            <Grid container className="mb-5px">
                <Typography component="p" style={{ color: "#848484", fontWeight: "lighter" }}>
                    {item?.description}
                </Typography>
            </Grid>
            <PrescriptionDetailsModal
                t={t}
                itemPrescription={itemPrescription}
                open={openPrescriptionDtails}
                handleClose={handleClosePrescriptionDetails}
                medicineIntakeMethods={medicineIntakeMethods}
            />
            <CustomPopup
                open={open}
                handleClose={handleClose}
                isAction={true}
                src={images.ConfirmDelete}
                text={t('Checkups.confirm_delete')}
                btnActionText={t('Checkups.yes_delete')}
                btnCloseText={t('Checkups.no')}
                handleAction={() => { removeDiagnosis(item.id); 
                    handleClose();
                 }}
                btnActionStyle='btn-yes btn-rounded'
                btnCloseStyle='btn-no btn-rounded'
            />
        </Grid>
    )
}

export default HistoryDiagnosisList