import { Grid, Box, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'

import { icons, images } from "../../../../../../Assets/images/IconHelper"
import NormalTabs from "../../../../../Atoms/Tabs/NormalTabs"
import TabPanel from "../../../../../Atoms/Tabs/TabPanel"
import AddEditExamination from "../../../../../Organisms/Modals/Checkups/AddEditExamination"
import CustomPopup from '../../../../../Organisms/Modals/CustomPopup/CustomPopup'
import ViewCheckup from '../../../../../Organisms/Modals/Checkups/ViewCheckup'
import Analysis from './Analysis'
import Xray from './Xray'
import CustomTablePagination from "../../../../../Atoms/Pagination/Pagination";

const CheckupTab = (props) => {
    const { t } = useTranslation()
    const { analysisListPaginationInfo, xrayPaginationInfo, getAnalysisList, getXrayList } = props;
    const [checkup, setCheckup] = useState(0)
    const [examinationType, setExaminationType] = useState('')
    const [examinationItem, setExaminationItem] = useState({})
    const [operationType, setOperationType] = useState('')
    const [openAddEditExaminationModal, setOpenAddEditExaminationModal] = useState(false)
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
    const [openViewImageModal, setOpenViewImageModal] = useState(false)
    const [idsDelete, setIdsDelete] = useState([])
    const [image, setImage] = useState([])

    const handleOpenAddEditExaminationModal = (type, operation, item) => {
        setExaminationType(type)
        setOperationType(operation)
        setExaminationItem(item)
        setOpenAddEditExaminationModal(true)
    }
    const handleCloseAddEditExaminationModal = () => {
        setOpenAddEditExaminationModal(false)
    }

    const handleOpenConfirmDeleteModal = (ids, type) => {
        setIdsDelete(ids)
        setExaminationType(type)
        setOpenConfirmDeleteModal(true)
    }

    const handleCloseConfirmDeleteModal = () => {
        setOpenConfirmDeleteModal(false)
    }

    const handleCheckupChange = (event, newValue) => {
        setCheckup(newValue)
    }

    const openViewImage = (image) => {
        setImage(image)
        setOpenViewImageModal(true)
    }

    const handleCloseViewImage = () => setOpenViewImageModal(false)

    const remove = async () => {
        await props.removeExaminations({ attachment_ids: idsDelete })
        examinationType === 'analysis' ? props.getAnalysisData() : props.getXrayData()
        handleCloseConfirmDeleteModal()
    }

    const CheckupTabOptions = [
        {
            id: 1,
            label: t("Checkups.analysis")
        },
        {
            id: 2,
            label: t("Checkups.xray")
        }
    ];

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const [historyFilterObj, setHistoryFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const paginationChangePage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_number': value + 1 });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value, type) => {
        if(type === "app") setFilterObj({ ...filterObj, 'page_size': value });
        if(type === "history") setHistoryFilterObj({ ...historyFilterObj, 'page_size': value });
    };
    useEffect(() => {
        getAnalysisList(filterObj)
    }, [filterObj])

    useEffect(() => {
        getXrayList(historyFilterObj)
    }, [historyFilterObj])

    return (
        <Grid>
            <Typography mt={4} variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                {t("UserProfile.checkups")}
            </Typography>
            <Grid container
                mt={5}
                py={2}
                px={3}
                sx={{ backgroundColor: "#e2f8fb", borderRadius: '14px' }}
            >
                <Grid item mx={2}>
                    <img src={icons.MedFile} alt='Medical File Number' />
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1'>
                        {t("UserProfile.your_medFile_number")}
                    </Typography>
                    <Typography variant='subtitle1' sx={{
                        fontWeight: 'bold',
                        color: 'var(--secondary-color)',
                        lineHeight: '1',
                        letterSpacing: '1.5px'
                    }}>
                        {props?.medicalFileNumber}
                    </Typography>
                </Grid>
            </Grid>
            <Box my={4} >
                <NormalTabs
                    TabsOptions={CheckupTabOptions}
                    value={checkup}
                    change={handleCheckupChange}
                    ContainerStyle="switch-container"
                    TabStyle="switch-btn"
                />
            </Box>
            {CheckupTabOptions.map((option, index) => (
                <TabPanel
                    value={checkup}
                    index={index}
                    key={index}>
                    {index === 0 ?
                        <>
                            <Analysis
                                analysisList={props.analysisList}
                                handleOpenAddEditExaminationModal={handleOpenAddEditExaminationModal}
                                removeExaminations={props.removeExaminations}
                                getAnalysisData={props.getAnalysisData}
                                handleOpenConfirmDeleteModal={handleOpenConfirmDeleteModal}
                                openView={openViewImage}
                            />
                            {analysisListPaginationInfo.total_count > 10 && (
                                <CustomTablePagination
                                    totalCount={analysisListPaginationInfo.total_count}
                                    changePage={(val) => paginationChangePage(val, "app")}
                                    handleRowsPerPage={(val) => handleRowsPerPage(val, "app")}
                                    t={t} 
                                />
                            )}
                        </>
                        :
                        <>
                            <Xray
                                xrayList={props.xrayList}
                                handleOpenAddEditExaminationModal={handleOpenAddEditExaminationModal}
                                removeExaminations={props.removeExaminations}
                                getXrayData={props.getXrayData}
                                handleOpenConfirmDeleteModal={handleOpenConfirmDeleteModal}
                                openView={openViewImage}
                            />
                            {xrayPaginationInfo.total_count > 10 && (
                                <CustomTablePagination
                                    totalCount={xrayPaginationInfo.total_count}
                                    changePage={(val) => paginationChangePage(val, "history")}
                                    handleRowsPerPage={(val) => handleRowsPerPage(val, "history")}
                                    t={t} 
                                />
                            )}
                        </>
                    }
                </TabPanel>
            ))}
            <AddEditExamination
                t={t}
                open={openAddEditExaminationModal}
                handleClose={handleCloseAddEditExaminationModal}
                examinationType={examinationType}
                operationType={operationType}
                addExaminations={props.addExaminations}
                item={examinationItem}
                removeExaminations={props.removeExaminations}
                getXrayData={props.getXrayData}
                getAnalysisData={props.getAnalysisData}
                editExaminationDate={props.editExaminationDate}
            />
            <CustomPopup
                open={openConfirmDeleteModal}
                handleClose={handleCloseConfirmDeleteModal}
                isAction={true}
                src={images.ConfirmDelete}
                text={t('Checkups.confirm_delete')}
                btnActionText={t('Checkups.yes_delete')}
                btnCloseText={t('Checkups.no')}
                handleAction={() => remove()}
                btnActionStyle='btn-yes btn-rounded'
                btnCloseStyle='btn-no btn-rounded'
            />
            <ViewCheckup
                t={t}
                open={openViewImageModal}
                handleClose={handleCloseViewImage}
                image={image}
            />
        </Grid>
    )
}

export default CheckupTab
