import { Grid, Typography } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import { images } from "../../../../Assets/images/IconHelper"
import CustomPopup from "../../../Organisms/Modals/CustomPopup/CustomPopup"
import { useState } from "react"

const SurgeryCard = ({ t, item, removeSurgery, openSurgeryModal }) => {
    const [open, setOpen] = useState(false);
    return (
        <Grid md={12} mt={3}>
            <Grid container>
                <Grid md={10}>
                    <Typography
                        variant="h6"
                        style={{ color: 'var(--secondary-color)' }}
                    >
                        {item?.surgery_name}
                    </Typography>
                    <Typography variant="body2">
                        {item?.date}
                    </Typography>
                </Grid>
                <Grid
                    md={1}
                    style={{ textAlign: "end" }}>
                    <PrimaryButton
                        btnType="icon"
                        startIcon={<img src={images.PenReverse} alt='Edit Icon' />}
                        click={() => openSurgeryModal('edit', item)}
                    />
                </Grid>
                <Grid
                    md={1}
                    style={{ textAlign: "end" }}>
                    <PrimaryButton
                        startIcon={<ClearIcon />}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={() => setOpen(true)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                mt={1}
                mb={3}
            >
                <Typography
                    variant="body2"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {item?.description}
                </Typography>
            </Grid>
            <CustomPopup
                open={open}
                handleClose={() => setOpen(false)}
                isAction={true}
                src={images.ConfirmDelete}
                text={t('Checkups.confirm_delete')}
                btnActionText={t('Checkups.yes_delete')}
                btnCloseText={t('Checkups.no')}
                handleAction={() => removeSurgery(item.id)}
                btnActionStyle='btn-yes btn-rounded'
                btnCloseStyle='btn-no btn-rounded'
            />
        </Grid>
    )
}

export default SurgeryCard