import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import UserHomeMainPanel from "../../../Components/Organisms/Home/UserHome/UserHomeMainPanel";
import AppointmentPanel from "../../../Components/Organisms/ProfilePanels/AppointmentPanel";
import { Route, Routes as Switch } from "react-router-dom";
import TelemedicineSettings from "../../../Components/Organisms/Home/UserHome/TelemedicineFlow/TelemedicineSettings";
import PrivateDoctorSearch from "../../../Components/Organisms/Home/UserHome/TelemedicineFlow/PrivateDoctorSearch";
import SearchResultPage from "../../../Components/Organisms/Home/UserHome/TelemedicineFlow/SearchResultPage";
import GroupTherapySearch from "../../../Components/Organisms/Home/UserHome/TelemedicineFlow/GroupTherapySearch";
import MedicalEntitiesSettings from "../../../Components/Organisms/Home/UserHome/Hospitals&ClinicsFlow/MedicalEntitiesSettings";
import MedicalEntitiesSearch from "../../../Components/Organisms/Home/UserHome/Hospitals&ClinicsFlow/MedicalEntitiesSearch";
import MedicalEntitiesList from "../../../Components/Organisms/Home/UserHome/Hospitals&ClinicsFlow/MedicalEntitiesList";
import { useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader";

const UserHomeTemplate = (props) => {
  const {
    t,
    getListServivesForPatient,
    ListServivesForPatient,
    profileObj, specialities,
    medicalTitles,
    updateProfileCallback,
    getSubSpecialities,
    subSpecialities,
    getListMedicalProviders,
    medicalProvidersList,
    getDoctorInMedicalEntity,
    getProfileData,
    getUserAppointments,
    appointmentsList,
    paginationInfo,
    updateShowPrivacyPopup,
    offersList,
    adsList
  } = props;
  const [openLoader, setLoader] = useState(false);
  return (
    <Grid container spacing={0}>
      <Loader open={openLoader} />
      <Grid item xs className="grey-bg"></Grid>
      <Grid
        container
        xl={7}
        lg={9.5}
        md={11}
        sm={12}
        xs={12}
        rowSpacing={3}
        columnSpacing={{ xs: 3 }}
        className="grey-bg tp-bt-padding main-container"
      >
        <Grid item xs={9}>
          <Switch>
            <Route
              path="main"
              element={
                <UserHomeMainPanel
                  profileObj={profileObj}
                  updateShowPrivacyPopup={updateShowPrivacyPopup}
                  offersList={offersList}
                  adsList={adsList}
                />
              }
            />

            <Route
              path="main/telemedicine"
              element={
                <TelemedicineSettings
                  updateProfileCallback={updateProfileCallback}
                  profileObj={profileObj}
                  getProfileData={getProfileData}
                />
              }
            />
            <Route
              path="main/:param"
              element={<MedicalEntitiesSettings />}
            />
            <Route
              path="main/telemedicine/private-doctor-search"
              element={
                <PrivateDoctorSearch
                  subSpecialities={subSpecialities}
                  getSubSpecialities={(id) => getSubSpecialities(id)}
                  specialities={specialities}
                />
              }
            />
            <Route
              path="main/telemedicine/group-therapy-search"
              element={
                <GroupTherapySearch
                  subSpecialities={subSpecialities}
                  getSubSpecialities={(id) => getSubSpecialities(id)}
                  specialities={specialities}
                />
              }
            />
            <Route
              path="main/:param/medical-provider-search"
              element={
                <MedicalEntitiesSearch
                  subSpecialities={subSpecialities}
                  getSubSpecialities={(id) => getSubSpecialities(id)}
                  specialities={specialities}
                />
              }
            />
            <Route
              path="main/:param/medical-provider-search/list"
              element={
                <MedicalEntitiesList
                  medicalProvidersList={medicalProvidersList}
                  getListMedicalProviders={(serviceType, filterObj) => getListMedicalProviders(serviceType, filterObj)}
                  paginationInfo={paginationInfo}
                />
              }
            />
            <Route
              path="main/:param/:param/result-page/:param"
              element={
                <SearchResultPage
                  getListServivesForPatient={
                    (serviceType, filterObj) => getListServivesForPatient(serviceType, filterObj)
                  }
                  ListServivesForPatient={ListServivesForPatient}
                  medicalTitles={medicalTitles}
                  specialities={specialities}
                  getDoctorInMedicalEntity={
                    (serviceType, id, filterObj) => getDoctorInMedicalEntity(serviceType, id, filterObj)
                  }
                  paginationInfo={paginationInfo}
                />
              }
            />
          </Switch>
        </Grid>
        <Grid item xs={3}>
          <AppointmentPanel
            getUserAppointments={getUserAppointments}
            appointmentsList={appointmentsList}
            setLoader={setLoader}
          />
        </Grid>
      </Grid>
      <Grid item xs className="grey-bg"></Grid>
    </Grid>
  );
};

export default withTranslation()(UserHomeTemplate);
