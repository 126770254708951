import { Grid, Typography } from "@mui/material";
import { images } from "../../Assets/images/IconHelper";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CustomPopup from "../../Components/Organisms/Modals/CustomPopup/CustomPopup";

const ConfirmationTemplate = ({t}) => {
  const [open, setOpen] = useState(false);
  const navigateToDoctorHome = () => {
    window.location.href = "/"
  }
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
  }, []);
  return (
    <Grid container spacing={0}>
      <Grid item xs>
        <Grid
          container
          style={{ marginTop: "30px" }}
          justifyContent={"flex-end"}
        >
        </Grid>
      </Grid>
      <Grid item md={6} style={{textAlign: "center"}} className="Auth_container">
        <h1 style={{ color: "#161616", fontSize: "26px" }}>{t("CustomPopup.data_confirmation")}</h1>
        <div>
          <img src={images.Confirmation} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className="mb-30px"
        >
          <Typography>{t("CustomPopup.will_review")}</Typography>
        </div>
        <PrimaryButton
          text={t("CustomPopup.done")}
          classBtn="primary btn-rounded btn-medium"
          click={() => navigateToDoctorHome()}
        />
      </Grid>
      <Grid item xs></Grid>
      <CustomPopup
        open={open}
        handleClose={() => handleClose()}
        src={images.verifayNumber}
        t={t}
        title={t("CustomPopup.done_successfully")}
        text={t("CustomPopup.verify_phone")}
        btnText={t("CustomPopup.ok")}
      />
    </Grid>
  );
};

export default withTranslation()(ConfirmationTemplate);
