import { useState } from "react"
import { withTranslation } from "react-i18next"
import { Grid, Typography } from "@mui/material"

import "./ProfilePanels.css"
import ChangeMobileTab from "../../Molecules/Settings/ChangeMobileTab.jsx"
import ChangePasswordTab from "../../Molecules/Settings/ChangePasswordTab.jsx"
import DeactivateAccount from "../../Molecules/Settings/DeactivateAccount"
import SwitchAccount from "../../Molecules/Settings/SwitchAccount"
import TabPanel from "../../Atoms/Tabs/TabPanel"
import NormalTabs from "../../Atoms/Tabs/NormalTabs"
import { icons, images } from "../../../Assets/images/IconHelper"
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton"
import CustomPopup from '../Modals/CustomPopup/CustomPopup'

const UserSettingsPanel = ({ t, remove, open, handleClose, handleOpen, changePassword, updatePhone, sendOTP, verifyOTP, verifyPhone, deactivateAccount, accounts }) => {

    const [value, setValue] = useState(0)
    const userType = localStorage.getItem("userType")

    const handleChange = (event, newValue) => setValue(newValue)

    const TabsOptions = userType === 'medical_provider' ? [
        {
            id: 1,
            label: t("Settings.change_mobile"),
            icon: <img src={images.Mobile} alt="change mobile" />,
        },
        {
            id: 2,
            label: t("Settings.change_password"),
            icon: <img src={images.Password} alt="change password" />,
        },
        {
            id: 3,
            label: t("Settings.deactivate_account"),
            icon: <img src={images.Deactivate} alt="deactivate account" />,
        }
    ] : [
        {
            id: 1,
            label: t("Settings.change_mobile"),
            icon: <img src={images.Mobile} alt="change mobile" />,
        },
        {
            id: 2,
            label: t("Settings.change_password"),
            icon: <img src={images.Password} alt="change password" />,
        }
    ];

    const MultiOptions = userType === 'medical_provider' ? [
        {
            id: 1,
            label: t("Settings.change_mobile"),
            icon: <img src={images.Mobile} alt="change mobile" />,
        },
        {
            id: 2,
            label: t("Settings.change_password"),
            icon: <img src={images.Password} alt="change password" />,
        },
        {
            id: 3,
            label: t("Settings.deactivate_account"),
            icon: <img src={images.Deactivate} alt="deactivate account" />,
        },
        {
            id: 4,
            label: t("Settings.switch"),
            icon: <img src={icons.SwitchAccounts} alt="switch account" />,
        }
    ] : [
        {
            id: 1,
            label: t("Settings.change_mobile"),
            icon: <img src={images.Mobile} alt="change mobile" />,
        },
        {
            id: 2,
            label: t("Settings.change_password"),
            icon: <img src={images.Password} alt="change password" />,
        },
        {
            id: 3,
            label: t("Settings.switch"),
            icon: <img src={icons.SwitchAccounts} alt="switch account" />,
        }
    ];

    return (
        <Grid
            container
            item
            xl={7}
            lg={9.5}
            md={11}
            sm={12}
            xs={12}
            className="tp-bt-padding"
        >
            <Grid item xs={3} justifyContent='center'>
                <NormalTabs
                    TabsOptions={accounts ? MultiOptions : TabsOptions}
                    value={value}
                    change={handleChange}
                    orientation="vertical"
                    TabStyle="toggle-btn settings-toggle-btn mb-20px"
                    style={{ marginRight: '5px', marginLeft: '5px' }}
                />
                <PrimaryButton
                    text={t("Settings.delete_account")}
                    classBtn='delete-account-btn btn-rounded'
                    style={{ marginTop: '40px' }}
                    click={handleOpen}
                />
            </Grid>
            <Grid item xs={9}>
                <Grid container style={{ padding: "0 24px" }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                        {t("TabsOptions.settings")}
                    </Typography>
                </Grid>
                <Grid container>
                    {!accounts && TabsOptions.map((option, index) => (
                        <TabPanel value={value} index={index} key={index}>
                            {index === 0 && (
                                <ChangeMobileTab
                                    t={t}
                                    updatePhone={updatePhone}
                                    sendOTP={sendOTP}
                                    verifyOTP={verifyOTP}
                                    verifyPhone={verifyPhone}
                                />
                            )}
                            {index === 1 && (
                                <ChangePasswordTab
                                    t={t}
                                    changePassword={changePassword}
                                />
                            )}
                            {index === 2 && (
                                <DeactivateAccount
                                    t={t}
                                    deactivateAccount={deactivateAccount}
                                />
                            )}
                        </TabPanel>
                    ))}
                    {accounts && MultiOptions.map((option, index) => (
                        <TabPanel value={value} index={index} key={index}>
                            {index === 0 && (
                                <ChangeMobileTab
                                    t={t}
                                    updatePhone={updatePhone}
                                    sendOTP={sendOTP}
                                    verifyOTP={verifyOTP}
                                    verifyPhone={verifyPhone}
                                />
                            )}
                            {index === 1 && (
                                <ChangePasswordTab
                                    t={t}
                                    changePassword={changePassword}
                                />
                            )}
                            {index === 2 && userType === 'medical_provider' && (
                                <DeactivateAccount
                                    t={t}
                                    deactivateAccount={deactivateAccount}
                                />
                            )}
                            {index === 2 && userType === 'patient' && (
                                <SwitchAccount
                                    t={t}
                                    accounts={accounts}
                                />
                            )}
                            {index === 3 && (
                                <SwitchAccount
                                    t={t}
                                    accounts={accounts}
                                />
                            )}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            <CustomPopup
                open={open}
                handleClose={handleClose}
                isAction={true}
                src={images.DeleteAccount}
                text={t("Settings.confirm_delete_account")}
                handleAction={remove}
                btnActionText={t("Checkups.yes_delete")}
                btnCloseText={t("Checkups.no")}
                btnActionStyle='primary btn-rounded btn-medium'
                btnCloseStyle='success btn-rounded btn-medium border'
            />
        </Grid>
    )
}

export default withTranslation()(UserSettingsPanel)
