import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./TextFieldInput.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
function TextFieldInput({
  type = "text",
  placeholder = "",
  StartAdornment = null,
  EndAdornment = null,
  multiline = false,
  rows,
  className,
  onchange,
  error,
  value,
  labelStyle,
  label,
  clickEnter,
  InputProps,
  disabled,
  ...props
}) {
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Grid container style={{ position: "relative" }} >
      {label && (
        <span className={labelStyle}>{label}</span>
      )}
      <TextField
        disabled={disabled}
        fullWidth
        multiline={multiline}
        rows={rows}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        className={"textfield " + className}
        error={error ? true : false}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused': {
            bgcolor: 'white',
            border: 'none',
            outline: 'none',
            '& > fieldset': {
              outline: 'none'
            },
            '& > input': {
              outline: 'none !important'
            }
          }
        }}
        value={value}
        onChange={onchange}
        onKeyDown={(e) => {
          if (e.key === "Enter") clickEnter();
        }}
        InputProps={InputProps ? InputProps : {
          startAdornment: (
            <InputAdornment position="start">
              <>{StartAdornment}</>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <>
                {type === "password" ? (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility className="password-icon" /> : <VisibilityOff className="password-icon" />}
                  </IconButton>
                ) : (
                  EndAdornment
                )}
              </>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Grid>
  );
}

TextFieldInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  StartAdornment: PropTypes.element,
  EndAdornment: PropTypes.element,
  className: PropTypes.string,
};
export default TextFieldInput;
