import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons, images } from "../../../../Assets/images/IconHelper";
import { Grid, Typography } from '@mui/material';
import ReactTimeAgo from 'react-time-ago'
import { useNavigate } from "react-router-dom";
import Placeholder from "../../../Atoms/Placeholder/Placeholder";
import Loader from "../../../Atoms/Loader/Loader";
import { ConvertToArabicNumbers } from '../../../../Contexts/API/Helper/Helper';

const lang = localStorage.getItem("lang");
const ITEM_HEIGHT = 60;

export default function NotificationsList(props) {
  const Navigate = useNavigate();
  const { t, notificationsList, refetchNotificationsList, notificationsCount, openLoader, updateCallStatus } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    refetchNotificationsList()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const notificationAction = (needAction, type) => {
    if (needAction) {
      if (type === 'patient_early_reminder') {
        Navigate('/user/appointments');
      } else if (type === 'provider_early_reminder') {
        Navigate('/doctor/home');
      } else if (type === 'new_offer') {
        Navigate('/offers');
      } else if (type === 'new_telemedicine_reservation') {
        Navigate('doctor/home');
      } else if (type === 'start_early') {
        updateCallStatus(true);
      }
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <div style={{ lineHeight: "4.5" }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <PrimaryButton
          btnType="icon"
          startIcon={<img src={icons.NotificationIcon} alt="Notification Icon" />}
        // click={refetchNotificationsList}
        />
        {notificationsCount !== 0 && (
          <Typography className="unseen-count" component="span">
            {notificationsCount}
          </Typography>
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 9.5,
            width: '40ch',
            direction: lang === 'ar' ? 'rtl' : 'ltr',
            borderRadius: '15px',
            minHeight: '500px',
            position: 'relative'
          },
        }}
      >
        <Loader open={openLoader} />
        {notificationsList.length === 0 && !openLoader ? (
          <Placeholder
            src={images.EmptyPlaceholder}
            alt="Empty Notifications"
            mainTitle={t("Placeholders.no_notifications")}
            txtContainer="text-container"
          />
        ) : (
          <>
            {notificationsList.map((notification, index) => (
              <MenuItem className="notification-item" selected={!notification.is_seen} key={notification.id} onClick={() => notificationAction(notification.need_action, notification.notification_type)}>
                <Grid container md={12}>
                  <Grid sm={2}>
                    {!notification?.notifier ? (
                      <img src={icons.NewNotification} />
                    ) : (
                      <img className="avatar" src={notification?.notifier?.avatar === null || notification?.notifier?.avatar === '' ? images.userAvatar : notification?.notifier?.avatar} />
                    )}
                  </Grid>
                  <Grid sm={7}>
                    <Typography component="p" style={{ fontWeight: 'bold', color: '#000' }}>
                      {notification.title}
                    </Typography>
                    <Typography component="p">
                      {lang === 'en' ? notification.body : ConvertToArabicNumbers(notification.body)}
                    </Typography>
                  </Grid>
                  <Grid sm={3} style={{ textAlign: 'end' }}>
                    <Typography component="span">
                      <ReactTimeAgo date={notification.created_at} locale={lang === 'ar' ? 'ar-AR' : 'en-US'} />
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </div>
  );
}