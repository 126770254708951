import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import "./Layout.css";
import { images } from '../../../Assets/images/IconHelper'
import moment from "moment";

function Footer(props) {
    const { t } = props;
    const lang = localStorage.getItem("lang")

    return (
        <Grid container className="Footer">
            <Grid container item md={4}
                justifyContent='center'
                alignItems='center'
            >
                {lang === 'ar' ? <>
                    <a href='https://inovaeg.com/' target='_blank'>
                        <img src={images.Logo1} alt='' style={{ height: '16px' }} />
                        <img src={images.Logo2} alt='' style={{ height: '16px' }} />
                        <img src={images.Logo3} alt='' style={{ height: '16px' }} />
                    </a>
                    <Typography sx={{ fontSize: '20px', fontWeight: '300', marginInlineStart: '12px' }}>
                        Developed by Inova
                    </Typography>
                </> :
                    <>
                        <Typography sx={{ fontSize: '20px', fontWeight: '300', marginInlineEnd: '12px' }}>
                            Developed by Inova
                        </Typography>
                        <a href='https://inovaeg.com/' target='_blank'>
                            <img src={images.Logo3} alt='' style={{ height: '16px' }} />
                            <img src={images.Logo2} alt='' style={{ height: '16px' }} />
                            <img src={images.Logo1} alt='' style={{ height: '16px' }} />
                        </a>
                    </>
                }
            </Grid>
            <Grid item md={3}></Grid>
            <Grid
                item
                md={5}
            >
                {t("CopyRight.copy_right")}{moment().year()}
            </Grid>
        </Grid>
    );
}

export default withTranslation()(Footer);