import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { images } from "../../../../Assets/images/IconHelper";
import SwitchLabel from "../../../Atoms/Buttons/Switch/SwitchButton";
import { useNavigate } from "react-router-dom";

function TelemedicinePrivacy(props) {
    const { t, updateShowPrivacyPopup } = props;
    const dir = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
    const [open, setOpen] = useState(false);
    const [switchValue, setSwitchValue] = useState(false);
    const navigate = useNavigate();
    const telemedicinePrivacyContent = [
        {
            icon: images.NotDiagnosis,
            title: t("TelemedicinePrivacyPopup.not_diagnosis"),
            text: t("TelemedicinePrivacyPopup.not_diagnosis_text"),
        },
        {
            icon: images.NotForEmergency,
            title: t("TelemedicinePrivacyPopup.not_emergency"),
            text: "",
        },
        {
            icon: images.InfoShield,
            title: t("TelemedicinePrivacyPopup.secured_date"),
            text: t("TelemedicinePrivacyPopup.secured_date_text"),
        }
    ];
    const handleClose = () => {
        props.handleClose(false);
    };

    const navigateToTelemedicine = () => {
        updateShowPrivacyPopup(false);
        navigate("telemedicine");
    }

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='600'
            style={{ direction: dir }}
        >
            <Grid container style={{ width: "600px", background: "#2794a4", color: "#fff" }}>
                <Grid container md={12}>
                    <Grid item xs={11}>
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                            style={{ fontWeight: "lighter", marginTop: "50px", opacity: "0.7" }}
                        >
                            {t("TelemedicinePrivacyPopup.read_policy")}
                        </DialogTitle>
                    </Grid>
                    <Grid item xs={1} style={{ lineHeight: "4" }}>
                        <PrimaryButton
                            startIcon={<ClearIcon />}
                            btnType="icon"
                            classBtn="clear-icon white"
                            click={() => handleClose()}
                        />
                    </Grid>
                </Grid>
                <DialogContent dividers>
                    <Typography component="h1" className="mb-20px">{t("TelemedicinePrivacyPopup.remember")}</Typography>
                    {telemedicinePrivacyContent.map((item, i) => (
                        <Grid container md={12} className="telemedicine-privacy-item mb-20px" key={i}>
                            <Grid item xs={1.5}>
                                <img src={item.icon} alt="" />
                            </Grid>
                            <Grid item xs={10.5}>
                                <Typography component="h1" style={{ fontSize: "17px", fontWeight: "bolder" }}>{item.title}</Typography>
                                <Typography component="p" style={{ opacity: "0.7" }}>{item.text}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container md={12} style={{ marginTop: "40px", marginBottom: "40px" }}>
                        <Grid item xs={10}>
                            <Typography component="p">{t("TelemedicinePrivacyPopup.accept_privacy")}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <SwitchLabel
                                label=""
                                checked={switchValue}
                                change={(val) => setSwitchValue(val)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container md={12}>
                        <PrimaryButton
                            classBtn={switchValue ? "edit-btn" : "edit-btn disabled-btn"}
                            text={t("TelemedicinePrivacyPopup.continue")}
                            click={() => navigateToTelemedicine()}
                        />
                    </Grid>
                </DialogContent>
            </Grid>
        </Dialog>
    );
}

export default withTranslation()(TelemedicinePrivacy);
