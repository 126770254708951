import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import TextFieldInput from '../../Atoms/Input/TextField/TextFieldInput';
import PrimaryButton from '../../Atoms/Buttons/Primary/PrimaryButton';
import { icons } from "../../../Assets/images/IconHelper";
import MessageItem from "./Components/MessageItem.jsx";
import "./Messages.css";
import FileInput from '../../Atoms/Input/FileInput/FileInput';
import ClearIcon from '@mui/icons-material/Clear';

function ChatBox({ t, Chatmessages, message, setmessage, sendMessage, handleFileInput, chatRoom, history, Files, removeImage }) {
    const lang = localStorage.getItem("lang") == "en" ? "en" : "ar"
    const chatPlacHolder = lang == "en" ? "Write here" : "اكتب هنا"
    const chatHead = lang == "en" ? "Chat" : "المحادثة";

    useEffect(() => {
        var chatbox = document.getElementById('chat-box');
        chatbox.scrollTop = chatbox.scrollHeight;
    }, [<MessageItem />, message]);
    return (
        <>
            <Grid item className="profile-wallet-title grey-bg">
                <h4>{chatHead}</h4>
            </Grid>
            <Grid item xs={12} className="profile-wallet-body ">
                <div className='msg_section' id="chat-box" style={{ height: '410px' }}>
                    <div style={{ height: "400px" }}>
                        {Chatmessages?.map((el, index) => {
                            return el != '' &&
                                <MessageItem
                                    image={chatRoom?.chat_members?.find(member => parseInt(member.id) === parseInt(el.sender_id))?.avatar}
                                    name={chatRoom?.chat_members?.find(member => parseInt(member.id) === parseInt(el.sender_id))?.name}
                                    key={index}
                                    message={el.message}
                                    files={el.file_url}
                                    date={el.created_at}
                                    type={el.sender_id == localStorage.getItem("userId") ? "sent" : "received"}
                                />
                        })}
                    </div>
                </div>
                {!history && (
                    <>
                        {Files.length > 0 && (
                            <>
                                {Files?.map((file) => (
                                    <div style={{ margin: "10px 0" }}>
                                        <span
                                            style={{
                                                background: "#b0afaf",
                                                color: "#fff",
                                                padding: "0 20px",
                                                borderRadius: "15px",
                                                position: "relative"
                                            }}
                                        >
                                            {file?.name.length > 20 ? file?.name.slice(0, 19) + "..." : file?.name}
                                            <PrimaryButton
                                                startIcon={<ClearIcon />}
                                                btnType="icon"
                                                classBtn="delete-img"
                                                click={removeImage}
                                            />
                                        </span>
                                    </div>
                                ))}
                            </>
                        )}
                        <Grid container className='send_section' style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <Grid item xs={1}>
                                <FileInput
                                    icon={icons.attach}
                                    change={handleFileInput}
                                    accept="image/*,application/pdf"
                                    btnStyle='chat-btn'
                                />
                            </Grid>
                            <Grid item xs={9} sx={{paddingInlineStart:'2px'}}>
                                <TextFieldInput
                                    placeholder={chatPlacHolder}
                                    value={message}
                                    onKeyDown={(e) => { if (e.key === "Enter") sendMessage(); }}
                                    className=""
                                    type="text"
                                    onchange={(e) => setmessage(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <PrimaryButton
                                    click={sendMessage}
                                    startIcon={
                                        <img
                                            style={{ transform: lang === 'en' ? "rotate(180deg)" : '' }}
                                            src={icons.send} />}
                                    btnType="icon"
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>

    )
}

export default ChatBox
