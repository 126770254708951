import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import SearchPanel from "../../../../Molecules/Home/UserHome/SearchPanel";
import { images } from "../../../../../Assets/images/IconHelper";
import SearchOptions from "../../../../Molecules/Home/UserHome/SearchOptions";
import { useSearchParams, useLocation } from "react-router-dom";

const MedicalEntitiesSearch = (props) => {
    const { t, specialities, getSubSpecialities, subSpecialities } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    
    return (
        <Grid container className="telemedicine-settings-container">
            <Typography component="h1">{pathnameArr[4] === 'clinic' ? t("TabsOptions.clinics") : t("TabsOptions.hospitals")}</Typography>
            <Grid container md={12}>
                <Grid xs={12} className="mb-20px">
                    <SearchOptions 
                        specialities={specialities}
                        searchParams={searchParams}
                        getSubSpecialities={(id) => getSubSpecialities(id)}
                        subSpecialities={subSpecialities}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withTranslation()(MedicalEntitiesSearch);