import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import SearchGroup from "../../../../Molecules/Home/UserHome/SearchGroup";
import MedicalProviderList from "../../../../Molecules/Home/UserHome/MedicalProviderList";
import CustomTablePagination from "../../../../Atoms/Pagination/Pagination";

const MedicalEntitiesList = (props) => {
    const { t, getListMedicalProviders, medicalProvidersList, paginationInfo={paginationInfo} } = props;
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const pathnameArr = location.pathname.split('/');
    const [filterObj, setFilterObj] = useState({
        'medical_provider_type': 'doctor',
        'gender': searchParams.get("doctorGender"),
        'search_keys[name]': '',
        'search_keys[speciality_id]': searchParams.get("speciality_id"),
        'search_keys[subspeciality_id]': searchParams.get("subSpeciality_id"),
        'user[lat]': searchParams.get("lat"),
        'user[long]': searchParams.get("long"),
        'page_size': 10,
        'page_number': 1
    });
    const getSearchKeywords = (keyword) => {
        setFilterObj({ ...filterObj, 'search_keys[name]': keyword });
    };
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    useEffect(() => {
        const service_type = pathnameArr[4] === 'clinic' ? '/clinic_service' : '/hospital_service';
        getListMedicalProviders(service_type, filterObj);
    },[filterObj]);
    return (
        <Grid container >
            <Grid container className="mb-30px">
                <SearchGroup getSearchKeywordsCallback={(searchKeywords) => getSearchKeywords(searchKeywords)} />
            </Grid>
            <Grid container >
                <MedicalProviderList medicalProvidersList={medicalProvidersList}/>
            </Grid>
            {paginationInfo?.total_count > 10 && (
                <CustomTablePagination
                    totalCount={paginationInfo.total_count}
                    changePage={(val) => paginationChangePage(val)}
                    handleRowsPerPage={(val) => handleRowsPerPage(val)}
                    t={t} 
                />
            )}
        </Grid>
    )
};

export default withTranslation()(MedicalEntitiesList);