import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function getDoctorCertificates() {
  return await axiosFn("get", URL.DOCTOR_CERTIFICATES)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getDoctorCertificatesAPI() {
  let { err, res } = await getDoctorCertificates();
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function deleteFileAPI(id){
  return await axiosFn('delete', URL.DELETE_CERTIFICATE + '?certificate_ids=' + id)
  .then(res => {
    if (res) {
      return res.data;
    }
  }).catch(handleError);
}

export async function addCertificateAPI(certificates){
  return await axiosFn('put', URL.ADD_CERTIFICATE,{medical_provider:{certificates}} )
  .then(res => {
    if (res) {
      return res.data;
    }
  }).catch(handleError);
}
