import { withTranslation } from "react-i18next"
import { Grid } from "@mui/material"

import UserInfoPanel from "../../../Components/Organisms/ProfilePanels/UserInfoPanel"
import UserActionPanel from "../../../Components/Organisms/ProfilePanels/UserActionPanel"
import AppointmentPanel from "../../../Components/Organisms/ProfilePanels/AppointmentPanel"
import Loader from "../../../Components/Atoms/Loader/Loader"

const UserProfileTemplate = (props) => {
    const {
        paginationInfo,
        getDiagnosisApp,
        paginationDiagnosisHistory,
        getDiagnosisHistory,
        paginationPrescriptionApp,
        paginationPrescriptionHistory,
        getPrescriptionApp,
        getPrescriptionHistory,
        analysisListPaginationInfo,
        xrayPaginationInfo,
        getAnalysisList,
        getXrayList,
        surgeriesPaginationInfo,
        getSurgeries,
        diseasesList,
        setAvatar,
        hijriBirthDate
    } = props;
    return (
        <Grid container spacing={0} >
            <Loader open={props.openLoader} />
            <Grid item xs className="grey-bg"></Grid>
            <Grid
                container
                xl={7}
                lg={9.5}
                md={11}
                sm={12}
                xs={12}
                rowSpacing={3}
                columnSpacing={{ xs: 3 }}
                className="grey-bg tp-bt-padding main-container"
            >
                <Grid item xs={9}>
                    <UserInfoPanel
                        mainInfo={props.profileData}
                        phoneNumber={props.phoneNumber}
                        countryCode={props.countryCode}
                        birthDate={props.birthDate}
                        age={props.age}
                        editProfileData={props.editProfileData}
                        handleCallMainInfo={() => props.handleCallMainInfo()}
                        setAvatar={setAvatar}
                        hijriBirthDate={hijriBirthDate}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppointmentPanel
                        getUserAppointments={props.getUserAppointments}
                        appointmentsList={props.appointmentsList}
                        setLoader={props.setLoader}
                    />
                </Grid>
            </Grid>
            <Grid item xs className="grey-bg"></Grid>
            <Grid item md={12} className="white-bg">
                <UserActionPanel
                    profileData={props.profileData}
                    diagnosisList={props.diagnosisList}
                    open={props.open}
                    item={props.item}
                    type={props.type}
                    openModal={props.openModal}
                    createDiagnosis={props.createDiagnosis}
                    removeDiagnosis={props.removeDiagnosis}
                    editDiagnosis={props.editDiagnosis}
                    handleClose={props.handleClose}
                    openPrescription={props.openPrescription}
                    typePrescription={props.typePrescription}
                    itemPrescription={props.itemPrescription}
                    prescriptionList={props.prescriptionList}
                    handleClosePrescription={props.handleClosePrescription}
                    openPrescriptionModal={props.openPrescriptionModal}
                    createPrescription={props.createPrescription}
                    removePrescription={props.removePrescription}
                    editPrescription={props.editPrescription}
                    medicineIntakeMethods={props.medicineIntakeMethods}
                    openPrescriptionDtails={props.openPrescriptionDtails}
                    openPrescriptionDetailsModal={props.openPrescriptionDetailsModal}
                    handleClosePrescriptionDetails={props.handleClosePrescriptionDetails}
                    medicineDosagePeriods={props.medicineDosagePeriods}
                    xrayList={props.xrayList}
                    analysisList={props.analysisList}
                    addExaminations={props.addExaminations}
                    removeExaminations={props.removeExaminations}
                    getXrayData={props.getXrayData}
                    getAnalysisData={props.getAnalysisData}
                    editExaminationDate={props.editExaminationDate}
                    isAllergic={props.isAllergic}
                    allergyList={props.allergyList}
                    createAllergy={props.createAllergy}
                    removeAllergy={props.removeAllergy}
                    surgeries={props.surgeries}
                    removeSurgery={props.removeSurgery}
                    createSurgery={props.createSurgery}
                    editSurgery={props.editSurgery}
                    questions={props.questions}
                    answers={props.answers}
                    saveAnswers={props.saveAnswers}
                    medicalFileNumber={props.medicalFileNumber}
                    appDiagnosisList={props.appDiagnosisList}
                    showDiagnosis={props.showDiagnosis}
                    idNumber={props.idNumber}
                    appPrescriptionList={props.appPrescriptionList}
                    showPrescription={props.showPrescription}
                    getPdfPrescription={props.getPdfPrescription}
                    paginationInfo={paginationInfo}
                    getDiagnosisApp={getDiagnosisApp}
                    paginationDiagnosisHistory={paginationDiagnosisHistory}
                    getDiagnosisHistory={getDiagnosisHistory}
                    paginationPrescriptionApp={paginationPrescriptionApp}
                    paginationPrescriptionHistory={paginationPrescriptionHistory}
                    getPrescriptionApp={getPrescriptionApp}
                    getPrescriptionHistory={getPrescriptionHistory}
                    analysisListPaginationInfo={analysisListPaginationInfo}
                    xrayPaginationInfo={xrayPaginationInfo}
                    getAnalysisList={getAnalysisList}
                    getXrayList={getXrayList}
                    surgeriesPaginationInfo={surgeriesPaginationInfo}
                    getSurgeries={getSurgeries}
                    diseasesList={diseasesList}
                />
            </Grid>
        </Grid>
    )
}

export default withTranslation()(UserProfileTemplate)
