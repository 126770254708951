import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"

export async function getDiseasesList(filter_obj) {
    return await axiosFn("get", URL.DISEASES + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getDiseasesListAPI(filter_obj) {
    let { err, res } = await getDiseasesList(filter_obj)

    if (res) return res

    if (err) return err
}
