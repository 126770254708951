import UserPrivacyPolicyTemplate from '../../../Templates/Main/PrivactPolicy/UserPrivacyPolicy.template.jsx'
import {getPrivacyPolicyAPI} from '../../../Contexts/API/SystemLookups/PrivacyPolicyApi.js'
import { useState,useEffect } from 'react'

const UserPrivacyPolicyPage = () => {

    const [policy,setPolicy] = useState('')

    const callApi = async () => {
        const res = await getPrivacyPolicyAPI()
        if(res) setPolicy(res)
    }

    useEffect(() => {
        callApi()
    },[])

    return <UserPrivacyPolicyTemplate policy={policy}/>
        
}
 
export default UserPrivacyPolicyPage