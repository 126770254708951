import { Grid } from "@mui/material";
import ChatBox from "../../../../Components/Organisms/Chat/ChatBox";
import { images } from "../../../../Assets/images/IconHelper"

const PatientTelemedicineSessionTemplate = ({ handleFileInput, message, Chatmessages, sendMessage, setmessage, chatRoom, Files, removeImage }) => {


    return (
        <>
            <Grid container className="navbar-container" >
                <Grid container item md={12} justifyContent='center'>
                    <img src={images.Logo} alt="Logo" style={{ height: '40px', marginTop: '21px' }} />
                </Grid>
            </Grid>
            <Grid style={{ height: '100vh', width: '100vw', marginTop: '80px'  }}>
                <Grid container className="grey-bg tp-bt-padding main-container">
                    <Grid
                        xl={7.5}
                        lg={9.5}
                        md={11}
                        sm={12}
                        xs={12}
                        container
                        spacing={3}
                        className="mb-20px"
                        style={{ margin: "auto" }}
                    >
                        <Grid item md={8} id="zoom_part" style={{direction: "ltr"}}></Grid>
                        <Grid item md={4}>
                            <Grid
                                container
                                className="profile-wallet-container"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <ChatBox
                                        message={message}
                                        Chatmessages={Chatmessages}
                                        sendMessage={sendMessage}
                                        setmessage={setmessage}
                                        handleFileInput={handleFileInput}
                                        chatRoom={chatRoom}
                                        Files={Files}
                                        removeImage={removeImage}  
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PatientTelemedicineSessionTemplate;
