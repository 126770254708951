import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import RadioButtonsGroup from "../../../Atoms/Input/Radio/RadioInput";
import { useState } from "react";
import { icons } from "../../../../Assets/images/IconHelper";
import { useLocation } from 'react-router-dom';

function ServiceOptions(props) {
    const { t, mode } = props;
    const location = useLocation();
    const servicesType = [
        {
            label: t("TelemedicineSettings.private_doctor"),
            value: "privateDoctor",
        },
        {
            label: t("TelemedicineSettings.group_therapy"),
            value: "groubTherapy"
        },
    ];
    const gender = [
        {
            label: t("TelemedicineSettings.all"),
            value: ""
        },
        {
            label: t("TelemedicineSettings.maleDoctor"),
            value: "male",
            src: icons.ManAvatar
        },
        {
            label: t("TelemedicineSettings.femaleDoctor"),
            value: "female",
            src: icons.WomanAvatar
        },
    ];
    const [doctorGender, setDoctorGender] = useState("");
    const [serviceType, setServiceType] = useState(location.state ? location?.state?.serviceType : "privateDoctor");
    const getDoctorGender = (val) => {
        setDoctorGender(val);
    };
    const getServiceType = (val) => {
        setServiceType(val);
    };
    useEffect(() => {
        props.getServiceOptions(doctorGender, serviceType);
    },[doctorGender, serviceType]);

    return (
        <Grid container>
            {mode !== 'medical_entity' && (
                <Grid xs={12} className="mb-20px radio-full-width">
                    <RadioButtonsGroup 
                        formLabel={t("TelemedicineSettings.service_choices")}
                        options={servicesType}
                        row={true}
                        containerStyle={{width: "30%"}}
                        change={(val) => getServiceType(val)}
                        value={serviceType}
                    />
                </Grid>
            )}
            <Grid xs={12} className="mb-20px radio-full-width">
                <RadioButtonsGroup 
                    options={gender}
                    row={true}
                    containerStyle={{width: "30%"}}
                    change={(val) => getDoctorGender(val)}
                    value={doctorGender}
                />
            </Grid>
        </Grid>
    );
  }
  
export default withTranslation()(ServiceOptions);