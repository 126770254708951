import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState, useEffect } from "react";
import { images } from "../../../../Assets/images/IconHelper";
// import StarRatings from 'react-star-ratings';
import CustomTablePagination from "../../../Atoms/Pagination/Pagination";
import RatingAtom from "../../../Atoms/Rating/Rating";

function UserReview(props) {
    const { t, getUserReview, userReview, paginationInfo } = props;

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    
    useEffect(() => {
        getUserReview(filterObj)
    }, [filterObj])

    return (
        <Grid container className="user-review-container">
            <Typography style={{color: "#000", fontWeight: "bolder"}} component="h1" className="mb-30px">{t("DoctorProfile.clients_comments")}</Typography>
            <Grid container md={12}>
                {userReview?.map((review) => (
                    <Grid md={11} className="user-review-item mb-20px">
                        <Grid container sm={12} className="mb-30px">
                            <Grid xs={2}>
                                <img src={review?.user?.avatar === null || review?.user?.avatar === '' ? images.userAvatar : review?.user?.avatar}/>
                            </Grid>
                            <Grid xs={6}>
                                <Typography component="h2">
                                    {review?.user?.name}   
                                </Typography>
                                <Typography component="p">
                                    {review?.updated_at?.slice(0,10)}   
                                </Typography>
                            </Grid>
                            <Grid xs={4} style={{textAlign: "end", lineHeight: "4"}}>
                                    {/* <StarRatings
                                        starDimension="25px"
                                        rating={review?.rate}
                                        starRatedColor="#fcb862"
                                    /> */}
                                <RatingAtom
                                    initialValue={review?.rate}
                                    readonly={true}
                                    size={25}
                                    fillColor='#fcb862'
                                />
                            </Grid>
                        </Grid>
                        <Grid container sm={12}>
                            <Typography component="p">
                                {review?.body}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            {paginationInfo.total_count > 10 && (
                <CustomTablePagination
                    totalCount={paginationInfo.total_count}
                    changePage={(val) => paginationChangePage(val)}
                    handleRowsPerPage={(val) => handleRowsPerPage(val)}
                    t={t} 
                />
            )}
        </Grid>
    );
}

export default withTranslation()(UserReview);