import { Box } from "@mui/material";
import { images } from "../../../Assets/AssetsHelper";

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src={images.Logo}
      sx={{ width: { xs: "103px", md: "140px" }, height: { xs: "51px", md: "70px" }, ...sx }}
    />
  );
}
