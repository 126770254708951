import { Grid } from "@mui/material"
import ReactHtmlParser from 'react-html-parser'

const UserPrivacyPolicyTemplate = ({ t ,policy}) => {
    //var Body = new DOMParser().parseFromString(policy,"text/html")
    return (
        <Grid container>
            <Grid item xs></Grid>
            <Grid
                container
                lg={7}
                md={10}
                xs={12}
                rowSpacing={3}
                columnSpacing={{ xs: 3 }}
                className="tp-bt-padding main-container"
            >
                {ReactHtmlParser(policy)}
                {/* <span
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight:'300'
                    }}
                    dangerouslySetInnerHTML={{ __html: Body?.body?.innerHTML }}
                /> */}
            </Grid>
            <Grid item xs></Grid>
        </Grid>
    )
}
 
export default UserPrivacyPolicyTemplate