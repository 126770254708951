import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function GetWalletInfo() {
    return await axiosFn("get", URL.USER_STR + URL.SETTINGS + URL.GET_WALLET)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function GetWalletInfoAPI() {
    let { err, res } = await GetWalletInfo()

    if (res) return res

    if (err) return err
}

export async function getPackages() {
    return await axiosFn("get", URL.PACKAGES)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getPackagesAPI() {
    let { err, res } = await getPackages()

    if (res) return res

    if (err) return err
}


export async function depositWalletToAccount() {
    return await axiosFn("post", URL.USER_STR + URL.SETTINGS + URL.DEPOSIT_WALLET)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function depositWalletToAccountAPI() {
    let { err, res } = await depositWalletToAccount()
    return { err, res }
}

export async function subscripeToPackage(id) {
    return await axiosFn("post", URL.PACKAGES + '/' + id + URL.SUBSCRIPE)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function subscripeToPackageAPI(id) {
    let { err, res } = await subscripeToPackage(id)

    if (res) return res

    if (err) return err
}