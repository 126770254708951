import * as React from 'react';
import TabPanel from "../../../Atoms/Tabs/TabPanel";
import NormalTabs from "../../../Atoms/Tabs/NormalTabs";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { images } from "../../../../Assets/images/IconHelper";
import Certificates from "../../../Organisms/ProfilePanels/TabPanels/Certificates";
import Telemedicine from "../../../Organisms/ProfilePanels/TabPanels/Telemedicine";
import GroupTherapy from "../../../Organisms/ProfilePanels/TabPanels/GroupTherapy";
import UserReview from "../../../Organisms/ProfilePanels/TabPanels/UserReview";

function ActionsView(props) {
    const { t, getUserReview, userReview, paginationInfo, diseasesList } = props;
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const TabsOptions = [
        {
            id: 1,
            label: t("DoctorProfile.certificates"),
            icon: <img src={images.certificate} alt=""/>,
        },
        {
            id: 2,
            label: t("DoctorProfile.doctor_dates"),
            icon: <img src={images.careerIcon} alt=""/>,
        },
        {
            id: 3,
            label: t("DoctorProfile.groupـtherapy"),
            icon: <img src={images.people} alt=""/>,
        },
        {
            id: 4,
            label: t("DoctorProfile.clients_comments"),
            icon: <img src={images.comment} alt=""/>,
        },
    ] 

    return (
      <Grid container columnSpacing={{ xs: 3 }}>
        <Grid item xs={3}>
            <NormalTabs 
                TabsOptions={TabsOptions} 
                value={value} 
                change={handleChange}
                orientation="vertical"
                ContainerStyle="toggle-btns-container"
                TabStyle="toggle-btn mb-20px"
            />
        </Grid>
        <Grid item xs={9}>
            {TabsOptions.map((option, index) => (
                <TabPanel value={value} index={index} key={index}>
                    {index === 0 && (
                        <Certificates images={images}/>
                    )}
                    {index === 1 && (
                        <Telemedicine/>
                    )}
                    {index === 2 && (
                        <GroupTherapy
                            diseasesList={diseasesList}
                        />
                    )}
                    {index === 3 && (
                        <UserReview 
                            getUserReview={getUserReview} 
                            userReview={userReview}
                            paginationInfo={paginationInfo} 
                        />
                    )}
                </TabPanel>
            ))}
        </Grid>
      </Grid>
    );
}

export default withTranslation()(ActionsView);