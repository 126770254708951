import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import TotalAmount from "../../Molecules/Wallet/TotalAmount";
import WalletDetails from "../../Molecules/Wallet/WalletDetails";
import "./Wallet.css";

const UserWalletPanel = (props) => {
    const { t, walletInfo, depositToAccount } = props;
    
    return (
        <Grid
            container
            item
            xl={7}
            lg={9.5}
            md={11}
            sm={12}
            xs={12} 
            className="wallet-container tp-bt-padding" 
        >
            <Typography className="mb-30px" component="h1" style={{fontSize: "18px", fontWeight: "bold"}}>
               {t("AccountMenu.my_wallet")}
            </Typography>
            <Grid container md={12}>
                <Grid sm={6}>
                    <TotalAmount 
                        totalAmount={walletInfo?.wallet_amount}
                        depositToAccount={depositToAccount}
                    />
                </Grid>
                <Grid sm={6}>
                    <WalletDetails actions={walletInfo?.actions}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(UserWalletPanel)
