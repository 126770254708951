import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"

export async function getOffersList(filterObj) {
    return await axiosFn("get", URL.OFFERS_LIST + makeFilterString(filterObj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getOffersListAPI(filterObj) {
    let { err, res } = await getOffersList(filterObj)

    if (res) return res

    if (err) return err
}

export async function checkOfferState(id) {
    return await axiosFn("post", URL.OFFERS_LIST + id + URL.CHECK_STATE)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function checkOfferStateAPI(id) {
    let { err, res } = await checkOfferState(id)

    if (res) return res

    if (err) return err
}
