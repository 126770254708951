import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function getDoctorApointements(date, service_type, page, rowsPerPage) {
  var filterObj = {
    date: date,
    service_type: service_type,
    page_size: rowsPerPage,
    page_number: page
  }
  return await axiosFn("get", URL.PROFILE_STR + URL.DOCTOR_RESERVATIONS + makeFilterString(filterObj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getDoctorApointementsAPI(date, service_type, page, rowsPerPage) {
  let { err, res } = await getDoctorApointements(date, service_type, page, rowsPerPage);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function getDoctorApointementsSummary(filter_obj) {
  return await axiosFn("get", URL.PROFILE_STR + URL.DOCTOR_RESERVATIONS_SUMMARY + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getDoctorApointementsSummaryAPI(filter_obj) {
  let { err, res } = await getDoctorApointementsSummary(filter_obj);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}