import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { images } from "../../../Assets/images/IconHelper";
import "./DoctorProfile.css";
import InfoPanel from "../../../Components/Organisms/ProfilePanels/InfoPanel";
import WalletPanel from "../../../Components/Organisms/ProfilePanels/WalletPanel";
import DatesPanel from "../../../Components/Organisms/ProfilePanels/DatesPanel";
import ActionsPanel from "../../../Components/Organisms/ProfilePanels/ActionsPanel";
import { getDoctorMainInfoAPI } from "../../../Contexts/API/DoctorProfile/mainInfo";
import { useEffect, useState } from "react";
import { getUserReviewApi } from "../../../Contexts/API/DoctorProfile/UserReview";
import Loader from "../../../Components/Atoms/Loader/Loader";

const DoctorProfileTemplate = (props) => {
  const { t, getDoctorAppointments, appointmentsList, appointmentObj, diseasesList, walletAmount, setAvatar } = props;
  const [profileData, setProfileData] = useState({});
  const [medicalProviderId, setMedicalProviderId] = useState(null);
  const [userReview, setUserReview] = useState([]);
  const [openLoader, setLoader] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({});

  const getProfileData = async () => {
    const res = await getDoctorMainInfoAPI();
    if ( res ) setProfileData(res?.data?.profile);
    setMedicalProviderId(res?.data?.profile?.medical_provider?.medical_provider_id);
  }
  const handleCallMainInfo = () => {
    getProfileData();
  };
  const getUserReview = async (filter_obj) => {
    setLoader(true);
    const res = await getUserReviewApi(medicalProviderId, filter_obj);
    if (res) setUserReview(res?.data?.reviews);
    setPaginationInfo(res?.extra);
    setLoader(false);
  };
  useEffect(() => {
    getProfileData();
  }, {})
  return (
    <Grid container spacing={0} >
      <Loader open={openLoader}/>
      <Grid item xs className="grey-bg"></Grid>
      <Grid 
        container
        xl={7}
        lg={9.5}
        md={11}
        sm={12}
        xs={12} 
        rowSpacing={3} 
        columnSpacing={{ xs: 3 }}  
        className="grey-bg tp-bt-padding main-container"
      >
        <Grid item xs={9}>
          <InfoPanel 
            mainInfo={profileData}
            handleCallMainInfo={() => handleCallMainInfo()}
            setAvatar={setAvatar}
          />
        </Grid>
        <Grid item xs={3}>
        <DatesPanel
          getDoctorAppointments={getDoctorAppointments}
          appointmentsList={appointmentsList}
          appointmentObj={appointmentObj} 
        />
          <WalletPanel walletAmount={walletAmount}/>
        </Grid>
      </Grid>
      <Grid item xs className="grey-bg"></Grid>
      <Grid item md={12} className="white-bg">
        <ActionsPanel 
          getUserReview={getUserReview} 
          userReview={userReview}
          paginationInfo={paginationInfo}
          diseasesList={diseasesList}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(DoctorProfileTemplate);
