import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

// List LifeStyle Questions
export async function getListQuestions() {
    return await axiosFn("get", URL.LIFESTYLE_QUESTIONS )
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getListQuestionsAPI() {
    let { err, res } = await getListQuestions()
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// List LifeStyle Answers
export async function getListAnswers(id) {
    return await axiosFn("get", URL.USER_STR + id + URL.USER_LIFESTYLE)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getListAnswersAPI(id = localStorage.getItem("userId")) {
    let { err, res } = await getListAnswers(id)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Save Answers
export async function saveAnswers(data) {
    return await axiosFn("put", URL.USER_STR + localStorage.getItem("userId") + URL.USER_LIFESTYLE ,data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function saveAnswersAPI(data) {
    let { err, res } = await saveAnswers(data)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}