import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function acceptStartEarly(id) {
  return await axiosFn("post", URL.RESERVATIONS + id + URL.ACCEPT_START_EARLY)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function acceptStartEarlyAPI(id) {
  let { err, res } = await acceptStartEarly(id);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function rejectStartEarly(id) {
    return await axiosFn("post", URL.RESERVATIONS + id + URL.REJECT_START_EARLY)
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
  }
  export async function rejectStartEarlyAPI(id) {
    let { err, res } = await rejectStartEarly(id);
    if (res) {
      return res;
    }
    if (err) {
      return err;
    }
  }