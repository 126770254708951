import { useState, useEffect } from "react";
import { Grid, Divider, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import ClearIcon from '@mui/icons-material/Clear';
import Placeholder from "../../../Atoms/Placeholder/Placeholder";
import { images } from "../../../../Assets/images/IconHelper";

function Diagnosis(props) {
    const { t, open, handleClose, reservationId, userId, getDiagnosis, diagnosisList, personalId } = props;
    const arrayLength = diagnosisList?.length
    const [diagnosis, setDiagnosis] = useState({});
    const [index, setIndex] = useState(0);

    useEffect(() => {
        getDiagnosis(reservationId, userId);
    }, [open])

    useEffect(() => {
        if (arrayLength === 0) setDiagnosis(null)
        else setDiagnosis(diagnosisList?.[0])
    }, [diagnosisList]);

    useEffect(() => {
        setDiagnosis(diagnosisList?.[index])
    }, [index]);

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='600'
            PaperProps={{
                sx: {
                    borderRadius: "15px",
                    height: 'auto',
                    width: "600px",
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogTitle>
                <Grid container>
                    <Grid md={6} justifyContent="start">
                        <Typography className="cairo-font" component="h1" style={{ fontSize: '20px', fontWeight: "bold" }}>{t("UserAppointments.diagnosis")}</Typography>
                    </Grid>
                    <Grid md={6} style={{ textAlign: "end" }}>
                        <PrimaryButton
                            startIcon={<ClearIcon />}
                            btnType="icon"
                            classBtn="clear-icon"
                            click={handleClose}
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {diagnosis === null ? (
                    <Placeholder
                        src={images.EmptyPlaceholder}
                        alt="Empty Appointments"
                        mainTitle={t("UserAppointments.no_diagnosis")}
                        txtContainer="text-container"
                    />
                ) : (
                    <Grid container className="diagnosis-popup-container">
                        <Grid container md={12}>
                            <Grid sm={4}>
                                <Typography component="h2">{t("UserAppointments.doctor_name")}</Typography>
                                <Typography component="p">{diagnosis?.medical_provider_name}</Typography>
                            </Grid>
                            <Grid sm={4}>
                                <Typography component="h2">{t("DoctorProfile.commission_no")}</Typography>
                                <Typography component="p">{diagnosis?.medical_provider?.saudi_commission_registration_number}</Typography>
                            </Grid>
                            <Grid sm={4}>
                                <Typography component="h2">{t("UserAppointments.date")}</Typography>
                                <Typography component="p">{diagnosis?.date}</Typography>
                            </Grid>
                        </Grid>
                        <Divider style={{ width: "100%", margin: "20px 0" }} />
                        <Grid container md={12}>
                            <Grid sm={12}>
                                <Typography component="h2">{t("UserAppointments.user_national_id")}</Typography>
                                <Typography component="p">{personalId}</Typography>
                            </Grid>
                        </Grid>
                        <Divider style={{ width: "100%", margin: "20px 0" }} />
                        <Grid container md={12}>
                            <Grid sm={12} className="mb-20px">
                                <Typography component="h2">{t("UserAppointments.symptoms")}</Typography>
                                {diagnosis?.symptoms?.map((symptom) => (
                                    <Typography component="p">{`- ${symptom?.name}`}</Typography>
                                ))}
                            </Grid>
                            <Grid sm={12} className="mb-20px">
                                <Typography component="h2">{t("UserAppointments.diagnosis")}</Typography>
                                <Typography component="p">{diagnosis?.diagnosis_name}</Typography>
                            </Grid>
                            <Grid sm={12} className="mb-20px">
                                <Typography component="h2"> {t("UserAppointments.diagnosis_desc")} </Typography>
                                <Typography component="p">{diagnosis?.description}</Typography>
                            </Grid>
                            <Grid sm={12} className="mb-20px">
                                <Typography component="h2"> {t("UserAppointments.rumors_and_analyzes")} </Typography>
                                <Typography component="p">{diagnosis?.requested_procedures}</Typography>
                            </Grid>
                        </Grid>
                        <Divider style={{ width: "100%", margin: "20px 0" }} />
                        <Grid container md={12}>
                            {diagnosis?.prescription?.prescription_medicines?.map((prescription) => (
                                <>
                                    <Grid sm={4} className="mb-20px">
                                        <Typography component="h2">{t("UserAppointments.medicine_name")}</Typography>
                                        <Typography component="p">{prescription?.medicine_name}</Typography>
                                    </Grid>
                                    <Grid sm={4} className="mb-20px">
                                        <Typography component="h2">{t("UserAppointments.dosage")}</Typography>
                                        <Typography component="p">{prescription?.dosage_display}</Typography>
                                    </Grid>
                                    <Grid sm={4} className="mb-20px">
                                        <Typography component="h2">{t("UserAppointments.usage")}</Typography>
                                        <Typography component="p">{prescription?.medicine_intake_method}</Typography>
                                    </Grid>
                                    <Grid sm={4} className="mb-20px">
                                        <Typography component="h2">{t("UserAppointments.duration_of_use")}</Typography>
                                        <Typography component="p">{prescription?.medicine_duration}</Typography>
                                    </Grid>
                                    <Grid sm={4} className="mb-20px">
                                        <Typography component="h2">{t("UserAppointments.status")}</Typography>
                                        <Typography component="p">{prescription?.status == 'pending' ? t("DoctorTelemedicine.not_accept") : t("DoctorTelemedicine.accept")}</Typography>
                                    </Grid>
                                    <Grid sm={4} className="mb-20px">
                                        <Typography component="h2">{t("UserAppointments.doc")}</Typography>
                                        <Typography component="p">{prescription?.medical_provider_name}</Typography>
                                    </Grid>
                                    <Divider style={{ width: "100%", margin: "20px 0" }} />
                                </>
                            ))}
                        </Grid>
                        {diagnosis?.delegation && (
                            <>
                                <Grid container md={12} className="mb-20px">
                                    <Grid sm={12}>
                                        <Typography component="h2">{t("UserAppointments.referral_to_doctor")}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container md={12}>
                                    <Grid sm={4}>
                                        <Typography component="h2">{t("DoctorProfile.medical_specialty")}</Typography>
                                        <Typography component="p">{diagnosis?.delegation?.speciality?.name}</Typography>
                                    </Grid>
                                    <Grid sm={4}>
                                        <Typography component="h2">{t("DoctorProfile.accurate_specialty")}</Typography>
                                        <Typography component="p">{diagnosis?.delegation?.subspeciality?.name}</Typography>
                                    </Grid>
                                    <Grid sm={4}>
                                        <Typography component="h2">{t("DoctorProfile.medical_title")}</Typography>
                                        <Typography component="p">{diagnosis?.delegation?.medical_title?.name}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </DialogContent>
            {arrayLength > 1 &&
                <DialogActions>
                    <Grid container>
                        <Grid item xs={5.5}>
                            <PrimaryButton
                                text={t("UserAppointments.next")}
                                classBtn='btn primary btn-rounded'
                                disabled={arrayLength === index + 1}
                                click={() => setIndex(index + 1)}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5.5}>
                            <PrimaryButton
                                text={t("UserAppointments.prev")}
                                classBtn='btn primary btn-rounded'
                                disabled={index === 0}
                                click={() => setIndex(index - 1)}
                            />
                        </Grid>
                    </Grid>
                </DialogActions>
            }
        </Dialog>
    )
}

export default withTranslation()(Diagnosis)
