import { Typography, Dialog, DialogActions } from "@mui/material"

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import { icons, images } from '../../../../Assets/images/IconHelper'

const StartEarlyCall = ({ t, open, handleClose, acceptEarlyCall, rejectEarlyCall }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    padding: '49px 96px'
                }
            }}
            style={{ direction: localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr' }}
        >
            <Typography
                variant='h6'
                align='center'
                style={{ fontSize: 'var(--small-font)', color: "#474747", fontFamily: 'Cairo' }}
            >
                {t("call.incomingcall")}
            </Typography>
            <Typography
                variant='h6'
                align='center'
                style={{color:'#2794a4',fontWeight:'bold',fontSize:'var(--normal-font)', fontFamily: 'Cairo'}}
            >
                {t("call.doctor_want_start_early")}
            </Typography>
            <img
                src={images.callimg}
                alt='call img'
                width='240px'
                style={{ marginTop: '30px' }}
            />
            <DialogActions sx={{
                justifyContent: 'space-between',
                marginY: '50px'
            }}>
                <div style={{textAlign:'center'}}>
                    <img src={icons.acceptcall} style={{ cursor: 'pointer' }} onClick={acceptEarlyCall}/>
                    <p style={{ fontSize: 'var(--small-font)', color: "#474747" }}>{t("call.accept")}</p>
                </div>
                <div style={{textAlign:'center'}}>
                    <img src={icons.rejectcall} style={{ cursor: 'pointer' }} onClick={rejectEarlyCall}/>
                    <p style={{ fontSize: 'var(--small-font)', color: "#474747" }}>{t("call.reject")}</p>
                </div>

            </DialogActions>
        </Dialog >
    )
}

export default StartEarlyCall