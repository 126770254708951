import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
// import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";
import SelectBox from "../../../Atoms/Select/Select";
// import { icons } from "../../../../Assets/images/IconHelper";

function SelectGroup(props) {
    const { t, serviceTypeCallback, serviceType, setServiceType } = props;
    
    const appointmentsSelect = [
        {
            name:t("TabsOptions.telemedicine"),
            id: "telemedicine",
        },
        {
            name:t("TabsOptions.clinics"),
            id: "clinic_service",
        },
        {
            name:t("TabsOptions.hospitals"),
            id: "hospital_service",
        }
    ];
    const getServiceType = (e) => {
        setServiceType(e.target.value);
        serviceTypeCallback(e.target.value);
    };

    return (
        <Grid container>
            <Grid item md={2.5}>
                <Typography 
                    
                    style={{
                        fontSize: "20px", 
                        color: "var(--secondary-color)", 
                        fontWeight: "bold",
                        lineHeight: "2"
                    }}
                >
                    {t("TabsOptions.appointments")}
                </Typography>
            </Grid>
            <Grid item md={0.5}></Grid>
            <Grid item md={9} sx={{width:'100%'}}>
                <SelectBox
                    id="service_type"
                    name="service_type"
                    items={appointmentsSelect}
                    title={t("UserAppointments.choose_type")}
                    label={t("UserAppointments.choose_type")}
                    labelStyle="select-label grey"
                    value={serviceType}
                    onchange={(e) => getServiceType(e)}
                    sx={{width:'100%'}}
                />
            </Grid>
            {/* <Grid container sm={2} style={{justifyContent: "end"}}>
                <Grid md={2.5}>
                    <PrimaryButton
                        startIcon={<img src={icons.GridIcon}/>}
                        btnType="icon"
                    />
                </Grid>
                <Grid sm={2.5}>
                    <PrimaryButton
                        startIcon={<img src={icons.ListIcon}/>}
                        btnType="icon"
                    />
                </Grid>
            </Grid> */}
        </Grid>
    );
  }
  
export default withTranslation()(SelectGroup);