import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import RadioButtonsGroup from "../../../Atoms/Input/Radio/RadioInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function SearchOptions(props) {
    const { t, specialities, getSubSpecialities, subSpecialities } = props;
    const [searchOptions, setSearchOptions] = useState([]);
    const [subSearchOptions, setSubSearchOptions] = useState([]);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const [speciality, setSpeciality] = useState("");
    const [subValue, setSubValue] = useState("");
    const [subSpeciality, setSubSpeciality] = useState("");
    const [lat, setLat] = useState(0);
    const [long, setLong] = useState(0);
    const navigate = useNavigate();
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
        });
    }
    if(searchOptions.length === 0) {
        specialities?.map((speciality) => {
            searchOptions.push({label: speciality.name, value: speciality.id});
        });
    }
    const getSpeciality = (value) => {
        setSpeciality(value);
        getSubSpecialities(value);
    };
    const getSubSpeciality = (value) => {
        setSubValue(value);
        setSubSpeciality(value-20);
    }
    const navigateToResultPage = (params) => {
        if(pathnameArr[4] === 'telemedicine') {
            navigate("result-page/" + "speciality" + "?" + params + "&speciality_id=" + speciality + "&subSpeciality_id=" + subSpeciality);
        }else{
            navigate("list?" + params + "&speciality_id=" + speciality + "&subSpeciality_id=" + subSpeciality + "&lat=" + lat + "&long=" + long);
        }
    };
    
    return (
        <Grid container className="search-options-container">
            <Grid 
                xs={11} 
                style={{margin: "auto"}}
            >
                <Typography 
                    style={{
                        fontSize: "17px", 
                        color: "#000", 
                        lineHeight: "4"
                    }} 
                    component="p"
                >
                   {t("TelemedicineSettings.choose_a_specialty")}
                </Typography> 
            </Grid>
            <Grid 
                xs={12} 
                className="mb-20px radio-full-width"
                style={{height: "500px", overflowX: "hidden"}}
            >
                <RadioButtonsGroup 
                    options={searchOptions}
                    row={false}
                    style={{
                        padding: "0px 20px"
                    }}
                    containerStyle={{width: "100%"}}
                    nestedStyle={{
                        width: "100%",
                        padding: "5px 50px",
                    }}
                    change={(val) => getSpeciality(val)}
                    subChange={(val) => getSubSpeciality(val)}
                    value={speciality}
                    Accordion={true}
                    AccordionStyle="accordion-style"
                    subList={subSpecialities}
                    subValue={subValue}
                />
            </Grid>
            <Grid xs={12} className="mb-20px">
                <PrimaryButton 
                    classBtn={speciality ? "edit-btn" : "edit-btn disabled-btn"} 
                    text={t("TelemedicineSettings.choose")}
                    style={{maxWidth: "180px"}}
                    click={() => navigateToResultPage(props.searchParams)}
                />
            </Grid>
        </Grid>
    );
  }
  
export default withTranslation()(SearchOptions);