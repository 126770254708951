import { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Stack, Box, Chip } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput"
import RadioButtonsGroup from "../../../Atoms/Input/Radio/RadioInput.js"
import { toast } from 'react-toastify'

function AddEditAllergy({ t, open, handleClose, allergyList, isAllergic, createAllergy, removeAllergy }) {

    const [isAllergy, setIsAllergy] = useState(isAllergic)
    const [newAllergy, setNewAllergy] = useState([])
    const [allergy, setAllergy] = useState('')
    const [ids, setIds] = useState([])
    const lang = localStorage.getItem("lang")

    const onSubmit = async (e) => {
        e.preventDefault()
        let data = {}
        if (!isAllergy) {
            await removeAllergy({ allergy_ids: ids })
            data = {
                "user": {
                    "is_allergic": isAllergy,
                    "user_allergies": []
                }
            }
        } else {
            data = {
                "user": {
                    "is_allergic": isAllergy,
                    "user_allergies": newAllergy
                }
            }
        }
        await createAllergy(data)
        handleClose()
    }

    const addNewAllergy = () => {
        if (allergy.trim().length !== 0) setNewAllergy([...newAllergy, allergy.trim()])
        else toast.error(t("Validation.empty"))
        setAllergy('')
    }

    const handleDelete = async id => await removeAllergy({ allergy_ids: id })

    const handleLocalDelete = item => setNewAllergy(newAllergy.filter(obj => obj !== item))

    const getIds = () => {
        const ids = []
        allergyList?.forEach(obj => ids.push(obj.id))
        setIds(ids)
    }

    useEffect(() => {
        setIsAllergy(isAllergic)
        setAllergy('')
        setNewAllergy([])
        getIds()
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    width: '620px'
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogTitle>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={11}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {t("Allergy.edit_allergy")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        sx={{ justifyContent: 'flex-end', alignContent: 'center' }}
                    >
                        <PrimaryButton
                            type='icon'
                            startIcon={<CancelIcon sx={{ color: 'var(--primary-color)' }} />}
                            click={handleClose} />

                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <Grid container>
                    <Typography variant='h6'
                        sx={{
                            fontWeight: 'bold',
                            color: '#34a8b9'
                        }}>
                        {t("Allergy.question")}
                    </Typography>
                    <Grid
                        item
                        xs={12}
                        container
                        style={{ marginBottom: 30 }}
                    >
                        <RadioButtonsGroup
                            row={true}
                            options={[
                                { label: t("Allergy.yes"), value: true },
                                { label: t("Checkups.no"), value: false }
                            ]}
                            value={isAllergy}
                            change={() => setIsAllergy(!isAllergy)}

                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        style={{ marginBottom: 30 }}
                    >
                        <TextFieldInput
                            label={t("Allergy.allergy")}
                            labelStyle='field-label'
                            // inputProps={{ maxLength: 9 }}
                            value={allergy}
                            onChange={(e) => setAllergy(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="end"
                        mb={8}
                    >
                        <PrimaryButton
                            text={t("Allergy.new_add")}
                            classBtn='primary btn-rounded btn-small'
                            style={{ maxWidth: '140px' }}
                            click={addNewAllergy}
                            disabled={!isAllergy || allergy === ''}
                        />
                    </Grid>
                    <Stack direction="row" sx={{flexWrap:'wrap'}} gap={1}>
                        {allergyList?.length > 0 && allergyList?.map((item, i) => {
                            return <Chip
                                label={item.name}
                                onDelete={() => handleDelete(item.id)}
                                key={i}
                                deleteIcon={<CancelIcon
                                    style={{
                                        fill: "white",
                                        position: lang === "ar" ? 'inherit' : '',
                                        left: lang === 'ar' ? 10 : '' }}
                                />}
                                sx={{
                                    bgcolor: "rgba(0, 0, 0, 0.68)",
                                    color: 'white'
                                }}
                            />
                            //<Box component="span" key={i}
                            //     sx={{
                            //         position: 'relative',
                            //         marginInlineEnd: '10px',
                            //         height: '30px',
                            //         width: '115px',
                            //         bgcolor: 'rgba(0, 0, 0, 0.68)',
                            //         borderRadius: '25px',
                            //         paddingInlineStart: '10px',
                            //         marginBottom: '10px'
                            //     }}>
                            //     <Typography
                            //         variant='subtitle1'
                            //         sx={{ color: 'white' }}
                            //     >
                            //         {item.name}
                            //     </Typography>
                            //     <PrimaryButton
                            //         type='icon'
                            //         classBtn={localStorage.getItem("lang") === 'ar' ? 'delete-allergy-ar' : 'delete-allergy-en'}
                            //         startIcon={<CancelIcon />}
                            //         click={() => handleDelete(item.id)}
                            //     />
                            // </Box>
                        })}
                        {newAllergy.length > 0 && newAllergy.map((item, i) => {
                            return <Chip
                                label={item}
                                onDelete={() => handleLocalDelete(item)}
                                key={i}
                                deleteIcon={<CancelIcon
                                    style={{
                                        fill: "white",
                                        position: lang === "ar" ? 'inherit' : '',
                                        left: lang === 'ar' ? 10 : ''
                                     }}
                                />}
                                sx={{
                                    bgcolor: "rgba(0, 0, 0, 0.68)",
                                    color: 'white',
                                    // marginBottom:'2px'
                                }}
                            />
                            // <Box component="span" key={i}
                            //     sx={{
                            //         position: 'relative',
                            //         marginInlineEnd: '10px',
                            //         height: '30px',
                            //         width: '115px',
                            //         bgcolor: 'rgba(0, 0, 0, 0.68)',
                            //         paddingInlineStart: '10px',
                            //         borderRadius: '25px',
                            //         marginBottom: '10px'
                            //     }}>
                            //     <Typography
                            //         variant='subtitle1'
                            //         sx={{ color: 'white' }}
                            //     >
                            //         {item}
                            //     </Typography>
                            //     <PrimaryButton
                            //         type='icon'
                            //         classBtn={localStorage.getItem("lang") === 'ar' ? 'delete-allergy-ar' : 'delete-allergy-en'}
                            //         startIcon={<CancelIcon />}
                            //         click={() => handleLocalDelete(item)}
                            //     />
                            // </Box>
                        })}
                    </Stack>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'center',
                    marginTop: '60px',
                    marginBottom: '30px'
                }}>
                <PrimaryButton
                    type='submit'
                    text={t("DoctorProfile.save_btn")}
                    classBtn='primary btn-rounded'
                    style={{ maxWidth: '300px' }}
                    click={onSubmit}
                />
            </DialogActions>
        </Dialog >
    )
}

export default AddEditAllergy
