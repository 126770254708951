import { useState } from "react"

import PlaceHolder from '../../../../../Atoms/Placeholder/Placeholder'
import AppDiagnosisDetails from '../../../../../Organisms/Modals/Diagnosis/AppDiagnosisDetails'
import DiagnosisCard from "../../../Cards/DiagnosisCard"
import { images } from "../../../../../../Assets/images/IconHelper"

function AppDiagnosis({ t, appDiagnosisList, showDiagnosis, idNumber, getPdfPrescription }) {

    const [open, setOpen] = useState(false)
    const [diagnosis, setDiagnosis] = useState({})

    const handleOpen = async (id) => {
        const res = await showDiagnosis(id)
        if (res) setDiagnosis(res)
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    return (
        <>
            {appDiagnosisList?.length > 0 ?
                appDiagnosisList?.map((item, i) => {
                    return (
                        <DiagnosisCard
                            key={i}
                            t={t}
                            handleOpen={() => handleOpen(item.id)}
                            item={item}
                        />
                    )
                }) :
                <PlaceHolder
                    src={images.EmptyPlaceholder}
                    alt='Empty Box'
                    mainTitle={t("UserProfile.no_diagnosis")}
                    subTitle={t("UserProfile.add_prev_diagnosis")}
                    txtContainer='text-container'
                />}
            <AppDiagnosisDetails
                t={t}
                open={open}
                handleClose={handleClose}
                diagnosis={diagnosis}
                personalId={idNumber}
                getPdfPrescription={getPdfPrescription}
            />
        </>
    )
}

export default AppDiagnosis