import { useState, useEffect } from "react";
import { Grid, Divider, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import ClearIcon from '@mui/icons-material/Clear';
// import StarRatings from 'react-star-ratings';
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import RatingAtom from "../../../Atoms/Rating/Rating";

function ReviewAppointment(props) {
    const { t, open, handleClose, serviceType, parentSpName, spName, SendReviewData, reservationId, updateAppointmentsList, serviceTypeDisplay } = props;
    // const StarPath = "M16.54.5c.373 0 .725.125 1.014.344.282.213.503.517.622.887l2.7 8.713 9.443.53c.372-.008.725.111 1.015.327.283.212.506.516.621.882.114.36.11.74.006 1.084-.107.35-.317.663-.613.888l-7.116 5.424 2.452 9.62a1.84 1.84 0 0 1-.252 1.576 1.674 1.674 0 0 1-2.41.371l-7.063-5.382-7.896 5.379a1.677 1.677 0 0 1-2.415-.368 1.842 1.842 0 0 1-.25-1.58l2.71-8.755-7.377-6.285a1.758 1.758 0 0 1-.612-.888 1.833 1.833 0 0 1 .006-1.084c.115-.366.338-.67.621-.882.29-.216.643-.335 1.015-.328h8.764l3.38-9.248c.118-.364.34-.668.62-.881.29-.22.642-.344 1.015-.344z"
    const [reservationRating, setReservationRating] = useState(0);
    const [serviceRating, setServiceRating] = useState(0);
    const [spRating, setSpRating] = useState(0);
    const [instituteRating, setInstituteRating] = useState(0);
    const [serviceRemarks, setServiceRemarks] = useState("");
    const [spRemarks, setSpRemarks] = useState("");
    const [instituteRemarks, setInstituteRemarks] = useState("");
    const [reservationRemarks, setReservationRemarks] = useState("");

    const removeSavedData = () => {
        handleClose();
        setReservationRating(0);
        setServiceRating(0);
        setSpRating(0);
        setInstituteRating(0);
        setServiceRemarks("");
        setSpRemarks("");
        setInstituteRemarks("");
        setReservationRemarks("");
    };
    const SendReview = () => {
        var data = {};
        if (serviceType === 'telemedicine') {
            data = {
                "reservation_review": {
                    "rate": reservationRating,
                    "body": reservationRemarks
                },
                "medical_provider_review": {
                    "rate": spRating,
                    "body": spRemarks
                },
                "service_review": {
                    "rate": serviceRating,
                    "body": serviceRemarks
                }
            }
        } else {
            data = {
                "reservation_review": {
                    "rate": reservationRating,
                    "body": reservationRemarks
                },
                "medical_provider_review": {
                    "rate": spRating,
                    "body": spRemarks
                },
                "institute_review": {
                    "rate": instituteRating,
                    "body": instituteRemarks
                },
            }
        }
        SendReviewData(data, reservationId);
        updateAppointmentsList();
        removeSavedData();
    };

    return (
        <Dialog
            onClose={removeSavedData}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='600'
            PaperProps={{
                sx: {
                    borderRadius: "15px",
                    height: 'auto',
                    width: "600px",
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogTitle>
                <Grid container md={12} justifyContent="end">
                    <PrimaryButton
                        startIcon={<ClearIcon />}
                        btnType="icon"
                        classBtn="clear-icon"
                        click={removeSavedData}
                    />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid container md={12}>
                        <Grid md={12} style={{ textAlign: "center" }}>
                            <Typography className="cairo-font mb-20px" component="p" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                {t("UserAppointments.appointment_booking_review")}
                            </Typography>
                        </Grid>
                        <Grid md={12} style={{ textAlign: "center" }}>
                            {/* <StarRatings
                                rating={reservationRating}
                                starHoverColor="#f8e71c"
                                starRatedColor="#f8e71c"
                                changeRating={(val) => setReservationRating(val)}
                                svgIconPath={StarPath}
                            /> */}
                            <RatingAtom
                                initialValue={reservationRating}
                                readonly={false}
                                // size={17}
                                fillColor='#f8e71c'
                                onClick={(val) => setReservationRating(val)}
                            />
                        </Grid>
                        <Grid md={12} style={{ height: "110px" }}>
                            <TextFieldInput
                                multiline={true}
                                className='notes'
                                placeholder={t("AddDiagnosisModal.remarks")}
                                name='reservationRemarks'
                                id='reservationRemarks'
                                rows={3}
                                inputProps={{
                                    style: {
                                        position: 'absolute',
                                        top: '10px',
                                        width: '95%'
                                    }
                                }}
                                value={reservationRemarks}
                                onchange={(e) => setReservationRemarks(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Divider style={{ width: "100%", margin: "10px 0" }} />
                    {serviceType === 'telemedicine' ? (
                        <Grid container md={12}>
                            <Grid md={12} style={{ textAlign: "center" }}>
                                <Typography className="cairo-font mb-20px" component="p" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                    {t("UserAppointments.service_review")}{serviceTypeDisplay}
                                </Typography>
                            </Grid>
                            <Grid md={12} style={{ textAlign: "center" }}>
                                {/* <StarRatings
                                    rating={serviceRating}
                                    starHoverColor="#f8e71c"
                                    starRatedColor="#f8e71c"
                                    changeRating={(val) => setServiceRating(val)}
                                    svgIconPath={StarPath}
                                /> */}
                                <RatingAtom
                                    initialValue={serviceRating}
                                    readonly={false}
                                    // size={17}
                                    fillColor='#f8e71c'
                                    onClick={(val) => setServiceRating(val)}
                                />
                            </Grid>
                            <Grid md={12} style={{ height: "110px" }}>
                                <TextFieldInput
                                    multiline={true}
                                    className='notes'
                                    placeholder={t("AddDiagnosisModal.remarks")}
                                    name='ServiceRemarks'
                                    id='ServiceRemarks'
                                    rows={3}
                                    inputProps={{
                                        style: {
                                            position: 'absolute',
                                            top: '10px',
                                            width: '95%'
                                        }
                                    }}
                                    value={serviceRemarks}
                                    onchange={(e) => setServiceRemarks(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container md={12}>
                            <Grid md={12} style={{ textAlign: "center" }}>
                                <Typography className="cairo-font mb-20px" component="p" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                    {serviceType === 'clinic_service' ? `${t("UserAppointments.clinic_review")} - ${parentSpName}` : `${t("UserAppointments.hospital_review")} - ${parentSpName}`}
                                </Typography>
                            </Grid>
                            <Grid md={12} style={{ textAlign: "center" }}>
                                {/* <StarRatings
                                    rating={instituteRating}
                                    starHoverColor="#f8e71c"
                                    starRatedColor="#f8e71c"
                                    changeRating={(val) => setInstituteRating(val)}
                                    svgIconPath={StarPath}
                                /> */}
                                    <RatingAtom
                                        initialValue={instituteRating}
                                        readonly={false}
                                        // size={17}
                                        fillColor='#f8e71c'
                                        onClick={(val) => setInstituteRating(val)}
                                    />
                            </Grid>
                            <Grid md={12} style={{ height: "110px" }}>
                                <TextFieldInput
                                    multiline={true}
                                    className='notes'
                                    placeholder={t("AddDiagnosisModal.remarks")}
                                    name='instituteRemarks'
                                    id='instituteRemarks'
                                    rows={3}
                                    inputProps={{
                                        style: {
                                            position: 'absolute',
                                            top: '10px',
                                            width: '95%'
                                        }
                                    }}
                                    value={instituteRemarks}
                                    onchange={(e) => setInstituteRemarks(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Divider style={{ width: "100%", margin: "10px 0" }} />
                    <Grid container md={12}>
                        <Grid md={12} style={{ textAlign: "center" }}>
                            <Typography className="cairo-font mb-20px" component="p" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                {`${t("UserAppointments.mp_review")} - ${spName}`}
                            </Typography>
                        </Grid>
                        <Grid md={12} style={{ textAlign: "center" }}>
                            {/* <StarRatings
                                rating={spRating}
                                starHoverColor="#f8e71c"
                                starRatedColor="#f8e71c"
                                changeRating={(val) => setSpRating(val)}
                                svgIconPath={StarPath}
                            /> */}
                            <RatingAtom
                                initialValue={spRating}
                                readonly={false}
                                // size={17}
                                fillColor='#f8e71c'
                                onClick={(val) => setSpRating(val)}
                            />
                        </Grid>
                        <Grid md={12} style={{ height: "110px" }}>
                            <TextFieldInput
                                multiline={true}
                                className='notes'
                                placeholder={t("AddDiagnosisModal.remarks")}
                                name='spRemarks'
                                id='spRemarks'
                                rows={3}
                                inputProps={{
                                    style: {
                                        position: 'absolute',
                                        top: '10px',
                                        width: '95%'
                                    }
                                }}
                                value={spRemarks}
                                onchange={(e) => setSpRemarks(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid md={12} style={{ textAlign: "center" }}>
                        <PrimaryButton
                            text={t("Signup.confirm_btn")}
                            classBtn="primary btn-rounded btn-medium"
                            click={() => SendReview()}
                            disabled={reservationRating === 0 ||
                                serviceRating === 0 ||
                                serviceType === 'telemedicine' ?
                                spRating === 0 :
                                instituteRating === 0
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(ReviewAppointment)
