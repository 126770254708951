import { useState } from 'react'
import { withTranslation } from "react-i18next"
import { Grid, Box, Typography } from "@mui/material"
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

import TabPanel from "../../../Atoms/Tabs/TabPanel"
import NormalTabs from "../../../Atoms/Tabs/NormalTabs"
import { icons } from "../../../../Assets/images/IconHelper"
import LifeStyleTab from "../TabPanels/LifeStyle/LifeStyleTAb"
import DiagnosisTab from "../../../Molecules/PatientProfile/TabPanels/MedicalFile/Diagnosis/DiagnosisTab"
import PrescriptionTab from "../../../Molecules/PatientProfile/TabPanels/MedicalFile/Prescriptions/PrescriptionTab"
import CheckupTab from "../../../Molecules/PatientProfile/TabPanels/MedicalFile/Checkups/CheckupTab"
import PrimaryButton from '../../../Atoms/Buttons/Primary/PrimaryButton'
import SurgicalOperations from '../TabPanels/MedicalFile/SurgicalOperations/SurgicalOperations'
import Allergy from '../TabPanels/MedicalFile/Allergy/Allergy'

function UserActionView(props) {
    const {
        t,
        paginationInfo,
        getDiagnosisApp,
        paginationDiagnosisHistory,
        getDiagnosisHistory,
        paginationPrescriptionApp,
        paginationPrescriptionHistory,
        getPrescriptionApp,
        getPrescriptionHistory,
        analysisListPaginationInfo,
        xrayPaginationInfo,
        getAnalysisList,
        getXrayList,
        surgeriesPaginationInfo,
        getSurgeries,
        diseasesList
    } = props;

    const [filevalue, setFileValue] = useState(true)
    // const [lifeStylevalue, setLifeStyleValue] = useState(false)
    const [medFilevalue, setMedFileValue] = useState(0)
    const lang = localStorage.getItem("lang")

    const handleMedFileValueChange = () => {
        setFileValue(!filevalue)
        // setLifeStyleValue(!lifeStylevalue)
        setMedFileValue(0)
    }

    const handleLifeStyleValueChange = () => {
        // setLifeStyleValue(!lifeStylevalue)
        // setFileValue(!filevalue)
        setMedFileValue(4)
    }

    const handleMedFileChange = (event, newValue) => {
        setMedFileValue(newValue)
    }

    const MedicalFileTabOptions = [
        {
            id: 1,
            label: t("UserProfile.prescriptions"),
            icon: <img src={icons.Diagnostics} alt="diagnostics" />,
        },
        // {
        //     id: 1,
        //     label: t("UserProfile.prescriptions"),
        //     icon: <img src={icons.Prescriptions} alt="Prescriptions" />,
        // },
        {
            id: 2,
            label: t("UserProfile.checkups"),
            icon: <img src={icons.Checkups} alt="Checkups" />,
        },
        {
            id: 3,
            label: t("UserProfile.surgical_operations"),
            icon: <img src={icons.Surgical_operations} alt="Surgical operations" />,
        },
        {
            id: 4,
            label: t("UserProfile.allergies"),
            icon: <img src={icons.Allergies} alt="Allergies" />,
        }
    ]

    return (
        <Grid container >
            <Grid item xs={3}>
                <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }} mt={7} mb={4}>
                    {t("UserProfile.medical_file")}
                </Typography>
                <PrimaryButton
                    text={t("UserProfile.medical_file")}
                    startIcon={<img src={icons.MedicalFile} alt="medical file" style={{ paddingInlineEnd: "10px" }} />}
                    endIcon={filevalue ?
                        <KeyboardArrowUp
                            sx={{
                                color: "var(--primary-color)",
                                paddingInlineStart: lang === 'ar' ? "20px" : '0px'
                            }} /> :
                        <KeyboardArrowDown
                            sx={{
                                color: "var(--primary-color)",
                                paddingInlineStart: lang === 'ar' ? "20px" : '0px'
                            }} />}
                    classBtn={medFilevalue === 4 ? 'medical-file-btn' : 'medical-file-btn-selected'}
                    click={handleMedFileValueChange}
                />
                {filevalue &&
                    <Box >
                        <NormalTabs
                            TabsOptions={MedicalFileTabOptions}
                            value={medFilevalue}
                            change={handleMedFileChange}
                            orientation="vertical"
                            ContainerStyle="toggle-btns-container"
                            TabStyle="toggle-btn mb-20px medical-file-btns"
                        />
                    </Box>
                }
                <Box mt={!filevalue ? 2 : 0}>
                    <PrimaryButton
                        text={t("UserProfile.life_style")}
                        startIcon={<img src={icons.LifeStyle} alt="life style" style={{ paddingInlineEnd: "10px" }} />}
                        classBtn={medFilevalue !== 4 ? 'medical-file-btn' : 'medical-file-btn-selected'}
                        click={handleLifeStyleValueChange}
                    />
                </Box>
            </Grid>
            <Grid item xs={9}>
                {medFilevalue !== 4 ?
                    MedicalFileTabOptions.map((option, index) => (
                        <TabPanel
                            value={medFilevalue}
                            index={index}
                            key={index}>
                            {
                                index === 0 ?
                                    <DiagnosisTab
                                        profileData={props.profileData}
                                        diagnosisList={props.diagnosisList}
                                        open={props.open}
                                        item={props.item}
                                        type={props.type}
                                        openModal={props.openModal}
                                        createDiagnosis={props.createDiagnosis}
                                        removeDiagnosis={props.removeDiagnosis}
                                        editDiagnosis={props.editDiagnosis}
                                        handleClose={props.handleClose}
                                        medicalFileNumber={props.medicalFileNumber}
                                        appDiagnosisList={props.appDiagnosisList}
                                        showDiagnosis={props.showDiagnosis}
                                        idNumber={props.idNumber}
                                        paginationInfo={paginationInfo}
                                        getDiagnosisApp={getDiagnosisApp}
                                        paginationPrescriptionHistory={paginationPrescriptionHistory}
                                        getDiagnosisHistory={getDiagnosisHistory}
                                        diseasesList={diseasesList}
                                        getPdfPrescription={props.getPdfPrescription}
                                        openPrescription={props.openPrescription}
                                        typePrescription={props.typePrescription}
                                        itemPrescription={props.itemPrescription}
                                        prescriptionList={props.prescriptionList}
                                        handleClosePrescription={props.handleClosePrescription}
                                        openPrescriptionModal={props.openPrescriptionModal}
                                        createPrescription={props.createPrescription}
                                        removePrescription={props.removePrescription}
                                        editPrescription={props.editPrescription}
                                        medicineIntakeMethods={props.medicineIntakeMethods}
                                        openPrescriptionDtails={props.openPrescriptionDtails}
                                        openPrescriptionDetailsModal={props.openPrescriptionDetailsModal}
                                        handleClosePrescriptionDetails={props.handleClosePrescriptionDetails}
                                        medicineDosagePeriods={props.medicineDosagePeriods}
                                        getPrescriptionHistory={getPrescriptionHistory}
                                        paginationDiagnosisHistory={paginationDiagnosisHistory}
                                    /> :
                                    // index === 0 ?
                                    //     <PrescriptionTab
                                    //         openPrescription={props.openPrescription}
                                    //         typePrescription={props.typePrescription}
                                    //         itemPrescription={props.itemPrescription}
                                    //         prescriptionList={props.prescriptionList}
                                    //         handleClosePrescription={props.handleClosePrescription}
                                    //         openPrescriptionModal={props.openPrescriptionModal}
                                    //         createPrescription={props.createPrescription}
                                    //         removePrescription={props.removePrescription}
                                    //         editPrescription={props.editPrescription}
                                    //         medicineIntakeMethods={props.medicineIntakeMethods}
                                    //         openPrescriptionDtails={props.openPrescriptionDtails}
                                    //         openPrescriptionDetailsModal={props.openPrescriptionDetailsModal}
                                    //         handleClosePrescriptionDetails={props.handleClosePrescriptionDetails}
                                    //         medicineDosagePeriods={props.medicineDosagePeriods}
                                    //         medicalFileNumber={props.medicalFileNumber}
                                    //         appPrescriptionList={props.appPrescriptionList}
                                    //         showPrescription={props.showPrescription}
                                    //         idNumber={props.idNumber}
                                    //         getPdfPrescription={props.getPdfPrescription}
                                    //         paginationPrescriptionApp={paginationPrescriptionApp}
                                    //         paginationPrescriptionHistory={paginationPrescriptionHistory}
                                    //         getPrescriptionApp={getPrescriptionApp}
                                    //         getPrescriptionHistory={getPrescriptionHistory}
                                    //         diseasesList={diseasesList}
                                    //     /> :
                                    index === 1 ?
                                        <CheckupTab
                                            xrayList={props.xrayList}
                                            analysisList={props.analysisList}
                                            addExaminations={props.addExaminations}
                                            removeExaminations={props.removeExaminations}
                                            getXrayData={props.getXrayData}
                                            getAnalysisData={props.getAnalysisData}
                                            editExaminationDate={props.editExaminationDate}
                                            medicalFileNumber={props.medicalFileNumber}
                                            analysisListPaginationInfo={analysisListPaginationInfo}
                                            xrayPaginationInfo={xrayPaginationInfo}
                                            getAnalysisList={getAnalysisList}
                                            getXrayList={getXrayList}
                                        /> :
                                        index === 2 ?
                                            <SurgicalOperations
                                                t={t}
                                                surgeries={props.surgeries}
                                                removeSurgery={props.removeSurgery}
                                                createSurgery={props.createSurgery}
                                                editSurgery={props.editSurgery}
                                                medicalFileNumber={props.medicalFileNumber}
                                                surgeriesPaginationInfo={surgeriesPaginationInfo}
                                                getSurgeries={getSurgeries}
                                            /> :
                                            <Allergy
                                                t={t}
                                                isAllergic={props.isAllergic}
                                                allergyList={props.allergyList}
                                                createAllergy={props.createAllergy}
                                                removeAllergy={props.removeAllergy}
                                                medicalFileNumber={props.medicalFileNumber}
                                            />
                            }
                        </TabPanel>
                    )) :
                    <LifeStyleTab
                        t={t}
                        questions={props.questions}
                        answers={props.answers}
                        saveAnswers={props.saveAnswers}
                    />
                }
            </Grid>
        </Grid>
    )
}

export default withTranslation()(UserActionView)