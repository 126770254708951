import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { AddMsg, ChatRoom, GetMessages, handleFileInputs, handleFiles, OffDatabaseConnection, SendMessage } from "../../../../Contexts/API/Chatting/chat";
import { SignInFirebase, firebaseSDK } from "../../../../Contexts/Firebase/Firebase";
import { StartSession, websdkready } from "../../../../Contexts/Zoom/ZoomIntegration";
import DrTelemedicineSessionTemplate from "../../../../Templates/Main/Home/TelemedicineSession/DrTelemedicineSessionTemplate";
import { getDoctorMainInfoAPI } from "../../../../Contexts/API/DoctorProfile/mainInfo"
import { getListAnswersAPI } from '../../../../Contexts/API/PatientProfile/LifeStyleApi.js'
import { getListDiagnosisAPI, showDiagnosisAPI, createDiagnosisAPI, editDiagnosisAPI } from '../../../../Contexts/API/PatientProfile/DiagnosisApi.js'
import { getListAllergyAPI } from '../../../../Contexts/API/PatientProfile/AllergyApi.js'
import { getListSurgeryAPI } from '../../../../Contexts/API/PatientProfile/SurgeryApi.js'
import { getListCheckupAPI } from '../../../../Contexts/API/PatientProfile/CheckupApi.js'
import { getListPrescriptionAPI, showPrescriptionAPI } from '../../../../Contexts/API/PatientProfile/PrescriptionApi'
import { getMedicineIntakeMethodsAPI } from "../../../../Contexts/API/Lookups/MedicineIntakeMethods.js"
import { getMedicalTitlesAPI } from "../../../../Contexts/API/Lookups/medicalTitlesList.js"
import { getSpecialitiesAPI } from "../../../../Contexts/API/Lookups/specialitiesList.js"
import { getSubSpecialitiesAPI } from "../../../../Contexts/API/Lookups/subSpecialitiesList.js"
import { getMedicineDurationsAPI } from "../../../../Contexts/API/Lookups/MedicineDurations.js"
import { getSymptomsListAPI } from "../../../../Contexts/API/Lookups/SymptomsList.js"
import { getMedicineDosagePeriodsAPI } from "../../../../Contexts/API/Lookups/MedicineDosagePeriods.js"
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { getDiseasesListAPI } from "../../../../Contexts/API/Lookups/DiseasesList";
import { getMedicinesListAPI } from "../../../../Contexts/API/Lookups/MedicinesList";
import { toast } from "react-toastify";

const DrTelemedicineSession = ({ }) => {
  const { t } = useTranslation()
  const { id } = useParams();  // reservation id from url
  const location = useLocation()
  const [userID, setUserID] = useState(location?.state?.id) //first patient id received from doctor home
  const doctorNumber = location?.state?.suadiNumber  // doctor saudi no. received from doctor home
  const serviceType = location?.state?.serviceType  // service type received from doctor home
  const patientList = location?.state?.patientList  // patients list received from doctor home
  const RealTimeDatabase = firebaseSDK.database(); // for online/offline status
  const [UserStatus, setUserStatus] = useState([]); // for online/offline status
  var NewArr = [];  // for online/offline status
  const [chatRoom, setchatRoom] = useState({})
  const [Chatmessages, setChatmessages] = useState([]);
  const [message, setmessage] = useState('');
  const [Files, setfiles] = useState([]);
  const [FilesToSend, setFilesToSend] = useState([]);
  /* Patient State */
  const [profileData, setProfileData] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [age, setAge] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [idNumber, setIdNumber] = useState('')
  /* LifeStyle states */
  const [answers, setAnswers] = useState([])
  /* Diagnosis States */
  const [diagnosisList, setDiagnosisList] = useState([])
  const [appDiagnosisList, setAppDiagnosisList] = useState([])
  const [paginationInfo, setPaginationInfo] = useState({})
  const [paginationDiagnosisHistory, setPaginationDiagnosisHistory] = useState({})
  /* Allergy States */
  const [isAllergic, setIsAllergic] = useState(false)
  const [allergyList, setAllergyList] = useState([])
  /* Surgery state */
  const [surgeries, setSurgeries] = useState([])
  const [surgeriesPaginationInfo, setSurgeriesPaginationInfo] = useState({})
  /* checkups states */
  const [xrayList, setXrayList] = useState([])
  const [analysisList, setAnalysisList] = useState([])
  const [xrayPaginationInfo, setXrayPaginationInfo] = useState({})
  const [analysisListPaginationInfo, setAnalysisListPaginationInfo] = useState({})
  /* Prescription States */
  const [prescriptionList, setPrescriptionList] = useState([])
  const [appPrescriptionList, setAppPrescriptionList] = useState([])
  const [paginationPrescriptionApp, setPaginationPrescriptionApp] = useState({})
  const [paginationPrescriptionHistory, setPaginationPrescriptionHistory] = useState({})
  /* Medicine Intake Methods State*/
  const [medicineIntakeMethods, setMedicineIntakeMethods] = useState([])
  /* Medicine Durations State*/
  const [medicineDurations, setMedicineDurations] = useState([])
  /* Symptoms State*/
  const [symptoms, setSymptoms] = useState([])
  /* Medical Titles State*/
  const [medicalTitles, setMedicalTitles] = useState([])
  /* Speciality List State*/
  const [specialityList, setSpecialityList] = useState([])
  /* Medicine Dosage Periods List State*/
  const [medicineDosagePeriods, setMedicineDosagePeriods] = useState([])

  /* Medicines List State*/
  const [medicinesList, setMedicinesList] = useState([])

  const [openLoader, setOpenLoader] = useState(false);
  const [diseasesList, setDiseasesList] = useState([]);

  const startsession = async () => {
    let parent = document.getElementById("zoom_part");
    parent.appendChild(document.getElementById("zmmtg-root"))
    document.getElementById("zmmtg-root").classList.add("zoom-show");
    document.getElementById("zmmtg-root").classList.add("zoom-show")
    let res = await StartSession(Number(id));
    if (res) {
      websdkready(res?.res?.data?.session_details?.meeting_id, res?.res?.data?.session_details?.meeting_password, res?.res?.data?.session_details?.current_user?.name, 1);
    }
  }

  const getChatRoom = async () => {

    let { res, err } = await ChatRoom(id);
    if (res) {
      let url = new URL(res?.data?.chat_room?.messages_url)
      setchatRoom(res?.data?.chat_room)
      localStorage.setItem('MESSAGE_NODE', url.pathname.slice(1))
      SignInFirebase();
      setChatmessages([]);
    }
  }
  const getMessages = async () => {

    await GetMessages(localStorage.getItem('MESSAGE_NODE'))
    window.addEventListener('getmessage', async (evt) => {
      setChatmessages(evt.data);
    });

  }
  const sendMessage = async () => {

    if (message != "" || FilesToSend?.length > 0) {
      const msg_type = Files.length === 0 ? "text_msg" : "media_msg"
      await SendMessage(message, FilesToSend, localStorage.getItem('userId'), 'doctor')
      let { err, res } = await AddMsg(id, {
        message: message,
        message_type: msg_type,
        media_type: "photo",
        payload: FilesToSend[0]?.url
      })

      if (res) {
        setmessage("")
        setfiles([])
        setFilesToSend([])
        getMessages()
      }
    }
  }

  const handleFileInput = ({ target: { files } }) => {
    let result = handleFileInputs(Files, files)
    setfiles(result)
  }

  // const handleFileRemove = (index) => {
  //   let result = handleFileRemoves(Files, index)
  //   setfiles([...result])
  // }

  /* Get Patient Personal File Data */
  const getProfileData = async () => {
    const res = await getDoctorMainInfoAPI(userID)
    if (res) {
      const info = res?.data?.profile
      setProfileData(info)
      setPhoneNumber(info?.phone_number)
      setCountryCode(info?.country_code)
      setAge(info?.age?.number + " " +
        `${info?.age.period === 'year' ? t("TelemedicineSettings.year") :
          info?.age.period === 'month' ? t("TelemedicineSettings.month") :
            info?.age.period === 'day' ? t("TelemedicineSettings.day") : ""
        }`)
      setBirthDate(info?.birth_date)
      setIdNumber(info?.personal_id_number)
    }
  }

  /* Get Patient Life Style Data */
  const getLifeStyleAnswers = async () => {
    const res = await getListAnswersAPI(userID)
    if (res) setAnswers(res?.data?.lifestyle)
  }

  /* Get Patient Diagnosis data */
  const getDiagnosisData = async (filterObj) => {
    const res = await getListDiagnosisAPI(userID, "history", filterObj)
    if (res) setDiagnosisList(res?.data?.diagnosis)
    setPaginationDiagnosisHistory(res?.extra)
  }

  const getAppDiagnosisData = async (filterObj) => {
    const res = await getListDiagnosisAPI(userID, "app", filterObj)
    if (res) setAppDiagnosisList(res?.data?.diagnosis)
    setPaginationInfo(res?.extra)
  }

  const showDiagnosis = async (id) => {
    const res = await showDiagnosisAPI(id, userID)
    if (res) return res.data.diagnosis
  }

  const createDiagnosis = async (data) => {
    const {res,err} = await createDiagnosisAPI(data, userID)
    if (res) getAppDiagnosisData({ page_number: 1, page_size: 10 })
    if(err) toast.error(err)
  }

  const editDiagnosis = async (id, data) => {
    const { res, err } = await editDiagnosisAPI(id, data, userID)
    if (res) getAppDiagnosisData({ page_number: 1, page_size: 10 })
    if(err) toast.error(err)
  }

  /* Get Patient Allergy data */
  const getAllergyData = async () => {
    const res = await getListAllergyAPI(userID)
    if (res) {
      setIsAllergic(res?.data?.user?.is_allergic)
      setAllergyList(res?.data?.user?.user_allergies)
    }
  }

  /* Get Patient Xray data */
  const getXrayData = async (filterObj) => {
    const res = await getListCheckupAPI(userID, 'xray', filterObj)
    if (res) setXrayList(res?.data?.examination)
    setXrayPaginationInfo(res?.extra)
  }

  /* Get Patient Analysis data */
  const getAnalysisData = async (filterObj) => {
    const res = await getListCheckupAPI(userID, 'analysis', filterObj)
    if (res) setAnalysisList(res?.data?.examination)
    setAnalysisListPaginationInfo(res?.extra)
  }

  /* Surgeries API operations */
  const getSurgeriesData = async (filterObj) => {
    const res = await getListSurgeryAPI(userID, filterObj)
    if (res) setSurgeries(res?.data?.surgeries)
    setSurgeriesPaginationInfo(res?.extra)
  }

  /* Prescription API operations */
  const showPrescription = async (id) => {
    const res = await showPrescriptionAPI(id, userID)
    if (res) return res.data.prescription
  }

  const getPrescriptionData = async (filterObj) => {
    const res = await getListPrescriptionAPI(userID, "history", filterObj)
    if (res) setPrescriptionList(res?.data?.prescription)
    setPaginationPrescriptionHistory(res?.extra)
  }

  const getAppPrescriptionData = async (filterObj) => {
    const res = await getListPrescriptionAPI(userID, "app", filterObj)
    if (res) setAppPrescriptionList(res?.data?.prescription)
    setPaginationPrescriptionApp(res?.extra)
  }

  /* Medicine Intake Methods Function */
  const getMedicineIntakeMethods = async () => {
    const res = await getMedicineIntakeMethodsAPI()
    if (res) setMedicineIntakeMethods(res?.data?.medicine_intake_methods)
  }

  /* Medicine Durations Function */
  const getMedicineDurations = async () => {
    const res = await getMedicineDurationsAPI()
    if (res) setMedicineDurations(res?.data?.medicine_durations)
  }

  /* Medicine Dosage Periods Function */
  const getMedicineDosagePeriods = async () => {
    const res = await getMedicineDosagePeriodsAPI()
    if (res) setMedicineDosagePeriods(res?.data?.dosage_periods)
  }

  /* Medicines List Function */
  const getMedicinesList = async () => {
    const res = await getMedicinesListAPI()
    if (res) setMedicinesList(res?.data?.def_medicines)
  }

  /* Medical Titles Function */
  const getMedicalTitles = async () => {
    const res = await getMedicalTitlesAPI()
    if (res) setMedicalTitles(res?.data?.medical_titles)
  }

  /* Speciality List Function */
  const getSpecialityList = async () => {
    const res = await getSpecialitiesAPI()
    if (res) setSpecialityList(res?.data?.specialities)
  }

  /* Sub-Speciality List Function */
  const getSubSpecialityList = async (id) => {
    const res = await getSubSpecialitiesAPI(id)
    if (res) return res?.data?.subspecialities
  }

  /* Symptoms Function */
  const getSymptoms = async () => {
    const res = await getSymptomsListAPI({ body_part_id: "", body_angle: "" })
    if (res) setSymptoms(res?.data?.symptoms)
  }
  // remove image from chat
  const removeImage = () => {
    setfiles([]);
    setFilesToSend([]);
  };

  const getPatientData = () => {
    getProfileData()
    getLifeStyleAnswers()
    getAllergyData()
  };

  const getDiseases = async () => {
    const res = await getDiseasesListAPI({});
    if (res) setDiseasesList(res?.data?.diseases);
  };

  useEffect(() => {
    getPatientData() // call patient apis that depend on patient id with every change in patient id
  }, [userID])

  useEffect(async () => {
    setOpenLoader(true);
    let urls = await handleFiles(Files);
    const FilesArray = await Promise.all(urls);
    setFilesToSend(FilesArray);
    setOpenLoader(false);
  }, [Files])

  // useEffect(async () => {
  //   let urls = await handleFiles(Files)
  //   const FilesArray = await Promise.all(urls)
  //   setFilesToSend(FilesArray)
  // }, [Files])

  useEffect(() => {
    getDiseases()
    startsession();
    getChatRoom();

    getPatientData()  // call patient apis that depend on patient id at starting page

    /* call lookup apis only once */
    getMedicineIntakeMethods()
    getMedicineDurations()
    getSymptoms()
    getMedicalTitles()
    getSpecialityList()
    getMedicineDosagePeriods()
    getMedicinesList()

    setTimeout(() => {
      if (localStorage.getItem('MESSAGE_NODE')) getMessages();
    }, 3000);
    return () => {
      OffDatabaseConnection(localStorage.getItem('MESSAGE_NODE'));
      window.removeEventListener('getmessage', () => { console.log('event off') });
    };
  }, [])

  useEffect(() => {
    patientList?.map((patient) => {
      const userUrl = patient?.patient_profile?.firebase_node_url
      const userNode = new URL(userUrl).pathname.slice(1);
      var statusObj = RealTimeDatabase.ref(userNode + "/status")
      statusObj.on('value', (snapshot) => {
        NewArr[userNode] = (snapshot.val());
        setUserStatus(NewArr);
      });
    })
  }, [patientList]);

  return (
    <>
      <Loader open={openLoader} />
      <DrTelemedicineSessionTemplate
        chatRoom={chatRoom}
        Chatmessages={Chatmessages}
        setmessage={setmessage}
        message={message}
        sendMessage={sendMessage}
        handleFileInput={handleFileInput}
        profileData={profileData}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        age={age}
        birthDate={birthDate}
        answers={answers}
        diagnosisList={diagnosisList}
        isAllergic={isAllergic}
        allergyList={allergyList}
        surgeries={surgeries}
        xrayList={xrayList}
        analysisList={analysisList}
        prescriptionList={prescriptionList}
        showPrescription={showPrescription}
        idNumber={idNumber}
        medicineIntakeMethods={medicineIntakeMethods}
        appPrescriptionList={appPrescriptionList}
        medicineDurations={medicineDurations}
        appDiagnosisList={appDiagnosisList}
        showDiagnosis={showDiagnosis}
        symptoms={symptoms}
        doctorNumber={doctorNumber}
        medicalTitles={medicalTitles}
        specialityList={specialityList}
        getSubSpecialityList={getSubSpecialityList}
        createDiagnosis={createDiagnosis}
        medicineDosagePeriods={medicineDosagePeriods}
        editDiagnosis={editDiagnosis}
        reservationId={id}
        Files={Files}
        removeImage={removeImage}
        serviceType={serviceType}
        list={patientList}
        setUserID={setUserID}
        paginationInfo={paginationInfo}
        getDiagnosisApp={getAppDiagnosisData}
        paginationDiagnosisHistory={paginationDiagnosisHistory}
        getDiagnosisHistory={getDiagnosisData}
        paginationPrescriptionApp={paginationPrescriptionApp}
        paginationPrescriptionHistory={paginationPrescriptionHistory}
        getPrescriptionApp={getAppPrescriptionData}
        getPrescriptionHistory={getPrescriptionData}
        analysisListPaginationInfo={analysisListPaginationInfo}
        xrayPaginationInfo={xrayPaginationInfo}
        getAnalysisList={getAnalysisData}
        getXrayList={getXrayData}
        surgeriesPaginationInfo={surgeriesPaginationInfo}
        getSurgeries={getSurgeriesData}
        diseasesList={diseasesList}
        medicinesList={medicinesList}
        UserStatus={UserStatus}
      />
    </>
  );
};

export default DrTelemedicineSession;
