import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import "./ProfilePanels.css";
import ActionsView from "../../Molecules/DoctorProfile/ActionsPanels/ActionsView";

const ActionsPanel = (props) => {
  const { t, getUserReview, userReview, paginationInfo, diseasesList } = props;
  return (
    <Grid container className="profile-actions-container" style={{marginBottom: "80px"}}>
      <Grid item xs></Grid>
      <Grid 
        container 
        xl={7}
        lg={9.5}
        md={11}
        sm={12}
        xs={12} 
        className="actions-container" 
      >
        <ActionsView 
          getUserReview={getUserReview} 
          userReview={userReview} 
          paginationInfo={paginationInfo}
          diseasesList={diseasesList}
        />
      </Grid>
      <Grid item xs></Grid>
    </Grid>
    
  );
};

export default withTranslation()(ActionsPanel);
