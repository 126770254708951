import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function getMedicinesList() {
    return await axiosFn("get", URL.MEDICINES)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getMedicinesListAPI() {
    let { err, res } = await getMedicinesList()
    if (res) return res
    if (err) return err
}
