import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import Placeholder from "../../../Atoms/Placeholder/Placeholder";
import { images } from "../../../../Assets/images/IconHelper";
import AppointmentCards from "./AppointmentCards";

function AppointmentsList(props) {
    const { t, list, appointmentsType, serviceType, openInvoice, openReview, openDiagnosis } = props;
    
    return (
        <Grid container>
            {list?.length === 0 ? (
                <Placeholder
                    src={images.EmptyPlaceholder}
                    alt="Empty Appointments"
                    mainTitle={t("Placeholders.no_appointments")}
                    txtContainer="text-container"
                />
            ) : (
                <AppointmentCards 
                    serviceType={serviceType} 
                    appointmentsType={appointmentsType} 
                    list={list}
                    openInvoice={openInvoice}
                    openReview={openReview}
                    openDiagnosis={openDiagnosis}
                />
            )}
        </Grid>
    );
  }
  
export default withTranslation()(AppointmentsList);