import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import TherapyList from "./List/TherapyList";
import { useState } from "react"
import CustomTablePagination from "../../../../Atoms/Pagination/Pagination";
import { getGroupSessionList } from "../../../../../Contexts/API/DoctorProfile/groupSession";

function GroupTherapyList(props) {
    const { t, paginationInfo, getGroupSession } = props;
    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const updateView = (flag, action, data) => {
        props.updateView(flag, action, data);
    }
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    useEffect(() => {
        getGroupSession('group_session', filterObj)
    }, [filterObj])
    return (
        <Grid container>
            <Grid 
                xs={12}
                justifyContent="flex-end" 
                alignItems="baseline"
                style={{textAlign:"end"}} 
            >
                <PrimaryButton
                    classBtn="appointment-btn mb-20px"
                    text={t("DoctorProfile.add_session")}
                    click={() => updateView(true, '')}
                />
            </Grid>
            <Grid container>
                <TherapyList 
                    updateView={(flag, action, data) => updateView(flag, action, data)} 
                    list={props.list}
                />
            </Grid>
            {/* {paginationInfo?.total_count !== 0 && (
                <CustomTablePagination
                    totalCount={paginationInfo?.total_count}
                    changePage={(val) => paginationChangePage(val)}
                    handleRowsPerPage={(val) => handleRowsPerPage(val)}
                    t={t} 
                />
            )} */}
        </Grid>
    );
}

export default withTranslation()(GroupTherapyList);