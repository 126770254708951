import { Grid, Typography } from "@mui/material"

function PatientLifeStyle(props) {
    return (
        <Grid container
            style={{
                borderRadius: '12px',
                boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.1)'
            }}
            alignItems="center"
        >
            <Grid item xs={12} className="profile-wallet-title grey-bg" style={{
                paddingInlineStart: '25px'
            }}>
                <h4>{props.t("DoctorTelemedicine.patientLifestyle")}</h4>
            </Grid>
            <Grid item xs={12} container className="profile-wallet-body" style={{
                height: '360px',
                backgroundColor: 'white',
                padding: '25px',
                overflowY: 'auto'
            }}>
                {props.answers?.map((answer, i) => {
                    return <Grid item xs={6} key={i}>
                        <Typography variant='subtitle1' sx={{
                            color: 'var(--secondary-color)'
                        }}>
                            {answer?.question}
                        </Typography>
                        <Typography variant='subtitle1' sx={{
                            fontWeight: 'bold'
                        }}>
                            {answer?.user_answer !== null ? answer?.user_answer?.lifestyle_option_value : props.t('LifeStyle.no_answer')}
                        </Typography>
                    </Grid>
                })}
            </Grid>
        </Grid>
    )
}

export default PatientLifeStyle