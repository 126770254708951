import React, { useEffect, useState } from "react";
import SignupTemplate from "../../Templates/Auth/Signup.Template.jsx";
import { SignUpAPI } from "../../Contexts/API/Auth/Signup";
import { ValidateAPI } from "../../Contexts/API/Auth/Validate";
import { getMedicalTitlesAPI } from "../../Contexts/API/Lookups/medicalTitlesList";
import { getSpecialitiesAPI } from "../../Contexts/API/Lookups/specialitiesList";
import { getSubSpecialitiesAPI } from "../../Contexts/API/Lookups/subSpecialitiesList";
import { getPrivacyPolicyAPI } from '../../Contexts/API/SystemLookups/PrivacyPolicyApi.js'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getPrivacyPolicyTermsConditionsAPI } from "../../Contexts/API/SystemLookups/Terms&Conditions";

const MedicalSignup = (props) => {
  const [policy, setPolicy] = useState('')
  const [terms, setTerms] = useState('')
  const [mode, setMode] = useState("Doctor");
  const [medicalTitles, setMedicalTitles] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [subSpecialities, setSubSpecialities] = useState([]);
  const navigate = useNavigate();

  const handleRegister = async (data) => {
    // 1- Validate
    // 2- Register
    const res = await ValidateAPI(data);
    if (res.res && localStorage.getItem("SignupStep") == 2) {
      const userData = await SignUpAPI(data);
      if (userData.res) {
        localStorage.setItem("TOKEN", userData?.res?.data?.extra?.access_token);
        localStorage.setItem("FirebaseTOKEN", userData?.res?.data?.extra?.firebase_token)
        localStorage.setItem("userId", userData?.res?.data?.user?.id);
        localStorage.setItem("avatar", userData?.res?.data?.user?.avatar);
        localStorage.setItem("name", userData?.res?.data?.user?.name);
        localStorage.setItem("userType", userData?.res?.data?.user?.user_type);
        localStorage.setItem("OTP",userData?.res?.data?.user?.verification_code);
        localStorage.setItem("country_code",userData?.res?.data?.user?.country_code);
        localStorage.setItem("phone_number",userData?.res?.data?.user?.phone_number);
        localStorage.setItem("password",data?.user?.password);
        navigate("/verify");
      }
    } else {
      toast.error(res.err);
    }
  };

  const handleSpecialityChange = async (val) => {
    const res = await getSubSpecialitiesAPI(val);
    if (res) setSubSpecialities(res?.data?.subspecialities)
  };

  const getMedicalTitles = async () => {
    const res = await getMedicalTitlesAPI();
    if (res) {
      setMedicalTitles(res?.data?.medical_titles);
    }
  };

  const getSpecialities = async () => {
    const res = await getSpecialitiesAPI();
    if (res) {
      setSpecialities(res?.data?.specialities);
    }
  };

  const callApi = async () => {
    const res = await getPrivacyPolicyAPI()
    if (res) setPolicy(res)
  }

  const getTerms = async () => {
    const res = await getPrivacyPolicyTermsConditionsAPI()
    if (res) setTerms(res)
  }

  useEffect(() => {
    getMedicalTitles();
    getSpecialities();
    callApi()
    getTerms()
  }, []);

  return (
    <SignupTemplate
      mode={mode}
      setMode={setMode}
      handleSubmit={handleRegister}
      medicalTitlesList={medicalTitles}
      specialities={specialities}
      subSpecialities={subSpecialities}
      handleSpecialityChange={(val) => handleSpecialityChange(val)}
      policy={policy}
      terms={terms}
    />
  );
};

export default MedicalSignup;
