import { useState } from "react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import OfferCard from '../../../Components/Molecules/Offers/Cards/OfferCard'
import { images } from '../../../Assets/images/IconHelper'
import CustomPopup from '../../../Components/Organisms/Modals/CustomPopup/CustomPopup'
import Loader from "../../../Components/Atoms/Loader/Loader"
import CustomTablePagination from "../../../Components/Atoms/Pagination/Pagination"
import Placeholder from "../../../Components/Atoms/Placeholder/Placeholder"
import { ConvertToArabicNumbers } from "../../../Contexts/API/Helper/Helper"
import moment from "moment"

const OffersTemplate = ({ offersList, checkOffer, openLoader, totalCount, filterObj, setFilterObj }) => {
    
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const lang = localStorage.getItem("lang")
    
    const navigate = useNavigate()

    const handleClose = () => setOpen(false)

    const btnClick = async (id, type) => {
        const res = await checkOffer(id)
        if (!res?.is_active) setOpen(true)
        else {
            type === 'personal_telemedicine' ? navigate('/user/home/main/telemedicine') :
                type === 'group_session' ? navigate('/user/home/main/telemedicine', { state: { serviceType: 'groubTherapy' } }) :
                    type === 'hospital_service' ? navigate('/user/home/main/hospital') :
                        navigate('/user/home/main/clinic')
        }
    }

    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    }

    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    }

    return (
        <Grid container>
            <Loader open={openLoader} />
            <Grid item xs></Grid>
            <Grid
                item
                xl={7}
                lg={9.5}
                md={11}
                xs={12}
                className="tp-bt-padding main-container"
            >
                <Grid container>
                    <Typography variant='h5'>
                        {t('Offers.offers')}
                    </Typography>
                </Grid>
                <Grid container>
                    {offersList?.length > 0 && offersList?.map((offer) => {

                        const image = offer?.service_type === 'personal_telemedicine' ? images.TelemedicineService : offer?.service_type === 'hospital_service' ? images.HospitalService : offer?.service_type === 'group_session' ? images.GroupSession : images.ClinicService

                        const type = offer?.service_type === 'personal_telemedicine' ? t('Offers.telemedicine') : offer?.service_type === 'hospital_service' ? t('Offers.hospital') : offer?.service_type === 'group_session' ? t('Offers.group_session') : t('Offers.clinic')

                        return (
                            <OfferCard
                                t={t}
                                upperText={`${t('Offers.discount')} ${lang === 'en' ? offer?.discount_percentage : ConvertToArabicNumbers(offer?.discount_percentage)} %`}
                                middleText={type}
                                lowerText={`${t('Offers.valid')} ${lang === 'en' ? moment(offer?.expiration_date).locale('en').format("DD/MM/YYYY") : moment(offer?.expiration_date).locale('ar').format("YYYY/MM/DD")}`}
                                imgAlt='Offer Image'
                                imgSrc={image}
                                btnText={t("UserProfile.more")}
                                btnClass='primary btn-rounded offers-more-btn'
                                btnClick={() => btnClick(offer?.id, offer?.service_type)}
                            />
                        )
                    })}
                    {offersList?.length === 0 && openLoader === false &&
                        <Placeholder
                            src={images.EmptyPlaceholder}
                            alt="Empty Appointments"
                            mainTitle={t("Placeholders.no_offers")}
                            txtContainer="text-container"
                        />
                    }
                </Grid>
                {totalCount > 10 && (
                    <CustomTablePagination
                        totalCount={totalCount}
                        changePage={(val) => paginationChangePage(val)}
                        handleRowsPerPage={(val) => handleRowsPerPage(val)}
                        t={t}
                    />
                )}
            </Grid>
            <Grid item xs></Grid>
            <CustomPopup
                open={open}
                handleClose={handleClose}
                src={images.OfferEnded}
                btnText={t('CustomPopup.ok')}
                title={t('Offers.offer_ended')}
                text={t('Offers.offer_ended_text')}
                titleClass='title-color-secondary'
            />
        </Grid>
    )
}

export default OffersTemplate