import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import PrimaryButton from "../Buttons/Primary/PrimaryButton";

function CallStatus(props) {
  const { t, joinToCall } = props;
  return (
    <Grid container md={12} style={{margin: "auto"}} className="call-status-bar">
        <Grid container sm={6}>
            <Grid xs={1}>
                <PhoneIcon className={localStorage.getItem("lang") === 'ar' ? 'call-rotate-icon' : ''}/>
            </Grid>
            <Grid xs={11}>
                <Typography component="p" style={{lineHeight: "2"}}>
                    {t("call.you_can_join")}
                </Typography>
            </Grid>
        </Grid>
        <Grid sm={6} style={{textAlign: "end"}}>
            <PrimaryButton
                classBtn="call-status-btn small-font h-30px"
                text={t("call.join_now")}
                click={joinToCall}
            />
        </Grid>
    </Grid>
  );
}

export default withTranslation()(CallStatus);