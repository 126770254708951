import React from "react";
import { Route, BrowserRouter, Routes as Switch, Navigate } from "react-router-dom";
import Main from "./Pages/Main/Main.Page.jsx";
import Signin from "./Pages/Auth/Signin.page.jsx";
import Signup from "./Pages/Auth/Singup.page.jsx";
import MedicalSignup from "./Pages/Auth/MedicalSignup.page.jsx";
import Verification from "./Pages/Auth/Verification.page.jsx";
import ForgotPassword from "./Pages/Auth/ForgotPassword.page.jsx";
import ResetPasswordPage from "./Pages/Auth/ResetPassword.Page.jsx";
import ChangePasswordPage from "./Pages/Auth/ChangePassword.Page.jsx";
import MainTemplate from "./Templates/Main/Main.template";
import Footer from "./Components/Organisms/Layout/Footer";
import Confirmation from "./Pages/Auth/Confirmation.page.jsx";
import SessionCallModal from "./Components/Organisms/Modals/IncomingCall/SessionCallModal";
import StartEarlyCall from "./Components/Organisms/Modals/IncomingCall/StartEarlyCall";

import { t } from "i18next";
import ChooseAccountPage from "./Pages/Auth/ChooseAccount.Page.jsx";
import DeleteAccountPage from "./Pages/Auth/DeleteAccount.Page.jsx";
import { Provider } from "react-redux";
import { store } from "./services/store.js";

const Routes = (props) => {
  const isAuth = localStorage.getItem("TOKEN");
  const {
    call,
    reservationID,
    setreservationID,
    RejectCall,
    updateCallStatus,
    acceptCall,
    openStartEarly,
    closeStartEarly,
    acceptEarlyCall,
    rejectEarlyCall,
  } = props;
  const rejectCall = () => {
    RejectCall();
    setreservationID("");
  };
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route
              path="*"
              element={localStorage.getItem("TOKEN") ? <Main updateCallStatus={updateCallStatus} /> : <Navigate to="/login" />}
            />

            <Route path="/" exact element={<Main updateCallStatus={updateCallStatus} />} />
            <Route path="/reset-password" exact element={<ResetPasswordPage t={t} />} />
            <Route path="/change-password" exact element={<ChangePasswordPage />} />
            {!isAuth && (
              <>
                <Route path="/delete-account" exact element={<DeleteAccountPage />} />
                <Route path="/login" exact element={<Signin />} />
                <Route path="/register" exact element={<Signup />} />
                <Route path="/medical-register" exact element={<MedicalSignup />} />
                <Route path="/verify" exact element={<Verification t={t} />} />
                <Route path="/forgot-password" exact element={<ForgotPassword t={t} />} />
                <Route path="/confirmation-page" exact element={<Confirmation />} />
                <Route path="/choose-account" exact element={<ChooseAccountPage t={t} />} />
              </>
            )}
          </Switch>
        </BrowserRouter>
      </Provider>
      <SessionCallModal
        open={reservationID ? true : false}
        handleClose={() => rejectCall()}
        acceptCall={() => {
          window.location.href = "/user/home/telemedicine/telemedicine_session/" + reservationID;
          acceptCall();
        }}
        callData={call}
        t={t}
      />
      <StartEarlyCall
        t={t}
        open={openStartEarly}
        handleClose={closeStartEarly}
        acceptEarlyCall={() => acceptEarlyCall()}
        rejectEarlyCall={() => rejectEarlyCall()}
      />
      <Footer />
    </>
  );
};

export default Routes;
