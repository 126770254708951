import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import "./ProfilePanels.css";
import { images } from "../../../Assets/images/IconHelper";

const WalletPanel = (props) => {
  const { t, walletAmount } = props;
  return (
    <Grid 
      container 
      className="profile-wallet-container"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} className="profile-wallet-title grey-bg">
        <h4>{t("AccountMenu.my_wallet")}</h4>
      </Grid>
      <Grid item xs={12} className="profile-wallet-body mb-20px">
        <div className="coins-body">
          <img src={images.coins} alt=""/>
        </div>
        <div className="txt-body">
          <p className="mb-20px">{t("DoctorProfile.my_balance")}</p>
          <h4> {walletAmount} {t("TelemedicineSettings.sar")}</h4>
        </div>
      </Grid>
      
    </Grid>
  );
};

export default withTranslation()(WalletPanel);
