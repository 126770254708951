import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { images } from "../../../../Assets/images/IconHelper";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Placeholder from "../../../Atoms/Placeholder/Placeholder";
// import StarRatings from "react-star-ratings";
import RatingAtom from "../../../Atoms/Rating/Rating";

function MedicalProviderList(props) {
    const { t, medicalProvidersList } = props;
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const navigateToResultPage = (medical_provider_id, name) => {
        navigate('/user/home/main/' + pathnameArr[4] + '/medical-provider-search/result-page/list?' + 'id=' + medical_provider_id + '&' + searchParams + '&name=' + name);
    };

    return (
        <Grid container className="medical-provider-container" >
            {medicalProvidersList?.length === 0 ? (
                <Placeholder
                    src={images.EmptyPlaceholder}
                    alt="Empty List"
                    mainTitle={t("TelemedicineSettings.use_other_result")}
                    txtContainer="text-container"
                />
            ) : (
                <>
                    {medicalProvidersList?.map((provider) => (
                        <Grid container className="medical-provider-item mb-20px">
                            <Grid container md={11} style={{ margin: "auto" }}>
                                <Grid xs={2}>
                                    <img className="doctor-image-search" src={provider?.avatar === null || provider?.avatar === "" ? images.MedicalEntity : provider?.avatar} alt="" />
                                </Grid>
                                <Grid xs={8} className="current-panel-info">
                                    <Typography style={{ color: "var(--secondary-color)" }} component="h1">
                                        {provider.name}
                                    </Typography>
                                    <Typography component="p" className="medical-location-container mb-10px">
                                        <LocationOnIcon /> {provider.city && provider.country && `${provider.city} - ${provider.country}`}
                                    </Typography>
                                    {provider.distance_to_user &&
                                        <Typography component="p">
                                            {(provider.distance_to_user / 1000).toFixed(1) + t("Clinics.km")}
                                        </Typography>
                                    }
                                    <Grid xs={12} className="rate-container">
                                        {/* <StarRatings
                                            rating={provider?.avg_rating == null ? 0 : provider?.avg_rating}
                                            starRatedColor="#fcb862"
                                            starSpacing="1px"
                                        /> */}
                                        <RatingAtom
                                            initialValue={provider?.avg_rating == null ? 0 : provider?.avg_rating}
                                            readonly={true}
                                            size={17}
                                            fillColor='#fcb862'
                                        />
                                    </Grid>
                                </Grid>
                                <Grid xs={2} style={{ lineHeight: "7", textAlign: "center" }}>
                                    <PrimaryButton
                                        text={t("TelemedicineSettings.choose")}
                                        classBtn="appointment-btn h-30px"
                                        style={{ minWidth: "85px" }}
                                        click={() => navigateToResultPage(provider.medical_provider_id, provider.name)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </>
            )}
        </Grid>
    );
}

export default withTranslation()(MedicalProviderList);