import { TextField, Checkbox, Autocomplete, Grid } from "@mui/material"
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import "./Select.css"

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

function MultiSelect({
    items,
    inputLabel,
    title,
    value,
    onchange,
    id,
    name,
    className,
    disabled,
    labelStyle
}) {
    return (
        <Autocomplete
            disabled={disabled}
            multiple
            id={id}
            name={name}
            options={items}
            disableCloseOnSelect
            className={className + ' multi-s'}
            fullWidth
            value={value}
            onChange={onchange}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ direction: localStorage.getItem("lang") === 'en' ? "ltr" : "rtl" }}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <Grid style={{position: "relative"}}>
                    {inputLabel && (
                        <span className={labelStyle}>{inputLabel}</span>
                    )}
                    <TextField {...params} placeholder={title} />
                </Grid>
                
            )}
        />
    );
}

export default MultiSelect;
