import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import "./AccountMenu.css";
import { Link } from "react-router-dom";
import { icons } from "../../../../Assets/images/IconHelper";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomPopup from "../../../Organisms/Modals/CustomPopup/CustomPopup";
import { images } from "../../../../Assets/images/IconHelper";
import { LogoutAPI } from '../../../../Contexts/API/Auth/Logout';
// import { toast } from 'react-toastify';

function AccountMenu(props) {
  const { t, UserAvatar, UserName } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const open = Boolean(anchorEl);
  // const UserAvatar = localStorage.getItem("avatar");
  // const UserName = localStorage.getItem("name");
  const lang = localStorage.getItem("lang");
  const accounts = JSON.parse(localStorage.getItem("Accounts"));
  const [arrowAnimation, setArrowAnimation] = React.useState("")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowAnimation("arrow-up");
  };
  const handleClose = () => {
    setAnchorEl(null);
    setArrowAnimation("arrow-down");
  };
  
  const handleLogout = () => {
    if(accounts){
      const id = localStorage.getItem("userId")
      const newArray = accounts.filter(account => account.userId !== parseInt(id))
      const res = LogoutAPI()
      if (res) {
        localStorage.setItem("TOKEN", newArray[0].token);
        localStorage.setItem("FirebaseTOKEN", newArray[0].firebase_token)
        localStorage.setItem("userId", newArray[0].userId);
        localStorage.setItem("avatar", newArray[0].avatar);
        localStorage.setItem("name", newArray[0].name);
        localStorage.setItem("email", newArray[0].email);
        localStorage.setItem("userType", newArray[0].user_type);
        localStorage.setItem("readPopup", true);
        let url = new URL(newArray[0].node_url)
        localStorage.setItem("UserNode", url.pathname.slice(1));
        window.location.href = "/"
        localStorage.removeItem("Accounts")
      }
    }else{
      const res = LogoutAPI()
      if (res) {
        const lang = localStorage.getItem("lang");
        localStorage.clear();
        localStorage.setItem("lang", lang)
        window.location.href = "/login"
      }
    }
  };

  const DoctorMenuList = [
    {
      icon: <img src={icons.AvatarIcon} alt="" />,
      text: t("AccountMenu.my_account"),
      link: "doctor/profile",
      dir: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
    },
    {
      icon: <img src={icons.WalletIcon} alt="" />,
      text: t("AccountMenu.my_wallet"),
      link: "wallet",
    },
    {
      icon: <img src={icons.SettingsIcon} alt="" />,
      text: t("AccountMenu.account_settings"),
      link: "/doctor/settings",
    },
    {
      icon: <img src={icons.PrivacyIcon} alt="" />,
      text: t("AccountMenu.Privacy"),
      link: "/doctor/privacy-policy",
    },
  ];

  const UserMenuList = [
    {
      icon: <img src={icons.AvatarIcon} alt="profile" />,
      text: t("AccountMenu.my_account"),
      link: "user/profile",
      dir: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
    },
    {
      icon: <img src={icons.WalletIcon} alt="" />,
      text: t("AccountMenu.my_wallet"),
      link: "wallet",
    },
    {
      icon: <img src={icons.SettingsIcon} alt="settings" />,
      text: t("AccountMenu.account_settings"),
      link: "/user/settings",
    },
    {
      icon: <img src={icons.PrivacyIcon} alt="privacy policy" />,
      text: t("AccountMenu.Privacy"),
      link: "/user/privacy-policy",
    },
  ];

  const AccountMenuList = localStorage.getItem("userType") === "medical_provider" ? DoctorMenuList : UserMenuList;

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={lang === 'ar' ? 'إعدادت الحساب' : 'Account settings'}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className="avatar-icon-btn"
          >
            <Avatar sx={{ width: 32, height: 32 }} src={UserAvatar} />
            {/* {UserAvatar === null || UserAvatar === "" ? UserName?.slice(0, 1) : <img style={{ width: "100%", height: "100%" }} src={UserAvatar} />}
            </Avatar> */}
          </IconButton>
        </Tooltip>
        <Typography
          onClick={handleClick}
          className="account-menu-name"
          sx={{
            minWidth: 100,
            color: "var(--secondary-color)",
            fontWeight: "bold"
          }}
        >
          {UserName?.length > 15 ? `${UserName?.slice(0, 14)} ...` : UserName}
        </Typography>
        <KeyboardArrowDownIcon
          className={"menu-arrow " + arrowAnimation}
          sx={{ cursor: 'pointer' }}
          onClick={handleClick}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              borderRadius: 'var(--lg-radius)',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        style={{ direction: AccountMenuList[0].dir }}
      >
        {AccountMenuList.map((item, i) => (
          <div key={i}>
            <Grid container>
              <Link to={item.link}>
                <MenuItem
                  style={{
                    minWidth: "250px",
                    textAlign: "start",
                    minHeight: "45px"
                  }}
                >
                  {item.icon}
                  <Typography
                    style={{
                      color: "var(--font-color)",
                      margin: "0px 10px",
                      fontFamily: "Cairo"
                    }}
                    component="p"
                  >
                    {item.text}
                  </Typography>
                </MenuItem>
              </Link>
            </Grid>
            <Divider />
          </div>
        ))}
        <MenuItem
          style={{
            minWidth: "250px",
            textAlign: "start",
            minHeight: "45px"
          }}
          onClick={() => setOpenPopup(true)}
        >
          <img src={icons.LogoutIcon} alt="" />
          <Typography
            style={{
              color: "var(--font-color)",
              margin: "0px 10px",
              fontFamily: "Cairo"
            }}
            component="p"
          >
            {t("AccountMenu.log_out")}
          </Typography>
        </MenuItem>
      </Menu>
      <CustomPopup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        src={images.LogoutImage}
        t={t}
        title={t("CustomPopup.logoutTitle")}
        isAction={true}
        btnActionText={t("CustomPopup.yes_logout")}
        btnCloseText={t("CustomPopup.No")}
        btnActionStyle="primary"
        btnCloseStyle="settings-cancel-btn"
        handleAction={() => handleLogout()}
      />
    </React.Fragment>
  );
}

export default withTranslation()(AccountMenu);