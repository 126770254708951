import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function getPrivacyPolicy() {
    return await axiosFn("get", URL.PRIVACY_POLICY)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getPrivacyPolicyAPI() {
    let { err, res } = await getPrivacyPolicy()

    if (res) return res

    if (err) return err
}
