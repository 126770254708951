import TelemedicineDoctorProfileTemplate from "../../../Templates/Main/Home/TelemedicineDoctorProfile.template.jsx";
import { useState, useEffect } from "react";
import { showDoctorAPI, getDoctorTimeSlotsAPI, createReservationAPI } from "../../../Contexts/API/Home/userHome";
import { useSearchParams, useLocation } from "react-router-dom";
import { convertTimeFormate, ConvertToArabicNumbers } from "../../../Contexts/API/Helper/Helper";
import { GetWalletInfoAPI, getPackagesAPI, subscripeToPackageAPI } from "../../../Contexts/API/Wallet/Wallet";
import { getPaymentInfoAPI } from "../../../Contexts/API/Payment/Payment";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const TelemedicineDoctorProfilePage = (props) => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const lang = localStorage.getItem("lang")
    const pathnameArr = location.pathname.split('/');
    const [telemedicineDoctorProfile, setTelemedicineDoctorProfile] = useState({});
    const [duration, setDuration] = useState("");
    const [fees, setFees] = useState("");
    const [feesAfterDiscount, setFeesAfterDiscount] = useState("");
    const [timeSlots, setTimeSlots] = useState([]);
    const [medicalServices, setMedicalServices] = useState([]);
    const [walletInfo, setWalletInfo] = useState({});
    const [packages, setPackages] = useState([]);
    const [paymentInfo, setPaymentInfo] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const [openSuccessPage, setOpenSuccessPage] = useState(false);
    const [succesPageData, setSuccessPageData] = useState({});
    const [openFaildPopup, setOpenFaildPopup] = useState(false);
    const [acceptOnlinePayment, setOnlinePament] = useState(false);
    const [offers, setOffers] = useState([])

    const getWallet = async () => {
        setOpenLoader(true);
        const res = await GetWalletInfoAPI();
        if (res) setWalletInfo(res?.data);
        setOpenLoader(false);
    };
    const getPackages = async () => {
        const res = await getPackagesAPI();
        if (res) setPackages(res?.data?.packages);
    };
    const getDoctorProfile = async () => {
        setOpenLoader(true);
        var service_type
        if (pathnameArr[3] === 'telemedicine') {
            if (pathnameArr[4] === 'private-doctor-search') service_type = 'personal_telemedicine';
            else service_type = 'group_session';
        } else if (pathnameArr[3] === 'clinic') {
            service_type = 'clinic_service';
        } else if (pathnameArr[3] === 'hospital') {
            service_type = 'hospital_service';
        }
        const filter_obj = {
            medical_provider_type: 'doctor',
            service_type: service_type,
            date: searchParams.get("date")
        }
        const res = await showDoctorAPI(searchParams.get("id"), filter_obj);
        if (res) {
            setTelemedicineDoctorProfile(res?.data?.medical_provider);
            setDuration(res?.data?.medical_provider?.medical_services[0]?.duration);
            setFees(res?.data?.medical_provider?.medical_services[0]?.fees_SAR);
            setFeesAfterDiscount(res?.data?.medical_provider?.medical_services[0]?.fees_after_discount);
            setMedicalServices(res?.data?.medical_provider?.medical_services);
            setOnlinePament(res?.data?.medical_provider?.medical_services[0]?.accept_online_payments)
        }
        setOpenLoader(false);
    };

    const getTimeSlots = async (id, filterObj) => {
        setOpenLoader(true);
        const res = await getDoctorTimeSlotsAPI(id, filterObj);
        if (res) {
            if (pathnameArr[3] === 'telemedicine') {
                setTimeSlots(res?.data?.time_intervals);
            } else {
                const from = localStorage.getItem("lang") === 'en' ? ' from ' : ' من ';
                const to = localStorage.getItem("lang") === 'en' ? ' to ' : ' إلي ';
                const arr = res?.data?.time_intervals.map((time) => {
                    return { name: from + convertTimeFormate(time.start_time) + to + convertTimeFormate(time.end_time), id: time.id }
                })
                setTimeSlots(arr);
            }
        }
        setOpenLoader(false);
    };

    const createReservation = async (id) => {
        setOpenLoader(true);
        const data = {
            "reservation": {
                "date": searchParams.get("date"),
                "source": localStorage.getItem("paymentType")
            }
        }
        const filterObj = {
            offer_id: localStorage.getItem("offerId") || ''
        }
        let { res, err } = await createReservationAPI(id, data, filterObj);
        if (res) {
            localStorage.removeItem("serviceId");
            localStorage.removeItem("paymentType");
            localStorage.removeItem("offerId");
            setOpenSuccessPage(true);
            setSuccessPageData(res?.data?.reservation);
        }
        if (err) { toast.error(err) }
        setOpenLoader(false);
    };
    const gePaymentInfo = async (id, offer) => {
        localStorage.setItem("serviceId", id);
        localStorage.setItem("offerId", medicalServices?.[0]?.available_offers?.[offer - 1]?.id);
        setOpenLoader(true);
        const filterObj = {
            time_interval_id: id,
            offer_id: medicalServices?.[0]?.available_offers?.[offer - 1]?.id || ''
        }
        const res = await getPaymentInfoAPI(filterObj);
        if (res) setPaymentInfo(res?.data);
        document.getElementById("amount").value = res?.data?.amount * 100;
        document.getElementById("publishable_key").value = res?.data?.publishable_key;
        window.myFunction();
        setOpenLoader(false);
    };
    const subscripePackage = async (id) => {
        const res = await subscripeToPackageAPI(id);
        if (res) {
            localStorage.removeItem("packageId");
            searchParams.delete('status');
            setSearchParams(searchParams);
            getWallet();
        }
    }
    useEffect(() => {
        if (searchParams.get("status")) {
            if (searchParams.get("status") === "paid" && localStorage.getItem("paymentType") === "online_payment") {
                createReservation(localStorage.getItem("serviceId"));
            } else if (searchParams.get("status") === "paid" && localStorage.getItem("paymentType") === "wallet") {
                subscripePackage(localStorage.getItem("packageId"))
            } else {
                setOpenFaildPopup(true);
                searchParams.delete('status');
                setSearchParams(searchParams);
            }
        }
    }, [searchParams])

    useEffect(() => {
        getDoctorProfile();
        getWallet();
        getPackages();
    }, []);

    useEffect(() => {
        const array = []
        array.push({
            name: t("Offers.not_offers"),
            id: 0,
            value: 0
        })
        var service_type
        if (pathnameArr[3] === 'telemedicine') {
            if (pathnameArr[4] === 'private-doctor-search') service_type = t("Login.discount_telemedicine");
            else service_type = t("Login.discount_group");
        } else if (pathnameArr[3] === 'clinic') {
            service_type = t("Login.discount_clinic");
        } else if (pathnameArr[3] === 'hospital') {
            service_type = t("Login.discount_hospital");
        }
        medicalServices?.[0]?.available_offers?.forEach((offer, i) => {
            array.push({
                name: `${lang === 'en' ? offer?.discount_percentage : ConvertToArabicNumbers(offer?.discount_percentage)} % ${service_type}`,
                id: i + 1,
                value: offer?.discount_percentage
            })
        })
        setOffers(array)
    }, [medicalServices]);

    return (
        <>
            <Loader open={openLoader} />
            <TelemedicineDoctorProfileTemplate
                telemedicineDoctorProfile={telemedicineDoctorProfile}
                duration={duration}
                fees={fees}
                getTimeSlots={(id, filterObj) => getTimeSlots(id, filterObj)}
                timeSlots={timeSlots}
                gePaymentInfo={(id, offer) => gePaymentInfo(id, offer)}
                medicalServices={medicalServices}
                walletInfo={walletInfo}
                packages={packages}
                paymentInfo={paymentInfo}
                openSuccessPage={openSuccessPage}
                succesPageData={succesPageData}
                openFaildPopup={openFaildPopup}
                handleCloseFaildPopup={() => setOpenFaildPopup(false)}
                createReservation={createReservation}
                acceptOnlinePayment={acceptOnlinePayment}
                offers={offers}
                feesAfterDiscount={feesAfterDiscount}
                setFeesAfterDiscount={setFeesAfterDiscount}
            />
        </>
    )
};

export default TelemedicineDoctorProfilePage;