import { useState, useEffect } from "react"
import { Grid, Typography, Dialog, DialogActions } from "@mui/material"
import ReactCodeInput from "react-verification-code-input"
import Countdown from "react-countdown"
import { toast } from "react-toastify"
import CancelIcon from '@mui/icons-material/Cancel'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import { GetVerificationOTP } from "../../../../Contexts/API/Auth/GetVerificationOTP"

const ChangePhoneModal = ({ t, open, handleClose, countryCode, verifyOTP, verifyPhone }) => {

    const [countdown, setcountdown] = useState(true)
    const [OTP, setOTP] = useState("")
    const phone = localStorage.getItem("PHONE")

    const verify = async () => {
        const { err, res } = await verifyOTP({
            verification_code: OTP, //otp value in digits
            user: {
                country_code: countryCode,
                phone_number: phone,
            }
        })
        if (res) {
            const token = res?.data
            localStorage.setItem("VERIFY", token)
            const result = await verifyPhone({
                user: {
                    country_code: countryCode,
                    phone_number: phone,
                }
            })
            if (result) {
                toast.success(result.res.message)
                localStorage.removeItem("VERIFY")
                localStorage.removeItem("PHONE")
                handleClose()
            }
        } else if (err) toast.error(err)
    }

    const resend_code = async () => {
        let { err, res } = await GetVerificationOTP({
            user: {
                country_code: countryCode,
                phone_number: phone
            }
        })
        if (res) toast.success(t("Signup.sent"))
        else if (err) toast.error(err)
    }

    useEffect(() => {
        setTimeout(() => {
            if (countdown)
                setcountdown(false)
        }, 120000)
    }, [countdown])

    useEffect(() => {
        setcountdown(true)
        setOTP("")
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    width: '620px',
                    paddingX: '40px'
                }
            }}
            style={{ direction: localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr' }}
        >
            <Grid container>
                <Grid container justifyContent='end'>
                    <PrimaryButton
                        type='icon'
                        startIcon={<CancelIcon sx={{ color: 'var(--primary-color)' }} />}
                        click={handleClose}
                        style={{ height: '26px', width: '26px' }}
                    />
                </Grid>
                <Grid container justifyContent='center' mb={6}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {t("Settings.change_mobile")}
                    </Typography>
                </Grid>
                <Grid container justifyContent='center' mb={4}>
                    <Typography variant='subtitle2'>
                        {t("Settings.phone_change_code")}
                    </Typography>
                </Grid>
                <Grid container justifyContent='center'>
                    <ReactCodeInput
                        className="verification"
                        fieldHeight={70}
                        fieldWidth={70}
                        onChange={(value) => {
                            setOTP(value);
                        }}
                    />
                </Grid>
                <Grid container mt={7} sx={{ marginInlineStart: '80px', marginInlineEnd: '80px' }}>
                    <Grid item xs={6}>
                        <Typography
                            className="verification-link"
                            style={!countdown ? { cursor: 'pointer' } : { opacity: '0.6', cursor: 'not-allowed' }}
                            component="p"
                            onClick={() => {
                                if (!countdown) {
                                    resend_code()
                                    setcountdown(true)
                                }
                            }}
                        >
                            {t("Signup.resend_code")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent={"flex-end"}>
                        <Typography>
                            {countdown && <Countdown
                                date={Date.now() + 120000}
                                renderer={({ minutes, seconds }) => {
                                    return (
                                        <span style={{ color: "black" }}>
                                            {minutes}:{seconds}
                                        </span>
                                    );
                                }}
                            />}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions sx={{
                justifyContent: 'center',
                marginY: '32px'
            }}>
                <PrimaryButton
                    text={t("Signup.confirm_btn")}
                    classBtn='primary btn-rounded btn-medium'
                    click={verify}
                />
            </DialogActions>
        </Dialog >
    )
}

export default ChangePhoneModal