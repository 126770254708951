import { Grid, Typography } from '@mui/material'
import { useState, useEffect } from "react"
import { images } from "../../../../Assets/images/IconHelper"
import PlaceHolder from '../../../Atoms/Placeholder/Placeholder'
import SurgeriesCard from '../Cards/SurgeriesCard'
import CustomTablePagination from "../../../Atoms/Pagination/Pagination";

const SurgeriesPanel = (props) => {
    const { t, surgeriesPaginationInfo, getSurgeries } = props;

    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
    });
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    useEffect(() => {
        getSurgeries(filterObj)
    }, [filterObj])

    return (
        <Grid>
            <Typography mt={4} variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                {t("Surgeries.surgeries")}
            </Typography>
            {props.surgeries?.length !== 0 ?
                props.surgeries?.map((item, i) =>
                    <SurgeriesCard
                        item={item}
                        key={i}
                    />
                )
                :
                <PlaceHolder
                    src={images.EmptyPlaceholder}
                    alt='Empty Box'
                    mainTitle={t("Surgeries.no_surgeries")}
                    subTitle={t("Surgeries.add_surgeries")}
                    txtContainer='text-container'
                />
            }
            {surgeriesPaginationInfo.total_count > 10 && (
                <CustomTablePagination
                    totalCount={surgeriesPaginationInfo.total_count}
                    changePage={(val) => paginationChangePage(val)}
                    handleRowsPerPage={(val) => handleRowsPerPage(val)}
                    t={t} 
                />
            )}
        </Grid>
    )
}

export default SurgeriesPanel