import { Grid, Box, Typography, Divider } from "@mui/material"
import { withTranslation } from "react-i18next"
import { icons, images } from "../../../Assets/images/IconHelper"
import Placeholder from "../../Atoms/Placeholder/Placeholder";
import { convertTimeFormate } from "../../../Contexts/API/Helper/Helper";
import moment from "moment";

function AppointmentDetails(props) {
    const { t, appointmentsList } = props;
    const lang = localStorage.getItem("lang")
    return (
        <>
            {appointmentsList?.length === 0 ? (
                <Placeholder
                    src={images.EmptyPlaceholder}
                    alt="Empty Box"
                    imgStyle={{ width: "85%" }}
                />
            ) : (
                <>
                    {appointmentsList?.map((appointment) => (
                        <>
                            <Box mb={3} px={1.5}>
                                <Typography variant='h6' sx={{
                                    color: "var(--secondary-color)",
                                    fontWeight: 'bold',
                                    textAlign: 'start'
                                }}>
                                    {appointment?.medical_service?.medical_provider?.name}
                                </Typography>
                                <Typography variant='subtitle1' sx={{
                                    textAlign: 'start'
                                }}>
                                    {appointment?.medical_service?.medical_provider?.medical_title} - {appointment?.medical_service?.medical_provider?.speciality}
                                </Typography>
                                <Typography variant='subtitle2' sx={{
                                    textAlign: 'start'
                                }}>
                                    {appointment?.medical_service?.medical_provider?.subspeciality}
                                </Typography>
                                <Grid container mt={1} alignItems='start'>
                                    <Grid container item xs={1}>
                                        <img src={icons.CalendarIcon} alt='Calender Icon' width='16px' />
                                    </Grid>
                                    <Grid container item xs={5}>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                color: "var(--secondary-color)",
                                                fontSize: '12px',
                                                fontWeight: 600,
                                                textAlign: 'start',
                                                marginInlineStart: '4px'
                                            }}>
                                                {t("UserAppointments.date")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                textAlign: 'start',
                                                fontSize: '13px'
                                            }}>
                                                {lang === 'en' ? moment(appointment?.date).locale("en").format("DD-MM-YYYY") : moment(appointment?.date).locale("ar").format("YYYY/MM/DD")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={1}>
                                        <img src={icons.Clock} alt='Time Icon' width='16px' />
                                    </Grid>
                                    <Grid container item xs={5}>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                color: "var(--secondary-color)",
                                                textAlign: 'start',
                                                fontSize: '12px',
                                                fontWeight: 600,
                                                marginInlineStart: '4px'
                                            }}>
                                                {t("UserAppointments.time")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                textAlign: 'start',
                                                fontSize: '13px'
                                            }}>
                                                {t("DoctorProfile.from") + convertTimeFormate(appointment?.time_interval?.start_time) + t("DoctorProfile.to") + convertTimeFormate(appointment?.time_interval?.end_time)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                        </>
                    ))}
                </>
            )
            }
        </>
    )
}

export default withTranslation()(AppointmentDetails)