import UserAppointmentsTemplate from "../../../../Templates/Main/Appointments/UserAppointments.template.jsx";
import { useState } from "react";
import { getAppointmentsListAPI, reviewReservationApi, getDiagnosisListApi } from "../../../../Contexts/API/Appointments/AppointmentsApi";
import Loader from "../../../../Components/Atoms/Loader/Loader"; 
import { useLocation } from "react-router-dom";

const UserAppointmentsPage = () => {
  const location = useLocation()
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [openLoader, setLoader] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({});
  const servType = location?.state?.type

  const getUserAppointments = async (filterObj) => {
    setLoader(true);
    const res = await getAppointmentsListAPI(filterObj);
    setAppointmentsList(res?.data);
    setPaginationInfo(res?.extra);
    setLoader(false);
  };
  
  const SendReviewData = async (data, id) => {
    const res = await reviewReservationApi(data, id);
  };

  const getDiagnosis = async (reservationId, userId) => {
    const res = await getDiagnosisListApi(reservationId, userId);
    if (res) setDiagnosisList(res?.data?.diagnosis_list);
  };

  return(
    <>
      <Loader open={openLoader}/>
      <UserAppointmentsTemplate
        getUserAppointments={getUserAppointments}
        appointmentsList={appointmentsList}
        SendReviewData={SendReviewData}
        getDiagnosis={getDiagnosis}
        diagnosisList={diagnosisList}
        paginationInfo={paginationInfo}
        servType={servType}
      />
    </>
  )
};

export default UserAppointmentsPage;
