import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import NormalTabs from "../../../../Atoms/Tabs/NormalTabs";
import TabPanel from "../../../../Atoms/Tabs/TabPanel";
import DoctorInfoPanel from "../../../../Molecules/Home/UserHome/DoctorInfoPanel";
import { useSearchParams } from "react-router-dom";

const SearchResultTabs = (props) => {
    const { t, tabCallback, ListServivesForPatient, date } = props;
    const [value, setValue] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const TabsOptions = [
        {label: t("TelemedicineSettings.all"), disabled: false},
        {label: t("TelemedicineSettings.male"), disabled: false},
        {label: t("TelemedicineSettings.female"), disabled: false}
    ];
    const changeRouteParam = (value) => {
        if(value === 0) value = "all"
        else if(value === 1) value = "male"
        else if(value === 2) value = "female"
        setSearchParams("doctorGender=" + value + "&id=" + searchParams.get("id") + "&name=" + searchParams.get("name"));
        tabCallback(value);
    };
    const getTabValues = () => {
        const doctorGender = searchParams.get("doctorGender");
        if(doctorGender === "all") setValue(0);
        else if(doctorGender === "male") setValue(1);
        else if(doctorGender === "female") setValue(2);
    };

    useEffect(() => {
        getTabValues();
    }, [value]);
    return ( 
        <Grid container md={12}>
            <Grid
                container
                alignContent="center"
                justifyContent="center" 
                alignItems="center"
            >
                <NormalTabs
                    TabsOptions={TabsOptions} 
                    value={value} 
                    change={handleChange}
                    orientation="horizontal"
                    ContainerStyle="switch-container mb-20px"
                    TabStyle="switch-btn"
                    onclick={(val) => changeRouteParam(val)}
                />
            </Grid>
            <Grid xs={12}>
                {TabsOptions.map((option, index) => (
                    <TabPanel value={value} index={index} className="tab-panel-all" key={index}>
                        {index === 0 && (
                            <DoctorInfoPanel date={date} list={ListServivesForPatient}/>
                        )}
                        {index === 1 && (
                            <DoctorInfoPanel date={date} list={ListServivesForPatient}/>
                        )}
                        {index === 2 && (
                            <DoctorInfoPanel date={date} list={ListServivesForPatient}/>
                        )}
                    </TabPanel>
                ))}
            </Grid>
        </Grid>
    )
};

export default withTranslation()(SearchResultTabs);
