import { useState } from 'react'
import { Chip, Grid, Stack, Typography } from '@mui/material'

import { icons } from "../../../../../../Assets/images/IconHelper"
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton"
import AddEditAllergy from "../../../../../Organisms/Modals/Allergy/AddEditAllergy"

const Allergy = ({ t, isAllergic, allergyList, createAllergy, removeAllergy, medicalFileNumber }) => {

    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)

    return (
        <Grid>
            <Typography mt={4} variant='subtitle1'
                sx={{ fontWeight: 'bold' }}>
                {t("Allergy.allergy")}
            </Typography>
            <Grid container
                mt={5}
                py={2}
                px={3}
                sx={{
                    backgroundColor: "#e2f8fb",
                    borderRadius: '14px'
                }}
            >
                <Grid item mx={2}>
                    <img src={icons.MedFile} alt='Medical File Number' />
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1'>
                        {t("UserProfile.your_medFile_number")}
                    </Typography>
                    <Typography variant='subtitle1'
                        sx={{
                            fontWeight: 'bold',
                            color: 'var(--secondary-color)',
                            lineHeight: '1',
                            letterSpacing: '1.5px'
                        }}>
                        {medicalFileNumber}
                    </Typography>
                </Grid>
            </Grid>
            <Typography mt={6} variant='h6'
                sx={{
                    fontWeight: 'bold',
                    color: '#34a8b9'
                }}>
                {t("Allergy.question")}
            </Typography>
            <Typography mt={1} mb={4} variant='subtitle1'>
                {isAllergic ? t("Allergy.yes") : t("Checkups.no")}
            </Typography>
            <Stack direction="row" sx={{flexWrap:'wrap'}} gap={1}>
                {allergyList?.length > 0 && allergyList?.map((item, i) =>
                    <Chip
                        label={item.name}
                        key={i}
                        sx={{
                            bgcolor: "#55d4e5",
                            color: 'white'
                        }}
                    />
                    // <Grid item xs={2} key={i} sx={{
                    //     marginInlineEnd: '10px',
                    //     marginBottom:'10px'
                    // }}>
                    //     <Typography variant='subtitle1'
                    //         sx={{
                    //             bgcolor: '#55d4e5',
                    //             color: 'white',
                    //             textAlign: 'center',
                    //             borderRadius: '25px'
                    //         }}>
                    //         {item.name}
                    //     </Typography>
                    // </Grid>
                )}
            </Stack>
            <Grid container mt={30} justifyContent='center'>
                <PrimaryButton
                    text={t("DoctorProfile.edit_btn")}
                    classBtn='add-diagnosis-btn'
                    click={() => setOpen(true)}
                />
            </Grid>
            <AddEditAllergy
                t={t}
                open={open}
                handleClose={handleClose}
                isAllergic={isAllergic}
                allergyList={allergyList}
                createAllergy={createAllergy}
                removeAllergy={removeAllergy}
            />
        </Grid>
    )
}

export default Allergy