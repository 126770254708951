import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import "./UserAppointments.css";
import SelectGroup from "../../../Molecules/Appointments/UserAppointments/SelectGroup";
import AppointmentsTabs from "../../../Molecules/Appointments/UserAppointments/AppointmentsTabs";
import { useState, useEffect } from "react";
import InvoiceDetails from "../../Modals/Appointments/InvoiceDetails";
import ReviewAppointment from "../../Modals/Appointments/ReviewAppointment";
import Diagnosis from "../../Modals/Appointments/Diagnosis";
import CustomTablePagination from "../../../Atoms/Pagination/Pagination";
import { useSearchParams } from "react-router-dom";

const UserAppointmentsContainer = (props) => {
    const { t, getUserAppointments, appointmentsList, SendReviewData, getDiagnosis, diagnosisList, paginationInfo, servType } = props;
    const [serviceType, setServiceType] = useState(!servType ? "telemedicine" : servType === "clinic_service" ? "clinic_service" : servType === "hospital_service" ? "hospital_service" : "telemedicine");
    const [inoiceDetailsOpen, setInoiceDetailsOpen] = useState(false);
    const [spName, setSpName] = useState("");
    const [parentSpName, setParentSpName] = useState("");
    const [invoiceData, setInvoiceData] = useState({});
    const [reviewOpen, setReviewOpen] = useState(false);
    const [diagnosisOpen, setDiagnosisOpen] = useState(false);
    const [reservationId, setReservationId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [personalId, setPersonalId] = useState(null);
    const [serviceTypeDisplay, setServiceTypeDisplay] = useState("");
    const [searchParams, setSearchParams] = useSearchParams()

    const openInvoice = (open, invoice_details, sp_name) => {
        setInoiceDetailsOpen(open);
        setInvoiceData(invoice_details);
        setSpName(sp_name);
    };
    const openReview = (open, parent_sp_name, sp_name, reservation_id,service_type_display) => {
        setReviewOpen(open);
        setParentSpName(parent_sp_name);
        setSpName(sp_name);
        setReservationId(reservation_id);
        setServiceTypeDisplay(service_type_display)
    };
    const openDiagnosis = (open, reservation_id, user_id, personal_id_number) => {
        setDiagnosisOpen(open);
        setReservationId(reservation_id);
        setUserId(user_id);
        setPersonalId(personal_id_number);
    };
    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1,
        'list_scope': searchParams.get("type") === 'past' ? 'past' : 'upcoming',
        'service_type': serviceType
    });
    const getServiceType = (serviceType) => {
        setServiceType(serviceType);
        setFilterObj({ ...filterObj, service_type: serviceType, 'page_number': 1, 'page_size': 10 });
    };
    const getTabValue = (tabValue) => {
        setFilterObj({ ...filterObj, list_scope: tabValue, 'page_number': 1, 'page_size': 10 });
    };
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    const updateAppointmentsList = () => {
        setTimeout(() => {
            getUserAppointments(filterObj);
        }, 1000);
    };

    useEffect(() => {
        getUserAppointments(filterObj);
    }, [filterObj]);

    return (
        <Grid container className="user-appointments-container">
            <Grid container className="mb-20px">
                <SelectGroup
                    serviceTypeCallback={(serviceType) => getServiceType(serviceType)}
                    serviceType={serviceType}
                    setServiceType={setServiceType}
                />
            </Grid>
            <Grid container>
                <AppointmentsTabs
                    tabValueCallback={(tabValue) => getTabValue(tabValue)}
                    serviceType={serviceType}
                    appointmentsList={appointmentsList}
                    openInvoice={openInvoice}
                    openReview={openReview}
                    openDiagnosis={openDiagnosis}
                />
            </Grid>
            {paginationInfo?.total_count > 10 && (
                <CustomTablePagination
                    totalCount={paginationInfo?.total_count}
                    changePage={(val) => paginationChangePage(val)}
                    handleRowsPerPage={(val) => handleRowsPerPage(val)}
                    t={t}
                />
            )}
            <InvoiceDetails
                open={inoiceDetailsOpen}
                handleClose={() => setInoiceDetailsOpen(false)}
                t={t}
                invoiceData={invoiceData}
                spName={spName}
            />
            <ReviewAppointment
                open={reviewOpen}
                handleClose={() => setReviewOpen(false)}
                t={t}
                serviceType={serviceType}
                parentSpName={parentSpName}
                spName={spName}
                SendReviewData={SendReviewData}
                reservationId={reservationId}
                updateAppointmentsList={updateAppointmentsList}
                serviceTypeDisplay={serviceTypeDisplay}
            />
            <Diagnosis
                open={diagnosisOpen}
                handleClose={() => setDiagnosisOpen(false)}
                t={t}
                reservationId={reservationId}
                userId={userId}
                getDiagnosis={getDiagnosis}
                diagnosisList={diagnosisList}
                personalId={personalId}
            />
        </Grid>
    )
};

export default withTranslation()(UserAppointmentsContainer);