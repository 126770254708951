import UserHomeTemplate from "../../../Templates/Main/Home/UserHome.template.jsx";
import { getServiceListForPatientAPI, getMedicalProvidersListAPI, getDoctorsInMedicalEntityAPI, getUSerReservation } from "../../../Contexts/API/Home/userHome";
import { useEffect, useState } from "react";
import { getDoctorMainInfoAPI } from "../../../Contexts/API/DoctorProfile/mainInfo";
import { getMedicalTitlesAPI } from "../../../Contexts/API/Lookups/medicalTitlesList";
import { getSpecialitiesAPI } from "../../../Contexts/API/Lookups/specialitiesList";
import { getSubSpecialitiesAPI } from "../../../Contexts/API/Lookups/subSpecialitiesList";
import { editProfileAPI } from "../../../Contexts/API/DoctorProfile/mainInfo";
import { getAppointmentsListAPI } from "../../../Contexts/API/Appointments/AppointmentsApi";
import { getServiceTypeOffersListAPI } from "../../../Contexts/API/Lookups/ListServiceTypesOffers";
import { getAdsListAPI } from "../../../Contexts/API/Lookups/ListOfAds";
import Loader from "../../../Components/Atoms/Loader/Loader";

const UserHomePage = () => {
  const [ListServivesForPatient, setListServivesForPatient] = useState([]);
  const [medicalProvidersList, setMedicalProvidersList] = useState([]);
  const [profileObj, setProfileObj] = useState({});
  const [medicalTitles, setMedicalTitles] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [subSpecialities, setSubSpecialities] = useState([]);
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [offersList, setOffersList] = useState([]);
  const [adsList, setAdsList] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({});
  const [openLoader, setLoader] = useState(false);

  const getProfileInfo = async () => {
    setLoader(true);
    const res = await getDoctorMainInfoAPI();
    if (res) setProfileObj(res?.data?.profile);
    setLoader(false);
  };
  const getListServivesForPatient = async (serviceType, filterObj) => {
    setLoader(true);
    const res = await getServiceListForPatientAPI(serviceType, filterObj);
    if (res) setListServivesForPatient(res?.data?.medical_providers);
    setPaginationInfo(res?.extra);
    setLoader(false);
  };
  const getDoctorInMedicalEntity = async (serviceType, id, filterObj) => {
    setLoader(true);
    const res = await getDoctorsInMedicalEntityAPI(serviceType, id, filterObj);
    if (res) setListServivesForPatient(res?.data?.medical_providers);
    setPaginationInfo(res?.extra);
    setLoader(false);
  };
  const getListMedicalProviders = async (serviceType, filterObj) => {
    setLoader(true);
    const res = await getMedicalProvidersListAPI(serviceType, filterObj);
    if (res) setMedicalProvidersList(res?.data?.institutes);
    setPaginationInfo(res?.extra);
    setLoader(false);
  };
  const getMedicalTitles = async () => {
    const res = await getMedicalTitlesAPI();
    if (res) {
      setMedicalTitles(res?.data?.medical_titles);
    }
  };
  const getSpecialities = async () => {
    const res = await getSpecialitiesAPI();
    if (res) {
      setSpecialities(res?.data?.specialities);
    }
  };
  const getSubSpecialities = async (id) => {
    const res = await getSubSpecialitiesAPI(id);
    if (res) {
      const arr = res?.data?.subspecialities.map((speciality) => {
        return { label: speciality.name, value: speciality.id }
      });
      setSubSpecialities(arr);
    }
  };
  const updateProfileCallback = async (data) => {
    const user = {
      user: data
    }
    const {res,err}=await editProfileAPI(user);
    return {res,err}
  }
  const getUserAppointments = async (service_type) => {
    var filterObj = {
      'page_size': 3,
      'page_number': 1,
      'list_scope': 'upcoming',
      'service_type': service_type
    };
    let res = await getAppointmentsListAPI(filterObj);
    if (res) setAppointmentsList(res?.data);
  };
  const updateShowPrivacyPopup = async (flag) => {
    const user = {
      user: {
        show_privacy_policy: flag
      }
    }
    const {res,err} = await editProfileAPI(user);
    if (res) getProfileInfo();
  };

  const getOffersList = async () => {
    const res = await getServiceTypeOffersListAPI();
    if (res) {
      setOffersList(res?.data);
    }
  };

  const getAdsList = async () => {
    const res = await getAdsListAPI();
    if (res) {
      setAdsList(res?.data?.advertisements);
    }
  };
  
  useEffect(() => {
    getProfileInfo();
    getMedicalTitles();
    getSpecialities();
    getOffersList()
    getAdsList()
  }, []);
  return (
    <>
      <Loader open={openLoader} />
      <UserHomeTemplate
        getListServivesForPatient={(serviceType, filterObj) => getListServivesForPatient(serviceType, filterObj)}
        getListMedicalProviders={(serviceType, filterObj) => getListMedicalProviders(serviceType, filterObj)}
        getDoctorInMedicalEntity={(serviceType, id, filterObj) => getDoctorInMedicalEntity(serviceType, id, filterObj)}
        ListServivesForPatient={ListServivesForPatient}
        profileObj={profileObj}
        medicalTitles={medicalTitles}
        specialities={specialities}
        updateProfileCallback={updateProfileCallback}
        getSubSpecialities={(id) => getSubSpecialities(id)}
        subSpecialities={subSpecialities}
        medicalProvidersList={medicalProvidersList}
        getProfileData={getProfileInfo}
        getUserAppointments={getUserAppointments}
        appointmentsList={appointmentsList}
        paginationInfo={paginationInfo}
        updateShowPrivacyPopup={updateShowPrivacyPopup}
        offersList={offersList}
        adsList={adsList}
      />
    </>
  )
};

export default UserHomePage;
