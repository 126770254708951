import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import HomeButtons from "../../../Molecules/Home/UserHome/HomeButtons";
import ReservationPanel from "../../../Molecules/Home/UserHome/ReservationPanel";
import AdPanel from "../../../Molecules/Home/UserHome/AdPanel";
import TelemedicinePrivacy from "./TelemedicinePrivacy";
import { useState } from "react";
import "./UserHome.css";

const UserHomeMainPanel = (props) => {
    const { t, profileObj, updateShowPrivacyPopup, offersList, adsList } = props;
    const [open, setOpen] = useState(false);
    return (
        <Grid container>
            <Grid item xs={12} className="mb-30px">
                <Typography
                    component="h1"
                    style={{
                        fontSize: "25px",
                        fontWeight: "bolder"
                    }}
                    className="mb-10px"
                >
                    {t("Home.hi_user") + localStorage.getItem("name")}
                </Typography>
                <Typography
                    component="p"
                    style={{
                        color: "var(--font-color)"
                    }}
                >
                    {t("Home.explore_services")}
                </Typography>
            </Grid>
            <Grid item xs={12} className="mb-30px">
                <HomeButtons
                    profileObj={profileObj}
                    openTelemedicinePrivacy={(open) => setOpen(open)}
                    offersList={offersList}
                />
            </Grid>
            <Grid item xs={12} className="mb-30px">
                <ReservationPanel />
            </Grid>
            <Grid container item xs={12} className="mb-30px" justifyContent='center'>
                <AdPanel 
                    adsList={adsList}
                />
            </Grid>
            <Grid item xs={12} className="mb-30px">
                <ReservationPanel
                    mode="search"
                    title={t("Home.book_clinic")}
                    type="clinic"
                />
            </Grid>
            <Grid item xs={12} className="mb-30px">
                <ReservationPanel
                    mode="search"
                    title={t("Home.book_hospital")}
                    type="hospital"
                />
            </Grid>
            <Grid container>
                <TelemedicinePrivacy
                    handleClose={(open) => setOpen(open)}
                    open={open}
                    updateShowPrivacyPopup={updateShowPrivacyPopup}
                />
            </Grid>
        </Grid>
    )
};

export default withTranslation()(UserHomeMainPanel);