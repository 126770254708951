import { Grid } from "@mui/material";
import ChatBox from "../../../../Components/Organisms/Chat/ChatBox";
import DoctorInfo from "../../../../Components/Molecules/Appointments/ChatHistory/DoctorInfo";

const ChatHistoryTemplate = ({handleFileInput,message,Chatmessages,sendMessage,setmessage, medicalProvider  }) => {


    return (
        <Grid style={{ height: '100vh', width: '100vw' }}>
            <Grid container className="grey-bg tp-bt-padding main-container">
                <Grid 
                    xl={7.5}
                    lg={9.5}
                    md={11}
                    sm={12}
                    xs={12}  
                    container 
                    spacing={3} 
                    className="mb-20px" 
                    style={{margin: "auto"}}
                >
                    <Grid item md={7}>
                        <Grid
                            container
                            className="profile-wallet-container"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <ChatBox 
                                    message={message} 
                                    Chatmessages={Chatmessages} 
                                    sendMessage={sendMessage} 
                                    setmessage={setmessage}
                                    handleFileInput={handleFileInput}
                                    history={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={5}>
                        <Grid className="profile-wallet-container">
                            <DoctorInfo medicalProvider={medicalProvider}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChatHistoryTemplate;
