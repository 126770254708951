import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function resetPasswordOTP(recivedData, filter) {
  const data = { ...recivedData };
  
  return await axiosFn("post", URL.GET_PASSWORD_RESET_OTP + makeFilterString(filter), data)
    .then(async (res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getResetPasswordOTP(data,filter) {
  let { res, err } = await resetPasswordOTP(data,filter);
  return { res, err };
}
