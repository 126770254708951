import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import SelectBox from "../../../../Atoms/Select/Select";
import { Duration } from "../../../../../Contexts/API/Helper/Helper";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../../../Atoms/Loader/Loader";
import { useSearchParams, useLocation } from "react-router-dom";
import RadioButtonsGroup from "../../../../Atoms/Input/Radio/RadioInput";
import { convertTimeFormate } from "../../../../../Contexts/API/Helper/Helper";

function AppointementsPanel(props) {
    const { t, getTimeSlots, timeSlots, gePaymentInfo, acceptOnlinePayment, createReservationDirect, telemedicineDoctorProfile, offers, offer, setOffer, setFeesAfterDiscount, fees } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [intervalValue, setIntervalValue] = useState('AM');
    const [timeSlotValue, setTimeSlotValue] = useState("");
    const [openLoader, setLoader] = useState(false);
    const [activeClass, setActiveClass] = useState('');
    const [intervalId, setIntervalId] = useState('');
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const [paymentValue, setPaymentValue] = useState(2);
    
    var service_type
    if (pathnameArr[3] === 'telemedicine') {
        if (pathnameArr[4] === 'private-doctor-search') service_type = 'personal_telemedicine';
        else service_type = 'group_session';
    } else if (pathnameArr[3] === 'clinic') {
        service_type = 'clinic_service';
    } else if (pathnameArr[3] === 'hospital') {
        service_type = 'hospital_service';
    }
    const [filterObj, setFilterObj] = useState({
        'medical_provider_type': 'doctor',
        'service_type': service_type,
        'date': searchParams.get("date"),
        'period': (pathnameArr[3] === 'clinic' || pathnameArr[3] === 'hospital') ? '' : intervalValue
    });

    const getdurationValue = (e) => {
        setActiveClass('')
        setIntervalId('');
        setIntervalValue(e.target.value);
        setFilterObj({ ...filterObj, period: e.target.value });
    };
    const getTimeSlotValue = (e) => {
        setTimeSlotValue(e.target.value);
        setIntervalId(e.target.value);
    };
    const getOfferValue = (e) => {
        if (intervalId === '') toast.error(t("Reservation.interval_first"))
        else {
            setOffer(e.target.value);
            const newPrice = fees - (fees / 100 * offers[e.target.value].value)
            setFeesAfterDiscount(newPrice)
            gePaymentInfo(intervalId, e.target.value);
        }
    };
    const selectTime = (i, id) => {
        setActiveClass(i);
        setIntervalId(id);
    };
    const paymentOptions = [
        { label: t("Clinics.pay_now"), value: 1 },
        { label: t("Clinics.pay_in_institution"), value: 2 },
    ];
    const choosePaymentType = (id) => {
        if (pathnameArr[3] === 'clinic' || pathnameArr[3] === 'hospital') {
            if (paymentValue === '1') {
                gePaymentInfo(id, offer);
            } else {
                localStorage.setItem("offerId", telemedicineDoctorProfile?.medical_services?.[0]?.available_offers?.[offer - 1]?.id)
                createReservationDirect(id);
            }
        } else {
            gePaymentInfo(id, offer);
        }
    };
    useEffect(() => {
        getTimeSlots(searchParams.get("id"), filterObj);
    }, [filterObj, searchParams]);

    return (
        <Grid container style={{ background: "#fff" }} className="edit-settings-container">
            <Loader open={openLoader} />
            {pathnameArr[3] === 'telemedicine' ? (
                <Grid container>
                    <Grid xs={12}>
                        <div className="select-box">
                            <SelectBox
                                id="interval"
                                name="interval"
                                items={Duration}
                                title={t("DoctorProfile.duration")}
                                label={t("DoctorProfile.duration")}
                                labelStyle="select-label"
                                value={intervalValue}
                                onchange={(e) => getdurationValue(e)}
                            />
                        </div>
                    </Grid>
                    <Grid
                        container
                        className="appointments-options-container"
                    >
                        {timeSlots?.map((item, index) => (
                            <Grid xs={4} className="mb-20px" key={item?.id}>
                                <PrimaryButton
                                    classBtn={`${"appointment-option-profile"} ${activeClass === index ? "active-appointment" : ''}`}
                                    text={t("DoctorProfile.from") + convertTimeFormate(item.start_time) + t("DoctorProfile.to") + convertTimeFormate(item.end_time)}
                                    click={() => selectTime(index, item.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {telemedicineDoctorProfile?.medical_services?.[0]?.has_offers &&
                        <Grid xs={12} mt={5}>
                            <div className="select-box">
                                <SelectBox
                                    id="offers"
                                    name="offers"
                                    items={offers}
                                    // title={t("Offers.offer")}
                                    label={t("Offers.offer")}
                                    labelStyle="select-label"
                                    value={offer}
                                    onchange={(e) => getOfferValue(e)}
                                />
                            </div>
                        </Grid>}
                </Grid>
            ) : (
                <Grid container>
                    <Grid xs={12}>
                        <div className="select-box">
                            <SelectBox
                                id="interval"
                                name="interval"
                                items={timeSlots}
                                title={t("DoctorProfile.choose_appointment")}
                                labelStyle="select-label"
                                value={timeSlotValue}
                                onchange={(e) => getTimeSlotValue(e)}
                            />
                        </div>
                    </Grid>
                    {acceptOnlinePayment && (
                        <Grid xs={12}>
                            <RadioButtonsGroup
                                formLabel={t("TelemedicineSettings.paying_options")}
                                options={paymentOptions}
                                row={false}
                                containerStyle={{ width: "100%" }}
                                value={paymentValue}
                                Accordion={false}
                                change={(val) => setPaymentValue(val)}
                            />
                        </Grid>
                    )}
                    {telemedicineDoctorProfile?.medical_services?.[0]?.has_offers &&
                        <Grid xs={12} mt={5}>
                            <div className="select-box">
                                <SelectBox
                                    id="offers"
                                    name="offers"
                                    items={offers}
                                    // title={t("Offers.offer")}
                                    label={t("Offers.offer")}
                                    labelStyle="select-label"
                                    value={offer}
                                    onchange={(e) => getOfferValue(e)}
                                />
                            </div>
                        </Grid>}
                </Grid>
            )}
            <Grid container style={{ marginTop: "20px" }}>
                <Grid xs={5.5} style={{ margin: "auto" }}>
                    <PrimaryButton
                        classBtn="settings-edit-btn"
                        text={t("TelemedicineSettings.book")}
                        click={() => choosePaymentType(intervalId)}
                        disabled={intervalId === ''}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(AppointementsPanel);