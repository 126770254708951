import { Grid, Typography } from '@mui/material'

const Allergy = ({ t, isAllergic, allergyList }) => {

    return (
        <Grid>
            <Typography mt={4} variant='subtitle1'
                sx={{ fontWeight: 'bold' }}>
                {t("DoctorTelemedicine.allergy_history")}
            </Typography>
            <Typography mt={2} variant='h6'
                sx={{
                    fontWeight: 'bold',
                    color: '#34a8b9'
                }}>
                {t("Allergy.question")}
            </Typography>
            <Typography mt={1} mb={4} variant='subtitle1'>
                {isAllergic ? t("Allergy.yes") : t("Checkups.no")}
            </Typography>
            <Grid container>
                {allergyList?.length > 0 && allergyList?.map((item, i) =>
                    <Grid item xs={2} key={i} sx={{
                        marginInlineEnd: '10px',
                        marginBottom: '10px'
                    }}>
                        <Typography variant='subtitle1'
                            sx={{
                                bgcolor: '#55d4e5',
                                color: 'white',
                                textAlign: 'center',
                                borderRadius: '25px'
                            }}>
                            {item.name}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default Allergy