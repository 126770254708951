import { Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import { images } from "../../Assets/images/IconHelper";
import ReactCodeInput from "react-verification-code-input";
import "./Verification.css";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useState, useEffect } from "react";

const ResetPasswordTemplate = ({ handleSubmit, t, setOTP, i18n, countdown, resend_code, setcountdown }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(
    { date: Date.now(), delay: 120000 }
  );
  const wantedDelay = 120000;
  //[START] componentDidMount
  //Code runs only one time after each reloading
  useEffect(() => {
    const savedDate = localStorage.getItem("reset_end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear our saved end date
        if (localStorage.getItem("reset_end_date").length > 0)
          localStorage.removeItem("reset_end_date");
      } else {
        //No update the end date  
        setData({ date: currentTime, delay: delta });
      }
    }
  }, []);
  //[END] componentDidMount
  return (
    <Grid container spacing={0}>
      <Grid item xs>
        <Grid
          container
          style={{ marginTop: "30px" }}
          justifyContent={"flex-end"}
        >
          <PrimaryButton
            btnType="icon"
            startIcon={i18n.language === "ar" ? <ArrowForwardOutlinedIcon /> : <ArrowBackOutlinedIcon />}
            click={() => {
              navigate("/forgot-password");
              if (localStorage.getItem("reset_end_date")) localStorage.removeItem("reset_end_date");
            }}
          />
        </Grid>
      </Grid>
      <Grid item md={6} className="Auth_container">
        <h1 style={{ color: "#161616", fontSize: "26px" }}>
          {t("Signup.change_password")}
        </h1>
        <div>
          <img src={images.verificationLogo} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            style={{
              width: "452px",
            }}
            className="verification-text"
          >
            {t("Signup.enter_code")}
          </Typography>
        </div>
        <Grid
          container
          className="Auth_account"
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <ReactCodeInput
              className="verification"
              fieldHeight={70}
              fieldWidth={70}
              onChange={(value) => {
                setOTP(value);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: 420, marginTop: "15px" }}>
            <Grid container spacing={0}>
              <Grid item xs={6} container justifyContent={"flex-start"}>
                <Typography
                  className="verification-link"
                  style={!countdown ? { cursor: 'pointer' } : { opacity: '0.6', cursor: 'not-allowed' }}
                  component="p"
                  onClick={() => {
                    if (!countdown) {
                      resend_code()
                      setcountdown(true)
                      setData({ date: Date.now(), delay: 120000 });
                    }
                  }}
                >
                  {t("Signup.resend_code")}
                </Typography>
              </Grid>
              <Grid item xs={6} container justifyContent={"flex-end"}>
                <Typography>
                  {countdown && <Countdown
                    autoStart={countdown}
                    date={data.date + data.delay}
                    renderer={({ minutes, seconds, completed }) => {
                      if (completed) {
                        setcountdown(false)
                        return ""
                      } else {
                        return (
                          <span style={{ color: "black" }}>
                            {minutes}:{seconds}
                          </span>
                        );
                      }
                    }}
                    onStart={() => {
                      //Save the end date
                      if (localStorage.getItem("reset_end_date") == null)
                        localStorage.setItem(
                          "reset_end_date",
                          JSON.stringify(data.date + data.delay)
                        );
                    }}
                    onComplete={() => {
                      if (localStorage.getItem("reset_end_date") != null)
                        localStorage.removeItem("reset_end_date");
                    }} />
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "50px" }}>
            <PrimaryButton
              text={t("Signup.confirm_btn")}
              classBtn="primary btn-rounded btn-medium"
              click={() => {
                handleSubmit();
              }}
            // disabled={!phonee || !passwordd}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs></Grid>
    </Grid>
  );
};

export default withTranslation()(ResetPasswordTemplate);
