import React, { useRef } from "react";
import "./FileInput.css";

function FileInput (props) {
    const hiddenFileInput = useRef(null);
    return (
        <div className={props.btnStyle}>
            {props.icon && (
                <img 
                    onClick={() => hiddenFileInput?.current?.click()}
                    className={props.iconStyle+" file-input"} 
                    src={props.icon} 
                />
            )}
            {props.uploadText && (
                <span className={props.textStyle}>
                    {props.uploadText}
                </span>
            )}
            <input 
                id={props.id}
                ref={hiddenFileInput}
                hidden
                type="file"
                onChange={props.change}
                className="file-input"
                accept={props.accept}
            />
        </div>
    );
}

export default FileInput;