import { useState, useEffect } from "react";
import { Grid, Divider, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import ClearIcon from '@mui/icons-material/Clear';
import { images } from '../../../../Assets/images/IconHelper';

function PatientsList(props) {
    const { t, open, handleClose, patientsList, UserStatus } = props;
    
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={600}
            PaperProps={{
                sx: {
                    borderRadius: "15px",
                    height: 'auto',
                    width: "600px",
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogTitle>
                <Grid container>
                    <Grid md={6} justifyContent="start">
                        <Typography className="cairo-font" component="h1" style={{fontSize: '20px', fontWeight: "bold"}}>{t("TelemedicineSettings.patients_list")}</Typography>
                    </Grid>
                    <Grid md={6} style={{textAlign: "end"}}>
                        <PrimaryButton
                            startIcon={<ClearIcon/>}
                            btnType="icon"
                            classBtn="clear-icon"
                            click={handleClose}
                        />
                    </Grid>
                    <Divider style={{width: "100%", margin: "10px 0"}}/>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    {patientsList?.map((item) => (
                        <>
                            <Grid container md={12}>
                                <Grid sm={1.5} sx={{ position: 'relative' }}>
                                    <img 
                                        style={{
                                            width: '50px', 
                                            height: '50px', 
                                            borderRadius: '50%',
                                            border: '1px solid var(--secondary-color)'
                                        }} 
                                        src={item?.patient_profile?.avatar === "" || item?.patient_profile?.avatar === null ? images.userAvatar : item?.patient_profile?.avatar}
                                    />
                                    <span className={UserStatus[new URL(item?.patient_profile?.firebase_node_url).pathname.slice(1)] == 'online' ? 'user-status active' : 'user-status offline'} style={{ left: '15px', bottom: '3px' }}></span>
                                </Grid>
                                <Grid sm={10.5}>
                                    <Typography component="p" style={{lineHeight: '3', color: 'var(--font-color)'}}>
                                        {item?.patient_profile?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{width: "100%", margin: "10px 0"}}/>
                        </>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(PatientsList)
