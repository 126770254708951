import { Grid, Typography } from "@mui/material"

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

function OfferCard({ t, upperText, middleText, lowerText, btnText, btnClass, imgAlt, imgSrc, btnClick}) {
    
    return (
        <Grid
            container
            sx={{
                borderRadius: 'var(--lg-radius)',
                boxShadow: '0px 0px 5px 1px #ddd',
                padding: '1rem',
                marginTop: '10px',
                height: '100px',
                width:'620px'
            }}
        >
            <Grid
                item
                xs={2} 
            >
                <img
                    src={imgSrc}
                    alt={imgAlt}

                />
            </Grid>
            <Grid
                item
                xs={7}
            >
                <Grid
                    container
                    sx={{justifyContent: 'flex-start'}}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{
                        fontWeight: 'bold',
                        color: 'var(--secondary-color)',
                        bgcolor: '#f8e71c',
                        borderRadius: '25px',
                        paddingX:'5px'
                    }}>
                        {upperText}
                    </Typography>
                </Grid>
                <Grid
                    container
                    sx={{justifyContent: 'flex-start'}}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'var(--secondary-color)',
                            fontWeight:'bold'
                        }}
                    >
                        {middleText}
                    </Typography>
                </Grid>
                <Grid
                    container
                    sx={{justifyContent: 'flex-start'}}
                >
                    <Typography variant="body2">
                        {lowerText}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                xs={3}
                style={{ textAlign: "end" }}
            >
                <PrimaryButton
                    text={btnText}
                    classBtn={btnClass}
                    click={btnClick}
                />
            </Grid>
        </Grid>)
}

export default OfferCard