import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { resetPasswordAPI } from "../../Contexts/API/Auth/ResetPassword";
import ResetPasswordTemplate from "../../Templates/Auth/ResetPassword.Template.jsx";
import { useNavigate } from "react-router-dom";
import { getResetPasswordOTP } from "../../Contexts/API/Auth/GetResetPasswordToken";

const ResetPasswordPage = (props) => {
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();
  const type = localStorage.getItem("TYPE")
  
  const [countdown, setcountdown] = useState(true);
  // const codeMsg = localStorage.getItem("lang") === "en" ? ' Your verification code: ' : ' رمز التحقق هو  '

  const filter = {
    "code_scope": "reset_password",
    "user_type": type
  }

  const handleSubmit = async () => {
    if (OTP !== "") {
      const res = await resetPasswordAPI({
        verification_code: OTP,
        user: {
          country_code: localStorage.getItem("country_code"),
          phone_number: localStorage.getItem("phone_number"),
        }
      },filter);
      if (res.err) toast.error(res.err);
      else {
        localStorage.setItem("VERIFY", res?.res?.data);
        navigate("/change-password");
      }
    } else toast.error(props.t("Signup.empty"))
  };

  const resend_code = async () => {
    const res = await getResetPasswordOTP({
      user: {
        country_code: localStorage.getItem("country_code"),
        phone_number: localStorage.getItem("phone_number")
      }
    },filter);
    if (res.err) {
      toast.error(res.err);
    } else {
      toast.success(props.t("Signup.sent"))
      localStorage.setItem("OTP", res?.res?.data?.reset_password_token);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (countdown)
        setcountdown(false)
    }, 120000);
  }, [countdown]);

  return <ResetPasswordTemplate
    handleSubmit={handleSubmit}
    setOTP={setOTP}
    countdown={countdown}
    resend_code={resend_code}
    setcountdown={setcountdown}
  />;
};

export default ResetPasswordPage;
