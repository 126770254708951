/* eslint-disable no-console */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";

import "firebase/compat/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";


const config = {
    apiKey: "AIzaSyBwSEKUr3fcXNjFFG4jLvDHJKOdtAbX3Wk",
    authDomain: "med-cloud-dbec3.firebaseapp.com",
    databaseURL: "https://med-cloud-dbec3-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "med-cloud-dbec3",
    storageBucket: "med-cloud-dbec3.appspot.com",
    messagingSenderId: "181358067899",
    appId: "1:181358067899:web:d498c33f18c8650676e99e",
    measurementId: "G-ZQ0EVXPNMT"
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(config);
}
export const SignInFirebase = () => {
  signInWithCustomToken(firebase.auth(), localStorage.getItem("FirebaseTOKEN"))
  .then((userCredential) => {
    // Signed in
    const userUID = firebase.auth().currentUser.uid;
    localStorage.setItem("UID",userUID)
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });
}
class _Firebase {
  fcmToken = null;

  isMessagingSupported = true;

  constructor() {
    this.auth = firebase.auth();
    try {
      if (firebase.messaging.isSupported()) {
        this.messaging = firebase.messaging();
      } else {
        this.isMessagingSupported = false;
      }
    } catch (err) {
      console.log("[FIREBASE MESSAGING ERROR]: ", err);
      this.isMessagingSupported = false;
    }
  }

  async registerSW() {
    if (!this.isMessagingSupported) return null;
    const regists = await navigator.serviceWorker
      .getRegistrations()
      .catch((err) => err);
    if (!Array.isArray(regists)) {
      console.log("[ERROR REGISERING SW]: ", regists);
    }

    if (regists.length > 0) {
      [this.registration] = regists;
      return this.registration;
    }

    const regis = await navigator.serviceWorker.register(
      "/firebase-message-sw.js"
    );

    this.registration = regis;
    return this.registration;
  }

  async getFCMToken() {
    if (!this.isMessagingSupported)
      return "This is a dummy string for FCM token";
    if (this.fcmToken) return this.fcmToken;
    try {
      const token = await this.messaging.getToken({
        serviceWorkerRegistration: this.registration,
      });
      this.fcmToken = token;
      return this.fcmToken;
    } catch (error) {
      console.error("[FIREBASE ERROR]: ", error);
      this.fcmToken = null;
      return null;
    }
  }

  onMessageListener = () =>
    new Promise((resolve) => {
      this.messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
}

export const Firebase = new _Firebase();
export const firebaseSDK = firebase;
