import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import reportWebVitals from "./reportWebVitals.js";
import LocalizationProviders from "./Contexts/contextLocalization/localizationContext.js";
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import ar from 'javascript-time-ago/locale/ar.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
TimeAgo.addLocale(ar)

ReactDOM.render(
  <LocalizationProviders>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </LocalizationProviders>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
