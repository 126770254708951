// import { icons } from "assets/AssetHelper";

import AlertModal from "../../Components/Organisms/Modals/AlertModal/AlertModal";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAccountRequest } from "../../services/modules/Auth";
import { ToggleAlertModal } from "../../services/modules/modals/Actions";
import DeleteAccountTemplate from "../../Templates/Auth/DeleteAccount.Template";
import Loader from "../../Components/Atoms/Loader/Loader";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { icons } from "../../Assets/AssetsHelper";

const phoneNumberRegex = /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const validationSchema = Yup.object().shape({
  country_code: Yup.number().required("Required"),
  phone: Yup.string().matches(phoneNumberRegex, "invalid phone number").required("Required"),
  password: Yup.string().required().label("Password"),
  user_type: Yup.string(),
});
localStorage.setItem("lang", "en");

function DeleteAccountPage(props) {
  const { t } = props;
  const dispatch = useDispatch();
  const load = useSelector((state) => state?.auth?.isLoading);

  const handleDeleteAccount = (values) => {
    const types = {
      1: "all",
      2: "doctor",
      3: "patient",
    };
    const { country_code, phone, password, user_type } = values;

    const action = () => {
      dispatch(
        ToggleAlertModal({
          isOpen: true,
          icon: icons?.Success,
          message: "Your account has been successfully deleted.",
        })
      );
      formik?.resetForm();
    };
    const failAction = (error) => {
      const errorType = error?.response?.data?.error;
      if (errorType == "invalid_credentials") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.Exclamationmark,
            message: (
              <>
                Oops! There seems to be a mismatch. <br />
                Check your email and password again.
              </>
            ),
          })
        );
      } else if (errorType == "account_not_found") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.NotFound,
            message: (
              <>
                That account seems to be missing! <br />
                Double-check the email and try again.
              </>
            ),
          })
        );
      }
    };
    const requestData = {
      body: {
        user: {
          phone,
          password,
          country_code,
        },
      },
      params: { user_type: types[user_type] },
      action,
      failAction,
    };
    dispatch(DeleteAccountRequest(requestData));
  };

  const formik = useFormik({
    initialValues: { country_code: "966", phone: "", password: "", user_type: "1" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleDeleteAccount(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Loader open={Boolean(load?.loading)} />
      <AlertModal />
      <DeleteAccountTemplate formik={formik} t={t} />
    </>
  );
}

export default withTranslation()(DeleteAccountPage);
