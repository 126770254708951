import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function changePassword(recivedData, filter) {
  const data = { ...recivedData };

  return await axiosFn("post", URL.CHANGE_PASSWORD + makeFilterString(filter), data)
    .then(async (res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function changePasswordAPI(data, filter) {
  let { res, err } = await changePassword(data, filter);
  return { res, err };
}
