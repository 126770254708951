import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function getTelemedicineList(service_type) {
  return await axiosFn("get", URL.MEDICAL_SERVICES + service_type)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getTelemedicineListAPI(service_type) {
  let { err, res } = await getTelemedicineList(service_type);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function getPriceLimits(service_type) {
  return await axiosFn("get", URL.MEDICAL_SERVICES + service_type + URL.PRICE_LIMITS)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getPriceLimitsAPI(service_type) {
  let { err, res } = await getPriceLimits(service_type);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function updateTelemedicineSettings(service_type, data) {
  return await axiosFn("patch", URL.MEDICAL_SERVICES + service_type, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function updateTelemedicineSettingsAPI(service_type, data) {
  let { err, res } = await updateTelemedicineSettings(service_type, data);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function getAvailableTelemedicine(service_type, data) {
  return await axiosFn("get", URL.TIME_INTERVALS + service_type + '/available?day=' + data.day + '&period=' + data.period)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getAvailableTelemedicineAPI(service_type, data) {
  let { err, res } = await getAvailableTelemedicine(service_type, data);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function sendTimesIntervalsTelemedicine(service_type, data) {
  return await axiosFn("post", URL.TIME_INTERVALS + service_type, { time_intervals: data })
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function sendTimesIntervalsTelemedicineAPI(service_type, data) {
  let { err, res } = await sendTimesIntervalsTelemedicine(service_type, data);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function getSavedTimeIntervalsAPI(service_type, filter_obj) {
  return await axiosFn("get", URL.TIME_INTERVALS + service_type + makeFilterString(filter_obj))
    .then((res) => {
      return res.data
    })
    .catch(handleError);
}

export async function deleteIntervalAPI(service_type, ids) {
  return await axiosFn('delete', URL.TIME_INTERVALS + service_type + '/' + ids)
    .then(res => {
      if (res) {
        return {
          res: res.data
          , err: null
        };
      }
    }).catch(handleError);
}