import CloseIcon from "@mui/icons-material/Close";
import { Grid, Typography } from "@mui/material";
import { images } from "../../Assets/images/IconHelper";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";

import PhoneInput from "../../Components/Atoms/Input/PhoneInput/PhoneInput";
import en from "react-phone-number-input/locale/en.json";
import { withTranslation } from "react-i18next";
import Form from "../../Components/Organisms/Forms/Form";

const ChangePasswordTemplate = ({
  handleSubmit,
  t,
  countryCode,
  setPhone,
  setCountryCode,
}) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={0}>
      <Grid item xs>
        <Grid
          container
          style={{ marginTop: "30px" }}
          justifyContent={"flex-end"}
        >
          <PrimaryButton
            btnType="icon"
            startIcon={<CloseIcon />}
            click={() => {
              navigate("/Login");
            }}
          />
        </Grid>
      </Grid>
      <Grid item md={6} className="Auth_container">
        <h1 style={{ color: "#161616", fontSize: "26px" }}>{t("Login.Forgot_password")}</h1>
        <div>
          <img src={images.ForgotPasswordLogo} />
        </div>

        <Grid
          container
          className="Auth_account"
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} container justifyContent={"center"}>
            <Form
              mode={"ChangePassword"}
              labels={en}
              handleSubmit={handleSubmit}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "50px" }}>
            {/* <PrimaryButton
              text={"تغيير كلمة المرور"}
              classBtn="primary btn-rounded btn-medium"
              click={() => {
                handleSubmit();
              }}
              // disabled={!phonee || !passwordd}
            /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs></Grid>
    </Grid>
  );
};

export default withTranslation()(ChangePasswordTemplate);
