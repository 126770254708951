import { Grid, Typography, Divider } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { icons, images } from "../../../Assets/images/IconHelper";
import { useState } from "react";
import PlaceHolder from "../../Atoms/Placeholder/Placeholder";

const Packages = (props) => {
    const { t, packages, setOpenPayment } = props;
    const [isActive, setActive] = useState(null);
    // const [openPayment, setOpenPayment] = useState(false);

    const selectPackage = (i, id, price) => {
        localStorage.setItem("packageId", id);
        setActive(i);
        document.getElementById("amount").value = price * 100;
        document.getElementById("publishable_key").value = packages?.[0]?.publishable_key;
        window.myFunction();
    }

    return (
        <Grid container className="patient-wallet-container mb-30px">
            <Grid container md={10} style={{ margin: "auto" }}>
                <Typography style={{ fontSize: "13px", color: "var(--font-color)" }} className="mb-20px" component="h6">
                    {t("Wallet.recharge")}
                </Typography>
            </Grid>
            <Grid container className="packages-container">
                {packages?.length === 0 ? (
                    <PlaceHolder
                        src={images.EmptyPlaceholder}
                        alt='Empty Box'
                    />
                ) : (
                    <>
                        {packages?.map((packageItem, index) => (
                            <Grid container md={10} className="package-item mb-20px" key={packageItem?.id}>
                                <Grid sm={1} style={{ position: "relative" }}>
                                    <span onClick={() => selectPackage(index, packageItem?.id, packageItem?.price)} className={index === isActive ? "package-select active-select" : "package-select"}></span>
                                </Grid>
                                <Grid sm={2} style={{ position: "relative" }}>
                                    <img className="coins-packages" src={icons.Coins} />
                                </Grid>
                                <Grid sm={8}>
                                    <Typography component="p" style={{ fontSize: "20px", fontWeight: "bold", color: "var(--secondary-color)" }}>
                                        {packageItem?.name}
                                    </Typography>
                                    {packageItem?.is_top_seller && (
                                        <Typography className="most-sold" component="span">
                                            {t("Wallet.most_sold")}
                                        </Typography>
                                    )}
                                    <Typography display='inline' component="p" style={{ fontSize: "13px", color: "#434343", fontWeight: '600' }}>
                                        {`${t("Wallet.pay")} ${packageItem?.price} ${t("Wallet.sar")} ${t("Wallet.get")} `}
                                    </Typography>
                                    <Typography display='inline' component="p" style={{ fontSize: "15px", color: "#2794a4", fontWeight: 'bold' }}>
                                        {`${packageItem?.amount_recieved} ${t("Wallet.sar")}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            <Grid container md={10} style={{ margin: "auto" }}>
                <Grid container md={12} className="mb-20px">
                    <Typography component="p" style={{ fontSize: '13px', color: 'var(--font-color', marginBottom: '20px' }}>
                        {t("Payment.pay_via")}
                    </Typography>
                    <Grid container md={12} className="mb-20px">
                        <Grid xs={2}>
                            <img src={icons.MasterCard} alt="" />
                        </Grid>
                        <Grid xs={2} style={{ marginTop: "-2px" }}>
                            <img src={icons.Visa} alt="" />
                        </Grid>
                        <Grid xs={2}>
                            <img src={images.MadaLogo} alt="" />
                        </Grid>
                    </Grid>
                    <Grid container md={12} className="mb-20px">
                        <img src={images.stcLogo} alt="" />
                    </Grid>
                    <Grid container md={12} className="mb-20px">
                        <img src={images.ApplePay} alt="" />
                    </Grid>
                    <input id="amount" type="hidden" value="" />
                    <input id="publishable_key" type="hidden" value="" />
                </Grid>
            </Grid>
            <Grid container md={8} style={{ margin: "auto" }}>
                <PrimaryButton
                    text={t("Wallet.charge")}
                    classBtn='primary btn-rounded'
                    click={() => setOpenPayment(true)}
                    disabled={isActive === null}
                />
            </Grid>
        </Grid>
    )
}

export default withTranslation()(Packages)