import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import SearchGroup from "../../../../Molecules/Home/UserHome/SearchGroup";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../../../Assets/images/IconHelper";
import CustomCalendar from "../../../../Molecules/Home/DoctorHome/Calendar";
import SearchResultTabs from "./SearchResultTabs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import FilterSortPopup from "../../../../Molecules/Home/UserHome/FilterSortPopup";
import CustomTablePagination from "../../../../Atoms/Pagination/Pagination";

const SearchResultPage = (props) => {
    const { t, getListServivesForPatient, ListServivesForPatient, specialities, medicalTitles, getDoctorInMedicalEntity, paginationInfo } = props;
    const [date, setNewDate] = useState(moment().locale('en').format("DD/MM/YYYY"));
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [popupMode, setPopupMode] = useState("");
    const [searchKeywords, setSearchKeywords] = useState("");
    const [medicalProviderId, setMedicalProviderId] = useState(searchParams.get("id"));
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const searchBy = pathnameArr[pathnameArr?.length - 1]
    const [filterObj, setFilterObj] = useState({
        'medical_provider_type': 'doctor',
        'date': date,
        'gender': searchParams.get("doctorGender") !== "all" ? searchParams.get("doctorGender") : '',
        'search_keys[name]': searchKeywords,
        'order_by': 'rating',
        'medical_title_id': '',
        'search_keys[speciality_id]': searchBy === 'familyDoctor' ? 6 : searchParams.get("speciality_id"),
        'search_keys[subspeciality_id]': searchParams.get("subSpeciality_id"),
        'search_by': (searchBy === 'familyDoctor' || searchBy === 'speciality') ? 'speciality' : '',
        'institute_type': pathnameArr[4] === 'telemedicine' ? '' : pathnameArr[4],
        'page_size': 10,
        'page_number': 1
    });
    const getNewDate = (newDate) => {
        setNewDate(newDate);
        setFilterObj({ ...filterObj, date: newDate, page_number: 1 });
    };
    const getDoctorGender = (gender) => {
        if (gender !== 'all') setFilterObj({ ...filterObj, gender: gender });
        else setFilterObj({ ...filterObj, gender: '' });
    };
    const getSearchKeywords = (searchKeyword) => {
        setFilterObj({ ...filterObj, 'search_keys[name]': searchKeyword });
    };
    const handleCallback = (flag) => {
        setOpen(flag);
    };
    const openFilterSortPopup = (mode) => {
        setOpen(true);
        setPopupMode(mode);
    };
    const getSortValue = (sortValue) => {
        setFilterObj({ ...filterObj, order_by: sortValue });
    }
    const getFilterValues = (medicalTitle, speciality) => {
        setFilterObj({ ...filterObj, 'medical_title_id': medicalTitle, 'search_keys[speciality_id]': speciality, 'search_keys[subspeciality_id]': '' });
    };
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    useEffect(() => {
        if (pathnameArr[4] === 'clinic' || pathnameArr[4] === 'hospital') {
            const serviceType = pathnameArr[4] === 'clinic' ? '/clinic_service/' : '/hospital_service/'
            getDoctorInMedicalEntity(serviceType, medicalProviderId, filterObj);
        } else {
            const serviceType = pathnameArr[5] === 'group-therapy-search' ? 'group_session' : 'personal_telemedicine'
            getListServivesForPatient(serviceType, filterObj);
        }
    }, [date, filterObj, searchKeywords]);
    return (
        <Grid container className="search-result-container">
            <Grid container className="mb-30px">
                <SearchGroup getSearchKeywordsCallback={(searchKeywords) => getSearchKeywords(searchKeywords)} />
            </Grid>
            <Grid container md={12} className="search-result-page" style={{ paddingBottom: "30px" }}>
                <Grid container md={11} style={{ margin: "auto", padding: "2rem 0" }}>
                    <Grid xs={8}>
                        <Typography style={{ fontWeight: "bold", fontSize: "20px" }} component="h1">
                            {t("TelemedicineSettings.choose_a_doctor")}
                        </Typography>
                    </Grid>
                    <Grid container xs={4}>
                        <Grid xs={6} style={{ textAlign: "center" }}>
                            <PrimaryButton
                                startIcon={<img src={images.SortDesc} />}
                                text={t("TelemedicineSettings.sort")}
                                classBtn="sort-btn"
                                click={() => openFilterSortPopup('sort')}
                            />
                        </Grid>
                        <Grid xs={6} style={{ textAlign: "center" }}>
                            <PrimaryButton
                                startIcon={<img src={images.Filter} />}
                                text={t("TelemedicineSettings.filter")}
                                classBtn="sort-btn"
                                click={() => openFilterSortPopup('filter')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container md={11} style={{ margin: "auto", padding: "2rem 0" }}>
                    <CustomCalendar dateCallback={(date) => getNewDate(date)} />
                </Grid>
                <Grid container md={12}>
                    <SearchResultTabs
                        tabCallback={(doctorGeder) => getDoctorGender(doctorGeder)}
                        ListServivesForPatient={ListServivesForPatient}
                        date={date}
                    />
                </Grid>
                {paginationInfo?.total_count > 10 && (
                    <CustomTablePagination
                        totalCount={paginationInfo.total_count}
                        changePage={(val) => paginationChangePage(val)}
                        handleRowsPerPage={(val) => handleRowsPerPage(val)}
                        t={t}
                    />
                )}
            </Grid>
            <Grid container md={12}>
                <FilterSortPopup
                    handleCallback={(openFlag) => handleCallback(openFlag)}
                    open={open}
                    mode={popupMode}
                    getSortValue={(sortValue) => getSortValue(sortValue)}
                    specialities={specialities}
                    medicalTitles={medicalTitles}
                    getFilterValues={(medicalTitle, speciality) => getFilterValues(medicalTitle, speciality)}
                />
            </Grid>
        </Grid>
    )
};

export default withTranslation()(SearchResultPage);