import { Grid } from "@mui/material"
import { useTranslation } from 'react-i18next'

import { images } from "../../../../../../Assets/images/IconHelper"
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton"
import AddDiagnosisModal from '../../../../../Organisms/Modals/Diagnosis/AddEditDiagnosisModal'
import AddEditPrescriptionModal from '../../../../../Organisms/Modals/Prescription/AddEditPrescriptionModal'
import PlaceHolder from '../../../../../Atoms/Placeholder/Placeholder'
import HistoryDiagnosisList from './HistoryDiagnosisList'

function PreviousDiagnosis(props) {
    const { t } = useTranslation()
    const { diseasesList } = props;

    return (
        <Grid container justifyContent={'center'}>
            {props?.diagnosisList?.length !== 0 ?
                props?.diagnosisList?.map((item, i) =>
                    <HistoryDiagnosisList
                        t={t}
                        item={item}
                        key={i}
                        openEditView={props.openModal}
                        removeDiagnosis={props.removeDiagnosis}
                        itemPrescription={props.itemPrescription}
                        openPrescriptionDtails={props.openPrescriptionDtails}
                        openPrescriptionDetailsModal={props.openPrescriptionDetailsModal}
                        handleClosePrescriptionDetails={props.handleClosePrescriptionDetails}
                        medicineIntakeMethods={props.medicineIntakeMethods}
                    />
                )
                :
                <PlaceHolder
                    src={images.EmptyPlaceholder}
                    alt='Empty Box'
                    mainTitle={t("UserProfile.no_diagnosis")}
                    subTitle={t("UserProfile.add_prev_diagnosis")}
                    txtContainer='text-container'
                />
            }
            <Grid container mt={10} justifyContent='center'>
                <PrimaryButton
                    text={t("UserProfile.add_diagnosis")}
                    classBtn='add-diagnosis-btn'
                    click={() => props.openModal('add')} />
            </Grid>
            {/* <AddDiagnosisModal
                open={props.open}
                handleClose={props.handleClose}
                t={t}
                type={props.type}
                item={props.item}
                createDiagnosis={props.createDiagnosis}
                editDiagnosis={props.editDiagnosis}
                diseasesList={diseasesList}
            /> */}
            <AddEditPrescriptionModal
                // open={props.openPrescription}
                // handleClose={props.handleClosePrescription}
                open={props.open}
                handleClose={props.handleClose}
                t={t}
                // type={props.typePrescription}
                item={props.itemPrescription}
                type={props.type}
                // item={props.item}
                // createPrescription={props.createPrescription}
                // editPrescription={props.editPrescription}
                createPrescription={props.createDiagnosis}
                editPrescription={props.editDiagnosis}
                medicineIntakeMethods={props.medicineIntakeMethods}
                medicineDosagePeriods={props.medicineDosagePeriods}
                diseasesList={diseasesList}
            />
        </Grid>
    )
}

export default PreviousDiagnosis