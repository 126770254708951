import { useState, useEffect } from "react"
import { Grid, Divider, Typography, Button } from "@mui/material"
import moment from "moment-hijri"
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton"
import TextFieldInput from "../../../../Components/Atoms/Input/TextField/TextFieldInput"
import SelectBox from "../../../../Components/Atoms/Select/Select"
import MultiSelect from "../../../../Components/Atoms/Select/MultiSelect"
import SwitchLabel from "../../../../Components/Atoms/Buttons/Switch/SwitchButton"
import EditMsg from "../../../../Components/Atoms/FlashMessages/EditMsg"
import MedicineForm from './MedicineForm'

const EditAppDiagnosis = ({
    t,
    handleClose,
    medicineIntakeMethods,
    medicineDurations,
    symptoms,
    doctorNumber,
    medicalTitles,
    specialityList,
    getSubSpecialityList,
    editDiagnosis,
    medicineDosagePeriods,
    diagnosis,
    isSameDoctor,
    diseasesList,
    medicinesList
}) => {

    const [addMedicine, setAddMedicine] = useState(false)
    const [addDelegation, setAddDelegation] = useState(false)
    const [subSpecialityList, setSubSpecialityList] = useState([])
    const [oldMedicines, setOldMedicines] = useState([])
    const [deletedIds, setDeletedIds] = useState([])
    const dosage = [
        { "id": 1, "name": t("AddPrescriptionModal.one_dosage") },
        { "id": 2, "name": t("AddPrescriptionModal.two_dosage") },
        { "id": 3, "name": t("AddPrescriptionModal.three_dosage") }]
    const status = [
        { "id": 1, "name": t("DoctorTelemedicine.accept") },
        { "id": 0, "name": t("DoctorTelemedicine.not_accept") }]
    const medicineObj = {
        "medicine_name": '',
        "def_medicine_intake_method_id": '',
        "daily_dosage": '',
        "dosage_period": '',
        "def_medicine_duration_id": '',
        "status": ''
    }
    const [selected, setSelected] = useState([])

    const handleMultiSelectChange = (e, value) => setSelected(value)

    const addNewMedicine = (setFieldValue, values) => {
        const medicines = [...values.medicines]
        medicines.push(medicineObj)
        setFieldValue("medicines", medicines)
    }

    const handleSpecialityChange = async (val) => {
        const res = await getSubSpecialityList(val);
        if (res) setSubSpecialityList(res)
    }

    const handleToggleMedicine = (val, setFieldValue) => {
        val ? setFieldValue("medicines", [medicineObj]) : setFieldValue("medicines", [])
        setAddMedicine(val)
    }

    useEffect(async () => {
        const medicines = diagnosis?.prescription?.prescription_medicines
        const delegation = diagnosis?.delegation
        if (delegation) {
            setSubSpecialityList(await getSubSpecialityList(diagnosis?.delegation?.speciality?.id))
            setAddDelegation(true)
        }
        if (medicines) {
            document.getElementById("testButton").click();
            setAddMedicine(true)
        }
        setSelected(diagnosis?.symptoms)
    }, [])

    return (
        <Grid container className="therapy-item mb-10px" mt={3}>
            <Grid item md={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.doctor_name")}
                </Typography>
                <Typography
                    component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {localStorage.getItem("name")}
                </Typography>
            </Grid>
            <Grid item md={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.comission_id")}
                </Typography>
                <Typography
                    component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {doctorNumber}
                </Typography>
            </Grid>
            <Grid item md={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.date")}
                </Typography>
                <Typography
                    component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {moment().locale(localStorage.getItem("lang")).format("DD/MM/YYYY")}
                </Typography>
            </Grid>
            <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
            {!isSameDoctor && <EditMsg className="edit-flash-msg mb-20px" message={t("DoctorTelemedicine.change_medicine_warning")} />}
            <Grid container>
                <Formik
                    initialValues={{
                        symptoms: selected,
                        disease_id: diagnosis?.disease?.id,
                        description: diagnosis?.description,
                        examinations: diagnosis?.requested_procedures,
                        specialty: diagnosis?.delegation?.speciality?.id,
                        subSpeciality: diagnosis?.delegation?.subspeciality?.id,
                        medicalTitle: diagnosis?.delegation?.medical_title?.id,
                        medicines: []
                    }}
                    validationSchema={
                        Yup.object().shape({
                            disease_id:
                                Yup.string().required(t("Validation.required_field")),
                            medicines: Yup.array().of(
                                Yup.object().shape({
                                    medicine_name: Yup.string()
                                        .required(t("Validation.required_field")),
                                    daily_dosage: Yup.string()
                                        .when("medicine_name", (val) => {
                                            if (val) {
                                                return Yup.string().required(t("Validation.required_field"));
                                            } else {
                                                return Yup.string().notRequired();
                                            }
                                        }),
                                    dosage_period: Yup.string()
                                        .when("medicine_name", (val) => {
                                            if (val) {
                                                return Yup.string().required(t("Validation.required_field"));
                                            } else {
                                                return Yup.string().notRequired();
                                            }
                                        }),
                                    def_medicine_intake_method_id: Yup.string()
                                        .when("medicine_name", (val) => {
                                            if (val) {
                                                return Yup.string().required(t("Validation.required_field"));
                                            } else {
                                                return Yup.string().notRequired();
                                            }
                                        }),
                                    def_medicine_duration_id: Yup.string()
                                        .when("medicine_name", (val) => {
                                            if (val) {
                                                return Yup.string().required(t("Validation.required_field"));
                                            } else {
                                                return Yup.string().notRequired();
                                            }
                                        }),
                                    status: Yup.string()
                                        .when("medicine_name", (val) => {
                                            if (val) {
                                                return Yup.string().required(t("Validation.required_field"));
                                            } else {
                                                return Yup.string().notRequired();
                                            }
                                        })
                                })
                            )
                        })
                    }
                    onSubmit={async (values) => {
                        const array = selected.map((item) => item.id)
                        const newArray = [...values.medicines]
                        if(deletedIds.length > 0){
                            for (let index = 0; index < deletedIds.length; index++) {
                                oldMedicines.forEach(item => {
                                    if(item.id === deletedIds[index]){
                                        newArray.push({
                                            ...item,
                                            "_destroy": true
                                        })
                                    }
                                })
                            }
                        }
                        const manipulatedMedicines = newArray.map(medicine => {
                            return {
                                ...medicine,
                                "status": medicine.status === 0 ? 'pending' : 'approved',
                                // "medical_provider_name": localStorage.getItem('name')
                            }
                        })
                        const data = {
                            "diagnosis": {
                                "id": diagnosis?.id,
                                "disease_id": values.disease_id,
                                "description": values.description,
                                "requested_procedures": values.examinations,
                                "date": moment().locale("en").format("DD/MM/YYYY"),
                                //"reservation_id": 242,
                                "diagnosis_symptoms": array, //list of symptoms ids, when app
                                "prescription_medicines": manipulatedMedicines
                            },
                            "delegation": {
                                "speciality_id": values.specialty,
                                "subspeciality_id": values.subSpeciality,
                                "medical_title_id": values.medicalTitle
                            }
                        }
                        await editDiagnosis(diagnosis?.id, data)
                        setDeletedIds([])
                        handleClose()
                    }}
                >
                    {({ isSubmitting, submitForm, values, handleChange, setFieldValue }) => (
                        <Form style={{ width: "100%" }}>
                            <Button id="testButton" display='none' type="button" onClick={() => {
                                const array = []
                                diagnosis?.prescription?.prescription_medicines?.map((obj) => {
                                    array.push({
                                        "medicine_name": obj?.medicine_name,
                                        "def_medicine_intake_method_id": obj?.medicine_intake_method_id,
                                        "daily_dosage": obj?.daily_dosage?.value,
                                        "dosage_period": obj?.dosage_period?.value,
                                        "def_medicine_duration_id": obj?.medicine_duration_id,
                                        "status": obj?.status === 'pending' ? 0 : 1,
                                        "id": obj?.id
                                    })
                                })
                                setFieldValue("medicines", array)
                                setOldMedicines(array)
                            }}>
                            </Button>
                            <Grid
                                item
                                xs={12}
                                container
                                style={{ marginBottom: 30 }}
                            >
                                <Field
                                    as={MultiSelect}
                                    disabled={!isSameDoctor}
                                    title={t("DoctorTelemedicine.write_symptoms")}
                                    inputLabel={t("DoctorTelemedicine.symptoms")}
                                    labelStyle='field-label'
                                    items={symptoms}
                                    name='symptoms'
                                    id='symptoms'
                                    className="select-multi"
                                    value={selected}
                                    onchange={(e, value) => handleMultiSelectChange(e, value)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                style={{ marginBottom: 30 }}
                            >
                                <Field
                                    as={SelectBox}
                                    disabled={!isSameDoctor}
                                    label={t("AddDiagnosisModal.diagnosis_disease")}
                                    labelStyle='field-label'
                                    items={diseasesList}
                                    name='disease_id'
                                    id='disease_id'
                                    value={values.disease_id}
                                    onchange={handleChange}
                                />
                                <ErrorMessage
                                    name="disease_id"
                                    component="div"
                                    style={{ color: 'red' }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                sx={{ height: 140, marginBottom: '30px' }}
                            >
                                <Field
                                    as={TextFieldInput}
                                    disabled={!isSameDoctor}
                                    multiline={true}
                                    className='textarea'
                                    placeholder={t("DoctorTelemedicine.write_description")}
                                    label={t("DoctorTelemedicine.diagnosis_description")}
                                    labelStyle='field-label'
                                    name='description'
                                    id='description'
                                    rows={5}
                                    inputProps={{
                                        style: {
                                            position: 'absolute',
                                            top: '10px',
                                            width: '95%'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                sx={{ height: 140, marginBottom: "30px" }}
                            >
                                <Field
                                    as={TextFieldInput}
                                    disabled={!isSameDoctor}
                                    multiline={true}
                                    className='textarea'
                                    placeholder={t("DoctorTelemedicine.write_examinations")}
                                    label={t("UserAppointments.rumors_and_analyzes")}
                                    labelStyle='field-label'
                                    name='examinations'
                                    id='examinations'
                                    rows={5}
                                    inputProps={{
                                        style: {
                                            position: 'absolute',
                                            top: '10px',
                                            width: '95%'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid container item md={12} mb={6}>
                                <Grid item md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                        {t("DoctorTelemedicine.add_medicine")}
                                    </Typography>
                                </Grid>
                                <Grid container item md={6} justifyContent='end'>
                                    <SwitchLabel
                                        label=""
                                        checked={addMedicine}
                                        change={(val) => handleToggleMedicine(val, setFieldValue)}
                                    />
                                </Grid>
                            </Grid>
                            {addMedicine &&
                                <>
                                    <FieldArray name="medicines">
                                        {(arrayHelpers) => (values.medicines.map((medicine, i) => {
                                            return (
                                                <MedicineForm
                                                    key={i}
                                                    i={i}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    dosage={dosage}
                                                    status={status}
                                                    t={t}
                                                    medicineIntakeMethods={medicineIntakeMethods}
                                                    medicineDurations={medicineDurations}
                                                    medicineDosagePeriods={medicineDosagePeriods}
                                                    medicinesList={medicinesList}
                                                    sameDoctor={isSameDoctor}
                                                    oldMedicines={oldMedicines}
                                                    arrayHelpers={arrayHelpers}
                                                    setAddMedicine={setAddMedicine}
                                                    setDeletedIds={setDeletedIds}
                                                    deletedIds={deletedIds}
                                                />
                                            );
                                        }))}
                                    </FieldArray>
                                    <Grid
                                        container
                                        justifyContent="end"
                                    >
                                        <PrimaryButton
                                            text={t("AddPrescriptionModal.add_new_medicine")}
                                            classBtn='primary btn-rounded btn-small'
                                            style={{ maxWidth: '140px' }}
                                            click={
                                                () => addNewMedicine(setFieldValue, values)
                                            }
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid container item md={12} mb={4} mt={4}>
                                <Grid item md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                        {t("DoctorTelemedicine.add_delegation")}
                                    </Typography>
                                </Grid>
                                <Grid container item md={6} justifyContent='end'>
                                    <SwitchLabel
                                        label=""
                                        checked={addDelegation}
                                        change={(val) => setAddDelegation(val)}
                                    />
                                </Grid>
                            </Grid>
                            {addDelegation && <Grid
                                container
                                style={{ marginBottom: 30 }}
                            >
                                <Grid item md={12}>
                                    <Field
                                        as={SelectBox}
                                        disabled={!isSameDoctor}
                                        label={t("DoctorProfile.medical_specialty")}
                                        labelStyle='field-label'
                                        items={specialityList}
                                        name='specialty'
                                        id='specialty'
                                        onclick={(value) => handleSpecialityChange(value)}
                                        value={values.specialty}
                                        onchange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Field
                                        as={SelectBox}
                                        disabled={!isSameDoctor}
                                        label={t("DoctorProfile.accurate_specialty")}
                                        labelStyle='field-label'
                                        items={subSpecialityList}
                                        name='subSpeciality'
                                        id='subSpeciality'
                                        value={values.subSpeciality}
                                        onchange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Field
                                        as={SelectBox}
                                        disabled={!isSameDoctor}
                                        label={t("DoctorProfile.medical_title")}
                                        labelStyle='field-label'
                                        items={medicalTitles}
                                        name='medicalTitle'
                                        id='medicalTitle'
                                        value={values.medicalTitle}
                                        onchange={handleChange}
                                    />
                                </Grid>
                            </Grid>}
                            <Grid
                                container
                                sx={{
                                    justifyContent: 'flex-start', alignContent: 'center'
                                }}
                            >
                                <Typography variant="caption" sx={{ color: 'var(--secondary-color)' }}>
                                    {t("AddPrescriptionModal.required_fields")}
                                </Typography>
                            </Grid>
                            <Grid container mt={4}>
                                <Grid container item md={6} justifyContent='center'>
                                    <PrimaryButton
                                        type='submit'
                                        text={t("CustomPopup.done")}
                                        classBtn='primary btn-rounded'
                                        style={{ maxWidth: '270px' }}
                                        click={submitForm}
                                        disabled={isSubmitting}
                                    />
                                </Grid>
                                <Grid container item md={6} justifyContent='center'>
                                    <PrimaryButton
                                        text={t("DoctorProfile.cancel_btn")}
                                        classBtn='settings-cancel-btn btn-rounded'
                                        style={{ maxWidth: '270px' }}
                                        click={handleClose}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    )
}

export default EditAppDiagnosis
