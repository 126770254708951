import { useEffect, useState } from "react";
import { Grid, Typography, Avatar } from "@mui/material";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { LogoutAPI, BatchLogoutAPI } from "../../../Contexts/API/Auth/Logout";
import CustomPopup from "../../Organisms/Modals/CustomPopup/CustomPopup";
import { images } from "../../../Assets/images/IconHelper";

const SwitchAccount = ({ t, accounts }) => {
    const [choosenAccount, setChoosenAccount] = useState({});
    const [index, setIndex] = useState();
    const [indexLogout, setIndexLogout] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [open, setOpen] = useState(false);

    const chooseAccount = (account, i) => {
        setChoosenAccount(account)
        setIndex(i)
    }

    const confirmAccount = () => {
        localStorage.setItem("TOKEN", choosenAccount.token);
        localStorage.setItem("FirebaseTOKEN", choosenAccount.firebase_token)
        // let exp = res?.res?.expires_in + res?.res?.created_at;
        // localStorage.setItem("EXP", exp);
        localStorage.setItem("userId", choosenAccount.userId);
        localStorage.setItem("avatar", choosenAccount.avatar);
        localStorage.setItem("name", choosenAccount.name);
        localStorage.setItem("email", choosenAccount.email);
        localStorage.setItem("userType", choosenAccount.user_type);
        localStorage.setItem("readPopup", true);
        let url = new URL(choosenAccount.node_url)
        localStorage.setItem("UserNode", url.pathname.slice(1));
        window.location.href = "/"
    }

    const handleLogout = (i) => {
        const newArray = accounts.filter(account=>account !== accounts[i])
        localStorage.setItem("TOKEN",accounts[i].token)
        const res = LogoutAPI()
        if (res) {
            localStorage.setItem("TOKEN", newArray[0].token);
            localStorage.setItem("FirebaseTOKEN", newArray[0].firebase_token)
            // let exp = res?.res?.expires_in + res?.res?.created_at;
            // localStorage.setItem("EXP", exp);
            localStorage.setItem("userId", newArray[0].userId);
            localStorage.setItem("avatar", newArray[0].avatar);
            localStorage.setItem("name", newArray[0].name);
            localStorage.setItem("email", newArray[0].email);
            localStorage.setItem("userType", newArray[0].user_type);
            localStorage.setItem("readPopup", true);
            let url = new URL(newArray[0].node_url)
            localStorage.setItem("UserNode", url.pathname.slice(1));
            window.location.href = "/"
            localStorage.removeItem("Accounts")
        }
    };

    const LogoutAll = async () => {
        const arr = []
        accounts.forEach(account => {
            arr.push(account.token)
        })
        const res = await BatchLogoutAPI({
            "tokens": arr
        })
        if (res) {
            localStorage.clear()
            window.location.href = "/login"
        }
    }

    useEffect(() => {
        const obj = accounts.find(account => account.user_type === localStorage.getItem("userType"))
        const indx = accounts.indexOf(obj)
        setIndex(indx)
    }, []);

    return (
        <Grid container >
            <Grid container md={9} className="mb-20px">
                <Typography component="h1" sx={{ fontWeight: 'bold' }}>
                    {t("Settings.switch")}
                </Typography>
            </Grid>
            <Grid container md={9} className="mb-30px">
                <Typography component="p" style={{ color: 'var(--font-color)', fontSize: '14px' }}>
                    {t("Settings.if")}
                </Typography>
            </Grid>
            {accounts && accounts?.map((account, i) => {
                return (
                    <Grid container item xs={12}
                        sx={{
                            p: '16px',
                            borderRadius: '10px',
                            boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.07)',
                            maxHeight: '96px',
                            cursor: 'pointer',
                            border: i === index ? 'solid 2px #229cb0' : 'none'
                        }}
                        key={account.userId} mt={2}
                        onClick={() => chooseAccount(account, i)}
                    >
                        <Grid item xs={2}>
                            <Avatar
                                src={account.avatar}
                                sx={{ width: '64px', height: '64px' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: '#2794a4' }}>
                                {account.name}
                            </Typography>
                            <Typography>
                                {account.email}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', color: '#55d4e5' }}>
                                {account.user_type === 'patient' ? t("Login.patient") : t("Login.doctor")}
                            </Typography>
                        </Grid>
                        <Grid container item xs={2} alignItems='center'>
                            <PrimaryButton
                                text={t("AccountMenu.log_out")}
                                classBtn='primary btn-rounded'
                                click={() => {
                                    setIndexLogout(i)
                                    setOpen(true)
                                }}
                            />
                        </Grid>
                    </Grid>
                )
            })}
            <Grid container mt={15} justifyContent='center'>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#c73227',
                    cursor: 'pointer'
                }}
                    onClick={() => setOpenPopup(true)}
                >
                    {t("Settings.batch_logout")}
                </Typography>
            </Grid>
            <Grid container mt={5} justifyContent='center'>
                <PrimaryButton
                    text={t("Signup.confirm_btn")}
                    classBtn='primary btn-rounded btn-medium'
                    click={confirmAccount}
                />
            </Grid>
            <CustomPopup
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                src={images.LogoutImage}
                t={t}
                title={t("CustomPopup.logoutTitle")}
                isAction={true}
                btnActionText={t("CustomPopup.yes_logout")}
                btnCloseText={t("CustomPopup.No")}
                btnActionStyle="primary"
                btnCloseStyle="settings-cancel-btn"
                handleAction={() => LogoutAll()}
            />
            <CustomPopup
                open={open}
                handleClose={() => setOpen(false)}
                src={images.LogoutImage}
                t={t}
                title={t("CustomPopup.logoutTitle")}
                isAction={true}
                btnActionText={t("CustomPopup.yes_logout")}
                btnCloseText={t("CustomPopup.No")}
                btnActionStyle="primary"
                btnCloseStyle="settings-cancel-btn"
                handleAction={() => handleLogout(indexLogout)}
            />
        </Grid>
    )
}

export default SwitchAccount