import axios from "axios";
import { BASE_URL as BaseURL } from "../../Contexts/API/URLs";
import handleErrors from "../../services/modules/utils/handel_error";
// const BaseURL = "http://18.198.127.198/api/v1";

export const reduxRequest = async ({
  dispatch,
  isLoading,
  url,
  method = "post",
  loadingType = "list",
  body,
  action,
  failAction,
  successFunction,
}) => {
  dispatch(
    isLoading({
      type: loadingType,
      loading: true,
    })
  );
  try {
    const { data } = await axios({
      url: url,
      baseURL: BaseURL,
      method: method,
      data: body,
    });
    dispatch(successFunction(data));
    action && action();
  } catch (error) {
    failAction ? failAction(error) : handleErrors(error);
  } finally {
    dispatch(
      isLoading({
        type: "",
        loading: false,
      })
    );
  }
};
