import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function sendSignInData(recivedData) {
  const data = { ...recivedData };
  return await axiosFn("post", URL.AUTH_LOGIN, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function SignInAPI(data) {
  let { err, res } = await sendSignInData(data);
  return { res, err };
}
