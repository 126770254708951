import DoctorHomeTemplate from "../../../Templates/Main/Home/DoctorHome.template.jsx";
import { getDoctorApointementsAPI, getDoctorApointementsSummaryAPI } from "../../../Contexts/API/Home/doctorHome";
import { useEffect, useState } from "react";
import { getDoctorMainInfoAPI } from "../../../Contexts/API/DoctorProfile/mainInfo";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { GetWalletInfoAPI } from "../../../Contexts/API/Wallet/Wallet";

const HomePage = (props) => {
  const [telemedicineList, setTelemedicineList] = useState([]);
  const [hospitalsList, setHospitalsList] = useState([]);
  const [clinicsList, setClinicsList] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [appointmentObj, setAppointmentsObj] = useState({});
  const [paginationInfo, setPaginationInfo] = useState({});
  const [openLoader, setLoader] = useState(false);
  const [saudiCommissionNumber, setSaudiCommissionNumber] = useState("");
  const [walletAmount, setWalletAmount] = useState('');

  const getApprovalStatus = async () => {
    const res = await getDoctorMainInfoAPI();
    if (res) {
      setApprovalStatus(res?.data?.profile?.medical_provider?.approval_status)
      setRejectReason(res?.data?.profile?.medical_provider?.rejection_reason)
      setSaudiCommissionNumber(res?.data?.profile?.medical_provider?.saudi_commission_registration_number)
    }
  };
  const getAppointementsForDoctor = async (date, servie_type, page, rowsPerPage) => {
    setLoader(true);
    const res = await getDoctorApointementsAPI(date, servie_type, page, rowsPerPage);
    if (servie_type === 'telemedicine') setTelemedicineList(res?.data)
    else if (servie_type === 'hospital_service') setHospitalsList(res?.data)
    else if (servie_type === 'clinic_service') setClinicsList(res?.data)
    if (res) setPaginationInfo(res?.extra);
    setLoader(false);
  };
  const getDoctorAppointments = async (service_type) => {
    var filterObj = {
      'page_size': 10,
      'page_number': 1,
      'service_type': service_type
    };
    let res = await getDoctorApointementsSummaryAPI(filterObj);
    if (res && service_type === 'telemedicine') setAppointmentsObj(res?.data);
    else setAppointmentsList(res?.data);
  }

  const getWallet = async () => {
    const res = await GetWalletInfoAPI()
    if (res) setWalletAmount(res?.data?.wallet_amount)
  }

  useEffect(() => {
    getApprovalStatus();
    getWallet()
  }, []);

  return (
    <>
      <Loader open={openLoader} />
      <DoctorHomeTemplate
        getDocAppointements={(date, service_type, page, rowsPerPage) => getAppointementsForDoctor(date, service_type, page, rowsPerPage)}
        telemedicineList={telemedicineList}
        hospitalsList={hospitalsList}
        clinicsList={clinicsList}
        approvalStatus={approvalStatus}
        rejectReason={rejectReason}
        getDoctorAppointments={getDoctorAppointments}
        appointmentsList={appointmentsList}
        appointmentObj={appointmentObj}
        paginationInfo={paginationInfo}
        saudiCommissionNumber={saudiCommissionNumber}
        walletAmount={walletAmount}
      />
    </>
  )
};

export default HomePage;
