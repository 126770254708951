import { withTranslation } from "react-i18next";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../../Atoms/Input/TextField/TextFieldInput";
import SelectBox from "../../../../Atoms/Select/Select";
import RadioButtonsGroup from "../../../../Atoms/Input/Radio/RadioInput";
import { useState, useEffect } from "react";
import { DaysList, Duration, Intervals } from "../../../../../Contexts/API/Helper/Helper";
import { getAvailableGroupAPI, sendTimesIntervalsGroupAPI, editGroupItemAPI } from "../../../../../Contexts/API/DoctorProfile/groupSession";
import Loader from "../../../../Atoms/Loader/Loader";
import * as yup from "yup";
import { useFormik } from "formik";
import { convertTimeFormate } from "../../../../../Contexts/API/Helper/Helper";
// import MultiSelect from "../../../../Atoms/Select/MultiSelect";
import { getPriceLimitsAPI } from "../../../../../Contexts/API/DoctorProfile/telemedicine";
import { icons } from "../../../../../Assets/images/IconHelper";

function GroupTherapyForm(props) {
    const { t, diseasesList } = props;
    const lang = localStorage.getItem('lang')
    const [radioFlag, changeSessionView] = useState(false);
    const [diseaseFlag, setDiseaseFlag] = useState(false);
    const [dayValue, setDayValue] = useState('');
    const [intervalValue, setIntervalValue] = useState('');
    const [intervalsList, setIntervalsList] = useState([]);
    const [appointmentsList, setAppointmentsList] = useState([]);
    const [openLoader, setLoader] = useState(false);
    const [activeClass, setActiveClass] = useState([]);
    const [formData, setFormData] = useState({ duration: "" });
    const [diseaseValue, setDiseaseValue] = useState('');
    const [diseaseIds, setDiseasIds] = useState([]);
    const [priceLimits, setPriceLimits] = useState([]);
    const [intervals, setIntervals] = useState([]);
    const [limitError, setLimitError] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    // var tempDiseases = [];
    const IntialValues = {
        title: "",
        description: "",
        fees_SAR: "",
        duration: "",
        capacity: "",
        day: "",
        duration: "",
        period: "",
    };

    const EditIntialValues = {
        title: props?.itemData?.medical_service?.title,
        description: props?.itemData?.medical_service?.description,
        fees_SAR: props?.itemData?.medical_service?.fees_SAR,
        capacity: props?.itemData?.capacity,
    };

    const EditValidationSchema = yup.object({
        title: yup.string().required(t("Validation.required_field")),
        description: yup.string().required(t("Validation.required_field")),
        fees_SAR: yup.string().required(t("Validation.required_field")),
        capacity: yup.number().typeError(t("Validation.numbers")).positive(t("Validation.positive")).required(t("Validation.required_field")),
    });

    const EditFreeSchema = yup.object({
        title: yup.string().required(t("Validation.required_field")),
        description: yup.string().required(t("Validation.required_field")),
        capacity: yup.number().typeError(t("Validation.numbers")).positive(t("Validation.positive")).required(t("Validation.required_field")),
    });


    if (radioFlag === 'false' || radioFlag === false) {
        var ValidationSchema = yup.object({
            title: yup.string().required(t("Validation.required_field")),
            description: yup.string().required(t("Validation.required_field")),
            fees_SAR: yup.number().required(t("Validation.required_field")),
            duration: yup.string().required(t("Validation.required_field")),
            capacity: yup.number().typeError(t("Validation.numbers")).positive(t("Validation.positive")).required(t("Validation.required_field")),
            day: yup.string().required(t("Validation.required_field")),
            duration: yup.string().required(t("Validation.required_field")),
            period: yup.string().required(t("Validation.required_field")),
        });
    } else {
        var ValidationSchema = yup.object({
            title: yup.string().required(t("Validation.required_field")),
            description: yup.string().required(t("Validation.required_field")),
            duration: yup.string().required(t("Validation.required_field")),
            capacity: yup.number().typeError(t("Validation.numbers")).positive(t("Validation.positive")).required(t("Validation.required_field")),
            day: yup.string().required(t("Validation.required_field")),
            duration: yup.string().required(t("Validation.required_field")),
            period: yup.string().required(t("Validation.required_field")),
        });
    }
    
    const formik = useFormik({
        initialValues: props.mode === 'Edit' ? EditIntialValues : IntialValues,
        validationSchema: (
            props.mode === 'Edit' && props?.itemData?.medical_service?.fees_SAR === null ? EditFreeSchema :
                props.mode === 'Edit' && props?.itemData?.medical_service?.fees_SAR !== null ? EditValidationSchema :
                    ValidationSchema
        ),
        onSubmit: (values) => {
            if (radioFlag === true || radioFlag === "true") {
                values.fees_SAR = '';
                {
                    props.mode === 'Edit' ? (
                        updateView(false, 'Edit', {
                            time_interval: {
                                day: props?.itemData?.day,
                                start_time: props?.itemData?.start_time,
                                end_time: props?.itemData?.end_time,
                                capacity: values.capacity
                            },
                            medical_service: {
                                title: values.title,
                                description: values.description,
                                fees_SAR: values.fees_SAR,
                                duration: props?.itemData?.medical_service?.duration,
                                disease_ids: diseaseIds
                            }
                        })
                    ) : (
                        updateView(false, 'Add', {
                            time_intervals: appointmentsList,
                            medical_service: {
                                title: values.title,
                                description: values.description,
                                fees_SAR: values.fees_SAR,
                                duration: values.duration,
                                capacity: values.capacity,
                                disease_ids: diseaseIds // we need to return diseases ids to create edit 
                            }
                        })
                    )
                }
            } else {
                if (props.mode === 'Edit') {
                    if (parseFloat(priceLimits?.find(item => item.duration === props?.itemData?.medical_service?.duration)?.lower_limit) <= parseFloat(values.fees_SAR) && parseFloat(values.fees_SAR) <= parseFloat(priceLimits?.find(item => item.duration === props?.itemData?.medical_service?.duration)?.upper_limit)) {
                        updateView(false, 'Edit', {
                            time_interval: {
                                day: props?.itemData?.day,
                                start_time: props?.itemData?.start_time,
                                end_time: props?.itemData?.end_time,
                                capacity: values.capacity
                            },
                            medical_service: {
                                title: values.title,
                                description: values.description,
                                fees_SAR: values.fees_SAR,
                                duration: props?.itemData?.medical_service?.duration,
                                disease_ids: diseaseIds
                            }
                        })
                    } else {
                        setLimitError(true)
                    }
                } else {
                    if (parseFloat(priceLimits?.find(item => item.duration === values.duration)?.lower_limit) <= parseFloat(values.fees_SAR) && parseFloat(values.fees_SAR) <= parseFloat(priceLimits?.find(item => item.duration === values.duration)?.upper_limit)) {
                        updateView(false, 'Add', {
                            time_intervals: appointmentsList,
                            medical_service: {
                                title: values.title,
                                description: values.description,
                                fees_SAR: values.fees_SAR,
                                duration: values.duration,
                                capacity: values.capacity,
                                disease_ids: diseaseIds
                            }
                        })
                    } else {
                        setLimitError(true)
                    }
                }
                // if (parseFloat(priceLimits?.find(item => item.duration === values.duration)?.lower_limit) <= parseFloat(values.fees_SAR) && parseFloat(values.fees_SAR) <= parseFloat(priceLimits?.find(item => item.duration === values.duration)?.upper_limit)) {
                //     setLimitError(false)
                //     {
                //         props.mode === 'Edit' ? (
                //             updateView(false, 'Edit', {
                //                 time_interval: {
                //                     day: props?.itemData?.day,
                //                     start_time: props?.itemData?.start_time,
                //                     end_time: props?.itemData?.end_time,
                //                     capacity: values.capacity
                //                 },
                //                 medical_service: {
                //                     title: values.title,
                //                     description: values.description,
                //                     fees_SAR: values.fees_SAR,
                //                     duration: props?.itemData?.medical_service?.duration,
                //                     disease_ids: diseaseIds
                //                 }
                //             })
                //         ) : (
                //             updateView(false, 'Add', {
                //                 time_intervals: appointmentsList,
                //                 medical_service: {
                //                     title: values.title,
                //                     description: values.description,
                //                     fees_SAR: values.fees_SAR,
                //                     duration: values.duration,
                //                     capacity: values.capacity,
                //                     disease_ids: diseaseIds // we need to return diseases ids to create edit 
                //                 }
                //             })
                //         )
                //     }
                // } else {
                //     setLimitError(true)
                // }
            }
        }
    })

    const updateView = async (flag, action, data) => {
        setLoader(true);
        if (action === 'Add') {
            const res = await sendTimesIntervalsGroupAPI('group_session', data);
            if (res) props.updateView(flag, action);
        } else if (action === 'Edit') {
            const res = await editGroupItemAPI('group_session', props.itemData.id, data);
            if (res) props.updateView(flag, action);
        } else {
            props.updateView(flag, action);
        }
        setLoader(false);
    }

    const handleChange = (val) => {
        changeSessionView(val);
    }

    useEffect(() => {
        if (props?.itemData?.medical_service?.fees_SAR === null || props?.itemData?.medical_service?.fees_SAR === 0) {
            changeSessionView(true);
        }
        if (dayValue && intervalValue && formData.duration) {
            getAvailableGroupIntervals();
        }
    }, [
        dayValue,
        intervalValue,
        formData.duration,
        props.mode,
        props.itemData
    ])

    const getAvailableGroupIntervals = async () => {
        let data = {
            day: dayValue,
            period: intervalValue,
            duration: formData.duration
        }
        const res = await getAvailableGroupAPI('group_session', data);
        if (res) setIntervalsList(res?.data?.available_intervals);
    }

    const handleSelectChange = (indecator, e) => {
        if (indecator === 'day') setDayValue(e);
        if (indecator === 'interval') {
            setIntervalValue(e);
            setAppointmentsList([])
            setActiveClass([])
        }
        if (indecator === 'duration') setFormData({ ...formData, duration: e });
    }

    const selectAppointmentOption = (i, start_time, end_time, day) => {
        if (activeClass.includes(i)) {
            const array = [...activeClass]
            const list = [...appointmentsList]
            const index = array.indexOf(i)
            array.splice(index, 1)
            list.splice(index, 1)
            setActiveClass(array)
            setAppointmentsList(list)
        } else {
            setActiveClass(activeClass => [...activeClass, i]);
            setAppointmentsList(appointmentsList => [...appointmentsList, {
                day: day,
                start_time: start_time,
                end_time: end_time,
            }])
        }
    }

    const sessionOptions = [
        {
            label: t("DoctorProfile.free"),
            value: true,
            disabled: false
        },
        {
            label: t("DoctorProfile.paid"),
            value: false,
            disabled: false
        },
    ];
    const diseasesOptions = [
        {
            label: t("Allergy.yes"),
            value: true,
            disabled: false
        },
        {
            label: t("Checkups.no"),
            value: false,
            disabled: false
        },
    ];

    const changeDiseaseValue = (value) => {
        setDiseaseValue(value);
        setDiseasIds([value]);
    };

    const getPriceLimits = async () => {
        const res = await getPriceLimitsAPI("group_session");
        if (res) {
            setPriceLimits(res?.data?.price_limits);
            const array = []
            res?.data?.price_limits.forEach(item => {
                array.push({
                    name: `${item?.duration} ${t("TelemedicineSettings.min")}`,
                    id: item?.duration
                })
            })
            setIntervals(array)
        }
    }

    useEffect(() => {
        getPriceLimits()
    }, []);

    useEffect(() => {
        if (props.mode === 'Edit' && props?.itemData?.medical_service?.disease_ids?.length > 0) {
            changeDiseaseValue(props?.itemData?.medical_service?.disease_ids?.[0])
            setDiseaseFlag(true)
        }
    }, [props.mode]);

    return (
        <Grid container className="group-therapy-form">
            <Loader open={openLoader} />
            <Grid md={12}>
                <Typography
                    component="h3"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                    className="mb-20px"
                >
                    {props.mode !== 'Edit' ? t("DoctorProfile.add_group_therapy") : t("DoctorProfile.edit_group_therapy")}
                </Typography>
            </Grid>
            <Grid container>
                <Grid md={12}>
                    <Grid className="input-field">
                        <TextFieldInput
                            id="title"
                            name="title"
                            className="mb-20px"
                            placeholder={t("DoctorProfile.title")}
                            label={t("DoctorProfile.title")}
                            labelStyle="field-label"
                            value={formik.values.title}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            onchange={formik.handleChange}
                        />
                    </Grid>
                    <Grid className="input-field">
                        <TextFieldInput
                            id="description"
                            name="description"
                            className="textarea mb-20px"
                            placeholder={t("DoctorProfile.desc")}
                            label={t("DoctorProfile.desc")}
                            labelStyle="field-label"
                            style={{ height: "130px" }}
                            value={formik.values.description}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            onchange={formik.handleChange}
                            multiline={true}
                            rows={4}
                            inputProps={{
                                style: {
                                    position: 'absolute',
                                    top: '20px',
                                    width: '95%'
                                }
                            }}
                        />
                    </Grid>
                    {props.mode !== 'Edit' && (
                        <Grid container md={12}>
                            <Grid xs={12} className="input-field">
                                <SelectBox
                                    id="day"
                                    name="day"
                                    items={DaysList}
                                    title={t("DoctorProfile.day")}
                                    label={t("DoctorProfile.day")}
                                    labelStyle="select-label"
                                    value={formik.values.day}
                                    error={formik.touched.day && Boolean(formik.errors.day)}
                                    helperText={formik.touched.day && formik.errors.day}
                                    onclick={(val) => handleSelectChange('day', val)}
                                    onchange={formik.handleChange}
                                />
                            </Grid>
                            <Grid xs={12} className="input-field">
                                <SelectBox
                                    id="duration"
                                    name="duration"
                                    items={intervals}
                                    title={t("DoctorProfile.duration")}
                                    label={t("DoctorProfile.duration")}
                                    labelStyle="select-label"
                                    value={formik.values.duration}
                                    error={formik.touched.duration && Boolean(formik.errors.duration)}
                                    helperText={formik.touched.duration && formik.errors.duration}
                                    onclick={(val) => handleSelectChange('duration', val)}
                                    onchange={formik.handleChange}
                                />
                            </Grid>
                            <Grid xs={12} className="input-field">
                                <SelectBox
                                    id="period"
                                    name="period"
                                    items={Duration}
                                    title={t("DoctorProfile.period")}
                                    label={t("DoctorProfile.period")}
                                    labelStyle="select-label"
                                    value={formik.values.period}
                                    error={formik.touched.period && Boolean(formik.errors.period)}
                                    helperText={formik.touched.period && formik.errors.period}
                                    onclick={(val) => handleSelectChange('interval', val)}
                                    onchange={formik.handleChange}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    {intervalsList?.map((item, index) => (
                        <Grid xs={4} className="mb-20px">
                            <PrimaryButton
                                classBtn={`${"appointment-option"} ${activeClass.includes(index) ? "active-appointment" : ''}`}
                                text={t("DoctorProfile.from") + convertTimeFormate(item?.start_time) + t("DoctorProfile.to") + convertTimeFormate(item?.end_time)}
                                click={() => selectAppointmentOption(index, item?.start_time, item?.end_time, item?.day)}
                            />
                        </Grid>
                    ))}
                </Grid>
                <hr className="gray-line" />
                <Grid
                    container
                    className="mb-20px"
                    style={{ color: "var(--font-color)" }}
                >
                    <Grid container className="mb-30px">
                        <RadioButtonsGroup
                            formLabel={t("DoctorProfile.session_be")}
                            row={true}
                            options={sessionOptions}
                            change={(val) => handleChange(val)}
                            value={radioFlag}
                            className="mb-20px"
                        />
                    </Grid>
                    <Grid container>
                        <Grid md={12} className={"input-field fees " + radioFlag}>
                            <TextFieldInput
                                id="fees_SAR"
                                name="fees_SAR"
                                className="mb-20px"
                                placeholder={t("DoctorProfile.prices")}
                                label={t("DoctorProfile.prices")}
                                labelStyle="field-label"
                                value={formik.values.fees_SAR}
                                error={formik.touched.fees_SAR && Boolean(formik.errors.fees_SAR) || limitError}
                                helperText={formik.touched.fees_SAR && formik.errors.fees_SAR}
                                onchange={formik.handleChange}
                                EndAdornment={
                                    <PrimaryButton
                                        btnType="icon"
                                        startIcon={<img
                                            src={icons.Information}
                                            alt='fees information'
                                        />}
                                        aria_controls={open ? 'basic-menu' : undefined}
                                        aria_haspopup="true"
                                        aria_expanded={open ? 'true' : undefined}
                                        click={handleClick}
                                    />
                                }
                            />
                            <Typography component='p'>
                                {formik.values.duration && (t("DoctorProfile.set_fees") + priceLimits?.find(item => item.duration === formik.values.duration)?.lower_limit + t("TelemedicineSettings.sar") + t("DoctorProfile.to") + priceLimits?.find(item => item.duration === formik.values.duration)?.upper_limit + t("TelemedicineSettings.sar"))}
                            </Typography>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{ direction: lang === 'ar' ? 'rtl' : 'ltr', boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.35)' }}
                            >
                                <Grid container sx={{ width: lang === 'ar' ? '370px' : "650px", paddingX: '15px', borderRadius: '18px' }}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#369faf' }}>
                                            {t("DoctorProfile.prices")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} mb={2} mt={1}>
                                        <Typography sx={{ fontSize: '14px', color: '#909090' }}>
                                            {t("DoctorProfile.set_fee")}
                                        </Typography>
                                    </Grid>
                                    {priceLimits?.length > 0 && priceLimits?.map(limit => {
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontSize: '15px', color: '#878787' }}>
                                                        {`${limit?.duration} ${t("TelemedicineSettings.min")}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#393939' }}>
                                                        {`${t("DoctorProfile.between")} ${limit?.lower_limit} ${t("DoctorProfile.to")} ${limit?.upper_limit} ${t("TelemedicineSettings.sar")}`}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </Menu>
                        </Grid>
                        <Grid item md={12}>
                            <Typography>

                            </Typography>
                        </Grid>
                        <Grid md={12} className="input-field">
                            <TextFieldInput
                                id="capacity"
                                name="capacity"
                                className="mb-20px"
                                placeholder={t("DoctorProfile.capacity")}
                                label={t("DoctorProfile.capacity")}
                                labelStyle="field-label"
                                value={formik.values.capacity}
                                error={formik.touched.capacity && Boolean(formik.errors.capacity)}
                                helperText={formik.touched.capacity && formik.errors.capacity}
                                onchange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="mb-30px">
                        <RadioButtonsGroup
                            formLabel={t("DoctorProfile.diseases_question")}
                            row={true}
                            options={diseasesOptions}
                            change={(val) => {
                                setDiseaseFlag(val)
                                if (val === 'false') {
                                    setDiseasIds([])
                                    setDiseaseValue('')
                                }
                            }}
                            value={diseaseFlag}
                            className="mb-20px"
                        />
                    </Grid>
                    <Grid xs={12} className={"input-field disease " + diseaseFlag}>
                        <SelectBox
                            id="disease"
                            name="disease"
                            items={diseasesList}
                            title={t("TelemedicineSettings.choose")}
                            label={t("DoctorProfile.disease_type")}
                            labelStyle="field-label"
                            value={diseaseValue}
                            onchange={(e) => changeDiseaseValue(e.target.value)}
                        />
                        {/* <MultiSelect
                            title={t("TelemedicineSettings.choose")}
                            inputLabel={t("DoctorProfile.disease_type")}
                            labelStyle='field-label'
                            items={diseasesList}
                            name='disease'
                            id='disease'
                            className="select-multi"
                            value={diseaseValue}
                            onchange={(e, value) => changeDiseaseValue(value)}
                        /> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                {props.mode === 'Edit' ? (
                    <Grid xs={5.5}>
                        <PrimaryButton
                            classBtn="settings-edit-btn"
                            text={t("DoctorProfile.edit_btn")}
                            click={formik.handleSubmit}
                        />
                    </Grid>
                ) : (
                    <Grid xs={5.5}>
                        <PrimaryButton
                            classBtn="settings-edit-btn"
                            text={t("DoctorProfile.add_btn")}
                            click={formik.handleSubmit}
                        />
                    </Grid>
                )}
                <Grid xs={1}></Grid>
                <Grid xs={5.5}>
                    <PrimaryButton
                        classBtn="settings-cancel-btn"
                        text={t("DoctorProfile.cancel_btn")}
                        click={() => updateView(false, 'cancel')}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withTranslation()(GroupTherapyForm);