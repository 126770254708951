import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function getServiceListForPatient(serviecType, filter_obj) {
  return await axiosFn("get", '/' + serviecType + '/list' + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getServiceListForPatientAPI(serviecType, filter_obj) {
  let { err, res } = await getServiceListForPatient(serviecType, filter_obj);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function showDoctor(id, filter_obj) {
  return await axiosFn("get", URL.DOCTORS + id + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function showDoctorAPI(id, filter_obj) {
  let { err, res } = await showDoctor(id, filter_obj);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function getDoctorTimeSlots(id, filter_obj) {
  return await axiosFn("get", URL.MEDICAL_PROVIDER + id + URL.GET_SERVICE_INTERVALS + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getDoctorTimeSlotsAPI(id, filter_obj) {
  let { err, res } = await getDoctorTimeSlots(id, filter_obj);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function createReservation(id, data, filter_obj) {
  return await axiosFn("post", URL.TIME_INTERVALS + id + URL.RESERVE + makeFilterString(filter_obj), data )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function createReservationAPI(id, data, filter_obj) {
  let { res,err } = await createReservation(id, data, filter_obj);
  if (res) {
    return {
      res: res,
      err: null,
    };
  }
  if (err) {
    return {
      res: null,
      err: err,
    };
  }
}

export async function getMedicalProvidersList(service_type, filter_obj) {
  return await axiosFn("get", service_type + URL.INDEX_INSTITUTES + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getMedicalProvidersListAPI(service_type, filter_obj) {
  let { err, res } = await getMedicalProvidersList(service_type, filter_obj);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function getDoctorsInMedicalEntity(service_type, id, filter_obj) {
  return await axiosFn("get", service_type + id + '/medical_providers' + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getDoctorsInMedicalEntityAPI(service_type, id, filter_obj) {
  let { err, res } = await getDoctorsInMedicalEntity(service_type, id, filter_obj);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}


export async function getUSerReservations(filter,page_number,page_size,service_type) {
  return await axiosFn("get", URL.GET_USER_RESERVATIONS+makeFilterString({
    "list_scope":filter,
    "page_number": page_number,
    "page_size": page_size,
    "service_type": service_type
  }))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getUSerReservation(filter,page_number,page_size,service_type) {
  let { err, res } = await getUSerReservations(filter,page_number,page_size,service_type);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}