import { Avatar, Grid, Typography } from "@mui/material";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { isEmptyObject } from "../../Contexts/API/Helper/Helper";

const ChooseAccountTemplate = (props) => {
    const { t, accounts } = props
    const navigate = useNavigate()
    const [choosenAccount, setChoosenAccount] = useState({});
    const [index, setIndex] = useState();

    const chooseAccount = (account,i) => {
        setChoosenAccount(account)
        setIndex(i)
    }

    const confirmAccount = ()=>{
        localStorage.setItem("TOKEN", choosenAccount.token);
        localStorage.setItem("FirebaseTOKEN", choosenAccount.firebase_token)
        // let exp = res?.res?.expires_in + res?.res?.created_at;
        // localStorage.setItem("EXP", exp);
        localStorage.setItem("userId", choosenAccount.userId);
        localStorage.setItem("avatar", choosenAccount.avatar);
        localStorage.setItem("name", choosenAccount.name);
        localStorage.setItem("email", choosenAccount.email);
        localStorage.setItem("userType", choosenAccount.user_type);
        localStorage.setItem("readPopup", true);
        let url = new URL(choosenAccount.node_url)
        localStorage.setItem("UserNode", url.pathname.slice(1));
        window.location.href = "/"
    }

    const switchLang = (lang) => {
        localStorage.setItem("lang", lang);
        window.location.reload();
    };

    return (
        <Grid container
            spacing={0}
            className="unauth_body"
            sx={{overflow:'auto'}}
        >
            <Grid item xs></Grid>
            <Grid container item md={6}
            // className="Auth_container switch-lang"
            >
                <Grid item xs={6} mt={4} mb={10}>
                    <PrimaryButton
                        btnType="icon"
                        startIcon={i18n.language === "ar" ? <ArrowForwardOutlinedIcon /> : <ArrowBackOutlinedIcon />}
                        click={() => {
                            navigate("/Login");
                        }}
                    />
                </Grid>
                <Grid container item xs={6} justifyContent='end' mt={4} mb={10}>
                    {i18n.language === "ar" ?
                        <h4 onClick={() => switchLang("en")}>English</h4> :
                        <h4 onClick={() => switchLang("ar")}>العربية</h4>
                    }
                </Grid>
                <Grid container item xs={12} justifyContent='center' mb={5}>
                    <Typography sx={{ fontSize: '26px', fontWeight: 'bold', color: '#161616' }}>
                        {t("Login.welcome")}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justifyContent='center' mb={4}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#000000' }}>
                        {t("Login.which")}
                    </Typography>
                </Grid>
                {accounts && accounts?.map((account,i) => {
                    return (
                        <Grid container item xs={12} 
                        sx={{ p: '16px', 
                        borderRadius: '10px', 
                        boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.07)', 
                        maxHeight: '96px', 
                        cursor: 'pointer',
                        border: i === index ? 'solid 2px #229cb0' : 'none'
                    }} 
                        key={account.userId} mt={2} 
                        onClick={()=>chooseAccount(account,i)}
                        >
                            <Grid item xs={2}>
                                <Avatar
                                    src={account.avatar}
                                    sx={{ width: '64px', height: '64px' }}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: '#2794a4' }}>
                                    {account.name}
                                </Typography>
                                <Typography>
                                    {account.email}
                                </Typography>
                                <Typography sx={{ fontSize: '14px', color: '#55d4e5' }}>
                                    {account.user_type === 'patient' ? t("Login.patient") : t("Login.doctor")}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid container mt={10} mb={10} justifyContent='center'>
                    <PrimaryButton
                        text={t("Signup.confirm_btn")}
                        classBtn="primary btn-rounded btn-medium"
                        click={confirmAccount}
                        disabled={isEmptyObject(choosenAccount)}
                    />
                </Grid>
            </Grid>
            <Grid item xs></Grid>
        </Grid>
    );
}

export default ChooseAccountTemplate;