import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function updatePhoneData(recivedData) {
    const data = { ...recivedData }

    return await axiosFn("put", URL.UPDATE_PHONE, data)
        .then(async (res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function updatePhoneDataAPI(data) {
    let { err, res } = await updatePhoneData(data)  
    return { err, res }
}
