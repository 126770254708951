import React, { useState, useEffect } from "react";
import SignupTemplate from "../../Templates/Auth/Signup.Template.jsx";
import { SignUpAPI } from "../../Contexts/API/Auth/Signup";
import { ValidateAPI } from "../../Contexts/API/Auth/Validate";
import { getPrivacyPolicyAPI } from '../../Contexts/API/SystemLookups/PrivacyPolicyApi.js'
import { getPrivacyPolicyTermsConditionsAPI } from "../../Contexts/API/SystemLookups/Terms&Conditions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Signup = (props) => {
  const [policy, setPolicy] = useState('')
  const [terms, setTerms] = useState('')
  const [mode, setMode] = useState("User");
  const navigate = useNavigate();

  const handleRegister = async (data) => {
    // 1- Validate
    // 2- Register
    const res = await ValidateAPI(data);
    if (res.res) {
      const userData = await SignUpAPI(data);
      if (userData.res) {
        localStorage.setItem("TOKEN",userData?.res?.data?.extra?.access_token);
        localStorage.setItem("FirebaseTOKEN", userData?.res?.data?.extra?.firebase_token)
        localStorage.setItem("userId", userData?.res?.data?.user?.id);
        localStorage.setItem("avatar", userData?.res?.data?.user?.avatar);
        localStorage.setItem("name", userData?.res?.data?.user?.name);
        localStorage.setItem("userType", userData?.res?.data?.user?.user_type);
        localStorage.setItem("OTP",userData?.res?.data?.user?.verification_code);
        localStorage.setItem("country_code",userData?.res?.data?.user?.country_code);
        localStorage.setItem("phone_number",userData?.res?.data?.user?.phone_number);
        localStorage.setItem("password",data?.user?.password);
        let url = new URL(userData?.res?.data?.user?.firebase_node_url)
        localStorage.setItem("UserNode", url.pathname.slice(1));
        navigate("/verify");
      } else toast.error(userData.err);
    } else toast.error(res.err);
  };

  const getPrivacyPolicy = async () => {
    const res = await getPrivacyPolicyAPI()
    if (res) setPolicy(res)
  }
  const getTerms = async () => {
    const res = await getPrivacyPolicyTermsConditionsAPI()
    if (res) setTerms(res)
  }

  useEffect(() => {
    getPrivacyPolicy()
    getTerms()
  }, [])

  return (
    <SignupTemplate
      mode={mode}
      setMode={setMode}
      handleSubmit={handleRegister}
      policy={policy}
      terms={terms}
    />
  );
};

export default Signup;
