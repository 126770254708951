import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import "./ProfilePanels.css";
import { images } from "../../../Assets/images/IconHelper";
import FileInput from "../../Atoms/Input/FileInput/FileInput";
import EditProfile from "./EditProfile";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { useState, useEffect } from "react";
import { getMedicalTitlesAPI } from "../../../Contexts/API/Lookups/medicalTitlesList";
import { getPresignedLinkAPI, uploadFileAPI } from "../../../Contexts/API/General/upload";
import { editProfileAPI } from "../../../Contexts/API/DoctorProfile/mainInfo";
import Loader from "../../Atoms/Loader/Loader";
import AvatarModal from "../Modals/Profile/AvatarModal";
import { toast } from "react-toastify";

const InfoPanel = (props) => {
  const { t, setAvatar, mainInfo, handleCallMainInfo } = props;
  const [open, setOpen] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false)
  const [medicalTitles, setMedicalTitles] = useState([]);
  const [openLoader, setLoader] = useState(false);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const lang = localStorage.getItem("lang");
  const accounts = JSON.parse(localStorage.getItem("Accounts"))
  let phoneView = mainInfo?.phone_number ? `${phone} ${code}+` : ''
  let codeView = mainInfo?.phone_number ? `+${code} ${phone}` : ''

  const changeProfilePic = async (event) => {
    const file = event.target.files[0];
    if (file.type.indexOf("image") !== -1) {
      const files = [{ "extension": "png" }];
      setLoader(true);
      const res = await getPresignedLinkAPI(files);
      const presignedUrl = res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        updateProfilePic(res?.data[0].file_url);
        if (accounts) {
          const array = accounts.map(account => {
            if (account.userId === parseInt(localStorage.getItem("userId"))) {
              return { ...account, avatar: res?.data[0].file_url }
            }
            return account
          })
          localStorage.setItem("Accounts", JSON.stringify(array))
        }
      }
      setLoader(false);
    } else toast.error(t("EditUserProfile.image"));
  };
  const updateProfilePic = async (avatar) => {
    setLoader(true);
    let data = {
      user: {
        avatar: avatar
      },
      medical_provider: {
        bio: mainInfo?.medical_provider?.bio
      }
    }
    const { res, err } = await editProfileAPI(data);
    if (res) {
      handleCallMainInfo();
      localStorage.setItem("avatar", res?.data?.user?.avatar)
      setAvatar(res?.data?.user?.avatar)
    }
    setLoader(false);
  };
  const openEditProfile = () => {
    setOpen(true);
  };
  const handleCallback = (flag) => {
    setOpen(flag);
    handleCallMainInfo();
  }
  const getMedicalTitles = async () => {
    const res = await getMedicalTitlesAPI();
    if (res) {
      setMedicalTitles(res?.data?.medical_titles);
    }
  };

  useEffect(() => {
    getMedicalTitles();
  }, []);

  useEffect(() => {
    setPhone(mainInfo?.phone_number)
    setCode(mainInfo?.country_code)
  }, [mainInfo]);

  const doctorInformation = [
    {
      title: t("DoctorProfile.medical_title"),
      txt: mainInfo?.medical_provider?.medical_title,
    },
    {
      title: t("DoctorProfile.medical_specialty"),
      txt: mainInfo?.medical_provider?.speciality,
    },
    {
      title: t("DoctorProfile.accurate_specialty"),
      txt: mainInfo?.medical_provider?.subspeciality,
    },
    {
      title: t("DoctorProfile.gender"),
      txt: mainInfo?.gender,
    },
    {
      title: t("Signup.Iqama"),
      txt: mainInfo?.personal_id_number,
    },
    {
      title: t("DoctorProfile.commission_no"),
      txt: mainInfo?.medical_provider?.saudi_commission_registration_number,
    },
  ]
  return (
    <div className="main-info-container">
      <Loader open={openLoader} />
      <div className="profile-cover">
        <h4>{t("UserProfile.personal_file")}</h4>
      </div>
      <div className="profile-pic mb-20px">
        <img
          src={(mainInfo?.avatar === null || mainInfo?.avatar === '') ?
            images.userAvatar :
            mainInfo?.avatar}
          alt=""
        />
        {mainInfo?.avatar === null || mainInfo?.avatar === '' ?
          <FileInput
            icon={images.Camera}
            btnStyle="upload-profile-pic"
            change={(e) => changeProfilePic(e)}
            accept="image/*"
          /> :
          <div className='upload-profile-pic'>
            <img
              src={images.Camera}
              alt='upload avatar'
              onClick={() => {
                setOpenAvatar(true)
              }}
            />
          </div>
        }
      </div>
      <Grid className="info-container">
        <Grid container>
          <Grid md={10} className="info-name mb-20px">
            <h3 >{mainInfo?.name}</h3>
            {mainInfo?.phone_number !== undefined &&
              <p>{lang === 'ar' ? phoneView : codeView}</p>
            }
          </Grid>
          <Grid
            container
            xs={2}
            justifyContent="flex-end"
            alignItems="flex-start"
          // style={{ textAlign: "end", lineHeight: 4 }}
          >
            <PrimaryButton
              btnType="icon"
              startIcon={<img src={images.PenIcon} />}
              click={() => openEditProfile()}
            />
          </Grid>
        </Grid>
        <Grid rowSpacing={4} container className="information-body">
          {doctorInformation.map((item, i) => (
            <Grid item xs={4} className="info-item" key={i}>
              <h4 className="mb-10px">{item.title}</h4>
              <p>{item.txt}</p>
            </Grid>
          ))}
          <Grid item xs={12} >
            <h4 className="mb-10px">{t("TelemedicineSettings.bio")}</h4>
            <p>{mainInfo?.medical_provider?.bio == null ? t("TelemedicineSettings.no_bio") : mainInfo?.medical_provider?.bio}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="edit-profile-form">
        <EditProfile
          medicalTitles={medicalTitles}
          handleCallback={(openFlag) => handleCallback(openFlag)}
          open={open}
          userInfo={mainInfo?.medical_provider}
        />
      </Grid>
      <AvatarModal
        open={openAvatar}
        handleClose={() => setOpenAvatar(false)}
        text={t("UserProfile.avatar_delete")}
        btnActionText={t("Settings.change")}
        btnDeleteText={t("Settings.delete")}
        btnActionStyle='btn-no'
        btnDeleteStyle='btn-yes'
        handleAction={() => {
          setOpenAvatar(false)
          document.getElementById("hiddenInput").click();
        }}
        handleDelete={() => {
          setOpenAvatar(false)
          updateProfilePic(null)
        }}
      />
      <FileInput
        id='hiddenInput'
        change={(e) => changeProfilePic(e)}
        accept="image/*"
      />
    </div>
  );
};

export default withTranslation()(InfoPanel);
