import { toast } from "react-toastify";
import VerificationTemplate from "../../Templates/Auth/Verification.template.jsx";
import { VerifyDataAPI } from "../../Contexts/API/Auth/Verify";
import { verifyPhoneData } from "../../Contexts/API/Auth/VerifyPhone";
import { useState, useEffect } from "react";
import { SignInAPI } from "../../Contexts/API/Auth/Signin";
import { useNavigate } from "react-router-dom";
import { GetVerificationOTP } from "../../Contexts/API/Auth/GetVerificationOTP";

const Verification = ({t}) => {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [countdown, setcountdown] = useState(true);
  // const codeMsg = localStorage.getItem("lang") === "en" ? ' Your verification code: ' : ' رمز التحقق هو  '

  const verifyAccount = async () => {
    const { res, err } = await VerifyDataAPI({
      verification_code: OTP, //otp value in digits
      user: {
        country_code: localStorage.getItem("country_code"),
        phone_number: localStorage.getItem("phone_number"),
      },
    });
    if (res) {
      await localStorage.setItem("VERIFY", res?.data);
      const v = await verifyPhoneData({
        user: {
          country_code: localStorage.getItem("country_code"),
          phone_number: localStorage.getItem("phone_number"),
        },
      });
      if (v) {
        const Data = {
          user: {
            country_code: localStorage.getItem("country_code"),
            phone_number: localStorage.getItem("phone_number"),
            password: localStorage.getItem("password"),
          },
          device: {
            uuid: "dummy",
            device_type: "web", //"android" , "ios" , "web", "unknown"
            fcm_token: "dummy",
            voip_token: "voip_token",
          },
        };
        const SigninRes = await SignInAPI(Data);
        if (SigninRes) {
          if (localStorage.getItem("userType") === "patient") window.location.href = "/";
          else navigate('/confirmation-page');
          localStorage.removeItem("country_code");
          localStorage.removeItem("phone_number");
          localStorage.removeItem("VERIFY");
          localStorage.removeItem("password");
          sessionStorage.removeItem("SignupData");
          localStorage.removeItem("certificates");
          localStorage.removeItem("FormType");
          localStorage.removeItem("SignupStep");
        }
      }
    } else {
      toast.error(err)
    }

  };
  const resend_code = async () => {
    let { err, res } = await GetVerificationOTP({
      user: {
        country_code: localStorage.getItem("country_code"),
        phone_number: localStorage.getItem("phone_number")
      }
    })
    if (res) toast.success(t("Signup.sent"))
    if (err) toast.error(err)
  }
  // useEffect(() => {
  //   if (localStorage.getItem("OTP"))
  //     toast.success( codeMsg + localStorage.getItem("OTP"));

  // }, []);
  useEffect(() => {
    setTimeout(() => {
      if (countdown)
        setcountdown(false)
    }, 120000);
  }, [countdown]);
  return <VerificationTemplate
    handleSubmit={verifyAccount}
    setOTP={setOTP}
    countdown={countdown}
    resend_code={resend_code}
    setcountdown={setcountdown}
  />;
};

export default Verification;
