import { Grid, IconButton, ImageListItemBar, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import "./Form.css";
import PhoneInput from "../../Atoms/Input/PhoneInput/PhoneInput";
import FormSubmit from "../../Molecules/FormSubmit/FormSubmit";
import SelectBox from "../../Atoms/Select/Select";
import { icons } from "../../../Assets/images/IconHelper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import * as yup from "yup";
import { useFormik } from "formik";
import FileInput from "../../Atoms/Input/FileInput/FileInput";
import { getPresignedLinkAPI, uploadFileAPI } from "../../../Contexts/API/General/upload";
import ViewCheckup from "../../Organisms/Modals/Checkups/ViewCheckup";
import { toast } from "react-toastify";

const Form = ({
  labels,
  mode,
  setMode,
  t,
  handleSubmit,
  medicalTitlesList,
  specialities,
  subSpecialities,
  handleSpecialityChange,
  policy,
  terms
}) => {
  const noWhiteSpaces = /^(?!\s+$).*/  // /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/
  const whenSpecialityChange = (val) => {
    handleSpecialityChange(val);
  }
  const changePasswordValidation = yup.object({
    password: yup
      .string()
      .trim()
      .min(8, t("Validation.min_password"))
      .max(20, t("Validation.max_password"))
      .required(t("Validation.password_required")),
    confirm_password: yup
      .string()
      .trim()
      .oneOf([yup.ref("password"), null], t("Validation.match_password"))
      .required(t("Validation.confirm_password")),
  });
  const forgotPasswordValidation = yup.object({
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.valid_number")
      )
      .required(t("Validation.phone_required")),
  });
  const userValidationSchema = yup.object({
    name: yup.string().required(t("Validation.name_required")).max(30, t("Validation.too_long")).matches(
      noWhiteSpaces, t("Validation.empty")),
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.valid_number")
      )
      .required(t("Validation.phone_required")),
    email: yup.string()
      .email(t("Validation.valid_email"))
    // .required(t("Validation.email_required"))
    ,
    password: yup
      .string()
      .trim()
      .min(8, t("Validation.min_password"))
      .max(20, t("Validation.max_password"))
      .required(t("Validation.password_required")),
    confirm_password: yup
      .string()
      .trim()
      .oneOf([yup.ref("password"), null], t("Validation.match_password"))
      .required(t("Validation.confirm_password")),
  });

  const doctorValidationSchema = yup.object({
    name: yup.string().required(t("Validation.name_required")).max(30, t("Validation.too_long")).matches(
      noWhiteSpaces, t("Validation.empty")),
    gender: yup.string().required(t("Validation.pick_gender")),
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.valid_number")
      )
      .required(t("Validation.phone_required")),
    email: yup.string()
      .email(t("Validation.valid_email"))
    // .required(t("Validation.email_required"))
    ,
    password: yup
      .string()
      .trim()
      .min(8, t("Validation.min_password"))
      .max(20, t("Validation.max_password"))
      .required(t("Validation.password_required")),
    confirm_password: yup
      .string()
      .trim()
      .oneOf([yup.ref("password"), null], t("Validation.match_password"))
      .required(t("Validation.confirm_password")),
    personal_id_type: yup
      .string()
      .required(t("Validation.personal_id_required")),
    personal_id: yup.string().max(10, t("Validation.personal_id_max")).min(10, t("Validation.personal_id_min")).required(t("Validation.enter_personal_id"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.id")
      ),
    medical_title: yup.string().required(t("Validation.medical_title_required")),
    speciality: yup.string().required(t("Validation.medical_speciality_required")),
    sub_speciality: yup.string(),
    saudi_commission_registration_number: yup
      .string()
      .required(t("Validation.comission_registration_required"))
      .min(11, t("Validation.saudi_id_min"))
      .max(11, t("Validation.saudi_id_max"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.id")
      ),
  });
  const doctorValidationSchemaOne = yup.object({
    name: yup.string().required(t("Validation.name_required")).max(30, t("Validation.too_long")).matches(
      noWhiteSpaces, t("Validation.empty")),
    gender: yup.string().required(t("Validation.pick_gender")),
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.valid_number")
      )
      .required(t("Validation.phone_required")),
    email: yup.string()
      .email(t("Validation.valid_email"))
    // .required(t("Validation.email_required"))
    ,
    password: yup
      .string()
      .trim()
      .min(8, t("Validation.min_password"))
      .max(20, t("Validation.max_password"))
      .required(t("Validation.password_required")),
    confirm_password: yup
      .string()
      .trim()
      .oneOf([yup.ref("password"), null], t("Validation.match_password"))
      .required(t("Validation.confirm_password")),
    personal_id_type: yup
      .string()
      .required(t("Validation.personal_id_required")),
    personal_id: yup.string().max(10, t("Validation.personal_id_max")).min(10, t("Validation.personal_id_min")).required(t("Validation.enter_personal_id"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.id")
      ),
  });
  const validationSchema = yup.object({
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Validation.valid_number")
      )
      .required(t("Validation.phone_required")),
    password: yup
      .string()
      .trim()
      .min(8, t("Validation.min_password"))
      .max(20, t("Validation.max_password"))
      .required(t("Validation.password_required")),
  });
  const changePasswordValues = {
    password: "",
    confirm_password: "",
  };
  const forgotPasswordValues = {
    phone: "",
  };

  const loginIntialValues = {
    phone: "",
    password: "",
  };
  const userIntialValues = {
    name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  };
  const doctorIntialValues = {
    name: "",
    phone: "",
    email: "",
    password: "",
    gender: "",
    confirm_password: "",
    personal_id_type: "",
    personal_id: "",
    medical_title: "",
    speciality: "",
    sub_speciality: "",
    saudi_commission_registration_number: "",
  };

  const submitDoctor = (values) => {
    if (certificateList.length === 0) setCertificatesListError(true)
    else {
      handleSubmit({
        user: {
          user_type: "medical_provider",
          name: values.name.trim(),
          country_code: doctorCountryCode,
          phone_number: values.phone,
          password: values.password,
          gender: values.gender,
          email: values.email,
          medical_provider_type: "Doctor",
          personal_id_type: values.personal_id_type, // "national_id" or "iqama"
          personal_id_number: values.personal_id,
        },
        medical_provider: {
          medical_title_id: values.medical_title,
          speciality_id: values.speciality,
          subspeciality_id: values.sub_speciality,
          saudi_commission_registration_number:
            values.saudi_commission_registration_number,
          certificates: certificateList,
        },
        device: {
          uuid: "{{device_uuid}}",
          device_type: "web", //"android" , "ios" , "web", "unknown"
          fcm_token: "{{fcm_token}}",
          voip_token: "{{voip_token}}",
        },
      })
    }
  }

  const formik = useFormik({
    initialValues:
      mode === "Login"
        ? loginIntialValues
        : mode === "User"
          ? userIntialValues
          : mode === "ForgotPassword"
            ? forgotPasswordValues
            : mode === "ChangePassword"
              ? changePasswordValues
              : doctorIntialValues,
    validationSchema:
      mode === "Login"
        ? validationSchema
        : mode === "User"
          ? userValidationSchema
          : mode == "ForgotPassword"
            ? forgotPasswordValidation
            : mode === "ChangePassword"
              ? changePasswordValidation
              : mode == "Doctor"
                ? doctorValidationSchemaOne
                : doctorValidationSchema,
    onSubmit: (values) => {
      mode === "Login"
        ? handleSubmit({
          user: {
            country_code: countryCode,
            phone_number: values.phone,
            password: values.password,
          },
          device: {
            uuid: "dummy",
            device_type: "web", //"android" , "ios" , "web", "unknown"
            fcm_token: "dummy",
            voip_token: "voip_token",
          },
        })
        : mode === "User"
          ? handleSubmit({
            user: {
              user_type: "patient",
              name: values.name.trim(),
              country_code: userCountryCode,
              phone_number: values.phone,
              password: values.password,
              email: values.email,
            },
            device: {
              uuid: "{{device_uuid}}",
              device_type: "web", //"android" , "ios" , "web", "unknown"
              fcm_token: "{{fcm_token}}",
              voip_token: "{{voip_token}}",
            },
          })
          : mode == "ForgotPassword"
            ? handleSubmit({
              user: {
                country_code: countryCode,
                phone_number: values.phone,
              },
            })
            : mode == "ChangePassword"
              ? handleSubmit({
                user: {
                  password: values.password,
                  password_confirmation: values.confirm_password,
                },
              })
              : mode == "Doctor2"
                ?
                submitDoctor(values)
                :
                console.log("done")
    },
  });

  const [certificateList, setCertificateList] = useState([]);
  const [countryCode, setCountryCode] = useState("966");
  const [doctorCountryCode, setDoctorCountryCode] = useState("966");
  const [userCountryCode, setUserCountryCode] = useState("966");
  const [openViewImageModal, setOpenViewImageModal] = useState(false)
  const [image, setImage] = useState([])
  const [certificatesListError, setCertificatesListError] = useState(false)

  const openViewImage = (image) => {
    setImage(image)
    setOpenViewImageModal(true)
  }

  const handleCloseViewImage = () => setOpenViewImageModal(false)
  const clickEnter = () => {
    formik.handleSubmit();
  };
  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type.indexOf("image") !== -1 || file.type.indexOf("pdf") !== -1) {
        const files = [{ "extension": file.type.split("/")[1] }];
        const res = await getPresignedLinkAPI(files);
        const presignedUrl = res?.data[0].presigned_url;
        const upload = await uploadFileAPI(presignedUrl, file);
        if (upload) {
          setCertificateList([...certificateList, res?.data[0]?.file_url]);
          setCertificatesListError(false)
        }
      } else toast.error(t("EditUserProfile.pdf"));
    }
  };

  // useEffect(() => {
  //   if (sessionStorage.getItem("SignupData")) {
  //     const userData = JSON.parse(sessionStorage.getItem("SignupData"))
  //     if (localStorage.getItem("FormType") === "User") {
  //       userIntialValues.name = userData.name;
  //       userIntialValues.phone = userData.phone;
  //       userIntialValues.email = userData.email;
  //       userIntialValues.password = userData.password;
  //       userIntialValues.confirm_password = userData.confirm_password
  //       if (localStorage.getItem("country_code")) {
  //         setUserCountryCode(localStorage.getItem("country_code"));
  //       }
  //     } else if (localStorage.getItem("FormType") === "Doctor") {
  //       if (userData.speciality && mode === "Doctor") {
  //         whenSpecialityChange(userData.speciality)
  //       }
  //       doctorIntialValues.name = userData.name;
  //       doctorIntialValues.phone = userData.phone;
  //       doctorIntialValues.email = userData.email;
  //       doctorIntialValues.password = userData.password;
  //       doctorIntialValues.gender = userData.gender;
  //       doctorIntialValues.confirm_password = userData.confirm_password;
  //       doctorIntialValues.personal_id_type = userData.personal_id_type;
  //       doctorIntialValues.personal_id = userData.personal_id;
  //       doctorIntialValues.medical_title = userData.medical_title;
  //       doctorIntialValues.speciality = userData.speciality;
  //       doctorIntialValues.sub_speciality = userData.sub_speciality;
  //       doctorIntialValues.saudi_commission_registration_number = userData.saudi_commission_registration_number;
  //       if (localStorage.getItem("country_code")) {
  //         setDoctorCountryCode(localStorage.getItem("country_code"));
  //       }
  //       if (localStorage.getItem("certificates")) {
  //         setCertificateList(JSON.parse(localStorage.getItem("certificates")));
  //       }
  //     }
  //   }
  // }, []);

  return (
    <Grid
      container
      className="Auth_form"
      direction="column"
      alignItems="center"
      item
      lg={7}
      md={11}
      sm={9}
      xs={12}
    >
      {(mode == "User" || mode == "Doctor") && (
        <TextFieldInput
          id="name"
          name="name"
          placeholder={t("Signup.name")}
          className="mb-20px"
          value={formik.values.name}
          onchange={formik.handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") clickEnter();
          }}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      )}
      {mode == "Doctor" && (
        <SelectBox
          id="gender"
          name="gender"
          value={formik.values.gender}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
          onchange={formik.handleChange}
          items={[
            { id: "male", name: t("TelemedicineSettings.male") },
            { id: "female", name: t("TelemedicineSettings.female") },
          ]}
          title={t("DoctorProfile.gender")}
        />
      )}
      {(mode == "Login" ||
        mode == "Doctor" ||
        mode == "User" ||
        mode == "ForgotPassword") && (
          <PhoneInput
            id="phone"
            name="phone"
            inputValue={formik.values.phone}
            setPhone={formik.handleChange}
            clickEnter={clickEnter}
            placeholder={t("Login.phone_number")}
            labels={labels}
            value={mode == "User" ? userCountryCode : mode == "Doctor" ? doctorCountryCode : countryCode}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            onChange={(input) => {
              mode == "User" ? setUserCountryCode(input) : mode == "Doctor" ? setDoctorCountryCode(input) : setCountryCode(input);
            }}
          />
        )}
      {(mode == "User" || mode == "Doctor") && (
        <TextFieldInput
          id="email"
          name="email"
          placeholder={t("Signup.email")}
          className="mb-20px"
          onchange={formik.handleChange}
          value={formik.values.email}
          onKeyDown={(e) => {
            if (e.key === "Enter") clickEnter();
          }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      )}
      {mode == "Doctor" && (
        <SelectBox
          id="personal_id_type"
          name="personal_id_type"
          value={formik.values.personal_id_type}
          error={
            formik.touched.personal_id_type &&
            Boolean(formik.errors.personal_id_type)
          }
          helperText={
            formik.touched.personal_id_type && formik.errors.personal_id_type
          }
          onchange={formik.handleChange}
          items={[
            { id: "national_id", name: t("Signup.National ID") },
            { id: "iqama", name: t("Signup.Iqama") },
          ]}
          title={t("Signup.iqama_id")}
        />
      )}
      {mode == "Doctor" && (
        <TextFieldInput
          id="personal_id"
          name="personal_id"
          placeholder={t("Signup.enter_iqama_id")}
          className="mb-20px"
          onchange={formik.handleChange}
          value={formik.values.personal_id}
          onKeyDown={(e) => {
            if (e.key === "Enter") clickEnter();
          }}
          error={
            formik.touched.personal_id && Boolean(formik.errors.personal_id)
          }
          helperText={formik.touched.personal_id && formik.errors.personal_id}
        />
      )}
      {(mode == "Login" ||
        mode == "Doctor" ||
        mode == "User" ||
        mode == "ChangePassword") && (
          <TextFieldInput
            id="password"
            name="password"
            placeholder={t("Login.password")}
            className="mb-20px"
            type="password"
            onchange={formik.handleChange}
            value={formik.values.password}
            onKeyDown={(e) => {
              if (e.key === "Enter") clickEnter();
              if (e.keyCode === 32) e.preventDefault();
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        )}

      {(mode == "User" || mode == "Doctor" || mode == "ChangePassword") && (
        <TextFieldInput
          id="confirm_password"
          name="confirm_password"
          placeholder={t("Signup.confirm_password")}
          className="mb-30px"
          type="password"
          onchange={formik.handleChange}
          value={formik.values.confirm_password}
          onKeyDown={(e) => {
            if (e.key === "Enter") clickEnter();
            if (e.keyCode === 32) e.preventDefault();
          }}
          error={
            formik.touched.confirm_password &&
            Boolean(formik.errors.confirm_password)
          }
          helperText={
            formik.touched.confirm_password && formik.errors.confirm_password
          }
        />
      )}
      {mode == "Doctor2" && (
        <SelectBox
          id="medical_title"
          name="medical_title"
          value={formik.values.medical_title}
          error={
            formik.touched.medical_title && Boolean(formik.errors.medical_title)
          }
          helperText={
            formik.touched.medical_title && formik.errors.medical_title
          }
          onchange={formik.handleChange}
          items={medicalTitlesList}
          title={t("DoctorProfile.medical_title")}
        />
      )}
      {mode == "Doctor2" && (
        <SelectBox
          id="speciality"
          name="speciality"
          value={formik.values.speciality}
          error={formik.touched.speciality && Boolean(formik.errors.speciality)}
          helperText={formik.touched.speciality && formik.errors.speciality}
          onchange={formik.handleChange}
          onclick={(value) => whenSpecialityChange(value)}
          items={specialities}
          title={t("DoctorProfile.medical_specialty")}
        />
      )}
      {mode == "Doctor2" && (
        <SelectBox
          id="sub_speciality"
          name="sub_speciality"
          title={t("DoctorProfile.accurate_specialty")}
          className="mb-20px"
          value={formik.values.sub_speciality}
          onchange={formik.handleChange}
          items={subSpecialities}
        />
      )}
      {mode == "Doctor2" && (
        <TextFieldInput
          id="saudi_commission_registration_number"
          name="saudi_commission_registration_number"
          placeholder={t("DoctorProfile.commission_id")}
          className="mb-20px"
          onchange={formik.handleChange}
          value={formik.values.saudi_commission_registration_number}
          onKeyDown={(e) => {
            if (e.key === "Enter") clickEnter();
          }}
          error={
            formik.touched.saudi_commission_registration_number &&
            Boolean(formik.errors.saudi_commission_registration_number)
          }
          helperText={
            formik.touched.saudi_commission_registration_number &&
            formik.errors.saudi_commission_registration_number
          }
        />
      )}

      {mode == "Doctor2" && (
        <Grid container justifyContent="flex-start" direction="column">
          <Grid item container justifyContent="flex-start">
            <Typography variant="p" style={{ marginBottom: "11px" }}>
              {t("DoctorProfile.certificates")}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" mb={1}>
            <FileInput
              btnStyle={"input-btn"}
              icon={icons.cameraIcon}
              change={(event) => {
                onImageChange(event);
              }}
              accept="image/*,application/pdf"
            />
          </Grid>
          <Grid item container>
            <ImageList fullWidth sx={{ height: 120 }} cols={5} rowHeight={120}>
              {certificateList?.map((item, index) => (
                <ImageListItem key={index} id={item}>
                  <img
                    src={item.includes("pdf") ? `${icons.DownloadPDF}?w=164&h=164&fit=crop&auto=format` : `${item}?w=164&h=164&fit=crop&auto=format`}
                    // srcSet={`${item}`}
                    loading="lazy"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (item.includes("pdf")) window.location.href = item
                      else openViewImage(item)
                    }}
                  />
                  <ImageListItemBar
                    sx={{
                      background:
                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    position="top"
                    actionIcon={
                      <IconButton
                        style={{ padding: '0' }}
                        sx={{ color: 'white' }}
                        aria-label={`star remove`}
                        onClick={() => {
                          let arr = certificateList
                          arr.splice(index, 1)
                          setCertificateList([...arr])
                        }}
                      >
                        <img src={icons.remove} loading="lazy" />
                      </IconButton>
                    }
                    actionPosition="left"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          {certificatesListError &&
            <Grid item container>
              <span
                style={{
                  fontSize: "13px",
                  color: "#d32f2f"
                }}
              >
                {t("Validation.certificates")}
              </span>
            </Grid>
          }
        </Grid>
      )}

      <ViewCheckup
        t={t}
        open={openViewImageModal}
        handleClose={handleCloseViewImage}
        image={image}
      />

      <FormSubmit
        t={t}
        mode={mode}
        setMode={setMode}
        values={formik.values}
        errors={formik.errors}
        handleSubmit={formik.handleSubmit}
        certificates={certificateList}
        policy={policy}
        terms={terms}
        doctorCountryCode={doctorCountryCode}
      />
    </Grid>
  );
};

export default withTranslation()(Form);
