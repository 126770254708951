import { useState, useEffect } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Box } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'
import moment from "moment-hijri"
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js"
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput"
import { icons } from "../../../../Assets/images/IconHelper"

const AddEditSurgery = ({ t, open, handleClose, type, item, createSurgery, editSurgery }) => {

    const [date, setDate] = useState()
    const [error, setError] = useState(false)

    // const validateDate = () => {
    //     if (date === undefined) return t("Validation.required_field")
    //     else return null
    // }
    const setDateState = () => {
        type === 'edit' ? setDate(moment(item?.date)) : setDate(undefined)
    }

    useEffect(() => {
        setDateState()
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    width: '100%'
                }
            }}
            style={{ direction: localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr' }}
        >
            <DialogTitle>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={11}
                        sx={{ justifyContent: 'flex-start', alignContent: 'center' }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {t("Surgeries.add_surgery")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <PrimaryButton
                            type='icon'
                            startIcon={<CancelIcon sx={{ color: 'var(--primary-color)' }} />}
                            click={handleClose} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <Formik
                initialValues={{
                    surgery_name: item?.surgery_name,
                    description: item?.description,

                }}
                validationSchema={
                    Yup.object().shape({
                        surgery_name: Yup.string().required(t("Validation.required_field"))
                    })
                }
                onSubmit={(values) => {
                    if (date !== undefined) {
                        const data = {
                            surgery: {
                                surgery_name: values.surgery_name,
                                date: date.locale("en").format("DD/MM/YYYY"),
                                description: values.description
                            }
                        }
                        type === 'add' ?
                            createSurgery(data) :
                            editSurgery(item?.id, data)
                        handleClose()
                    } else {
                        setError(true)
                    }
                }}
            >
                {({ isSubmitting, submitForm, errors, isValid }) => (
                    <Form>
                        <DialogContent >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    style={{ marginBottom: 30 }}
                                >
                                    <Field
                                        as={TextFieldInput}
                                        placeholder={t("Surgeries.surgery_name")}
                                        name='surgery_name'
                                        id='surgery_name'
                                    />
                                    {errors.surgery_name ? <Box sx={{
                                        color: 'red',
                                        paddingTop: '5px'
                                    }}>{errors.surgery_name}</Box> : null}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    style={{ marginBottom: 24 }}
                                >
                                    <Field
                                        as={BasicDatePicker}
                                        selected={date}
                                        change={e => {
                                            setDate(e)
                                            setError(false)
                                        }}
                                        placeholderText={t("Surgeries.operation_date")}
                                        icon={icons.Calendar}
                                    />
                                    {error ? <Box sx={{
                                        color: 'red',
                                        paddingTop: '5px'
                                    }}>{t("Validation.required_field")}</Box> : null}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    sx={{ height: 140 }}
                                >
                                    <Field
                                        as={TextFieldInput}
                                        multiline={true}
                                        className='textarea'
                                        placeholder={t("AddDiagnosisModal.remarks")}
                                        name='description'
                                        id='description'
                                        rows={5}
                                        inputProps={{
                                            style: {
                                                position: 'absolute',
                                                top: '10px',
                                                width: '95%'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    mt={1}
                                    sx={{ justifyContent: 'flex-start', alignContent: 'center' }}>
                                    <Typography variant="caption">
                                        {t("Surgeries.remark")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    sx={{
                                        justifyContent: 'flex-start', alignContent: 'center',
                                        marginTop: '109px'
                                    }}
                                >
                                    <Typography variant="caption" sx={{ color: 'var(--secondary-color)' }}>
                                        {t("AddPrescriptionModal.required_fields")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                justifyContent: 'center',
                                marginBottom: '30px'
                            }}>
                            <PrimaryButton
                                type='submit'
                                text={t("DoctorProfile.save_btn")}
                                classBtn='primary btn-rounded'
                                style={{ maxWidth: '300px' }}
                                click={submitForm}
                                disabled={!isValid}
                            />
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    )
}

export default AddEditSurgery