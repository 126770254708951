import { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"
import SelectBox from "../../../Atoms/Select/Select"

function EditLifeStyleModal({ t, open, handleClose, questions, saveAnswers, answers }) {

    const [oldAnswers, setOldAnswers] = useState([])
    const [newAnswers, setNewAnswers] = useState([])

    const onSubmit = async (e) => {
        e.preventDefault()
        const backEndArray = newAnswers.map(obj => {
            return {
                "lifestyle_question_id": obj.lifestyle_question_id,
                "lifestyle_option_id": obj.id 
            }
        })
        await saveAnswers({ "lifestyle": backEndArray })
        handleClose()
    }
    
    const initializeAnswers = () => {
        const array = answers?.map((item) => {
            return {
                lifestyle_question_id: item?.id,
                id: item?.user_answer?.lifestyle_option_id
            }
        })
        setOldAnswers(array)
        setNewAnswers([])
    }

    useEffect(() => {
        initializeAnswers()
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    width: '800px'
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}
        >
            <DialogTitle>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={11}
                        sx={{ justifyContent: 'flex-start', alignContent: 'center' }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {t("LifeStyle.edit_lifestyle")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        sx={{ justifyContent: 'flex-end', alignContent: 'center' }}
                    >
                        <PrimaryButton
                            type='icon'
                            startIcon={<CancelIcon sx={{ color: 'var(--primary-color)' }} />}
                            click={handleClose} />

                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <Grid container>
                    {questions?.length !== 0 && questions?.map((item, i) => {
                        return (
                            !item?.is_hidden && <Grid
                                item
                                xs={12}
                                mt={1.5}
                                mb={1}
                                container
                            >
                                <SelectBox
                                    label={item?.question}
                                    labelStyle='field-label'
                                    items={item?.options}
                                    name={item?.question}
                                    id={item?.id}
                                    value={oldAnswers?.find((answer) => answer.lifestyle_question_id
                                        === item.id)?.id}
                                    onchange={(e) => {
                                        const newState = oldAnswers.map(obj => {
                                            if (obj.lifestyle_question_id === item?.id) {
                                                return { ...obj, id: e.target.value }
                                            }
                                            return obj
                                        })
                                        setOldAnswers(newState)
                                        setNewAnswers([...newAnswers,{
                                            lifestyle_question_id : item?.id,
                                            id: e.target.value
                                        }])
                                    }}
                                />
                            </Grid>
                        )
                    }
                    )}
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'center',
                    marginTop: '37px',
                    marginBottom: '30px'
                }}>
                <PrimaryButton
                    type='submit'
                    text={t("DoctorProfile.save_btn")}
                    classBtn='primary btn-rounded'
                    style={{ maxWidth: '300px' }}
                    click={onSubmit}
                />
            </DialogActions>
        </Dialog >
    )
}

export default EditLifeStyleModal
