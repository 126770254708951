import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DoctorProfile from "../../../Components/Organisms/Home/UserHome/DoctorProfile";

const TelemedicineDoctorProfileTemplate = (props) => {
  const { t, telemedicineDoctorProfile, duration, fees, getTimeSlots, timeSlots, gePaymentInfo, medicalServices, walletInfo, packages, paymentInfo, openSuccessPage, succesPageData, openFaildPopup, handleCloseFaildPopup, createReservation, acceptOnlinePayment, offers, feesAfterDiscount, setFeesAfterDiscount } = props;
  return (
    <Grid container spacing={0}>
      <Grid item xs className="grey-bg"></Grid>
      <Grid
        container
        xl={7}
        lg={9.5}
        md={11}
        sm={12}
        xs={12}
        rowSpacing={3}
        columnSpacing={{ xs: 3 }}
        className="grey-bg tp-bt-padding main-container"
      >
        <DoctorProfile
          t={t}
          telemedicineDoctorProfile={telemedicineDoctorProfile}
          duration={duration}
          fees={fees}
          getTimeSlots={(id, filterObj) => getTimeSlots(id, filterObj)}
          timeSlots={timeSlots}
          gePaymentInfo={(id, offer) => gePaymentInfo(id, offer)}
          medicalServices={medicalServices}
          walletInfo={walletInfo}
          packages={packages}
          paymentInfo={paymentInfo}
          openSuccessPage={openSuccessPage}
          succesPageData={succesPageData}
          openFaildPopup={openFaildPopup}
          handleCloseFaildPopup={handleCloseFaildPopup}
          createReservation={createReservation}
          acceptOnlinePayment={acceptOnlinePayment}
          offers={offers}
          feesAfterDiscount={feesAfterDiscount}
          setFeesAfterDiscount={setFeesAfterDiscount}
        />
      </Grid>
      <Grid item xs className="grey-bg"></Grid>
    </Grid>
  );
};

export default withTranslation()(TelemedicineDoctorProfileTemplate);
