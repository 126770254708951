import { Button, Stack, Box, Typography, TextField, Select } from "@mui/material";
import { icons } from "../../../Assets/AssetsHelper";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import PhoneInput from "../../Atoms/Input/PhoneInput/PhoneInput";
import en from "react-phone-number-input/locale/en.json";
import SelectBox from "../../Atoms/Select/Select";
export default function DeleteAccountForm({ formik, t }) {
  console.log(formik.values);

  return (
    <form onSubmit={formik?.handleSubmit} style={{ height: "100%" }}>
      <Stack
        sx={{
          boxShadow: { xs: "none", md: "0px 0px 24px 12px rgba(194, 194, 194, 0.10)" },
          height: "100%",
          py: { xs: 0, md: 5 },
        }}
      >
        <Stack
          sx={{
            width: { xs: "100%", md: "90%" },
            mx: "auto",
            height: "100%",
          }}
          gap={{ xs: 1, md: 5 }}
        >
          <Stack alignItems={{ xs: "end", md: "center" }} gap={1}>
            <Box
              component="img"
              src={icons.DeleteAccount}
              sx={{
                width: { xs: "112px", md: "150px" },
                height: { xs: "112px", md: "150px" },
                fontSize: "25px",
              }}
            />
            <Typography sx={{ fontSize: { xs: "20px", md: "32px" }, fontFamily: "Jost", fontWeight: "500" }}>
              Delete account
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "24px" },
                lineHeight: { xs: "18px", md: "31px" },
                textAlign: { xs: "end", md: "center" },
                fontWeight: 400,
              }}
            >
              By deleting this account you will lose all your <br />
              information you will not able to use it again this <br /> can&apos;t be undone
            </Typography>
          </Stack>
          <Stack
            gap={2}
            sx={{
              ".MuiInputLabel-root": { fontSize: { xs: "16px", md: "18px" } },
              ".MuiInput-input": { color: "#000" },
            }}
          >
            <Stack>
              <PhoneInput
                id="phone"
                name="phone"
                value={formik.values.country_code}
                inputValue={formik.values.phone}
                setPhone={formik.handleChange}
                clickEnter={() => void 0}
                placeholder={t("Login.phone_number")}
                labels={en}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                onChange={(e) => formik.setValues({ ...formik?.values, country_code: e })}
              />
            </Stack>
            <TextFieldInput
              name="password"
              value={formik?.values["password"]}
              onChange={formik.handleChange}
              // error={formik.errors["password"]}
              type="password"
              placeholder={t("Login.password")}
            />
            <Typography sx={{ color: "red" }}>{formik.errors["password"]}</Typography>

            <Typography sx={{ textAlign: "center", fontSize: { xs: "12px", md: "16px" } }}>
              If you have more than one account with the above credentials and want to delete all or just one of them, please
              select the accounts you want to delete
            </Typography>
            <SelectBox
              items={[
                { id: "1", name: "All Accounts" },
                { id: "2", name: "Doctor Account" },
                { id: "3", name: "Patient Account" },
              ]}
              name="user_type"
              onchange={formik.handleChange}
              value={formik?.values?.user_type}
            />
          </Stack>
          <Button
            variant="contained"
            type="submit"
            sx={{
              bgcolor: "#55D4E5",
              "&:hover": { bgcolor: "#55D4E5" },
              width: "100%",
              py: 2,
              fontSize: "18px",
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
