import React from "react"
import { Dialog, DialogTitle, DialogContent, Grid, Typography, Divider } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

const PrescriptionDetailsModal = (props) => {
    const { t, open, handleClose, itemPrescription, medicineIntakeMethods } = props
    const medicines = itemPrescription?.prescription?.prescription_medicines || []
    // const medicines = itemPrescription?.prescription_medicines || []

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto'
                }
            }}
            style={{
                direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
            }}>
            <DialogTitle>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={11}
                        sx={{ justifyContent: 'flex-start', alignContent: 'center' }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {t("PrescriptionDetailsModal.prescription_details")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        sx={{ justifyContent: 'flex-end', alignContent: 'center' }}
                    >
                        <PrimaryButton
                            type='icon'
                            startIcon={<CancelIcon sx={{ color: 'var(--primary-color)' }} />}
                            click={handleClose} />

                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container mb={5}>
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                            {t("PrescriptionDetailsModal.diagnosis")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <Typography variant="body2" >
                            {itemPrescription?.diagnosis_name}
                        </Typography>
                    </Grid>
                    <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                            {t("DoctorProfile.desc")}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        <Typography variant="body2" >
                            {itemPrescription?.description}
                        </Typography>
                    </Grid>
                    <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    <Grid container item xs={4} >
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                            {t("AddPrescriptionModal.medicine_name")}
                        </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                            {t("AddPrescriptionModal.dosage")}
                        </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                            {t("AddPrescriptionModal.usage")}
                        </Typography>
                    </Grid>
                    {medicines?.length > 0 && medicines?.map((medicine) => {
                        return (
                            <>
                                <Grid
                                    container
                                    item
                                    xs={4}
                                >
                                    <Typography variant="body2" >
                                        {medicine.medicine_name}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={4}
                                >
                                    <Typography variant="body2" >
                                        {medicine.dosage_display}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={4}
                                >
                                    {medicine?.medicine_intake_method_id ?
                                        <Typography variant="body2" >
                                            {medicineIntakeMethods?.find(item => item.id === medicine.medicine_intake_method_id)?.name}
                                        </Typography>
                                        :
                                        <p></p>
                                    }
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default PrescriptionDetailsModal