import { withTranslation } from "react-i18next";
import Form from "../../Components/Organisms/Forms/Form";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import "./Auth.css";
import { images } from "../../Assets/images/IconHelper";
import en from "react-phone-number-input/locale/en.json";
const SigninTemplate = (props) => {
  const { t, mode, handleSubmit } = props;
  const switchLang = (lang) => {
    localStorage.setItem("lang", lang);
    window.location.reload();
  };
  return (
    <Grid container spacing={0} className="unauth_body">
      <Grid item xs></Grid>
      <Grid item md={6} className="Auth_container switch-lang">
        {props.i18n.language === "ar" ? <h4 onClick={() => switchLang("en")}>English</h4> : <h4 onClick={() => switchLang("ar")}>العربية</h4>}
        <h1 style={{ color: "#161616" }}>{t("Login.login_header")}</h1>
        <div style={{ margin: "50px 75.8px 50px 74px" }}>
          <img src={images.LoginLogo} />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form mode={mode} handleSubmit={handleSubmit} labels={en} />
        </div>
        <Grid
          container
          className="Auth_account"
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Link className="" to="/register">
            <span className="Auth_account">{t("Login.signup")}</span>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs></Grid>
    </Grid>
  );
};

export default withTranslation()(SigninTemplate);
