import { axiosFn } from "../API";

var SessionId = "";

export async function getSDKData() {
  return await axiosFn("get", '/zoom_app_credentials')
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(() => console.log("error"));
}
export async function StartSession(id) { //for doctor
  SessionId = id;
  return await axiosFn("post", `/reservations/${id}/start_session`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(() => {
      window.location.href = "/home/doctor"
    }
    );
}

export async function EndSession(id) {
  return await axiosFn("post", `/reservations/${id}/end_session`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(() => console.log("error"));
}

export async function StartEarlySession(id) {
  return await axiosFn("post", `/reservations/${id}/start_early`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(() => console.log("error"));
}

export async function AcceptCall(id) { //for patient
  return await axiosFn("get", `/reservations/${id}/accept_call`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(() => {
      window.location.href = "/user/home/main"
    });
}

function generateSignature(sdkKey, sdkSecret, meetingNumber, role) {

  const iat = Math.round((new Date().getTime() - 30000) / 1000)
  const exp = iat + 60 * 60 * 2
  const oHeader = { alg: 'HS256', typ: 'JWT' }

  const oPayload = {
    sdkKey: sdkKey,
    mn: meetingNumber,
    role: role,
    iat: iat,
    exp: exp,
    appKey: sdkKey,
    tokenExp: iat + 60 * 60 * 2
  }

  const sHeader = JSON.stringify(oHeader)
  const sPayload = JSON.stringify(oPayload)
  const signature = window.KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
  return signature
}

export async function websdkready(meetingNumber, meetingPass, username, role) {

  var meetingConfig = {
    sdkKey: "",
    meetingNumber: Number(meetingNumber),
    userName: username,
    passWord: meetingPass,
    leaveUrl: role === 0 ? "/user/home/main" : "/home/doctor", // 0 patient or 1 doctor
    role: 0,
    userEmail: "",
    signature: ""
  };

  let { res, err } = await getSDKData();

  if (res) {
    meetingConfig['sdkKey'] = res?.data?.sdk_key
    meetingConfig['signature'] = generateSignature(res?.data?.sdk_key, res?.data?.sdk_secret, meetingConfig.meetingNumber, role)
  }

  window.ZoomMtg.preLoadWasm();
  window.ZoomMtg.prepareJssdk();

  function beginJoin(signature) {
    window.ZoomMtg.init({
      leaveUrl: meetingConfig.leaveUrl,
      webEndpoint: meetingConfig.webEndpoint,
      disableCORP: !window.crossOriginIsolated, // default true
      disablePreview: true, // default false
      showMeetingHeader: false, //option
      //  disableJoinAudio: false, //optional
      //  audioPanelAlwaysOpen: false, //optional
      showPureSharingContent: false, //optional
      isSupportAV: true, //optional,
      isSupportChat: false, //optional,
      isSupportQA: false, //optional,
      isSupportPolling: false, //optional
      isSupportBreakout: false, //optional
      isSupportCC: false, //optional,
      screenShare: false, //optional,
      rwcBackup: '', //optional,
      videoDrag: false, //optional,
      sharingMode: 'both', //optional,

      success: function () {
        console.log(meetingConfig);
        console.log("signature", signature);
        window.ZoomMtg.i18n.load(meetingConfig.lang);
        window.ZoomMtg.i18n.reload(meetingConfig.lang);
        window.ZoomMtg.join({
          meetingNumber: meetingConfig.meetingNumber,
          userName: meetingConfig.userName,
          signature: meetingConfig.signature,
          sdkKey: meetingConfig.sdkKey,
          passWord: meetingConfig.passWord,
          success: function (res) {
            console.log("join meeting success");
            console.log("get attendeelist");
            window.ZoomMtg.getAttendeeslist({});
            window.ZoomMtg.getCurrentUser({
              success: function (res) {
                console.log("success getCurrentUser", res.result.currentUser);
              },
            });
          },
          error: function (res) {
            console.log(res);
          },
        });
      },
      error: function (res) {
        console.log(res);
      },
    });

    window.ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
      console.log('inMeetingServiceListener onUserJoin', data);
      setTimeout(() => {
        document.querySelector('.join-audio-by-voip__join-btn').click();
      }, 2000);
    });

    window.ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
      console.log('inMeetingServiceListener onUserLeave', data);
    });

    window.ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data) {
      console.log('inMeetingServiceListener onUserIsInWaitingRoom', data);
    });

    window.ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
      console.log('inMeetingServiceListener onMeetingStatus', data);
    });
  }

  beginJoin(meetingConfig.signature);
  /* setTimeout(() => {
    document.querySelectorAll('.zmu-btn.footer__leave-btn.ax-outline.ellipsis.zmu-btn--danger.zmu-btn__outline--blue')[0].addEventListener('click', function () {
      setTimeout(() => {
        document.querySelector('.leave-meeting-options__btn--danger').addEventListener("click", EndCall);
      }, 1000);
    })
  }, 7000);

  function EndCall() {
    if (window.confirm('Are you sure you want to end meeting for all ?')) {
      EndSession(SessionId);
      alert('End Session Fired')
    }else {
      setTimeout(() => {
        beginJoin(meetingConfig.signature);
      }, 4000);
      console.log('Begin Join Fired')
    }
  }; */

};
