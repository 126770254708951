import DoctorProfileTemplate from "../../../Templates/Main/DoctorProfile/DoctorProfile.Template.jsx";
import { getDoctorApointementsSummaryAPI } from "../../../Contexts/API/Home/doctorHome";
import { useEffect, useState } from "react";
import { getDiseasesListAPI } from "../../../Contexts/API/Lookups/DiseasesList";
import { GetWalletInfoAPI } from "../../../Contexts/API/Wallet/Wallet";

const DoctorProfile = ({ setAvatar }) => {
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [appointmentObj, setAppointmentsObj] = useState({});
  const [diseasesList, setDiseasesList] = useState([]);
  const [walletAmount, setWalletAmount] = useState('');

  const getDoctorAppointments = async (service_type) => {
    var filterObj = {
      'page_size': 10,
      'page_number': 1,
      'service_type': service_type
    }; 
    let res = await getDoctorApointementsSummaryAPI(filterObj);
    if(res && service_type === 'telemedicine') setAppointmentsObj(res?.data);
    else setAppointmentsList(res?.data);
  }

  const getDiseases = async () => {
    const res = await getDiseasesListAPI({});
    if(res) setDiseasesList(res?.data?.diseases);
  };

  const getWallet = async () => {
    const res = await GetWalletInfoAPI()
    if (res) setWalletAmount(res?.data?.wallet_amount)
  }

  useEffect(() => {
    getDiseases();
    getWallet()
  }, [])
  
  return (
    <DoctorProfileTemplate
      getDoctorAppointments={getDoctorAppointments}
      appointmentsList={appointmentsList}
      appointmentObj={appointmentObj}
      diseasesList={diseasesList}
      walletAmount={walletAmount}
      setAvatar={setAvatar}
    />
  ) 
};

export default DoctorProfile;
