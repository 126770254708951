import React from "react";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import GroupTherapyList from "../../../Molecules/DoctorProfile/TabPanels/GroupTherapy/GroupTherapyList";
import GroupTherapyForm from "../../../Molecules/DoctorProfile/TabPanels/GroupTherapy/GroupTherapyForm";
import { getGroupSessionListAPI } from "../../../../Contexts/API/DoctorProfile/groupSession";
import Loader from "../../../Atoms/Loader/Loader";

function GroupTherapy(props) {
    const { t, diseasesList } = props;
    const [viewFlag, changeView] = useState(false);
    const [therapyList, setTherapyList] = useState([]);
    const [mode, setMode] = useState('Add');
    const [itemData, setItemData] = useState({});
    const [openLoader, setLoader] = useState(false);
    const [paginationInfo, setPaginationInfo] = useState({});

    const updateAddView = (flag, action, data) => {
        if (action === 'Add' || action === 'delete' || action === 'Edit') getTherapyList('group_session', {});
        changeView(flag);
        setMode(action);
        setItemData(data);
    }
    const getTherapyList = async (service_type, filter_obj) => {
        setLoader(true);
        const res = await getGroupSessionListAPI(service_type, filter_obj);
        if (res) {
            setTherapyList(res?.data?.time_intervals);
            setPaginationInfo(res?.extra);
        }
        setLoader(false);
    }

    return (
        <div>
            <Loader open={openLoader} />
            <h3 className="mb-20px">{t("DoctorProfile.groupـtherapy")}</h3>
            {!viewFlag && (
                <GroupTherapyList
                    list={therapyList}
                    updateView={(flag, action, data) => updateAddView(flag, action, data)}
                    paginationInfo={paginationInfo}
                    getGroupSession={getTherapyList}
                />
            )}
            {viewFlag && (
                <GroupTherapyForm
                    updateView={(flag, action) => updateAddView(flag, action)}
                    mode={mode}
                    itemData={itemData}
                    diseasesList={diseasesList}
                />
            )}
        </div>
    );
}

export default withTranslation()(GroupTherapy);