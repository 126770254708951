import * as React from 'react';
import TabPanel from "../../../Atoms/Tabs/TabPanel";
import NormalTabs from "../../../Atoms/Tabs/NormalTabs";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import AppointmentsList from "../../../Molecules/Appointments/UserAppointments/AppointmentsList";
import { useSearchParams, useNavigate } from 'react-router-dom';

function AppointmentsTabs(props) {
    const { t, tabValueCallback, serviceType, appointmentsList, openInvoice, openReview, openDiagnosis } = props;
    const [searchParams, setSearchParams] = useSearchParams()
    const [value, setValue] = React.useState(searchParams.get('type') === 'past' ? 1 : 0);
    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // setSearchParams(newValue === 0 ? { type: 'upcoming' } : { type: 'past' })
        newValue === 0 ? navigate('/user/appointments?type=upcoming', { replace: true }) : navigate('/user/appointments?type=past', { replace: true })
    };

    const TabsOptions = [
        {
            id: 1,
            label: t("UserAppointments.upcoming"),
        },
        {
            id: 2,
            label: t("UserAppointments.past"),
        }
    ];

    const getTabValue = (tabValue) => {
        if (tabValue === 0) tabValue = "upcoming";
        else if (tabValue === 1) tabValue = "past"
        tabValueCallback(tabValue);
    };
    // React.useEffect(() => {
    //     getTabValue(value)
    // }, [value]);

    // React.useEffect(() => {
    //     if(searchParams.get('type') === 'past') {
    //         setValue(1)}
    //     else {
    //         setValue(0)}
    // }, []);

    return (
        <Grid container columnSpacing={{ xs: 3 }}>
            <Grid item xs={2.5}>
                <NormalTabs
                    TabsOptions={TabsOptions}
                    value={value}
                    change={handleChange}
                    orientation="vertical"
                    ContainerStyle="toggle-btns-container appointments"
                    TabStyle="toggle-btn mb-20px"
                    onclick={(tabValue) => getTabValue(tabValue)}
                />
            </Grid>
            <Grid item md={0.5}></Grid>
            <Grid item xs={9}>
                {TabsOptions.map((option, index) => (
                    <TabPanel value={value} index={index} className="appointment-tab" key={index}>
                        {index === 0 && (
                            <Grid container>
                                <AppointmentsList
                                    appointmentsType="upcoming"
                                    list={appointmentsList}
                                    serviceType={serviceType}
                                    openInvoice={openInvoice}
                                    openReview={openReview}
                                    openDiagnosis={openDiagnosis}
                                />
                            </Grid>
                        )}
                        {index === 1 && (
                            <Grid container>
                                <AppointmentsList
                                    appointmentsType="past"
                                    list={appointmentsList}
                                    serviceType={serviceType}
                                    openInvoice={openInvoice}
                                    openReview={openReview}
                                    openDiagnosis={openDiagnosis}
                                />
                            </Grid>
                        )}
                    </TabPanel>
                ))}
            </Grid>
        </Grid>
    );
}

export default withTranslation()(AppointmentsTabs);