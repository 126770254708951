import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import "./Layout.css";
import { NavLink, useNavigate } from 'react-router-dom'
import { images, icons } from "../../../Assets/images/IconHelper";
import AccountMenu from "../../Molecules/Layout/Menu/AccountMenu";
// import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import NotificationsList from "../../Molecules/Layout/Notifications/NotificationsList";

function Navbar(props) {
    const { t, notificationsList, refetchNotificationsList, notificationsCount, openLoader, updateCallStatus, avatar, UserName } = props;
    const changeLang = (lang) => {
        localStorage.setItem("lang", lang);
        window.location.reload();
    }
    const navGrid = localStorage.getItem("userType") === "medical_provider" ? 8 : 10;
    const navOutline = localStorage.getItem("userType") === "medical_provider" ? 2 : 1;
    const logoLangGrid = localStorage.getItem("userType") === "medical_provider" ? 4 : 3;
    const Navigate = useNavigate();

    return (
        <Grid container className="navbar-container">
            <Grid item xs={navOutline}></Grid>
            <Grid container item xs={navGrid}>
                <Grid
                    item
                    xs={logoLangGrid}
                    className="logo-container"
                    onClick={() => { localStorage.getItem("userType") === "medical_provider" ? Navigate("/doctor/home") : Navigate("/user/home/main") }}
                >
                    <img src={images.Logo} alt="Logo" />
                </Grid>
                {localStorage.getItem("userType") === "medical_provider" ? (
                    <Grid item xs={4} className="links-container">
                        <div>
                            <NavLink to="/doctor/home" className="nav-link">{t("Navbar.home")}</NavLink>
                            <NavLink to="/about-us" className="nav-link">{t("Navbar.about")}</NavLink>
                            <NavLink to="/contact-us" className="nav-link">{t("Navbar.contact")}</NavLink>
                        </div>
                    </Grid>
                ) : (
                    <Grid item xs={6} className="links-container">
                        <div>
                            <NavLink to="/user/home/main" className="nav-link">{t("Navbar.home")}</NavLink>
                            <NavLink to="/user/appointments" className="nav-link">{t("Navbar.appointments")}</NavLink>
                            <NavLink to="/offers" className="nav-link">{t("Navbar.offers")}</NavLink>
                            <NavLink to="/about-us" className="nav-link">{t("Navbar.about")}</NavLink>
                            <NavLink to="/contact-us" className="nav-link">{t("Navbar.contact")}</NavLink>
                        </div>
                    </Grid>
                )}
                <Grid container item xs={logoLangGrid}>
                    <Grid item xs={8}>
                        <div className="account-menu-container">
                            <AccountMenu UserAvatar={avatar} UserName={UserName} />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        className="notification-icon-container"
                    >
                        <NotificationsList
                            notificationsList={notificationsList}
                            refetchNotificationsList={refetchNotificationsList}
                            notificationsCount={notificationsCount}
                            t={t}
                            openLoader={openLoader}
                            updateCallStatus={updateCallStatus}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{ textAlign: "center" }}
                    >
                        <Typography
                            component="p"
                            className="lang-link"
                        >
                            {localStorage.getItem("lang") === "ar" ? (
                                <span onClick={() => changeLang("en")}>English</span>
                            ) : (
                                <span onClick={() => changeLang("ar")}>Arabic</span>
                            )}

                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={navOutline}></Grid>
        </Grid>
    );
}

export default withTranslation()(Navbar);