import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper";

export async function getGroupSessionList(service_type, filterObj) {
  return await axiosFn("get", URL.TIME_INTERVALS + service_type + makeFilterString(filterObj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getGroupSessionListAPI(service_type, filterObj) {
  let { err, res } = await getGroupSessionList(service_type, filterObj);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function getAvailableGroup(service_type, data) {
  return await axiosFn("get", URL.TIME_INTERVALS + service_type + '/available?day=' + data.day + '&period=' + data.period + '&medical_service[duration]=' + data.duration)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getAvailableGroupAPI(service_type, data) {
  let { err, res } = await getAvailableGroup(service_type, data);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function sendTimesIntervalsGroup(service_type, data) {
  return await axiosFn("post", URL.TIME_INTERVALS + service_type,  data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function sendTimesIntervalsGroupAPI(service_type, data) {
  let { err, res } = await sendTimesIntervalsGroup(service_type, data);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}

export async function editGroupItem(service_type, id, data) {
  return await axiosFn("patch", URL.TIME_INTERVALS + service_type + '/' + id,  data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function editGroupItemAPI(service_type, id, data) {
  let { err, res } = await editGroupItem(service_type, id, data);
  if (res) {
    return res;
  }
  if (err) {
    return err;
  }
}