import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"

// List Diagnosis
export async function getListDiagnosis(id, type, filterObj) {
    return await axiosFn("get", URL.USER_STR + id + URL.DIAGNOSES_STR + makeFilterString({ index_scope: type, page_number: filterObj.page_number, page_size: filterObj.page_size }))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getListDiagnosisAPI(id = localStorage.getItem("userId"), type = "history", filterObj) {
    let { err, res } = await getListDiagnosis(id, type, filterObj)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Show Diagnosis
export async function showDiagnosis(id, userID) {
    return await axiosFn("get", URL.USER_STR + userID + URL.DIAGNOSES_STR + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function showDiagnosisAPI(id, userID = localStorage.getItem("userId")) {
    let { err, res } = await showDiagnosis(id, userID)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Create History Diagnosis
export async function createDiagnosis(data, id) {
    return await axiosFn("post", URL.USER_STR + id + URL.DIAGNOSES_STR, data)
        .then((res) => {
            return {
                res: res.data,
                err: null
            }
        })
        .catch(handleError)
}
export async function createDiagnosisAPI(data, id = localStorage.getItem("userId")) {
    let { err, res } = await createDiagnosis(data, id)
    if (res) {
        return {
            res,
            err: null
        }
    }
    if (err) {
        return {
            res: null,
            err
        }
    }
}

// Edit History Diagnosis
export async function editDiagnosis(id, data, userID) {
    const Url = URL.USER_STR + userID + URL.DIAGNOSES_STR + id
    return await axiosFn("put", Url, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function editDiagnosisAPI(id, data, userID = localStorage.getItem("userId")) {
    let { res, err } = await editDiagnosis(id, data, userID);
    if (res) {
        return {
            res,
            err: null
        };;
    }
    if (err) {
        return {
            res: null,
            err
        };
    }
}

// Delete History Diagnosis
export async function deleteDiagnosis(id) {
    return await axiosFn("delete", URL.USER_STR + localStorage.getItem("userId") + URL.DIAGNOSES_STR + id)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function deleteDiagnosisAPI(id) {
    let { err, res } = await deleteDiagnosis(id)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}