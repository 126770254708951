import React from "react";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../../Atoms/Buttons/Primary/PrimaryButton";
import Placeholder from "../../../../../Atoms/Placeholder/Placeholder";
import { images } from "../../../../../../Assets/images/IconHelper";
import ClearIcon from '@mui/icons-material/Clear';
import { deleteIntervalAPI } from "../../../../../../Contexts/API/DoctorProfile/telemedicine";
import { groupBy } from "../../../../../../Contexts/API/Helper/Helper";
import Loader from "../../../../../Atoms/Loader/Loader";
import { convertTimeFormate, convertDayLocale } from "../../../../../../Contexts/API/Helper/Helper";
import CustomTablePagination from "../../../../../Atoms/Pagination/Pagination";

function AppointmentsList (props) {
    const { t, paginationInfo, updateAppointmentsList } = props;
    const [openLoader, setLoader] = useState(false);
    const removeInterval = async (ids) => {
        setLoader(true);
        const {res,err} = await deleteIntervalAPI('personal_telemedicine', ids);
        if (res) props.updateAddView(false, 'delete');
        setLoader(false);
    };
    const [filterObj, setFilterObj] = useState({
        'page_size': 10,
        'page_number': 1
    });
    const groupIds = (item) => {
        var grouped = groupBy(item[1], 'id');
        var group_ids = Object.keys(grouped)
        var ids =''
        group_ids.forEach(function(key){
            ids += key +','
            removeInterval(ids)
        });
    }
    const paginationChangePage = (value) => {
        setFilterObj({ ...filterObj, 'page_number': value + 1 });
    };
    const handleRowsPerPage = (value) => {
        setFilterObj({ ...filterObj, 'page_size': value });
    };
    useEffect(() => {
        updateAppointmentsList(filterObj);
    }, [filterObj])
    return (
        <Grid container>
            <Loader open={openLoader}/>
            {props.appointmentlist[0]?.length == 0 ? (
                <Grid container>
                    <Placeholder
                        src={images.EmptyPlaceholder}
                        alt="Empty Appointments"
                        mainTitle={t("Placeholders.no_appointments")}
                        txtContainer="text-container"
                    />
                </Grid>
            ) : (
                <Grid container className="appointments-items-container">
                    { props.appointmentlist[0]?.map((item,index) => (
                        <Grid container className="appointment-item mb-20px" key={index}>
                            <Grid md={4} className="appointment-day">
                                <Typography component="p">
                                {convertDayLocale(item[0])}
                                </Typography>
                            </Grid>
                            <Grid container md={8}>
                                {item[1]?.map((interval,i) => (
                                    <Grid container className="mb-20px" key={i}>
                                        <Grid xs={8} style={{textAlign: "center"}}>
                                            <Typography component="p" style={{color: "var(--font-color)"}}>
                                                {t("DoctorProfile.from") + convertTimeFormate(interval?.start_time) + t("DoctorProfile.to") + convertTimeFormate(interval?.end_time)}  
                                            </Typography>
                                        </Grid>
                                        <Grid xs={4} style={{textAlign: "end"}}>
                                            <PrimaryButton
                                                startIcon={<ClearIcon/>}
                                                btnType="icon"
                                                classBtn="clear-icon"
                                                click={() => removeInterval(interval.id)}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid md={12} style={{textAlign: "end"}}>
                                <PrimaryButton
                                    classBtn="appointment-btn"
                                    text={t("DoctorProfile.delete_all_appointments")}
                                    click={() => groupIds(item)}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    {/* {props?.appointmentlist[0]?.length !== 0 && (
                        <CustomTablePagination
                            totalCount={paginationInfo.total_count}
                            changePage={(val) => paginationChangePage(val)}
                            handleRowsPerPage={(val) => handleRowsPerPage(val)}
                            t={t} 
                        />
                    )} */}
                </Grid>
            )}
        </Grid>
    )
}
export default withTranslation()(AppointmentsList);