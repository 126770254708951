import { withTranslation } from "react-i18next";
import MainTemplate from "../../Templates/Main/Main.template.jsx";
import { getNotificationsAPI, getNotificationsCountAPI } from "../../Contexts/API/Notifications/Notifications";
import { useEffect, useState } from "react";
import { firebaseSDK } from "../../Contexts/Firebase/Firebase";
import NotificationsSound from "../../Assets/sounds/notification.mp3";
import useSound from 'use-sound';

const Main = (props) => {
  const { t, updateCallStatus } = props;
  const RealTimeDatabase = firebaseSDK.database();
  const [notificationsList, setNotificationsList] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [openLoader, setLoader] = useState(false);
  const [playNotificationsSound] = useSound(NotificationsSound);

  const fetchNotifications = async () => {
    setLoader(true);
    const res = await getNotificationsAPI();
    if(res) setNotificationsList(res?.data?.notifications);
    setLoader(false);
  };
  useEffect(async() => {
    var notificationCount = await RealTimeDatabase.ref(localStorage.getItem("UserNode") + "/notification_count")
    notificationCount.on('value', async (snapshot) => {
        const count = await snapshot.val();
        setNotificationsCount(count);
        if(count != 0){
          playNotificationsSound();
        }
      });
  }, [notificationsCount]);

  return(
    <MainTemplate 
      notificationsList={notificationsList} 
      refetchNotificationsList={fetchNotifications}
      notificationsCount={notificationsCount}
      openLoader={openLoader}
      updateCallStatus={updateCallStatus}
    />
  )
};

export default Main;
