import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import PatientTotalAmount from "../../Molecules/Wallet/PatientTotalAmount";
import WalletDetails from "../../Molecules/Wallet/WalletDetails";
import Packages from "../../Molecules/Wallet/Packages";

import "./Wallet.css";

const PatientWalletPanel = (props) => {
    const { t, walletInfo, packages, openPackages } = props;
    const [showPackage, setShowPackage] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    return (
        <Grid
            container
            xl={7}
            lg={9.5}
            md={11}
            sm={12}
            xs={12} 
            className="wallet-container tp-bt-padding" 
        >
            <Typography className="mb-30px" component="h1" style={{fontSize: "18px", fontWeight: "bold"}}>
               {t("AccountMenu.my_wallet")}
            </Typography>
            <Grid container md={12} spacing={2}>
                <Grid item sm={6}>
                    <PatientTotalAmount
                        totalAmount={walletInfo?.wallet_amount}
                        openPackages={() => setShowPackage(true)}
                        openPayment={openPayment}
                        closePayment={() => {
                            setOpenPayment(false)
                            setShowPackage(true)
                        }}
                    />
                    <Divider style={{width: "100%", margin: "10px 0"}}/>
                    <Typography className="mb-30px" component="h1" style={{fontSize: "18px"}}>
                        {t("Wallet.previous_operations")}
                    </Typography>
                    <WalletDetails actions={walletInfo?.actions}/>
                </Grid>
                <Grid item sm={6} style={{display: showPackage === true ? 'block' : 'none'}}>
                    <Packages 
                        packages={packages}
                        setOpenPayment={(flag) => {
                            setOpenPayment(flag)
                            setShowPackage(false)
                        }}
                    />
                </Grid>
                <Grid item sm={6} style={{display: openPayment === true ? 'block' : 'none'}}>
                    <Grid className="profile-panel-container mb-30px">
                        <div className="mysr-form"></div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withTranslation()(PatientWalletPanel)
