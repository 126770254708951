import React from "react";
import DatePicker from "@deskpro/react-datepicker-hijri";
import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";
import { Grid } from '@mui/material';
import "./DatePicker.css";
import moment from "moment-hijri";

export default function BasicDatePicker(props) {
  const lang = localStorage.getItem("lang");
  const onKeyDown	= (e) => e.preventDefault()
  return (
    <Grid container
      style={{ position: "relative" }}
      className="datepicker-container"
    >
      {props.label && (
        <span className={props.labelStyle}>{props.label}</span>
      )}
      {props.icon && (
        <img
          className="date-picker-icon"
          src={props.icon}
          alt="calendar-icon"
          style={{
            right: lang === "ar" ? 'unset' : '30px',
            left: lang === "ar" ? '30px' : 'unset',
          }}
        />
      )}
      <DatePicker
        locale={lang === 'ar' ? 'ar' : 'en'}
        calendar={props.calendar}
        selected={props.selected}
        onChange={props.change}
        dateFormat={lang === 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY"}
        placeholderText={props.placeholderText}
        className={"datePicker " + props.className}
        minDate={moment().subtract(85, "year")}
        maxDate={moment()}
        showMonthDropdown={props.calendar === 'hijri' ? false : true}
        showYearDropdown={props.calendar === 'hijri' ? false : true}
        dropdownMode={props.calendar === 'hijri' ? false : 'select'}
        showMonthYearDropdown={props.calendar === 'hijri' ? true : false}
        onKeyDown={onKeyDown}
      />
      {props.className === 'red-border' && (
        <span
          style={{
            fontSize: "13px",
            color: "#d32f2f",
            textAlign: "left",
            width: "97%",
            margin: "auto"
          }}
        >
          {props.errorText}
        </span>
      )}
    </Grid>
  );
}