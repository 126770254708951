import { useNavigate } from "react-router-dom";
import { getResetPasswordOTP } from "../../Contexts/API/Auth/GetResetPasswordToken";
import ForgotPasswordTemplate from "../../Templates/Auth/ForgotPassword.template.jsx";
import { CheckPhoneAPI } from "../../Contexts/API/Auth/CheckPhone";
import { toast } from "react-toastify";
import AccountTypeModal from "../../Components/Organisms/Modals/Auth/AccountTypeModal";
import { useState } from "react";

const ForgotPassword = ({ t }) => {

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userType, setUserType] = useState("");

  const handleClose = () => setOpen(false)

  const handleSubmit = async (data) => {

    localStorage.setItem("country_code", data.user.country_code);
    localStorage.setItem("phone_number", data.user.phone_number);

    const result = await CheckPhoneAPI(data);
    if (result.err) toast.error(result.err)
    else {
      if (result?.res?.data?.user_types?.length === 1) {
        const filter = {
          "code_scope": "reset_password",
          "user_type": result?.res?.data?.user_types?.[0]
        }
        const res = await getResetPasswordOTP(data, filter);
        if (res.err) toast.error(res.err);
        else {
          localStorage.setItem("OTP", res?.res?.data?.reset_password_token);
          localStorage.setItem("TYPE", result?.res?.data?.user_types?.[0]);
          navigate("/reset-password");
        }
      } else {
        if (userType === "") setOpen(true)
        else {
          const filter = {
            "code_scope": "reset_password",
            "user_type": userType
          }
          const res = await getResetPasswordOTP(data, filter);
          if (res.err) toast.error(res.err);
          else {
            localStorage.setItem("OTP", res?.res?.data?.reset_password_token);
            localStorage.setItem("TYPE", userType);
            navigate("/reset-password", { state: { type: userType } });
          }
        }
      }
    }
  };

  return (
    <>
      <ForgotPasswordTemplate
        mode={"ForgotPassword"}
        handleSubmit={handleSubmit}
      />
      <AccountTypeModal
        t={t}
        open={open}
        handleClose={handleClose}
        setUserType={setUserType}
        userType={userType}
      />
    </>
  );
};

export default ForgotPassword;
