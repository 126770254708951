import { Grid, Container, Box, Typography } from "@mui/material"
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import TextFieldInput from '../../Atoms/Input/TextField/TextFieldInput'
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton"

const ChangePasswordTab = ({ t, changePassword }) => {

    return (
        <Grid container>
            <Grid>
                <Typography
                    sx={{ marginBottom: '24px' }}
                >
                    {t("Settings.change_password")}
                </Typography>
            </Grid>
            <Formik
                initialValues={{
                    password: '',
                    newPassword: '',
                    confirmNewPassword: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        password: Yup
                            .string()
                            .trim()
                            .min(8, t("Validation.min_password"))
                            .required(t("Validation.password_required")),
                        newPassword: Yup
                            .string()
                            .trim()
                            .min(8, t("Validation.min_password"))
                            .max(20, t("Validation.max_password"))
                            .required(t("Validation.password_required")),
                        confirmNewPassword: Yup
                            .string()
                            .trim()
                            .oneOf([Yup.ref("newPassword"), null], t("Validation.match_password"))
                            .required(t("Validation.confirm_password"))
                    })
                }
                onSubmit={(values, { resetForm }) => {
                    const data = {
                        "user": {
                            "current_password": values.password,
                            "password": values.newPassword,
                            "password_confirmation": values.confirmNewPassword
                        }
                    }
                    changePassword(data)
                    resetForm()
                }}
            >
                {({ isSubmitting, submitForm, isValid, errors, touched }) => (
                    <Form>
                        <Grid container>
                            <Field
                                as={TextFieldInput}
                                name='password'
                                type="password"
                                placeholder={t("Settings.current_password")}
                            />
                            {errors.password && touched.password ?
                                <Box sx={{
                                    color: 'red',
                                    marginTop: '10px'
                                }}>
                                    {errors.password}
                                </Box> : null}
                            <Field
                                as={TextFieldInput}
                                name='newPassword'
                                type="password"
                                placeholder={t("Settings.new_password")}
                                style={{ marginTop: '16px' }}
                            />
                            {errors.newPassword && touched.newPassword ?
                                <Box sx={{
                                    color: 'red',
                                    marginTop: '10px'
                                }}>{errors.newPassword}
                                </Box> : null}
                            <Field
                                as={TextFieldInput}
                                name='confirmNewPassword'
                                type="password"
                                placeholder={t("Settings.confirm_new_password")}
                                style={{ marginTop: '16px' }}
                            />
                            {errors.confirmNewPassword && touched.confirmNewPassword ?
                                <Box sx={{
                                    color: 'red',
                                    marginTop: '10px'
                                }}>{errors.confirmNewPassword}
                                </Box> : null}
                            <Grid container mt={30} justifyContent='center'>
                                <PrimaryButton
                                    type='submit'
                                    text={t("Settings.change")}
                                    classBtn='primary btn-rounded btn-medium'
                                    click={submitForm}
                                    disabled={isSubmitting || !isValid}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}

export default ChangePasswordTab