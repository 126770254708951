import { useState } from "react"
import { Grid, Box, Container, Typography } from "@mui/material"
import en from "react-phone-number-input/locale/en.json"
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { toast } from "react-toastify"

import PhoneInput from "../../Atoms/Input/PhoneInput/PhoneInput"
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton"
import ChangePhoneModal from '../../Organisms/Modals/Settings/ChangePhoneModal'

const ChangeMobileTab = ({ t, updatePhone, sendOTP, verifyOTP, verifyPhone }) => {
    const [countryCode, setCountryCode] = useState("966")
    const [open, setOpen] = useState(false)
    
    const handleClose = () => setOpen(false)

    return (
        <Grid container >
            <Grid item xs={12}>
                <Typography
                    sx={{ marginBottom: '24px' }}
                >
                    {t("Settings.change_mobile")}
                </Typography>
            </Grid>
            <Formik
                initialValues={{
                    phone: ''
                }}
                validationSchema={
                    Yup.object().shape({
                        phone: Yup
                            .string()
                            .required(t("Validation.phone_required"))
                            .matches(
                                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                t("Validation.valid_number")
                            )
                    })
                }
                onSubmit={async (values, { resetForm }) => {
                    const data = {
                        "user": {
                            "country_code": countryCode,
                            "phone_number": values.phone
                        }
                    }
                    const { err, res } = await updatePhone(data)
                    if (res) {
                        const { err, res } = await sendOTP(data)
                        if (res) {
                            localStorage.setItem("PHONE", values.phone)
                            setOpen(true)
                            resetForm()
                        } else if (err) toast.error(err)
                    } else if (err) toast.error(err)
                }}
            >
                {({ submitForm, isValid, errors, values, handleChange, touched }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid item xs={12} >
                            <Field
                                as={PhoneInput}
                                id="phone"
                                name="phone"
                                inputValue={values.phone}
                                setPhone={handleChange}
                                //clickEnter={clickEnter}
                                placeholder={t("Login.phone_number")}
                                labels={en}
                                value={countryCode}
                                onChange={(input) => setCountryCode(input)}
                            />
                            {errors.phone && touched.phone ?
                                <Box sx={{
                                    color: 'red'
                                }}>
                                    {errors.phone}
                                </Box> : null}
                        </Grid>
                        <Grid container item xs={12} justifyContent='center' mt={30}>
                            <PrimaryButton
                                type='submit'
                                text={t("Settings.change")}
                                classBtn='primary btn-rounded btn-medium'
                                click={submitForm}
                                disabled={!isValid}
                            />
                        </Grid>
                        <ChangePhoneModal
                            t={t}
                            open={open}
                            handleClose={handleClose}
                            countryCode={countryCode}
                            verifyOTP={verifyOTP}
                            verifyPhone={verifyPhone}
                        />
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}

export default ChangeMobileTab