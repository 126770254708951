import { useEffect, useState } from "react";
import SigninTemplate from "../../Templates/Auth/Signin.Template.jsx";
import { SignInAPI } from "../../Contexts/API/Auth/Signin";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";

const Signin = (props) => {

  const { t } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  const login = async (data) => {
    let res = await SignInAPI(data);
    // window.location = "/";
    if (res.err) {
      toast.error(res.err);  //handle errors
    } else {
      if (res?.res?.data?.length === 1) {
        localStorage.setItem("TOKEN", res?.res?.data?.[0]?.extra?.access_token);
        localStorage.setItem("FirebaseTOKEN", res?.res?.data?.[0]?.extra?.firebase_token)
        let exp = res?.res?.expires_in + res?.res?.created_at;
        localStorage.setItem("EXP", exp);
        localStorage.setItem("userId", res?.res?.data?.[0]?.user?.id);
        localStorage.setItem("avatar", res?.res?.data?.[0]?.user?.avatar);
        localStorage.setItem("name", res?.res?.data?.[0]?.user?.name);
        localStorage.setItem("userType", res?.res?.data?.[0]?.user?.user_type);
        localStorage.setItem("readPopup", true);
        let url = new URL(res?.res?.data?.[0]?.user?.firebase_node_url)
        localStorage.setItem("UserNode", url.pathname.slice(1));
        window.location.href = "/"
      } else {
        let array = []
        res?.res?.data?.map(obj => {
          const account = {
            token: obj.extra.access_token,
            firebase_token: obj.extra.firebase_token,
            userId: obj.user.id,
            avatar: obj.user.avatar,
            user_type: obj.user.user_type,
            email: obj.user.email,
            name: obj.user.name,
            node_url: obj.user.firebase_node_url
          }
          array.push(account)
        })
        localStorage.setItem('Accounts', JSON.stringify(array))
        navigate("/choose-account")
      }
    }
  };

  useEffect(() => {
    if (searchParams.get("type") === 'signup') {
      // localStorage.setItem("invitationCode", searchParams.get("data_id"))
      navigate("/medical-register", { replace: true })
    }
  }, []);

  return <SigninTemplate
    t={t}
    handleSubmit={login}
    mode={"Login"}
  />;
};

export default withTranslation()(Signin);
