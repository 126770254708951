import React from "react";
import { withTranslation } from "react-i18next";
import SettingsView from "../../../Molecules/DoctorProfile/TabPanels/Telemedicine/Settings/SettingsView";
import EditSettings from "../../../Molecules/DoctorProfile/TabPanels/Telemedicine/Settings/EditSettings";
import { useState, useEffect } from "react";
import { getTelemedicineListAPI, getPriceLimitsAPI } from "../../../../Contexts/API/DoctorProfile/telemedicine";

function Settings (props) {
    const { t } = props;
    const [viewFlag, changeView] = useState(false);
    const [telemedicineData, setTelemedicineData] = useState({});
    const [priceLimits, setPriceLimits] = useState([]);
    const updateEditView = (editFlag, action, data) => {
        if (action === 'edit') getTelemedicineData();
        changeView(editFlag);
        props.handleCallback(data);
    };
    const getTelemedicineData = async () => {
        const res = await getTelemedicineListAPI("personal_telemedicine");
        if(res) setTelemedicineData(res?.data?.medical_service);
    }
    const getPriceLimits = async () => {
        const res = await getPriceLimitsAPI("personal_telemedicine");
        if(res) setPriceLimits(res?.data?.price_limits);
    }
    useEffect(() => {
        getTelemedicineData();
        getPriceLimits();
    }, {})
    
    return (
        <div>
            {!viewFlag && (
                <SettingsView 
                    data={telemedicineData} 
                    updateEditView={(editFlag, action) => updateEditView(editFlag, action)} 
                />
            )}
            {viewFlag && (
                <EditSettings 
                    data={priceLimits} 
                    updateEditView={(editFlag, action, data) => updateEditView(editFlag, action, data)}
                />
            )}
        </div>
    )
}

export default withTranslation()(Settings);