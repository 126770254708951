import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function ContactUs(data) {
    return await axiosFn("post", URL.CONTACT_US, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function ContactUsAPI(data) {
    let { err, res } = await ContactUs(data)

    if (res) return {res,err:null}

    if (err) return {res:null,err}
}
