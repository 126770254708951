import { useState, useEffect } from "react"
import { Grid, Divider, Typography } from "@mui/material"

import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton"

const AppDiagnosisDetailsCard = ({ t, id, showDiagnosis, idNumber, medicineIntakeMethods, medicineDurations, handleOpenEditDiagnosis, medicinesList }) => {

    const [diagnosis, setDiagnosis] = useState({})
    const [diagnosisId, setDiagnosisId] = useState('')
    const [doctorId, setDoctorId] = useState('')
    const [medicines, setMedicines] = useState([])
    const [isSameDoctor, setIsSameDoctor] = useState(true)
    const [canEdit, setCanEdit] = useState(true)

    const canEditDiagnosis = () => {
        const isIdMatch = doctorId.toString() === localStorage.getItem("userId")
        let isMedicinePending = false
        medicines?.forEach(medicine => {
            if (medicine.status === 'pending') isMedicinePending = true
        })
        setCanEdit(isIdMatch || isMedicinePending)
        setIsSameDoctor(isIdMatch)
    }

    useEffect(() => {
        showDiagnosis(id).then((res) => {
            const resMedicines = res?.prescription?.prescription_medicines
            setDiagnosis(res)
            setDiagnosisId(res?.id)
            if (resMedicines) setMedicines(resMedicines)
            setDoctorId(res?.medical_provider?.user_id)
        })
    }, [])

    useEffect(() => {
        canEditDiagnosis()
    }, [medicines, doctorId])

    return (
        <Grid container className="therapy-item mb-10px">
            <Grid item md={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.doctor_name")}
                </Typography>
                <Typography
                    component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {diagnosis?.medical_provider?.name}
                </Typography>
            </Grid>
            <Grid item md={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.comission_id")}
                </Typography>
                <Typography
                    component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {diagnosis?.medical_provider?.saudi_commission_registration_number}
                </Typography>
            </Grid>
            <Grid item md={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.date")}
                </Typography>
                <Typography
                    component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {diagnosis?.date}
                </Typography>
            </Grid>
            <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
            <Grid item>
                <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.patient_id")}
                </Typography>
                <Typography component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {idNumber}
                </Typography>
            </Grid>
            <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
            <Grid item>
                <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                    {t("DoctorTelemedicine.symptoms")}
                </Typography>
                {diagnosis?.symptoms?.length > 0 && diagnosis?.symptoms?.map((symptom) => {
                    return <Typography component="p"
                        style={{ color: "#848484", fontWeight: "lighter" }}>
                        -{symptom?.name}
                    </Typography>
                })}
                <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)', marginTop: '10px' }}>
                    {`${t("PrescriptionDetailsModal.diagnosis")} (${t("DoctorTelemedicine.no")} ${diagnosisId})`}
                </Typography>
                <Typography component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {diagnosis?.diagnosis_name}
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)', marginTop: '10px' }}>
                    {t("DoctorTelemedicine.diagnosis_description")}
                </Typography>
                <Typography component="p"
                    style={{ color: "#848484", fontWeight: "lighter" }}>
                    {diagnosis?.description}
                </Typography>
            </Grid>
            {medicines?.length > 0 && medicines?.map((medicine) => {
                return (
                    <>
                        <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                        <Grid container>
                            <Grid item md={4}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                    {t("DoctorTelemedicine.medicine_name")}
                                </Typography>
                                <Typography
                                    component="p"
                                    style={{ color: "#848484", fontWeight: "lighter", marginBottom: '10px' }}>
                                    {medicinesList?.find(item => item.id == Number(medicine.medicine_name))?.name }
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                    {t("DoctorTelemedicine.duration")}
                                </Typography>
                                <Typography
                                    component="p"
                                    style={{ color: "#848484", fontWeight: "lighter" }}>
                                    {medicine?.medicine_duration_id ?
                                        <Typography variant="body2" >
                                            {medicineDurations?.find(item => item.id === medicine.medicine_duration_id)?.name}
                                        </Typography>
                                        :
                                        <p></p>}
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                    {t("AddPrescriptionModal.dosage")}
                                </Typography>
                                <Typography
                                    component="p"
                                    style={{ color: "#848484", fontWeight: "lighter", marginBottom: '10px' }}>
                                    {medicine?.dosage_display}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                    {t("DoctorTelemedicine.status")}
                                </Typography>
                                <Typography
                                    component="p"
                                    style={{ color: "#848484", fontWeight: "lighter" }}>
                                    {medicine?.status}
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                    {t("AddPrescriptionModal.usage")}
                                </Typography>
                                <Typography
                                    component="p"
                                    style={{ color: "#848484", fontWeight: "lighter", marginBottom: '10px' }}>
                                    {medicine?.medicine_intake_method_id ?
                                        <Typography variant="body2" >
                                            {medicineIntakeMethods?.find(item => item.id === medicine.medicine_intake_method_id)?.name}
                                        </Typography>
                                        :
                                        <p></p>}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                                    {t("DoctorTelemedicine.doctor")}
                                </Typography>
                                <Typography
                                    component="p"
                                    style={{ color: "#848484", fontWeight: "lighter" }}>
                                    {medicine?.medical_provider_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                )
            })}
            <Divider style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
            <Grid container mb={5}>
                <Grid item md={12} mb={1}>
                    <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                        {t("DoctorTelemedicine.doctor_delegation")}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                        {t("DoctorProfile.medical_specialty")}
                    </Typography>
                    <Typography
                        component="p"
                        style={{ color: "#848484", fontWeight: "lighter" }}>
                        {diagnosis?.delegation?.speciality?.name}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                        {t("DoctorProfile.accurate_specialty")}
                    </Typography>
                    <Typography
                        component="p"
                        style={{ color: "#848484", fontWeight: "lighter" }}>
                        {diagnosis?.delegation?.subspeciality?.name}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'var(--secondary-color)' }}>
                        {t("DoctorProfile.medical_title")}
                    </Typography>
                    <Typography
                        component="p"
                        style={{ color: "#848484", fontWeight: "lighter" }}>
                        {diagnosis?.delegation?.medical_title?.name}
                    </Typography>
                </Grid>
            </Grid>
            {canEdit &&
                <Grid container justifyContent='center'>
                    <PrimaryButton
                        text={t("DoctorProfile.edit_btn")}
                        classBtn='primary btn-rounded'
                        style={{ maxWidth: '300px' }}
                        click={() => handleOpenEditDiagnosis(diagnosis, isSameDoctor)}
                        // disabled={!canEdit}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default AppDiagnosisDetailsCard