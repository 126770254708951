import { Grid, Typography, Divider } from "@mui/material";
import { withTranslation } from "react-i18next";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PlaceHolder from "../../Atoms/Placeholder/Placeholder";
import { images } from "../../../Assets/images/IconHelper";
import { ConvertToArabicNumbers } from "../../../Contexts/API/Helper/Helper";
import moment from "moment";

const WalletDetails = (props) => {
    const { t, actions } = props;
    const lang = localStorage.getItem('lang')

    return (
        <Grid container className="wallet-details-container">
            {actions?.length === 0 ? (
                <PlaceHolder
                    src={images.EmptyPlaceholder}
                    alt='Empty Box'
                />
            ) : (
                <>
                {actions?.map((action, index) => (
                    <>
                        <Grid container md={12} className="wallet-item" style={{margin: "0 15px"}}>
                            <Grid container sm={6}>
                                <Grid xs={2}>
                                    {action?.action_type === 'add' ? (
                                        <ArrowUpwardIcon style={{color: "#44db5e"}}/>
                                    ) : (
                                        <ArrowDownwardIcon style={{color: "#ff2851"}}/>
                                    )}
                                </Grid>
                                <Grid xs={10}>
                                    <Typography component="h1">
                                        {action?.action_source_display}
                                    </Typography>
                                    <Typography component="h2">
                                        {lang === 'en' ? moment(action?.date).locale("en").format("DD/MM/YYYY") : moment(action?.date).locale("ar").format("YYYY/MM/DD")}
                                    </Typography>
                                    <Typography component="p" style={{color: action?.action_type === 'add' ? '#44db5e' : '#ff2851' }}>
                                        {`${action?.action_type === 'add' ? '+' : '-'} ${lang === 'en' ? action?.amount : ConvertToArabicNumbers(action?.amount)} ${t("TelemedicineSettings.sar")}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid sm={6} style={{textAlign: "end"}}>
                                <Typography component="h1">
                                    {lang === 'en' ? action?.amount_after : ConvertToArabicNumbers(action?.amount_after) + t("TelemedicineSettings.sar")} 
                                </Typography>
                            </Grid>
                        </Grid>
                        {index < actions.length - 1 && (
                            <Divider style={{width: "100%", margin: "10px 0"}} />
                        )}
                        </>
                    ))}
                </>
            )}
        </Grid>
    )
}

export default withTranslation()(WalletDetails)