import React, { useEffect, useState } from "react";
import "./App.css";
import LocalizationProviders from "./Contexts/contextLocalization/localizationContext.js";
import Routes from "./Routes";
import { withTranslation } from "react-i18next";
import Axios from "axios";
import moment from "moment";
import "moment/locale/ar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { firebaseSDK, SignInFirebase } from "./Contexts/Firebase/Firebase";
import { getUSerReservation } from "./Contexts/API/Home/userHome";
import useSound from "use-sound";
import CallSound from "./Assets/sounds/mixkit-waiting-ringtone-1354.mp3";
import { Grid } from "@mui/material";
import CallStatus from "./Components/Atoms/FlashMessages/CallStatus";
import { acceptStartEarlyAPI, rejectStartEarlyAPI } from "./Contexts/API/Call/Call";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./services/store.js";
const App = (props) => {
  const [value, setvalue] = useState("en");
  const [localizationAr, setlocalizationAr] = useState(true);
  const RealTimeDatabase = firebaseSDK.database();
  const [call, setcall] = useState({});
  const [reservationID, setreservationID] = useState("");
  const [playCallSound] = useSound(CallSound);
  const [CallBar, setCallBar] = useState(false);
  const [CallUrl, setCallUrl] = useState("");
  const [openStartEarly, setOpenStartEarly] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("TOKEN")) {
      localStorage.setItem("USER_TYPE", "user");
    } else {
      localStorage.setItem("USER_TYPE", "guest");
    }

    if (localStorage.getItem("lang")) {
      if (localStorage.getItem("lang") === "en") {
        setlocalizationAr(false);
        setvalue("en");
        props.i18n.changeLanguage("en");
      } else {
        setlocalizationAr(true);
        setvalue("ar");
        props.i18n.changeLanguage("ar");
      }
    } else {
      localStorage.setItem("lang", "ar");
      props.i18n.changeLanguage("ar");
    }
  }, []);

  const onLanguageHandle = (event) => {
    let newLang = event;
    setvalue(newLang);
    props.i18n.changeLanguage(newLang);
    localStorage.setItem("lang", newLang);
    if (newLang === "ar") {
      setlocalizationAr(true);
      Axios.defaults.headers.common["Accept-Language"] = "ar";
      moment.locale("ar");
    } else {
      setlocalizationAr(false);
      Axios.defaults.headers.common["Accept-Language"] = "en";
      moment.locale("en");
    }
  };

  // Call handling
  const RejectCall = async () => {
    await RealTimeDatabase.ref(CallUrl).set("Rejected");
  };
  const joinToCall = async () => {
    await RealTimeDatabase.ref(CallUrl).set("started");
  };
  const updateCallStatus = () => {};
  const acceptCall = async () => {
    await RealTimeDatabase.ref(CallUrl).set("accpeted");
  };
  const acceptEarlyCall = async () => {
    const reservationRes = await getUSerReservation("upcoming", 1, 1, "telemedicine");
    const res = await acceptStartEarlyAPI(reservationRes?.data[0]?.id);
  };
  const rejectEarlyCall = async () => {
    const reservationRes = await getUSerReservation("upcoming", 1, 1, "telemedicine");
    const res = await rejectStartEarlyAPI(reservationRes?.data[0]?.id);
  };
  useEffect(async () => {
    const signInWithFirebase = await SignInFirebase();
    var presenceRef = await RealTimeDatabase.ref(localStorage.getItem("UserNode") + "/status");
    await RealTimeDatabase.ref(localStorage.getItem("UserNode") + "/status").set("online");
    presenceRef.onDisconnect().set("offline");
    if (localStorage.getItem("userType") === "patient") {
      var CallLink = await RealTimeDatabase.ref(localStorage.getItem("UserNode") + "/upcoming_appointment");
      CallLink.on("value", async (snapshot) => {
        const calllink = await snapshot.val();
        let callUrl = new URL(calllink);
        setCallUrl(callUrl.pathname.slice(1) + "/call_status");
      });
      var CallObj = await RealTimeDatabase.ref(CallUrl);
      CallObj.on("value", async (snapshot) => {
        const CallStatus = await snapshot.val();
        if (CallStatus === "started") {
          const reservationRes = await getUSerReservation("upcoming", 1, 1, "telemedicine");
          setcall(reservationRes?.data[0]?.medical_service.medical_provider);
          setreservationID(reservationRes?.data[0]?.id);
          document.querySelector(".play-call-sound").click();
          setCallBar(false);
        } else if (CallStatus === "ended") {
          setcall({});
          setreservationID("");
          setCallBar(false);
        } else if (CallStatus === "accpeted") {
          setcall({});
          setreservationID("");
          setCallBar(false);
        } else if (CallStatus === "Rejected") {
          setCallBar(true);
        } else if (CallStatus === "pending_request") {
          setOpenStartEarly(true);
        }
      });
    }
  }, [RealTimeDatabase, CallUrl]);

  return (
    <div className={localizationAr ? "ar" : "App"}>
      <LocalizationProviders localizationAr={localizationAr}>
        <ToastContainer />
        <button className="play-call-sound" onClick={() => playCallSound()} style={{ display: "none" }}></button>
        {CallBar && (
          <Grid
            container
            xl={7}
            lg={9.5}
            md={11}
            sm={12}
            xs={12}
            style={{
              margin: "auto",
              position: "relative",
            }}
          >
            <CallStatus joinToCall={joinToCall} />
          </Grid>
        )}
        <Routes
          onLanguageHandle={onLanguageHandle}
          value={value}
          call={call}
          setreservationID={setreservationID}
          reservationID={reservationID}
          RejectCall={RejectCall}
          updateCallStatus={updateCallStatus}
          acceptCall={acceptCall}
          openStartEarly={openStartEarly}
          closeStartEarly={() => setOpenStartEarly(false)}
          acceptEarlyCall={() => {
            acceptEarlyCall();
            setOpenStartEarly(false);
          }}
          rejectEarlyCall={() => {
            rejectEarlyCall();
            setOpenStartEarly(false);
          }}
        />
      </LocalizationProviders>
    </div>
  );
};

export default withTranslation()(App);
