import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import { images, icons } from "../../../../../Assets/images/IconHelper";
// import { useSearchParams } from "react-router-dom";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import { ConvertToArabicNumbers } from "../../../../../Contexts/API/Helper/Helper";

function PaymentMethod(props) {
    const { t, openPaymentPanel, walletInfo, showPackages, paymentInfo, duration, createReservation } = props;
    const lang = localStorage.getItem("lang")
    const wallet = lang === 'en' ? walletInfo?.wallet_amount : ConvertToArabicNumbers(walletInfo?.wallet_amount)
    const dur = lang === 'en' ? duration : ConvertToArabicNumbers(duration)
    const amount = lang === 'en' ? paymentInfo?.amount : ConvertToArabicNumbers(paymentInfo?.amount)
    const payOptions = [
        { optionName: t("Payment.direct_pay"), id: 1, desc: '', options: true },
        { optionName: t("AccountMenu.my_wallet"), id: 2, desc: `${'( ' + t("Payment.current_balance") + " " + wallet + t("Wallet.sar") + ' )'}`, options: false }
    ];
    const [isActive, setActive] = useState(1);
    const [walletError, setWalletError] = useState(false);
    // const [searchParams, setSearchParams] = useSearchParams();

    const selectPackage = (i, id) => {
        setActive(i);
        if (id === 2) {
            openPaymentPanel(false);
        }
    };

    const continueToPayment = () => {
        if (isActive === 0) {
            openPaymentPanel(true);
            showPackages(false);
            localStorage.setItem("paymentType", "online_payment");
            document.getElementById("amount").value = paymentInfo?.amount * 100;
            document.getElementById("publishable_key").value = paymentInfo?.publishable_key;
            window.myFunction();
        } else {
            localStorage.setItem("paymentType", "wallet");
            if (paymentInfo?.amount > walletInfo?.wallet_amount) {
                setWalletError(true);
                showPackages(true);
            } else {
                createReservation(localStorage.getItem("serviceId"))
            }
        }
    };

    const cancelPayment = () => {
        setActive(1);
        openPaymentPanel(false);
        showPackages(false);
        setWalletError(false);
    };

    return (
        <Grid container className="profile-panel-container mb-30px">
            <Grid container md={10} style={{ margin: 'auto' }} className="payment-method-info">
                <Grid className="mb-20px" container sm={12}>
                    <Grid xs={10}>
                        <Typography component="p">
                            {dur + t("TelemedicineSettings.min") + t("TelemedicineSettings.deal_with_doctor")}
                        </Typography>
                    </Grid>
                    <Grid style={{ textAlign: 'end' }} sm={2}>
                        <Typography component="p">{amount + t("Wallet.sar")}</Typography>
                    </Grid>
                </Grid>
                {/* <Grid className="mb-20px" container sm={12}>
                    <Grid xs={10}>
                        <Typography component="p">خصم ١٠٪</Typography>
                    </Grid>
                    <Grid style={{textAlign: 'end'}} sm={2}>
                        <Typography component="p">15 ر٫س</Typography>
                    </Grid>
                </Grid> */}
                <Grid className="mb-20px" container sm={12}>
                    <Grid xs={10}>
                        <Typography component="h1">{t("Payment.total")}</Typography>
                    </Grid>
                    <Grid style={{ textAlign: 'end' }} sm={2}>
                        <Typography component="h1">{amount + t("Wallet.sar")}</Typography>
                        <input id="amount" type="hidden" value="" />
                        <input id="publishable_key" type="hidden" value="" />
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            <Grid container md={10} style={{ margin: 'auto' }}>
                <Typography className="mb-30px" component="p">{t("Wallet.choose_pay")}</Typography>
                {payOptions?.map((option, index) => (
                    <React.Fragment key={index}>
                        <Grid className="mb-20px" container md={12}>
                            <Grid sm={1} style={{ position: "relative" }}>
                                <span onClick={() => selectPackage(index, option?.id)} className={index === isActive ? "package-select active-select" : "package-select"}></span>
                            </Grid>
                            <Grid sm={11}>
                                <Typography component="p">
                                    {option?.optionName} <span style={{ margin: "0 10px", fontSize: "14px", color: "var(--font-color)" }}>{option?.desc}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        {option?.options && isActive === 0 && (
                            <Grid container md={12} className="mb-20px">
                                <Typography component="p" style={{ fontSize: '13px', color: 'var(--font-color', marginBottom: '20px' }}>
                                    {t("Payment.pay_via")}
                                </Typography>
                                <Grid container md={12} className="mb-20px">
                                    <Grid xs={2}>
                                        <img src={icons.MasterCard} alt="" />
                                    </Grid>
                                    <Grid xs={2} style={{ marginTop: "-2px" }}>
                                        <img src={icons.Visa} alt="" />
                                    </Grid>
                                    <Grid xs={2}>
                                        <img src={images.MadaLogo} alt="" />
                                    </Grid>
                                </Grid>
                                <Grid container md={12} className="mb-20px">
                                    <img src={images.stcLogo} alt="" />
                                </Grid>
                                <Grid container md={12} className="mb-20px">
                                    <img src={images.ApplePay} alt="" />
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
            <Grid container md={12} className="charge-panel">
                <Grid container md={10} style={{ margin: 'auto' }}>
                    <Grid sm={8}>
                        <Typography component="p">
                            {t("Payment.choose_package")}
                        </Typography>
                    </Grid>
                    <Grid sm={4} style={{ textAlign: 'end' }}>
                        <PrimaryButton
                            classBtn="appointment-btn h-30px"
                            text={t("Wallet.recharge")}
                            click={() => {
                                showPackages(true)
                                setActive(1)
                                openPaymentPanel(false)
                                localStorage.setItem("paymentType", "wallet")
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {walletError && <Typography style={{ color: '#d32f2f', fontSize: '12px', padding: '5px 40px' }} component="span">{t("Payment.balance_not_enough")}</Typography>}
            <Grid container rowSpacing={4} columnSpacing={2} md={10} style={{ margin: 'auto' }}>
                <Grid item sm={6}>
                    <PrimaryButton
                        classBtn="settings-edit-btn"
                        text={t("TelemedicinePrivacyPopup.continue")}
                        click={() => continueToPayment()}
                    />
                </Grid>
                <Grid item sm={6}>
                    <PrimaryButton
                        classBtn="settings-cancel-btn"
                        text={t("DoctorProfile.cancel_btn")}
                        click={() => cancelPayment()}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withTranslation()(PaymentMethod);