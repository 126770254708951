import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function getAdsList() {
    return await axiosFn("get", URL.ADS )
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getAdsListAPI() {
    let { err, res } = await getAdsList()

    if (res) return res

    if (err) return err
}
