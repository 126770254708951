import { Grid, Typography } from "@mui/material"
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

import PrimaryButton from '../../../../Atoms/Buttons/Primary/PrimaryButton'

function AppDiagnosisCard(props) {
    return (<Grid container className="therapy-item mb-10px">
        <Grid item md={11}>
            <Typography component="p" style={{
                color: "#848484",
                fontWeight: "lighter"
            }}>
                {props.item?.date}
            </Typography>
            <Typography component="h1">
                {props.item?.diagnosis_name}
            </Typography>
            <Typography component="p" style={{
                color: "#848484",
                fontWeight: "lighter"
            }}>
                {props.item?.medical_provider?.name || props.item?.medical_provider_name}
            </Typography>
        </Grid>
        <Grid item md={1}>
            <PrimaryButton endIcon={props.openAppCard === props.i ? <KeyboardArrowUp sx={{
                color: "var(--primary-color)"
            }} /> : <KeyboardArrowDown sx={{
                color: "var(--primary-color)"
            }} />} click={props.handleOpenAppCard} />
        </Grid>
    </Grid>);
}
 
export default AppDiagnosisCard