import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState, useEffect } from "react";
import NormalTabs from "../../../Atoms/Tabs/NormalTabs";
import TabPanel from "../../../Atoms/Tabs/TabPanel";
import Settings from "./Settings";
import Appointments from "./Appointments";

function Telemedicine(props) {
  const { t } = props;
  const [value, setValue] = useState(0);
  const [disabledTab, setTabDisabled] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCallback = (data) => {
    if (data?.duration !== null && data?.fees_SAR !== null) {
      setTabDisabled(false);
    } else {
      setTabDisabled(true);
    }
  }
  useEffect(() => {
    handleCallback();
  }, false)
  const TabsOptions = [
    {
      label: t("TabsOptions.settings"),
      disabled: false
    },
    {
      label: t("TabsOptions.appointments"),
      disabled: disabledTab
    }
  ]
  return (
    <div>
      <h3 className="mb-20px">{t("DoctorProfile.telemedicine_settings")}</h3>
      <NormalTabs
        TabsOptions={TabsOptions}
        value={value}
        change={handleChange}
        orientation="horizontal"
        ContainerStyle="switch-container mb-20px"
        TabStyle="switch-btn"
      />
      {TabsOptions.map((option, index) => (
        <TabPanel value={value} index={index} key={index}>
          {index === 0 && (
            <Settings handleCallback={(data) => handleCallback(data)} disabledTab={disabledTab} />
          )}
          {index === 1 && (
            <Appointments />
          )}
        </TabPanel>
      ))}
    </div>
  );
}

export default withTranslation()(Telemedicine);