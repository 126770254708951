import { useState } from 'react'
import { Grid, Typography, Divider } from '@mui/material'

import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton"
import EditLifeStyleModal from "../../../../Organisms/Modals/LifeStyle/EditLifeStyleModal"

const LifeStyleTab = ({ t, questions, answers, saveAnswers }) => {
     
    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)

    return (
        <Grid>
            <Typography mt={6} mb={4} variant='subtitle1'
                sx={{ fontWeight: 'bold' }}>
                {t("LifeStyle.lifestyle")}
            </Typography>
            <Grid container>
                {answers?.length !== 0 && answers?.map((item, i) => {
                     return (
                         !item?.is_hidden && <Grid item xs={12} key={i} >
                            <Typography variant='caption' sx={{ color: 'var(--secondary-color)' }}>
                                {item.question}
                            </Typography>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                 {item?.user_answer !== null ? item?.user_answer?.lifestyle_option_value : t('LifeStyle.no_answer')}
                            </Typography>
                             {i !== answers?.length - 1 && <Divider sx={{ marginY: 2 }} />}
                        </Grid>
                    )
                }
                )}
            </Grid>
            <Grid container mt={15} justifyContent='center'>
                <PrimaryButton
                    text={t("DoctorProfile.edit_btn")}
                    classBtn='add-diagnosis-btn'
                    click={() => setOpen(true)}
                />
            </Grid>
            <EditLifeStyleModal
                t={t}
                open={open}
                handleClose={handleClose}
                questions={questions}
                saveAnswers={saveAnswers}
                answers={answers}
            />
        </Grid>
    )
}

export default LifeStyleTab