import { withTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@mui/material";
import { images } from "../../../../Assets/images/IconHelper";
import { useNavigate } from "react-router-dom";
import { ConvertToArabicNumbers } from "../../../../Contexts/API/Helper/Helper";

function HomeButtons(props) {
    const { t, profileObj, openTelemedicinePrivacy, offersList } = props;
    const navigate = useNavigate();
    const lang= localStorage.getItem("lang")
    const homeTabsOptions = [
        {
            service_type: "telemedicine",
            icon: images.TelemedicineService,
            text: t("TabsOptions.telemedicine"),
            path: "telemedicine"
        },
        {
            service_type: "clinic_service",
            icon: images.ClinicService,
            text: t("TabsOptions.clinics"),
            path: "clinic"
        },
        {
            service_type: "hospital_service",
            icon: images.HospitalService,
            text: t("TabsOptions.hospitals"),
            path: "hospital"
        },
    ];

    const navigateToService = (path) => {
        if (path === 'telemedicine' && profileObj.show_privacy_policy) {
            openTelemedicinePrivacy(true);
        } else {
            navigate(path)
        }
    };

    return (
        <Grid container>
            <Typography
                component="h1"
                style={{
                    color: "var(--secondary-color)",
                    fontWeight: "bold"
                }}
                className="mb-20px"
            >
                {t("Home.choose_service")}
            </Typography>
            <Grid container md={12} spacing={4}>
                {homeTabsOptions.map((button, i) => (
                    <Grid
                        item xs={4}
                        onClick={() => navigateToService(button.path)}
                        key={i}
                    >
                        <Grid className="user-home-btn">
                            <img src={button.icon} alt=''/>
                            <span style={{ paddingTop: "10px" }}>{button.text}</span>
                            {offersList?.[i]?.has_offers &&
                                <Box sx={{
                                    height: '30px',
                                    backgroundColor: '#e53400',
                                    position: 'absolute',
                                    top: '0px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                >
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}>
                                        {t("Login.offers")}&nbsp;{lang === 'en' ? offersList?.find(item => item.service_type === button.service_type)?.max_offer : ConvertToArabicNumbers(offersList?.find(item => item.service_type === button.service_type)?.max_offer)}&nbsp;%
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default withTranslation()(HomeButtons);