import ChooseAccountTemplate from "../../Templates/Auth/ChooseAccount.Template.jsx";

const ChooseAccountPage = (props) => {
    const { t } = props;
    const accounts = JSON.parse(localStorage.getItem("Accounts"))
    
    return (
        <ChooseAccountTemplate
            t={t}
            accounts={accounts}
        />
    );
}

export default ChooseAccountPage;