import { withTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import "./ProfilePanels.css"
import UserActionView from "../../Molecules/PatientProfile/ActionsPanels/UserActionView"

const UserActionPanel = (props) => {
    const {
        paginationInfo,
        getDiagnosisApp,
        paginationDiagnosisHistory,
        getDiagnosisHistory,
        paginationPrescriptionApp,
        paginationPrescriptionHistory,
        getPrescriptionApp,
        getPrescriptionHistory,
        analysisListPaginationInfo,
        xrayPaginationInfo,
        getAnalysisList,
        getXrayList,
        surgeriesPaginationInfo,
        getSurgeries,
        diseasesList
    } = props;
    return (
        <Grid container className="profile-actions-container">
            <Grid item xs></Grid>
            <Grid
                container
                xl={7}
                lg={9.5}
                md={11}
                sm={12}
                xs={12}
                className="actions-container"
                style={{ marginBottom: "80px" }}
            >
                <UserActionView
                    profileData={props.profileData}
                    diagnosisList={props.diagnosisList}
                    open={props.open}
                    item={props.item}
                    type={props.type}
                    openModal={props.openModal}
                    createDiagnosis={props.createDiagnosis}
                    removeDiagnosis={props.removeDiagnosis}
                    editDiagnosis={props.editDiagnosis}
                    handleClose={props.handleClose}
                    openPrescription={props.openPrescription}
                    typePrescription={props.typePrescription}
                    itemPrescription={props.itemPrescription}
                    prescriptionList={props.prescriptionList}
                    handleClosePrescription={props.handleClosePrescription}
                    openPrescriptionModal={props.openPrescriptionModal}
                    createPrescription={props.createPrescription}
                    removePrescription={props.removePrescription}
                    editPrescription={props.editPrescription}
                    medicineIntakeMethods={props.medicineIntakeMethods}
                    openPrescriptionDtails={props.openPrescriptionDtails}
                    openPrescriptionDetailsModal={props.openPrescriptionDetailsModal}
                    handleClosePrescriptionDetails={props.handleClosePrescriptionDetails}
                    medicineDosagePeriods={props.medicineDosagePeriods}
                    xrayList={props.xrayList}
                    analysisList={props.analysisList}
                    addExaminations={props.addExaminations}
                    removeExaminations={props.removeExaminations}
                    getXrayData={props.getXrayData}
                    getAnalysisData={props.getAnalysisData}
                    editExaminationDate={props.editExaminationDate}
                    isAllergic={props.isAllergic}
                    allergyList={props.allergyList}
                    createAllergy={props.createAllergy}
                    removeAllergy={props.removeAllergy}
                    surgeries={props.surgeries}
                    removeSurgery={props.removeSurgery}
                    createSurgery={props.createSurgery}
                    editSurgery={props.editSurgery}
                    questions={props.questions}
                    answers={props.answers}
                    saveAnswers={props.saveAnswers}
                    medicalFileNumber={props.medicalFileNumber}
                    appDiagnosisList={props.appDiagnosisList}
                    showDiagnosis={props.showDiagnosis}
                    idNumber={props.idNumber}
                    appPrescriptionList={props.appPrescriptionList}
                    showPrescription={props.showPrescription}
                    getPdfPrescription={props.getPdfPrescription}
                    paginationInfo={paginationInfo}
                    getDiagnosisApp={getDiagnosisApp}
                    paginationDiagnosisHistory={paginationDiagnosisHistory}
                    getDiagnosisHistory={getDiagnosisHistory}
                    paginationPrescriptionApp={paginationPrescriptionApp}
                    paginationPrescriptionHistory={paginationPrescriptionHistory}
                    getPrescriptionApp={getPrescriptionApp}
                    getPrescriptionHistory={getPrescriptionHistory}
                    analysisListPaginationInfo={analysisListPaginationInfo}
                    xrayPaginationInfo={xrayPaginationInfo}
                    getAnalysisList={getAnalysisList}
                    getXrayList={getXrayList}
                    surgeriesPaginationInfo={surgeriesPaginationInfo}
                    getSurgeries={getSurgeries}
                    diseasesList={diseasesList}
                />
            </Grid>
            <Grid item xs></Grid>
        </Grid>
    )
}

export default withTranslation()(UserActionPanel)
