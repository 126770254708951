import { withTranslation } from "react-i18next";
import NormalCarousel from "../../../../Atoms/Carousel/Carousel";
import { Grid } from "@mui/material";
import Placeholder from "../../../../Atoms/Placeholder/Placeholder";
import { images } from "../../../../../Assets/images/IconHelper";
function CertificatesView(props) {
  const { t } = props;

  return (
    <div>
      <h3 className="mb-20px">{t("DoctorProfile.certificates")}</h3>
      {props?.images?.length === 0 ? (
        <Grid container className="mb-20px">
          <Placeholder
            src={images.EmptyPlaceholder}
            alt="Empty Certificates"
            mainTitle={t("Placeholders.no_certificates")}
            txtContainer="text-container"
          />
        </Grid>
      ) : (
        <NormalCarousel
          items={props.images}
          type='cert'
        />
      )}
    </div>
  );
}

export default withTranslation()(CertificatesView);