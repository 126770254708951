import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";

function SearchPanel(props) {
    const { t } = props;
    const navigate = useNavigate();
    const navigateToResultPage = (params) => {
        navigate("result-page/" + props.searchBy + "?" + params);
    };
    return (
        <Grid container>
            <Grid md={12} container className="search-panel-container">
                <Grid xs={1.5}>
                    <img src={props.icon} alt="search icon"/>
                </Grid>
                <Grid xs={8.5} >
                    <Typography component="p" style={{lineHeight: "4", fontSize: "17px", color: "#000"}}>
                        {props.title} 
                    </Typography>
                </Grid>
                <Grid xs={2} style={{lineHeight: "4", textAlign: "center"}}>
                    <PrimaryButton 
                        text={props.btnTxt}
                        classBtn="appointment-btn h-30px"
                        click={() => navigateToResultPage(props.searchParams)}
                        style={{minWidth: "95px"}}
                    /> 
                </Grid>
            </Grid>
        </Grid>
    );
  }
  
export default withTranslation()(SearchPanel);