import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"

import AboutUsTemplate from '../../../Templates/Main/AboutUs/AboutUsTemplate'
import { getAboutUsAPI } from '../../../Contexts/API/SystemLookups/AboutUsApi.js'

const AboutUsPage = () => {

    const {t} = useTranslation()
    const [aboutUs, setAboutUs] = useState('')

    const callApi = async () => {
        const res = await getAboutUsAPI()
        if (res) setAboutUs(res)
    }

    useEffect(() => {
        callApi()
    }, [])

    return <AboutUsTemplate t={t} aboutUs={aboutUs} />

}

export default AboutUsPage