import { useState } from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import SwitchLabel from "../../Atoms/Buttons/Switch/SwitchButton";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";

const DeactivateAccount = (props) => {
    const { t, deactivateAccount } = props;
    const [switchValue, setSwitchValue] = useState(false);

    return (
        <Grid container >
            <Grid container md={9} className="mb-20px">
                <Typography component="h1">
                    {t("Settings.deactivate_account")}
                </Typography>
            </Grid>
            <Grid container md={9} className="mb-30px">
                <Typography component="p" style={{color: 'var(--font-color)', fontSize: '14px'}}>
                    {t("Settings.deactivate_txt")}
                </Typography>
            </Grid>
            <Grid container md={9}>
                <Grid sm={11}>
                    <Typography component="h1">
                        {t("Settings.deactivate_account")}
                    </Typography>
                </Grid>
                <Grid sm={1}>
                    <SwitchLabel
                        label=""
                        checked={switchValue}
                        change={(val) => setSwitchValue(val)}
                    />
                </Grid>
            </Grid>
            <Grid container mt={30} justifyContent='center'>
                <PrimaryButton
                    type='submit'
                    text={t("Settings.deactivate_account")}
                    classBtn='primary btn-rounded btn-medium'
                    click={deactivateAccount}
                    disabled={!switchValue}
                />
            </Grid>
        </Grid>
    )
}

export default DeactivateAccount