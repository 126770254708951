import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next'

import ChatBox from "../../../../Components/Organisms/Chat/ChatBox";
import PatientPersonalFile from "../../../../Components/Organisms/Telemedicine/PatientPersonalFile"
import PatientLifeStyle from "../../../../Components/Organisms/Telemedicine/PatientLifeStyle"
import PatientList from "../../../../Components/Organisms/Telemedicine/PatientList"
import MedicalFileActionView from "../../../../Components/Organisms/Telemedicine/MedicalFileActionView"
import { images } from "../../../../Assets/images/IconHelper"

const DrTelemedicineSessionTemplate = ({ 
    chatRoom, 
    handleFileInput, 
    message, 
    Chatmessages, 
    sendMessage, 
    setmessage, 
    profileData, 
    phoneNumber, 
    countryCode, 
    age, 
    birthDate, 
    answers, 
    diagnosisList, 
    isAllergic, 
    allergyList, 
    surgeries, 
    xrayList, 
    analysisList, 
    prescriptionList, 
    showPrescription, 
    idNumber, 
    medicineIntakeMethods, 
    appPrescriptionList, 
    medicineDurations, 
    appDiagnosisList, 
    showDiagnosis, 
    symptoms, 
    doctorNumber, 
    medicalTitles, 
    specialityList, 
    getSubSpecialityList, 
    createDiagnosis, 
    medicineDosagePeriods, 
    editDiagnosis, 
    reservationId ,
    Files,
    removeImage,
    list, 
    setUserID,
    serviceType,
    paginationInfo,
    getDiagnosisApp,
    paginationDiagnosisHistory,
    getDiagnosisHistory,
    paginationPrescriptionApp,
    paginationPrescriptionHistory,
    getPrescriptionApp,
    getPrescriptionHistory,
    analysisListPaginationInfo,
    xrayPaginationInfo,
    getAnalysisList,
    getXrayList,
    surgeriesPaginationInfo,
    getSurgeries,
    diseasesList,
    medicinesList,
    UserStatus
}) => {

    const { t } = useTranslation()
    const lang = localStorage.getItem("lang")

    return (
        <>
            <Grid container className="navbar-container" >
                <Grid container item md={12} justifyContent='center'>
                    <img src={images.Logo} alt="Logo" style={{ height: '40px', marginTop: '21px' }} />
                </Grid>
            </Grid>
            <Grid style={{ height: '100vh', width: '100vw', marginTop: '80px' }}>
                <Grid className="grey-bg tp-bt-padding main-container">
                    <Grid
                        xl={7.5}
                        lg={9.5}
                        md={11}
                        sm={12}
                        xs={12}
                        container
                        spacing={3}
                        className="mb-20px"
                        style={{ margin: "auto" }}
                    >
                        <Grid item md={8} id="zoom_part" style={{direction: "ltr"}}></Grid>
                        <Grid item md={4}>
                            <Grid
                                container
                                className="profile-wallet-container"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12} >
                                    <ChatBox
                                        message={message}
                                        Chatmessages={Chatmessages}
                                        sendMessage={sendMessage}
                                        setmessage={setmessage}
                                        handleFileInput={handleFileInput}
                                        chatRoom={chatRoom}
                                        Files={Files}
                                        removeImage={removeImage} 
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    {serviceType === 'personal_telemedicine' && (
                        <Grid
                            xl={7.5}
                            lg={9.5}
                            md={11}
                            sm={12}
                            xs={12}
                            container
                            spacing={3}
                            className="mb-20px"
                            style={{ margin: "auto" }}
                        >
                            <Grid item md={6}>
                                <PatientPersonalFile
                                    t={t} 
                                    lang={lang}
                                    profileData={profileData}
                                    phoneNumber={phoneNumber}
                                    countryCode={countryCode}
                                    age={age}
                                    birthDate={birthDate} />
                            </Grid>
                            <Grid item md={6}>
                                <PatientLifeStyle t={t} answers={answers} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container className="profile-actions-container">
                    <Grid item xs></Grid>
                    <Grid
                        container
                        xl={7}
                        lg={9.5}
                        md={11}
                        sm={12}
                        xs={12}
                        className="actions-container"
                        style={{ marginBottom: "80px" }}
                    >
                        {serviceType === 'group_session' && (
                            <Grid container mt={4} mb={8}>
                                <Grid item md={6} sx={{ paddingInlineEnd: '20px' }}>
                                    <PatientList
                                        t={t}
                                        list={list}
                                        setUserID={setUserID}
                                        UserStatus={UserStatus}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <Grid container mb={2}>
                                        <PatientPersonalFile
                                            t={t}
                                            lang={lang}
                                            profileData={profileData}
                                            phoneNumber={phoneNumber}
                                            countryCode={countryCode}
                                            age={age}
                                            birthDate={birthDate}
                                        />
                                    </Grid>
                                    <Grid container>
                                        <PatientLifeStyle
                                            t={t}
                                            answers={answers}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <MedicalFileActionView
                            t={t}
                            diagnosisList={diagnosisList}
                            isAllergic={isAllergic}
                            allergyList={allergyList}
                            surgeries={surgeries}
                            xrayList={xrayList}
                            analysisList={analysisList}
                            prescriptionList={prescriptionList}
                            showPrescription={showPrescription}
                            idNumber={idNumber}
                            medicineIntakeMethods={medicineIntakeMethods}
                            appPrescriptionList={appPrescriptionList}
                            medicineDurations={medicineDurations}
                            appDiagnosisList={appDiagnosisList}
                            showDiagnosis={showDiagnosis}
                            symptoms={symptoms}
                            doctorNumber={doctorNumber}
                            medicalTitles={medicalTitles}
                            specialityList={specialityList}
                            getSubSpecialityList={getSubSpecialityList}
                            createDiagnosis={createDiagnosis}
                            medicineDosagePeriods={medicineDosagePeriods}
                            editDiagnosis={editDiagnosis}
                            reservationId={reservationId}
                            paginationInfo={paginationInfo}
                            getDiagnosisApp={getDiagnosisApp}
                            paginationDiagnosisHistory={paginationDiagnosisHistory}
                            getDiagnosisHistory={getDiagnosisHistory}
                            paginationPrescriptionApp={paginationPrescriptionApp}
                            paginationPrescriptionHistory={paginationPrescriptionHistory}
                            getPrescriptionApp={getPrescriptionApp}
                            getPrescriptionHistory={getPrescriptionHistory}
                            analysisListPaginationInfo={analysisListPaginationInfo}
                            xrayPaginationInfo={xrayPaginationInfo}
                            getAnalysisList={getAnalysisList}
                            getXrayList={getXrayList}
                            surgeriesPaginationInfo={surgeriesPaginationInfo}
                            getSurgeries={getSurgeries}
                            diseasesList={diseasesList}
                            medicinesList={medicinesList}
                        />
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DrTelemedicineSessionTemplate;
