import { withTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import ServiceOptions from "../../../../Molecules/Home/UserHome/ServiceOptions";
import { useState } from "react";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate, useLocation } from "react-router-dom";

const MedicalEntitiesSettings = (props) => {
    const { t } = props;
    const [doctorGender, setDoctorGender] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const getServiceOptions = (doctorGender) => {
        setDoctorGender(doctorGender);
    };
    const navigateToSearchPage = () => {
        navigate("medical-provider-search?doctorGender=" + doctorGender);
    };
    
    return (
        <Grid container className="telemedicine-settings-container">
            <Typography component="h1">{pathnameArr[4] === 'clinic' ? t("TabsOptions.clinics") : t("TabsOptions.hospitals")}</Typography>
            <Grid container md={12} className="telemedicine-settings-body">
                <Grid xs={11} style={{margin: "auto", padding: "1.5rem 0"}}>
                    <Typography className="mb-20px" component="h2" style={{color: "var(--font-color"}}>{t("TelemedicineSettings.service_choices")}</Typography>
                    <ServiceOptions cla mode="medical_entity" getServiceOptions={(doctorGender) => getServiceOptions(doctorGender)}/>
                    <Grid xs={12} className="mb-20px">
                        <PrimaryButton 
                            classBtn="edit-btn" 
                            text={t("Signup.next")}
                            style={{maxWidth: "220px"}}
                            click={() => navigateToSearchPage()}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withTranslation()(MedicalEntitiesSettings);