import { Grid, Typography } from "@mui/material"

import { images } from "../../../Assets/images/IconHelper"

function PatientPersonalFile(props) {
    return (<Grid container style={{
        borderRadius: '12px',
        boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.1)'
    }}>
        <Grid item xs={12} className="profile-wallet-title grey-bg" style={{
            paddingInlineStart: '25px'
        }}>
            <h4>{props.t("DoctorTelemedicine.patientFile")}</h4>
        </Grid>
        <Grid item xs={12} className="profile-wallet-body" style={{
            height: '360px',
            backgroundColor: 'white',
            paddingTop: '25px',
            paddingInlineStart: '20px'
        }}>
            <Grid container>
                <Grid item>
                    <img src={props.profileData?.avatar === null || props.profileData?.avatar === '' ? images.userAvatar : props.profileData?.avatar} alt="Avatar" style={{
                        width: '90px', height: '90px'
                    }} />
                </Grid>
                <Grid item sx={{
                    marginInlineStart: '25px'
                }}>
                    <Typography variant='h5' style={{
                        fontWeight: 'bold'
                    }}>
                        {props.profileData?.name}
                    </Typography>
                    <Typography variant='subtitle1'>
                        {props.profileData?.email}
                    </Typography>
                    <Typography variant='subtitle1'>
                        {props.phoneNumber ? props.lang === 'ar' ? `${props.phoneNumber} ${props.countryCode}+` : `+${props.countryCode} ${props.phoneNumber}` : ''}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={4}>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' display='inline' sx={{
                        paddingInlineEnd: '15px'
                    }}>
                        {props.t("DoctorProfile.gender")}
                    </Typography>
                    <Typography variant='h6' display='inline' sx={{
                        fontWeight: 'bold'
                    }}>
                        {props.profileData?.gender}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' display='inline' sx={{
                        paddingInlineEnd: '15px'
                    }}>
                        {props.t("DoctorTelemedicine.medical_file_number")}
                    </Typography>
                    <Typography variant='h6' display='inline' sx={{
                        fontWeight: 'bold'
                    }}>
                        {props.profileData?.medical_profile_number}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={4}>
                <Typography variant='subtitle1' display='inline' sx={{
                    paddingInlineEnd: '15px'
                }}>
                    {props.t("DoctorTelemedicine.birth")}
                </Typography>
                <Typography variant='h6' display='inline' sx={{
                    fontWeight: 'bold'
                }}>
                    {props.birthDate ? `${props.birthDate}  (${props.age})` : ''}
                </Typography>
            </Grid>
            <Grid container mt={4}>
                <Typography variant='subtitle1' display='inline' sx={{
                    paddingInlineEnd: '15px'
                }}>
                    {props.t("UserProfile.hijri_birthdate")}
                </Typography>
                <Typography variant='h6' display='inline' sx={{
                    fontWeight: 'bold'
                }}>
                    {props.profileData?.hijri_birth_date}
                </Typography>
            </Grid>
        </Grid>
    </Grid>)
}

export default PatientPersonalFile