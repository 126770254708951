import { Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/images/IconHelper";
import { ConvertToArabicNumbers } from "../../../Contexts/API/Helper/Helper";

const PatientTotalAmount = (props) => {
    const { t, totalAmount, openPackages, openPayment, closePayment } = props;
    const lang = localStorage.getItem("lang")
    
    return (
        <Grid container className="patient-wallet-container mb-30px">
            <Grid md={6} style={{textAlign: "center"}}>
                <img src={icons.PatientWallet} />
            </Grid>
            <Grid md={6} style={{textAlign: "center"}}>
                <Typography className="mb-10px" component="h1" style={{fontSize: "20px", fontWeight: "bold", color: "var(--font-color)"}}>
                    {t("DoctorProfile.my_balance")}
                </Typography>
                <Typography className="mb-20px" component="p" style={{fontSize: "25px", fontWeight: "bold", color: "var(--secondary-color)"}}>
                    {totalAmount === undefined ? '' : `${lang === 'en' ? totalAmount : ConvertToArabicNumbers(totalAmount)} ${t("TelemedicineSettings.sar")}`}
                </Typography>
                {!openPayment &&
                    <PrimaryButton
                        text={t("Wallet.recharge")}
                        classBtn='primary btn-rounded h-40px'
                        style={{width: "fit-content", padding: "0px 20px"}}
                        click={openPackages}
                    />
                }
                {openPayment &&
                    <PrimaryButton
                        text={t("DoctorProfile.cancel_btn")}
                        classBtn='primary btn-rounded h-40px'
                        style={{width: "fit-content", padding: "0px 20px"}}
                        click={closePayment}
                    />
                }
            </Grid>
        </Grid>
    )
}

export default withTranslation()(PatientTotalAmount)