import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import "./DoctorHome.css";
import CustomCalendar from "../../../Molecules/Home/DoctorHome/Calendar";
import DoctorAppointments from "./DoctorAppointments";
import { useState, useEffect } from "react";
import moment from "moment";
import CustomTablePagination from "../../../Atoms/Pagination/Pagination";

const HomeMainPanel = (props) => {
    const { t, getDocAppointements, telemedicineList, hospitalsList, clinicsList, paginationInfo, saudiCommissionNumber } = props;
    const [date, setNewDate] = useState(moment().locale('en').format("DD/MM/YYYY"));
    const [serviceType, setServiceType] = useState('telemedicine');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const getNewDate = (newDate) => {
        setNewDate(newDate);
    };
    const getServiceType = (service_type) => {
        setServiceType(service_type);
    };
    const paginationChangePage = (value) => {
        setPage(value + 1);
    };
    const handleRowsPerPage = (value) => {
        setRowsPerPage(value);
    };
    useEffect(() => {
        getDocAppointements(date, serviceType, page, rowsPerPage);
    }, [date, serviceType, page, rowsPerPage]);

    return ( 
        <Grid container className="main-info-container">
            <Grid 
                md={12}
            >
                <CustomCalendar dateCallback={(date) => getNewDate(date)}/>
            </Grid>
            <Grid 
                container 
                md={12}
            >
                <DoctorAppointments 
                    tabCallback={(serviceType) => getServiceType(serviceType) }
                    telemedicineList={telemedicineList}
                    hospitalsList={hospitalsList}
                    clinicsList={clinicsList}
                    saudiCommissionNumber={saudiCommissionNumber}
                    updateView={() => getDocAppointements(date, serviceType, page, rowsPerPage)}
                />
            </Grid>
            {paginationInfo?.total_count > 10 && (
                <CustomTablePagination
                    totalCount={paginationInfo?.total_count}
                    changePage={(val) => paginationChangePage(val)}
                    handleRowsPerPage={(val) => handleRowsPerPage(val)}
                    t={t} 
                />
            )}
        </Grid>
    )
};

export default withTranslation()(HomeMainPanel);
