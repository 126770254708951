import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"
import { makeFilterString } from "../Helper/Helper"

// List History Allergy
export async function getListAllergy(id) {
    return await axiosFn("get", URL.USER_STR + id + URL.ALLERGIES )
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function getListAllergyAPI(id = localStorage.getItem("userId")) {
    let { err, res } = await getListAllergy(id)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Create History Allergy
export async function createAllergy(data) {
    return await axiosFn("post", URL.USER_STR + localStorage.getItem("userId") + URL.ALLERGIES, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function createAllergyAPI(data) {
    let { err, res } = await createAllergy(data)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}

// Delete History Allergy
export async function deleteAllergy(filter_obj) {
    return await axiosFn("delete", URL.USER_STR + localStorage.getItem("userId") + URL.ALLERGIES + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
export async function deleteAllergyAPI(filter_obj) {
    let { err, res } = await deleteAllergy(filter_obj)
    if (res) {
        return res
    }
    if (err) {
        return err
    }
}