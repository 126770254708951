import { Dialog, DialogContent, DialogActions, Grid } from "@mui/material"
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch"

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

const ViewCheckup = ({ t, open, handleClose, image }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    width: '450px'
                }
            }}
            style={{ direction: localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr' }}
        >
            <TransformWrapper
                initialScale={1}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <DialogContent>
                        <Grid
                            container
                            justifyContent="center"
                            mb={1}
                        >
                            <PrimaryButton
                                click={() => zoomIn()}
                                text='+'
                                classBtn='sort-btn'
                                style={{ margin: '5px' }}
                            />

                            <PrimaryButton
                                click={() => zoomOut()}
                                text='-'
                                classBtn='sort-btn'
                                style={{margin: '5px'}}
                            />
                        </Grid>
                        <Grid
                            container
                            justifyContent="center"
                        >
                            <TransformComponent>
                                <img
                                    src={image}
                                    style={{
                                        width: '350px',
                                        height: '500px'
                                    }}
                                    alt='attachment'
                                />
                            </TransformComponent>
                        </Grid>
                    </DialogContent>
                )}
            </TransformWrapper>
            <DialogActions sx={{
                justifyContent: 'center',
                marginY: '5px'
            }}>
                <PrimaryButton
                    text={t("Checkups.close")}
                    classBtn='primary btn-rounded'
                    style={{ width: '150px' }}
                    click={handleClose}
                />
            </DialogActions>
        </Dialog >
    )
}

export default ViewCheckup