import React from "react";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import CurrentPanel from "../../../../Molecules/Home/DoctorHome/AppointmentsTelemedicine/CurrentPanel";
import Placeholder from "../../../../Atoms/Placeholder/Placeholder";
import { images } from "../../../../../Assets/images/IconHelper";
import PatientsList from "../../../Modals/Patients/PatientsList";
import { firebaseSDK } from "../../../../../Contexts/Firebase/Firebase.js";

function TelemedicineAppointments(props) {
    const { t, list, saudiCommissionNumber, updateView } = props;
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [patientsList, setPatientsList] = useState([]);
    let current = [];
    let next = [];
    let upcome = [];
    var NewArr = [];
    
    const RealTimeDatabase = firebaseSDK.database();
    const [UserStatus, setUserStatus] = useState([]);

    const openPatientsList = (list) => {
        setOpen(true);
        setPatientsList(list);
    };

    useEffect(() => {
        list?.map((appointment) => {
            const userUrl = appointment?.reservations[0]?.patient_profile?.firebase_node_url
            const userNode = new URL(userUrl).pathname.slice(1);
            var statusObj = RealTimeDatabase.ref(userNode + "/status")
            statusObj.on('value', (snapshot) => {
                NewArr[userNode] = (snapshot.val());
                setUserStatus(NewArr);
            });
        })
    }, [list]);
    
    // list?.map(appointment => {
    //     appointment.reservations.map(reservation => {
    //         appointment?.can_start_call && current.push(<Grid container xs={12}>
    //             <CurrentPanel
    //                 reservationID={appointment.id}
    //                 key={reservation.id}
    //                 patient={reservation.patient_profile}
    //                 start_time={appointment?.time_interval.start_time}
    //                 end_time={appointment?.time_interval.end_time}
    //                 can_start_call={appointment?.can_start_call}
    //                 can_send_start_early_request={appointment?.can_send_start_early_request}
    //                 duration={appointment?.duration}
    //                 serviceType={reservation.transaction.service_type}
    //                 openPatientsList={openPatientsList}
    //                 list={appointment.reservations}
    //                 saudiCommissionNumber={saudiCommissionNumber}
    //             />
    //         </Grid>)
    //         appointment?.can_send_start_early_request && next.push(<Grid container xs={12}>
    //             <CurrentPanel
    //                 reservationID={appointment.id}
    //                 key={reservation.id}
    //                 patient={reservation.patient_profile}
    //                 start_time={appointment?.time_interval.start_time}
    //                 end_time={appointment?.time_interval.end_time}
    //                 can_start_call={appointment?.can_start_call}
    //                 can_send_start_early_request={appointment?.can_send_start_early_request}
    //                 duration={appointment?.duration}
    //                 serviceType={reservation.transaction.service_type}
    //                 openPatientsList={openPatientsList}
    //                 list={appointment.reservations}
    //                 saudiCommissionNumber={saudiCommissionNumber}
    //             />
    //         </Grid>)
    //         !appointment?.can_send_start_early_request && !appointment?.can_start_call && upcome.push(
    //             <Grid container xs={12}>
    //                 <CurrentPanel
    //                     reservationID={appointment.id}
    //                     key={reservation.id}
    //                     patient={reservation.patient_profile}
    //                     start_time={appointment?.time_interval.start_time}
    //                     end_time={appointment?.time_interval.end_time}
    //                     can_start_call={appointment?.can_start_call}
    //                     can_send_start_early_request={appointment?.can_send_start_early_request}
    //                     duration={appointment?.duration}
    //                     serviceType={reservation.transaction.service_type}
    //                     openPatientsList={openPatientsList}
    //                     list={appointment.reservations}
    //                     saudiCommissionNumber={saudiCommissionNumber}
    //                 />
    //             </Grid>
    //         )
    //     })
    // })
    // list?.map((appointment, index) => {
    //     appointment?.can_start_call && current.push(<Grid container xs={12}>
    //         <CurrentPanel
    //             reservationID={appointment.id}
    //             key={appointment.id}
    //             patient={appointment.reservations[0].patient_profile}
    //             start_time={appointment?.time_interval.start_time}
    //             end_time={appointment?.time_interval.end_time}
    //             can_start_call={appointment?.can_start_call}
    //             can_send_start_early_request={appointment?.can_send_start_early_request}
    //             duration={appointment?.duration}
    //             serviceType={appointment.reservations[0].transaction.service_type}
    //             openPatientsList={openPatientsList}
    //             list={appointment.reservations}
    //             saudiCommissionNumber={saudiCommissionNumber}
    //             startDateTime={appointment?.start_datetime}
    //             earlyCountDown={appointment?.early_countdown}
    //             updateView={updateView}
    //             UserStatus={UserStatus}
    //             userFirebaseNode={new URL(appointment?.reservations[0]?.patient_profile?.firebase_node_url).pathname.slice(1)}
    //         />
    //     </Grid>)
    //     appointment?.can_send_start_early_request && next.push(<Grid container xs={12}>
    //         <CurrentPanel
    //             reservationID={appointment.id}
    //             key={appointment.id}
    //             patient={appointment.reservations[0].patient_profile}
    //             start_time={appointment?.time_interval.start_time}
    //             end_time={appointment?.time_interval.end_time}
    //             can_start_call={appointment?.can_start_call}
    //             can_send_start_early_request={appointment?.can_send_start_early_request}
    //             duration={appointment?.duration}
    //             serviceType={appointment.reservations[0].transaction.service_type}
    //             openPatientsList={openPatientsList}
    //             list={appointment.reservations}
    //             saudiCommissionNumber={saudiCommissionNumber}
    //             startDateTime={appointment?.start_datetime}
    //             earlyCountDown={appointment?.early_countdown}
    //             updateView={updateView}
    //             UserStatus={UserStatus}
    //             userFirebaseNode={new URL(appointment?.reservations[0]?.patient_profile?.firebase_node_url).pathname.slice(1)}
    //         />
    //     </Grid>)
    //     !appointment?.can_send_start_early_request && !appointment?.can_start_call && upcome.push(
    //         <Grid container xs={12}>
    //             <CurrentPanel
    //                 reservationID={appointment.id}
    //                 key={appointment.id}
    //                 patient={appointment.reservations[0].patient_profile}
    //                 start_time={appointment?.time_interval.start_time}
    //                 end_time={appointment?.time_interval.end_time}
    //                 can_start_call={appointment?.can_start_call}
    //                 can_send_start_early_request={appointment?.can_send_start_early_request}
    //                 duration={appointment?.duration}
    //                 serviceType={appointment.reservations[0].transaction.service_type}
    //                 openPatientsList={openPatientsList}
    //                 list={appointment.reservations}
    //                 saudiCommissionNumber={saudiCommissionNumber}
    //                 startDateTime={appointment?.start_datetime}
    //                 earlyCountDown={appointment?.early_countdown}
    //                 updateView={updateView}
    //                 UserStatus={UserStatus}
    //                 userFirebaseNode={new URL(appointment?.reservations[0]?.patient_profile?.firebase_node_url).pathname.slice(1)}
    //                 appointments={upcome}
    //                 index={index}
    //             />
    //         </Grid>
    //     )
    // })
    list?.map((appointment, index) => {
        appointment?.can_start_call && current.push(appointment)
        appointment?.can_send_start_early_request && next.push(appointment)
        !appointment?.can_send_start_early_request && !appointment?.can_start_call && upcome.push(appointment)
    })
    
    return (
        <Grid container className="telemedicine-appointments-container">
            <>
                {list?.length === 0 ? (
                    <Grid container>
                        <Placeholder
                            src={images.EmptyPlaceholder}
                            alt="Empty Appointments"
                            mainTitle={t("Placeholders.no_appointments")}
                            txtContainer="text-container"
                        />
                    </Grid>
                ) : (
                    <Grid container>
                        {current.length > 0 && <Grid container xs={12}>
                            <Typography
                                component="p"
                                className="panel-title"
                            >
                                {t("Home.current_panel")}
                            </Typography>
                        </Grid>}
                        <Grid container xs={12}>
                            {current.map(appointment=>{
                                const serviceType = appointment.reservations[0].transaction.service_type
                                return <Grid container xs={12} key={appointment.id}>
                                    <CurrentPanel
                                        reservationID={appointment.id}
                                        key={appointment.id}
                                        patient={appointment.reservations[0].patient_profile}
                                        start_time={appointment?.time_interval.start_time}
                                        end_time={appointment?.time_interval.end_time}
                                        can_start_call={appointment?.can_start_call}
                                        can_send_start_early_request={appointment?.can_send_start_early_request}
                                        duration={appointment?.duration}
                                        serviceType={appointment.reservations[0].transaction.service_type}
                                        openPatientsList={openPatientsList}
                                        list={appointment.reservations}
                                        saudiCommissionNumber={saudiCommissionNumber}
                                        startDateTime={appointment?.start_datetime}
                                        earlyCountDown={appointment?.early_countdown}
                                        updateView={updateView}
                                        UserStatus={UserStatus}
                                        userFirebaseNode={new URL(appointment?.reservations[0]?.patient_profile?.firebase_node_url).pathname.slice(1)}
                                        panelStatus={serviceType === 'personal_telemedicine' ? "current-panel-container" : "group-panel-container"}
                                        panelInfo={serviceType === 'personal_telemedicine' ? "current-panel-info" : "group-panel-info"}
                                    />
                                </Grid>
                            })}
                        </Grid>
                        {next.length > 0 && <Grid container xs={12}>
                            <Typography
                                component="p"
                                className="panel-title"
                            >
                                {t("Home.next_panel")}
                            </Typography>
                        </Grid>}
                        <Grid container xs={12}>
                            {next.map(appointment=>{
                                const serviceType = appointment.reservations[0].transaction.service_type
                                return <Grid container xs={12} key={appointment.id}>
                                    <CurrentPanel
                                        reservationID={appointment.id}
                                        key={appointment.id}
                                        patient={appointment.reservations[0].patient_profile}
                                        start_time={appointment?.time_interval.start_time}
                                        end_time={appointment?.time_interval.end_time}
                                        can_start_call={appointment?.can_start_call}
                                        can_send_start_early_request={appointment?.can_send_start_early_request}
                                        duration={appointment?.duration}
                                        serviceType={appointment.reservations[0].transaction.service_type}
                                        openPatientsList={openPatientsList}
                                        list={appointment.reservations}
                                        saudiCommissionNumber={saudiCommissionNumber}
                                        startDateTime={appointment?.start_datetime}
                                        earlyCountDown={appointment?.early_countdown}
                                        updateView={updateView}
                                        UserStatus={UserStatus}
                                        userFirebaseNode={new URL(appointment?.reservations[0]?.patient_profile?.firebase_node_url).pathname.slice(1)}
                                        panelStatus={serviceType === 'personal_telemedicine' ? "next-panel-container" : "incoming-panel-container"}
                                        panelInfo={serviceType === 'personal_telemedicine' ? "next-panel-info" : "incoming-panel-info"}
                                    />
                                </Grid>
                            })}
                        </Grid>
                        {upcome.length > 0 && <Grid container xs={12}>
                            <Typography
                                component="p"
                                className="panel-title"
                            >
                                {t("Home.incoming_panel")}
                            </Typography>
                        </Grid>}
                        <Grid container xs={12}>
                            {upcome.map((appointment,index)=>{
                                return <Grid container xs={12} key={appointment.id}>
                                    <CurrentPanel
                                        reservationID={appointment.id}
                                        key={appointment.id}
                                        patient={appointment.reservations[0].patient_profile}
                                        start_time={appointment?.time_interval.start_time}
                                        end_time={appointment?.time_interval.end_time}
                                        can_start_call={appointment?.can_start_call}
                                        can_send_start_early_request={appointment?.can_send_start_early_request}
                                        duration={appointment?.duration}
                                        serviceType={appointment.reservations[0].transaction.service_type}
                                        openPatientsList={openPatientsList}
                                        list={appointment.reservations}
                                        saudiCommissionNumber={saudiCommissionNumber}
                                        startDateTime={appointment?.start_datetime}
                                        earlyCountDown={appointment?.early_countdown}
                                        updateView={updateView}
                                        UserStatus={UserStatus}
                                        userFirebaseNode={new URL(appointment?.reservations[0]?.patient_profile?.firebase_node_url).pathname.slice(1)}
                                        panelStatus={index === 0 ? "next-panel-container" : "incoming-panel-container"}
                                        panelInfo={index === 0 ? "next-panel-info" : "incoming-panel-info"}
                                    />
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                )
                }
                <PatientsList
                    open={open}
                    handleClose={() => setOpen(false)}
                    t={t}
                    patientsList={patientsList}
                    UserStatus={UserStatus}
                />
            </>
        </Grid>
    )
}
export default withTranslation()(TelemedicineAppointments);