import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { images } from "../../../../Assets/images/IconHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

function ReservationPanel(props) {
    const { t } = props;
    const Navigate = useNavigate();

    return (
        <Grid container onClick={() => {
            if (props.mode === 'search') props.type === 'clinic' ? Navigate('clinic') : Navigate('hospital')
        }}>
            <Grid item md={11.5} container className="reservation-panel-container">
                <Grid item xs={2}>
                    {props.mode === "search" ? (
                        <img style={{ width: "80px" }} src={images.SearchImg} alt="search icon" />
                    ) : (
                        <img src={images.ReservingDoctorCall} alt="reservation call" />
                    )}

                </Grid>
                <Grid item xs={10}>
                    {props.mode === "search" ? (
                        <div>
                            <Typography component="h1">
                                {props.title}
                            </Typography>
                            <TextFieldInput
                                type="search"
                                placeholder={t("Home.search_by_specialty")}
                                StartAdornment={<SearchIcon />}
                                className="search-input"
                                clickEnter={() => {
                                    props.type === 'clinic' ? Navigate('clinic') : Navigate('hospital')
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            <Typography component="h1">
                                {t("Home.book_doctor_call")}
                            </Typography>
                            <Typography component="p">
                                {t("Home.follow_up_with_doctor")}
                            </Typography>
                            <PrimaryButton
                                text={t("Home.book_now")}
                                classBtn="appointment-btn h-30px"
                                click={() => Navigate('telemedicine')}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withTranslation()(ReservationPanel);